import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { HealthDataStorageService } from '../../health.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { healthMembers, healthProductType, healthToggle, insuredControls, insuredDiseaseID, patterns, policyType, remarkList } from 'src/app/shared/enums/healthEnums';
import { HealthDataStorage } from '../../health.modal';
import zerotatInsuredDetailsControls from "src/assets/json/zerotat-insured-details.json";
import { FormFields } from 'src/app/shared/interface/formFieldsModal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { popupDescriptionMsg, popupHeader, popupType } from 'src/app/shared/enums/popupEnums';
import { MatSelect } from '@angular/material/select';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { CritiShieldService } from 'src/app/shared/proxy-services/criti-shield.service';
import { ZeroTatAilmentResponseStructure } from 'src/app/shared/interface/critiShield';
import { FamilyShieldService } from 'src/app/shared/proxy-services/familyshield.service';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
@Component({
  selector: 'app-zero-tat-insured-details',
  templateUrl: './zero-tat-insured-details.component.html',
  styleUrls: ['./zero-tat-insured-details.component.scss']
})
export class ZeroTatInsuredDetailsComponent implements OnInit {

  @Output() InsuredvalueEmitter = new EventEmitter<{ FormValid: boolean, stepChange: boolean }>();
  insuredControls = [];
  healthFormData: HealthDataStorage;
  buttonData = {};
  existingDisease: ZeroTatAilmentResponseStructure[] = [];
  zerotatinsuredDetailsForm: FormGroup;
  private subscription: Subscription[] = [];
  totalData = [];
  adultRelationShip = [];
  childRelationShip = [];
  isSubmit: boolean = false;
  loadPanel: boolean = false;
  preExistingError: boolean = false;
  showAdultTabs: boolean = false;
  showChildTabs: boolean = false;
  showKYCBlock: boolean = false;
  serviceError: boolean = false;
  singleMemberCategory: boolean = false;
  memberDataIndex: number = 0;
  dataSetSuccessfully: boolean = false;
  patchingDone: boolean = false;
  keyDownListener: () => void;
  relArray = [];
  tabGroup = [];
  @ViewChild('insuredPanel') insuredPanel: MatExpansionPanel;
  @Input() memberDetailsData: HealthDataStorage;
  errorPopupData: PopupModal;
  hidePED: boolean = true;
  datepickerMobile: boolean = false;
  critiShieldAdditionalFieldArray = ["remarkCs"]
  csAdditionalFieldArray = ["remarkCriti"]
  loadFormContent: boolean = false;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  adultDetails = [];
  childDetails = [];

  constructor(
    private fb: FormBuilder,
    private healthDataStorageService: HealthDataStorageService,
    public utilityService: UtilityService,
    private popupService: PopupService,
    private el: ElementRef,
    private customStepperService: CustomStepperService,
    private renderer: Renderer2,
    private critishieldService: CritiShieldService,
    private familyshieldService: FamilyShieldService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.onLoadSteps();
    if (this.quoteDetails && this.quoteDetails.Data && this.quoteDetails.Data.Members) {
      this.updateSavedQuotesInsuredData();
    }
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }

  }

  onLoadSteps() {
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.constructMemberDetails();
    this.fetchHealthAilmentList();

  }

  // IPAA-126: Update healthFormData from of saved quotes page
  updateSavedQuotesInsuredData() {
    this.healthFormData.insuredDataSet = true;
    this.isSubmit = false;
    this.dataSetSuccessfully = true;
    if (this.healthFormData.quoteFormData.subProductType == 38) {
      this.healthFormData.insuredFormData = this.quoteDetails.Data.CritiShield.insuredData;
      this.healthFormData.quoteFormData.adultDetails = this.quoteDetails.Data.CritiShield.savedQuoteData.adultDetails;
      this.healthFormData.quoteFormData.childDetails = this.quoteDetails.Data.CritiShield.savedQuoteData.childDetails;
    } else if (this.healthFormData.quoteFormData.subProductType == 23) {
      this.healthFormData.insuredFormData = this.quoteDetails.Data.FamilyShield.insuredData;
      this.healthFormData.insuredFormData.totalMemberDetails = this.healthFormData.insuredFormData.totalData;
      this.healthFormData.quoteFormData.adultDetails = this.healthFormData.quoteFormData.totalMemberData;
    }
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  // IPAA-126: Covert user title name as per id
  titleIDConvert(id: string) {
    let titleName: string;
    if (id == '0') {
      titleName = "Mrs.";
    } else if (id == '1') {
      titleName = "Mr.";
    } else if (id == '2') {
      titleName = "Ms.";
    } else {
      titleName = "Mx.";
    }
    return titleName;
  }

  constructMemberDetails() {
    this.tabGroup = [];
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      this.tabGroup.push({
        'tabLabel': 'Adult',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/adults.png",
        'memberDetails': []
      })
      this.healthFormData.quoteFormData.adultDetails.forEach((members, index) => {
        this.tabGroup[0]['memberDetails'].push({
          'label': 'Adult ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
      this.memberChanged('Adult', 0);
      this.showAdultTabs = true;
      this.showChildTabs = false;
    }
    if (this.healthFormData.quoteFormData.childDetails.length > 0) {
      let childTabIndex = this.healthFormData.quoteFormData.adultDetails.length > 0 ? 1 : 0
      this.tabGroup.push({
        'tabLabel': 'Child',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/images/child1.png",
        'memberDetails': []
      });
      this.healthFormData.quoteFormData.childDetails.forEach((members, index) => {
        this.tabGroup[childTabIndex]['memberDetails'].push({
          'label': 'Child ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
    }

    this.totalData = this.healthFormData.quoteFormData['totalMemberData'];

    if (this.healthFormData.quoteFormData.childDetails.length > 0 && this.healthFormData.quoteFormData.adultDetails.length == 0) {
      this.memberChanged('Child', 0);
      this.showChildTabs = true;
      this.showAdultTabs = false;
    }

    // Use below flag to hide label if there is only one category present either adult or child
    this.singleMemberCategory = this.healthFormData.quoteFormData.adultDetails.length == 0 || this.healthFormData.quoteFormData.childDetails.length == 0 ? true : false;
  }

  //Below api fetches all the relations
  ZeroTatRelationship() {
    this.subscription.push(this.familyshieldService.getZeroTatRelationships().subscribe({
      next: (response: any) => {
        const relationship = response.Data;
        if (this.healthFormData.quoteFormData.subProductType == 38) {
          const adultRelationship = relationship.AdultRelationShip;
          const childRelationship = relationship.ChildRelationShip;

          let adultRelatinArr = adultRelationship.filter(data => data.Id == 11 || data.Id == 13 || data.Id == 15 || data.Id == 16);
          this.adultRelationShip = adultRelatinArr.map(relations => {
            let tempRelations = {
              'label': relations.Name, 'value': relations.Id
            }
            return tempRelations;
          });

          let childRelatinArr = childRelationship.filter(data => data.Id == 10 || data.Id == 8);
          this.childRelationShip = childRelatinArr.map(relations => {
            let tempRelations = {
              'label': relations.Name, 'value': relations.Id
            }
            return tempRelations;
          });
          let adultIndex = [];
          let childIndex = [];
          this.totalData.forEach((data, index) => {
            if (data['MemberType'] == healthMembers.Adult) {
              adultIndex.push(index);
            }
            else if (data['MemberType'] == healthMembers.Child) {
              childIndex.push(index);
            }
          })

          if (adultIndex.length > 0) {
            adultIndex.forEach(data => {
              let tempControls = [...this.totalData[data].controls];
              // Find relationship control
              let relationControlIndex = tempControls.findIndex(data => data.controlName.includes('Relationship'));
              this.totalData[data].controls = [];

              let tempRelation = {
                "controlName": "applicantRelationship",
                "label": "Relationship with Applicant",
                "type": "dropdown",
                "value": this.adultRelationShip,
                "hide": false,
                "defaultValue": "",
                "placeholder": "Select Relationship",
                "disabled": false,
                "id": "i1"
              }
              tempControls.splice(relationControlIndex, 1, tempRelation);
              this.totalData[data].controls = [...tempControls]
            })
          }
          if (childIndex.length > 0) {
            childIndex.forEach(data => {
              let tempControls1 = [...this.totalData[data].controls];
              let relationControlIndex = tempControls1.findIndex(data => data.controlName.includes('Relationship'));
              this.totalData[data].controls = [];

              let tempRelation1 = {
                "controlName": "applicantRelationship",
                "label": "Relationship with Applicant",
                "type": "dropdown",
                "value": this.childRelationShip,
                "hide": false,
                "defaultValue": "",
                "placeholder": "Select Relationship",
                "disabled": false,
                "id": "i1"
              }
              tempControls1.splice(relationControlIndex, 1, tempRelation1);
              this.totalData[data].controls = [...tempControls1];
            })
          }
          this.loadFormContent = true;
        }

        else {
          this.healthFormData.quoteFormData.totalMemberData.forEach((x: any) => {
            if (x.MemberType == healthMembers.Adult) {
              let adultRelationship = relationship.AdultRelationShip;
              let relatinArr = [];
              relatinArr = adultRelationship.map((filteredItem) => { return { 'label': filteredItem.Name } });

              this.totalData.forEach((element, ind) => {
                element.controls.map(data => {
                  if (data['controlName'] == "applicantRelationship") {
                    data['value'] = relatinArr;
                  }
                })
              })
            }
          })
          this.loadFormContent = true;
        }

      }
    }))
  }
  // Below api fetches all the disease list
  fetchHealthAilmentList() {
    this.serviceError = false;
    this.subscription.push(this.critishieldService.ZeroTatPed().subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.serviceError = false;
          if (response.Data.ped.length > 0) {
            this.existingDisease = response.Data.ped;
            this.fetchInsuredControls();
          }
          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
            this.serviceError = true;
          }
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
          this.serviceError = true;
        }
      },
      error: error => {
        this.serviceError = true;
      }
    }))
  }

  // below api responsible to fetch all controls
  fetchInsuredControls() {
    this.insuredControls = zerotatInsuredDetailsControls.zerotatinsuredControlsdata;
    if (this.healthFormData.quoteFormData.subProductType === 23) {
      for (let i = 4; i < this.insuredControls.length; i++)
        this.insuredControls[i].hide = true;
    } else if (this.healthFormData.quoteFormData.subProductType === 38) {
      for (let i = 4; i < this.insuredControls.length; i++) {
        if (this.insuredControls[i]['controlName'] === 'remarkCs' || this.insuredControls[i]['controlName'] === 'remarkCriti') {
          this.insuredControls[i].hide = true;
        } else {
          this.insuredControls[i].hide = false;
        }
      }
    }
    this.buttonData = zerotatInsuredDetailsControls.buttonData;
    if (this.totalData.length > 0) {
      this.totalData.forEach((data, index) => {
        this.totalData[index].controls = this.insuredControls;
      })
      this.ZeroTatRelationship();
    }
    this.constructInsuredForm();
    this.createMemberDetails(zerotatInsuredDetailsControls.zerotatinsuredControlsdata);

  }

  constructInsuredForm() {
    this.zerotatinsuredDetailsForm = this.fb.group({
      memberDetails: this.fb.array([]),
      preExistingDisease: this.fb.array([])
    })
  }

  get memberDetails(): FormArray {
    return this.zerotatinsuredDetailsForm.get('memberDetails') as FormArray;
  }

  get preExistingDisease(): FormArray {
    return this.zerotatinsuredDetailsForm.get('preExistingDisease') as FormArray;
  }

  createMemberDetails(controls) {
    let adultLength = this.healthFormData.quoteFormData.adultDetails.length;
    let formControls = {};
    // Segregation between adult Data and Child Data
    // Adult Data segregation
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      // If adults are present
      this.healthFormData.quoteFormData.adultDetails.forEach((adultData, adultIndex) => {
        formControls = {};
        // Adding label for adult 1 and adult 2 in total Data . So it will be easier to construct adult data in html
        // Now in start by default adult will be first .So simply we can assign label positions in total data as per adult data
        this.totalData[adultIndex].memberTypeCount = healthMembers.Adult + (adultIndex + 1);

        controls.forEach((formFields, controlsIndex) => {

          // If there are below controls patch the data
          if (formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob) {
            // If adult relationship is present patch that value
            if (formFields.controlName == insuredControls.applicantRelationship) {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                adultData['adultRealtionship' + (adultIndex + 1)] !== '' ? adultData['adultRealtionship' + (adultIndex + 1)] : "",
                [this.utilityService.requiredFieldValidation()]);
            }
            // If adult dob is present patch that value
            if (this.healthFormData.quoteFormData.subProductType == 23) {
              if (formFields.controlName == insuredControls.dob) {
                formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                  adultData['dobAdult' + (adultIndex + 1)] !== '' ? this.utilityService.formatDate(adultData['InsuredDOB']) : "",
                  [this.utilityService.requiredFieldValidation()]);
              }
            } else if (formFields.controlName == insuredControls.dob) {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                adultData['dobAdult' + (adultIndex + 1)] !== '' ? this.utilityService.formatDate(adultData['dobAdult' + (adultIndex + 1)]) : "",
                [this.utilityService.requiredFieldValidation()]);
            }
          }

          else if (formFields.controlName == insuredControls.previouslyInsured || formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
            // Now in insured since we need to assign min and max date criteria
            if (formFields.controlName == insuredControls.insuredSince) {
              // As of now we are allowing users to go lot back date.
              this.totalData[adultIndex].controls[controlsIndex].minDate = '';
              this.totalData[adultIndex].controls[controlsIndex].maxDate = this.healthFormData.portabilityFormData.policyStartDate;
            }

            // If type portability is not selected we need to hide below field
            if (this.healthFormData.quoteFormData.productType !== healthProductType.Portability) {
              this.totalData[adultIndex].controls[controlsIndex].hide = true;
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl('', null);
            }
            // If type portability is selected we need to enable previously insured field
            else if (this.healthFormData.quoteFormData.productType == healthProductType.Portability) {
              if (formFields.controlName == insuredControls.previouslyInsured) {
                this.totalData[adultIndex].controls[controlsIndex].hide = false;
              }
              else if (formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
                this.totalData[adultIndex].controls[controlsIndex].hide = true;
              }
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, null);
            }
          }

          else if (formFields.controlName == insuredControls.weight || formFields.controlName == insuredControls.heightFeetInches) {
            let validationsData = [];
            if (this.healthFormData.quoteFormData.subProductType != 23) {
              if (formFields.controlName == insuredControls.weight) {
                validationsData.push(this.utilityService.requiredFieldValidation(), this.utilityService.zeroValidation(), Validators.pattern(Constants.numberPatternRegExp));
              }
              else {
                validationsData.push(this.utilityService.requiredFieldValidation(), Validators.pattern(patterns.heightFeetInches));
              }
            }
            formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, validationsData)
          }

          else {
            // 23-- fs 38 cs
            if (this.healthFormData.quoteFormData.subProductType != 23) {
              if (this.critiShieldAdditionalFieldArray.includes(formFields.controlName) && this.csAdditionalFieldArray.includes(formFields.controlName)) {
                formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
              } else {
                formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue);
              }
            }
            else if (formFields.controlName == insuredControls.fullName) {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
            }
            else {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue);
            }
          }
        })
        // patching prexisting disease for every member
        let preExistingFormControls = {};
        let tempExistingDisease = this.patchExistingDisease();
        if (tempExistingDisease.length > 0) {
          tempExistingDisease.forEach(data => {
            preExistingFormControls[(data['Id']) + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(false, [this.utilityService.requiredFieldValidation()]);
          })
          this.preExistingDisease.push(new FormGroup(preExistingFormControls));
        }
        this.totalData[adultIndex].existingDisease = tempExistingDisease;
        this.totalData[adultIndex].existingDiseaseHide = true;
        this.memberDetails.push(new FormGroup(formControls));
      })
    }

    // Child Data segregation
    if (this.healthFormData.quoteFormData.childDetails.length > 0) {
      // If childs are present
      this.healthFormData.quoteFormData.childDetails.forEach((childData, childIndex) => {
        formControls = {};
        // Adding label for child 1 and child 2 in total Data . So it will be easier to construct child data in html
        // Now once we complete adult data block we need to modify child data .
        // Child data will always be after adult data/ if adult data is not present still it will take length as 0
        let childLength = childIndex == 0 ? adultLength - childIndex : adultLength + childIndex;
        this.totalData[childLength].memberTypeCount = healthMembers.Child + (childIndex + 1);
        this.totalData[childLength].existingDiseaseHide = true;

        controls.forEach((formFields, controlsIndex) => {

          // If there are below control fields patch the data
          if (formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob) {
            // If child relationship is present patch that value
            if (formFields.controlName == insuredControls.applicantRelationship) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(
                childData['childRelationship' + (childIndex + 1)] !== '' ? childData['childRelationship' + (childIndex + 1)] : "",
                [this.utilityService.requiredFieldValidation()]);
            }
            // If child dob is present patch that value
            if (formFields.controlName == insuredControls.dob) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(
                childData['dobChild' + (childIndex + 1)] !== '' ? this.utilityService.formatDate(childData['dobChild' + (childIndex + 1)]) : "",
                [this.utilityService.requiredFieldValidation()]);
            }

          }

          else if (formFields.controlName == insuredControls.previouslyInsured || formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
            // Now in insured since we need to assign min and max date criteria
            if (formFields.controlName == insuredControls.insuredSince) {
              this.totalData[adultLength - childIndex].controls[controlsIndex].minDate = '';
              this.totalData[adultLength - childIndex].controls[controlsIndex].maxDate = this.healthFormData.portabilityFormData.policyStartDate;
            }

            // If type portability is not selected we need to hide below field
            if (this.healthFormData.quoteFormData.productType !== healthProductType.Portability) {
              this.totalData[childIndex].controls[controlsIndex].hide = true;
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl('', null);
            }
            // If type portability is selected we need to enable previously insured field
            else if (this.healthFormData.quoteFormData.productType == healthProductType.Portability) {
              if (formFields.controlName == insuredControls.previouslyInsured) {
                this.totalData[childIndex].controls[controlsIndex].hide = false;
              }
              else if (formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
                this.totalData[childIndex].controls[controlsIndex].hide = true;
              }
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, null);
            }
          }
          else if (formFields.controlName == insuredControls.weight || formFields.controlName == insuredControls.heightFeetInches) {
            let validationsData = [];
            if (formFields.controlName == insuredControls.weight) {
              validationsData.push(this.utilityService.requiredFieldValidation(), this.utilityService.zeroValidation(), Validators.pattern(Constants.numberPatternRegExp));
            }
            else {
              validationsData.push(this.utilityService.requiredFieldValidation(), Validators.pattern(patterns.heightFeetInches));
            }
            formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, validationsData)
          }
          else {
            // 23-- fs 38 cs
            if (this.healthFormData.quoteFormData.subProductType != 23) {
              if (this.critiShieldAdditionalFieldArray.includes(formFields.controlName) && this.csAdditionalFieldArray.includes(formFields.controlName)) {
                formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
              } else {
                formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue);
              }
            }
            else if (formFields.controlName == insuredControls.fullName) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
            }
            else {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue);
            }
          }
        })
        let tempExistingDisease = this.patchExistingDisease();
        // Prexisting Disease
        let preExistingFormControls = {};
        if (tempExistingDisease.length > 0) {
          tempExistingDisease.forEach(data => {
            preExistingFormControls[(data['Id']).toString() + healthMembers.Child + `${childIndex + 1}`] = new FormControl(false, [this.utilityService.requiredFieldValidation()]);
          })
          this.preExistingDisease.push(new FormGroup(preExistingFormControls));
        }
        this.totalData[childLength].existingDisease = tempExistingDisease;
        this.memberDetails.push(new FormGroup(formControls));
      })
    }

    if (this.healthFormData.insuredDataSet && this.preExistingDisease.controls.length > 0) {
      this.patchExistingValue();
    }

    else if (!this.healthFormData.insuredDataSet) {
      this.loadPanel = true;
    }

    if (this.quoteDetails && this.quoteDetails.Data) {
      this.preExistingDisease.setValue(this.quoteDetails.Data.GenericData.insuredPED);
    }
  }

  patchExistingValue() {
    if (this.healthFormData.insuredFormData.insuredMemberDetails.length > 0) {
      this.healthFormData.insuredFormData.insuredMemberDetails.forEach((data, index) => {
        let memberDetails = this.memberDetails.controls[index] as FormGroup;
        let diseaseControls = this.preExistingDisease.controls[index] as FormGroup;

        // keys present in insuredMemberDetails is very much similar to insured member controls
        // Relationship, dob and pneumoccal dob should not be patched from insured member details
        // It should be patched from createMember Details

        for (let keys in data) {
          if (!(keys.includes(insuredControls.dob)) || keys.includes(insuredControls.applicantRelationship)) {
            memberDetails.controls[keys].setValue(data[keys]);
          }

          // Here also preexisting disease is similar to preexisting disease controls.
          // So If value exists controls will get bind automatically
          if (keys.includes(insuredControls.preExistinDisease)) {
            if (data[keys] == healthToggle.Yes) {

              // Below method is just used to hide show the existing disease block
              this.changeRadioValue(insuredControls.preExistinDisease, data[keys], index, this.totalData[index].memberTypeCount);

              // Below method will just patch all the disease data in respective controls
              if (this.healthFormData.insuredFormData.preExistingDisease.length > 0) {
                let preExistingData = this.healthFormData.insuredFormData.preExistingDisease[index];
                for (let diseaseKeys in preExistingData) {
                  // If addon cover is selected as personal accident then remarks would be asked
                  // Remarks block initially are not added in the control so if user has laready selected 40 / 41 existing disease 
                  // Then remark block should be shown
                  if (preExistingData[diseaseKeys]) {
                    let existingDiseaseId;
                    // Find the index of disease[] where 40 or 41 value disease is present
                    let existingDiseaseIndex: number = this.healthFormData.insuredFormData.existingDisease.findIndex(data => data.Value == existingDiseaseId);
                    // Below method will add remark section
                    this.preExistingDiseaseControls(diseaseKeys, index, existingDiseaseIndex, existingDiseaseId, preExistingData[diseaseKeys])
                  }
                  diseaseControls.controls[diseaseKeys].setValue(preExistingData[diseaseKeys]);
                }
              }
            }
          }

          if (this.healthFormData.quoteFormData.subProductType != 23) {
            if (keys.includes(insuredControls.pendingCriticalIllnessInsurance)) {
              if (data[keys] == healthToggle.Yes) {
                this.changeRadioValue(insuredControls.pendingCriticalIllnessInsurance, data[keys], index, this.totalData[index].memberTypeCount);
                this.healthFormData.insuredFormData.insuredMemberDetails.forEach((ele, ind) => {
                  memberDetails.controls[`remarkCs${this.totalData[index].memberTypeCount}`].setValue(ele[`remarkCs${this.totalData[index].memberTypeCount}`]);
                })
              }
            } else if (keys.includes(insuredControls.otherCriticalIllnesspolicy)) {
              if (data[keys] == healthToggle.Yes) {
                this.changeRadioValue(insuredControls.otherCriticalIllnesspolicy, data[keys], index, this.totalData[index].memberTypeCount);
                this.healthFormData.insuredFormData.insuredMemberDetails.forEach((ele, ind) => {
                  memberDetails.controls[`remarkCriti${this.totalData[index].memberTypeCount}`].setValue(ele[`remarkCriti${this.totalData[index].memberTypeCount}`]);
                })
              }
            }
          }

        }

        if (index == this.healthFormData.insuredFormData.insuredMemberDetails.length - 1) {
          this.patchingDone = true;
        }
      })
    }
    // check whether self exists then freeze title and fullname
    if (this.healthFormData.kycStatus.isKYCDoneSuccessfully) {
      if (this.healthFormData.kycStatus.normalSelf || this.healthFormData.kycStatus.ovdSelf) {
        // true means it needs to patch data from insured members
        this.checkWhetherSelfExists(true);
      }
      else {
        // If user changes relation as self and then he clicks on insured.
        // But he did kyc from normaal relation then also we need to check
        // false means that it needs to patch data from kyc applicant block
        this.checkWhetherSelfExists(false)
      }

    }

    if (this.patchingDone) {
      this.zerotatinsuredDetailsForm.valueChanges.subscribe(data => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
      })
    }
    this.loadPanel = true;
  }

  checkWhetherSelfExists(patchInsuredBlock: boolean) {
    if (this.healthFormData.insuredFormData.insuredMemberDetails.length > 0) {
      let selfIndex = this.healthFormData.insuredFormData.insuredMemberDetails.findIndex((data, index) => data['applicantRelationshipAdult' + (index + 1)] == 'SELF');
      if (selfIndex != -1) {
        let memberDetails = this.memberDetails.controls[selfIndex] as FormGroup;
        let adultControlName = 'Adult' + (selfIndex + 1);

        let controlsData: Array<FormFields> = [...this.totalData[selfIndex].controls];
        this.totalData[selfIndex].controls = [];
        controlsData.forEach((formFields) => {
          if (formFields.controlName == insuredControls.fullName || formFields.controlName == insuredControls.title) {
            let controlName = formFields.controlName == insuredControls.fullName ? (insuredControls.fullName + adultControlName) : (insuredControls.title + adultControlName);
            memberDetails.controls[controlName].disable();
            // If in quote it was set as self then patch applicant block data
            if (!patchInsuredBlock) {
              let applicantValue = formFields.controlName == insuredControls.fullName ? this.healthFormData.kycFormData.applicantDetails.applicantFullName : this.healthFormData.kycFormData.applicantDetails.applicantTitle
              memberDetails.controls[controlName].setValue(applicantValue);
            }
          }
          else if (formFields.controlName == insuredControls.dob) {
            let insuredDOBValue = !patchInsuredBlock ? this.utilityService.formatDate(this.healthFormData.kycFormData.applicantDetails.applicantDOB) : this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex][insuredControls.dob + adultControlName];
            insuredDOBValue = insuredDOBValue.includes('T') ? this.utilityService.formatDate(insuredDOBValue) : insuredDOBValue
            memberDetails.controls[insuredControls.dob + adultControlName].setValue(insuredDOBValue);
          }
        })
        this.totalData[selfIndex].controls = controlsData;
      }
    }
  }

  // patching preexisting disease for each member
  patchExistingDisease(): Array<{}> {
    let tempExistingDisease: Array<{}> = this.existingDisease;
    tempExistingDisease = tempExistingDisease.map(data => {
      let tempObject = {
        ...data,
        type: 'radio',
        hide: 'false',
        value: [healthToggle.Yes, healthToggle.No],
        showRemarks: false,
        showRemarksType: 'text',
        remarksLabel: 'Reason',
        remarksApplicable: false,
        remarkControlName: '',
      }
      return tempObject;
    })
    return tempExistingDisease
  }

  // To detect which member is selected
  memberTypeSelected(type) {
    if (type.index == 0) {
      this.memberChanged('Adult', 0);
      this.showChildTabs = false;
      this.showAdultTabs = true;
    }
    else {
      this.memberChanged('Child', 0);
      this.showAdultTabs = false;
      this.showChildTabs = true;
    }

  }

  memberChanged(type: string, index) {
    if (type == healthMembers.Adult && this.healthFormData.quoteFormData.adultDetails.length > 0) {
      if (index == 0) {
        this.memberDataIndex = 0;
      }

      else {
        this.memberDataIndex = index;
      }

      this.tabGroup.forEach((ele, ind) => {
        if (ele['tabLabel'] == type) {
          ele['memberDetails'].forEach((ele2, ind2) => {
            if (ind2 == index) {
              ele2['selected'] = true;
            } else {
              ele2['selected'] = false;
            }
          })
        }

      })

    }

    else {
      let adultLength = this.healthFormData.quoteFormData.adultDetails.length;
      if (this.healthFormData.quoteFormData.adultDetails.length == 0 && this.healthFormData.quoteFormData.childDetails.length > 0) {
        if (index == 0) {
          this.memberDataIndex = adultLength;
        }
        else if (index == 1) {
          this.memberDataIndex = adultLength + 1;
        }
      }

      else if (this.healthFormData.quoteFormData.adultDetails.length > 0 && this.healthFormData.quoteFormData.childDetails.length > 0) {
        // If child is 0 
        if (index == 0) {
          this.memberDataIndex = adultLength;
        }

        else if (index == 1) {
          if (adultLength == 0) {
            this.memberDataIndex = adultLength;
          }

          else if (adultLength == 1 || adultLength == 2) {
            this.memberDataIndex = adultLength + 1;
          }
        }

        else if (index == 2) {
          this.memberDataIndex = this.totalData.length - 1;
        }
      }

      // for child
      this.tabGroup.forEach((ele, ind) => {
        if (ele['tabLabel'] == type) {
          ele['memberDetails'].forEach((ele2, ind2) => {
            if (ind2 == index) {
              ele2['selected'] = true;
            } else {
              ele2['selected'] = false;
            }
          })
        }
      })

    }
  }

  // Feet height modification methods
  feetHeightFormatting(eventData, data, index, alphaCheck: boolean, numberCheck: boolean, controlName: string) {
    let memberDetails = this.memberDetails.controls[index] as FormGroup;
    let insuredHeightData = memberDetails.get(`feetInchesHeight${data.memberTypeCount}`);
    if (eventData.target.id.includes('feetInchesHeight')) {
      this.utilityService.dynamicControlsNumberDecimalOnly(eventData, numberCheck);
      if (!isNaN(eventData.target.value)) {
        let heightInput = insuredHeightData.value;
        let heightInputArr = heightInput.split('');
        if (heightInputArr[1] != '.' && heightInput.length == 2) {
          insuredHeightData.setValue(heightInputArr[0] + '.' + heightInputArr[1]);
        }
      }
    }
    else {
      this.utilityService.dynamicControlsAlphaOnly(eventData, alphaCheck);
      this.utilityService.dynamicControlsNumberOnly(eventData, numberCheck);
      if (controlName != insuredControls.fullName) {
        this.utilityService.restrictSpace(eventData);
      }
    }
  }

  feetHeightChange(eventData, data, ind) {
    let value = this.zerotatinsuredDetailsForm['controls']['memberDetails']['controls'][ind].get(`feetInchesHeight${data.memberTypeCount}`).value;
    if (value.length == 1) {
      this.zerotatinsuredDetailsForm['controls']['memberDetails']['controls'][ind].get(`feetInchesHeight${data.memberTypeCount}`).setValue(value + '.' + 0)
    }

  }

  // All logic w.r.t dropdown controls handle in below method
  changeOfDropdown(value) {
  }

  // All logic w.r.t radio controls handle in below method
  changeRadioValue(controlName, value, totalDataIndex, type) {
    let memberDetails = this.memberDetails.controls[totalDataIndex] as FormGroup;
    if (this.healthFormData.quoteFormData.subProductType != 23) {

      if (controlName == 'pendingCriticalIllnessInsurance' && value == "Yes") {
        let pendingCriticalIllnessInsuranceYES = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.critiShieldAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: false }
          } else {
            return ele
          }

        })
        this.totalData[totalDataIndex].controls = pendingCriticalIllnessInsuranceYES;

        this.critiShieldAdditionalFieldArray.forEach((ele, ind) => {
          let csControlName = ele + '' + type;
          memberDetails['controls'][csControlName].setValidators([Validators.required]);
          memberDetails['controls'][csControlName].updateValueAndValidity();
        })

      } else if (controlName == 'pendingCriticalIllnessInsurance' && value == "No") {

        let existingHIPNO = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.critiShieldAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: true }
          } else {
            return ele
          }
        })
        this.totalData[totalDataIndex].controls = existingHIPNO;

        this.critiShieldAdditionalFieldArray.forEach((ele, ind) => {
          let csControlName = ele + '' + type;
          memberDetails['controls'][csControlName].setValue("");
          memberDetails['controls'][csControlName].clearValidators();
          memberDetails['controls'][csControlName].updateValueAndValidity();
        })
      }


      if (controlName == 'otherCriticalIllnesspolicy' && value == "Yes") {
        let otherCriticalIllnesspolicyYES = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.csAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: false }
          } else {
            return ele
          }

        })
        this.totalData[totalDataIndex].controls = otherCriticalIllnesspolicyYES;

        this.csAdditionalFieldArray.forEach((ele, ind) => {
          let csControlName = ele + '' + type;
          memberDetails['controls'][csControlName].setValidators([Validators.required]);
          memberDetails['controls'][csControlName].updateValueAndValidity();
        })

      } else if (controlName == 'otherCriticalIllnesspolicy' && value == "No") {

        let existingHIPNO = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.csAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: true }
          } else {
            return ele
          }
        })
        this.totalData[totalDataIndex].controls = existingHIPNO;

        this.csAdditionalFieldArray.forEach((ele, ind) => {
          let csControlName = ele + '' + type;
          memberDetails['controls'][csControlName].setValue("");
          memberDetails['controls'][csControlName].clearValidators();
          memberDetails['controls'][csControlName].updateValueAndValidity();
        })
      }
    }

    // If previous insured is selected as yes show below fields or hide below fields
    if (controlName == insuredControls.previouslyInsured) {
      const newControlsArray = this.totalData[totalDataIndex].controls.map(obj => {
        if ((obj.controlName === insuredControls.insuredSince || obj.controlName === insuredControls.waiverPeriod) ||
          (obj.controlName === insuredControls.sumInsured && this.healthFormData.portabilityFormData.typeOfPolicy === policyType.Individual)
        ) {
          if (value == healthToggle.No) {
            this.hidePED = true;
            memberDetails.controls[obj.controlName + type].setValue('');
          }
          if (value == healthToggle.Yes) {
            this.hidePED = false;
          }
          (value == healthToggle.Yes) ? memberDetails.controls[obj.controlName + type].setValidators([this.utilityService.requiredFieldValidation()]) : memberDetails.controls[obj.controlName + type].clearValidators();
          memberDetails.controls[obj.controlName + type].updateValueAndValidity();
          return (value == healthToggle.Yes) ? { ...obj, hide: false } : { ...obj, hide: true };
        }
        return obj;
      });
      this.totalData[totalDataIndex].controls = newControlsArray;
    }

    if (controlName == insuredControls.preExistinDisease) {
      if (value == healthToggle.Yes) {
        this.hidePED = false;
        this.totalData[totalDataIndex].existingDiseaseHide = false;
        //Below is the method for CHecking EMI condition
        this.checkEMI();
      }

      else if (value == healthToggle.No) {
        this.hidePED = true;
        this.totalData[totalDataIndex].existingDiseaseHide = true;
        let memberDisease = this.totalData[totalDataIndex].existingDisease;
        let diseaseControls = this.preExistingDisease.controls[totalDataIndex] as FormGroup;
        let diseaseIndexArray = [];
        // Now when user selected NO we need to remove the remark section also if applicable
        memberDisease.forEach((data, diseaseIndex) => {
          // This block removes control if we have included extra remark objects in totatl data array
          if ((data.Value == insuredDiseaseID) && data.Name.includes(remarkList.Remark)) {
            diseaseIndexArray.push(diseaseIndex)
            diseaseControls.removeControl(remarkList + this.totalData[totalDataIndex].memberTypeCount)
          }
        })
        //  Now we need to remove the data backwards. IF u try to remove data from front side it will only delete one element
        if (diseaseIndexArray.length > 0) {
          for (let i = diseaseIndexArray.length - 1; i >= 0; i--) {
            memberDisease.splice(diseaseIndexArray[i], 1);
          }
        }
        this.totalData[totalDataIndex].existingDisease = memberDisease;
        // If user selects no then we also need to reset all fields 
        for (let diseaseKeys in this.preExistingDisease.controls[totalDataIndex]['controls']) {
          diseaseKeys.includes(remarkList.Remark) ? this.preExistingDisease.controls[totalDataIndex]['controls'][diseaseKeys].setValue('') : this.preExistingDisease.controls[totalDataIndex]['controls'][diseaseKeys].setValue(false);
        }
      }
    }
  }

  checkEMI() {
    if (this.healthFormData.quoteFormData.InstallmentApplicable == healthToggle.Yes) {
      // Reset emi values and store it
      this.healthFormData.quoteFormData.InstallmentApplicable = healthToggle.No;
      this.healthFormData.quoteFormData.InstallmentFrequency = '';
      this.healthDataStorageService.setHealthDataStorage(this.healthFormData);

      this.errorPopupData = this.popupService.fetchErrorPopupMsg('You are not Eligible for EMI');
      this.popupService.openGenericPopup(this.errorPopupData);
      //re-direct to quote page left
      this.InsuredvalueEmitter.emit({ FormValid: false, stepChange: true });
    }
  }

  preExistingDiseaseControls(controlName, totalDataIndex, existingDiseaseIndex, existingDiseaseID, controlValue) {
    let memberDisease = [];
    // Extracting based on member index disease controls
    memberDisease = this.totalData[totalDataIndex].existingDisease;
    memberDisease[existingDiseaseIndex].showRemarks = controlValue;
    //  Note: Here I'm adding extra two objects because in html my I'm looping my form array to print controls.And if there
    // is no blocks for remark controls html will start giving errors
    memberDisease.push({
      type: 'text',
      Value: existingDiseaseIndex,
      showRemarks: controlValue,
      hide: 'false',
      showRemarksType: 'text',
      remarksApplicable: true,
      remarksLabel: 'Reason',
    })
    this.totalData[totalDataIndex].existingDisease = memberDisease;
  }

  // This method is created to find controls and pass a comparison value so this method can return the index of that control
  findControlsByIndex(value: string): number {
    let index: number = this.insuredControls.findIndex(data => data.controlName == value);
    return index;
  }

  onSubmit(event: EmitButtonData) {
    this.zerotatinsuredDetailsForm.markAllAsTouched();
    this.isSubmit = true;
    let adultCount = 0;
    if (!this.zerotatinsuredDetailsForm.valid) {
      this.showKYCBlock = false;
      let memberDetails = this.zerotatinsuredDetailsForm.controls['memberDetails']['controls']
      let values = []; let messageArray = [];
      for (let i = 0; i < memberDetails.length; i++) {
        let memberDetailsStatus = this.zerotatinsuredDetailsForm.controls['memberDetails']['controls'][i].valid;
        let internalKeys = Object.keys(this.zerotatinsuredDetailsForm.controls['memberDetails']['controls'][i].value);
        let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        if (type == 'Adult') {
          adultCount = adultCount + 1;
        }
        let typeLen: number;

        if (memberDetails.length > 2) {
          if (type === 'Adult') {
            typeLen = i + 1;
          } else {
            typeLen = (adultCount === 1) ? i : i - 1;
          }
        } else {
          if (type === 'Adult') {
            typeLen = i + 1;
          } else {
            typeLen = (adultCount === 0) ? i + 1 : i;
          }
        }
        if (memberDetailsStatus) {
          if (!this.totalData[i].existingDiseaseHide) {
            for (let prop in this.preExistingDisease.value[i]) {
              if (this.preExistingDisease.value[i][prop]) {
                values.push(this.preExistingDisease.value[i][prop])
              }
            }
            if (values.length == 0) {
              messageArray.push(this.totalData[i].memberTypeCount)
            }
          }
          if (messageArray.length > 0) {
            let popupData: PopupModal = {
              popupType: popupType.confirmation,
              imgName: '',
              header: popupHeader.pedNotSelected + ` ${messageArray.join(', ')}`,
              description: popupDescriptionMsg.pedDescription,
              buttonLabel: '',
              buttonAction: ''
            }
            this.popupService.openGenericPopup(popupData);
            this.preExistingError = true;
            this.pedPopupValidation();
            break;
          }
          else {
            this.preExistingError = false;
          }
        } else {
          const popupString ='Please fill details for ' + type + ' ' + typeLen;
          this.utilityService.popUpDialog(popupString);
          
          return;
        }
      }
    }

    else if (this.zerotatinsuredDetailsForm.valid) {
      let count = 0;
      if (this.memberDetails.length > 1) {
        this.memberDetails.value.forEach((data, index) => {
          if (data[`applicantRelationshipAdult${index + 1}`] == 'SELF') {
            count++;
          }
        })
      }
      if (count > 1) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
      else {
        this.checkPreExistingValidation();
        if (!this.preExistingError) {
          this.isSubmit = false;

          // There could be a possiblity user has entered space in the text field.
          // Always trim input fields
          this.memberDetails.value.forEach((data) => {
            for (let objectKey in data) {
              if (objectKey.includes(insuredControls.fullName)) {
                data[objectKey] = (data[objectKey]).trim();
              }
            }
          })
          this.preExistingDisease.value.forEach((data) => {
            for (let objectKey in data) {
              if (objectKey.includes(remarkList.Remark)) {
                data[objectKey] = (data[objectKey]).trim();
              }
            }
          })

          this.healthFormData.insuredFormData.insuredMemberDetails = this.memberDetails.value;
          this.memberDetails.controls.forEach((data, index) => {
            //  Below logic is written for title if title is disabled then value is not getting patched
            for (let key in data['controls']) {
              let memberDetails = this.memberDetails.controls[index] as FormGroup;
              if (key.includes(insuredControls.title) || key.includes(insuredControls.fullName)) {
                this.healthFormData.insuredFormData.insuredMemberDetails[index][key] = memberDetails.controls[key].getRawValue();
              }
            }
          })
          this.healthFormData.insuredFormData.preExistingDisease = this.preExistingDisease.value;
          this.healthFormData.insuredFormData.existingDisease = this.existingDisease;
          this.healthFormData.insuredFormData.totalData = this.totalData;
          this.healthFormData.insuredDataSet = true;
          this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
          // Uncomment below line if you need entire insured details controls
          //  this.healthFormData['totalMemberDetails'] =  this.totalData;
          this.showKYCBlock = true;
          this.isSubmit = false;

          this.dataSetSuccessfully = true;
          this.customStepperService.emitStepData({ status: true, buttonId: 'f3' });
        }
      }
    }
  }

  checkMemberValidation() {
    for (const key of Object.keys(this.zerotatinsuredDetailsForm.controls)) {
      let a = this.zerotatinsuredDetailsForm.controls[key]['controls'];
      for (let i = 0; i < a.length; i++) {
        let status = this.zerotatinsuredDetailsForm.controls[key]['controls'][i].valid;
        let internalKeys = Object.keys(this.zerotatinsuredDetailsForm.controls[key]['controls'][i].value);
        let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        let typeRes = (type == 'Adult') ? i + 1 : i - 1;
        let typeLen = a.length > 2 ? typeRes : i + 1;
        if (!status) {
          let msg = 'Please fill details for ' + type + ' ' + typeLen;;
          this.utilityService.popUpDialog('Ok');
          return;
        }
      }
    }
  }

  checkPreExistingValidation() {
    let messageArray = [];
    this.totalData.forEach((totaldata, index) => {
      let values = [];
      if (!totaldata.existingDiseaseHide) {
        for (let prop in this.preExistingDisease.value[index]) {
          if (this.preExistingDisease.value[index][prop]) {
            values.push(this.preExistingDisease.value[index][prop])
          }
        }
        if (values.length == 0) {
          messageArray.push(totaldata.memberTypeCount)
        }
      }
    })

    // This is temproary we need to add alert box to show atleast one pre existing disease is needed
    if (messageArray.length > 0) {
      let popupData: PopupModal = {
        popupType: popupType.confirmation,
        imgName: '',
        header: popupHeader.pedNotSelected + ` ${messageArray.join(', ')}`,
        description: popupDescriptionMsg.pedDescription,
        buttonLabel: '',
        buttonAction: ''
      }
      this.popupService.openGenericPopup(popupData);
      this.preExistingError = true;
      this.pedPopupValidation();
    }
    else {
      this.preExistingError = false;
    }
  }

  pedPopupValidation() {
    // Checking for PED popup
    this.popupService.pedNotRequired$.subscribe(status => {
      if (status) {
        // If user does not want any ped make all ped as no
        this.totalData.forEach((totaldata, index) => {
          let pedPresentForMember: boolean = false;
          if (!totaldata.existingDiseaseHide) {
            for (let prop in this.preExistingDisease.value[index]) {
              if (this.preExistingDisease.value[index][prop]) {
                pedPresentForMember = true;
              }
            }
            // No ped is selected for that member
            if (!pedPresentForMember) {
              // We need to hide existing disease and mark the value as no
              totaldata.existingDiseaseHide = true;
              this.memberDetails.at(index).get(insuredControls.preExistinDisease + totaldata.memberTypeCount).setValue(healthToggle.No);
            }
          }
        })
        // In any case it will be false
        this.preExistingError = false;
      }
      // If he clicks cancel that means he wants to select
      else if (!status) {
        this.preExistingError = true;
      }
    })
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const focusedElement = this.el.nativeElement.ownerDocument.activeElement as HTMLElement;
    const focusedElementId = focusedElement.id;
    const remainingString = focusedElementId.replace('fullName', "");
    const newString = "dob" + remainingString;
    if (focusedElementId.includes('fullName') && event.key === 'Tab') {
      const nextElement = this.el.nativeElement.ownerDocument.querySelector(`#${newString}`) as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  removeKeyListener() {
    if (this.keyDownListener) {
      window.removeEventListener('keydown', this.keyDownListener);
      this.keyDownListener = null;
    }
  }



  onRefresh(status) {
    if (status) {
      this.fetchHealthAilmentList();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  getClassObject(data: Object) {
    return {
      'col-sm-3 form-group': !data['hide'] && !data['controlName'].includes(insuredControls.pendingCriticalIllnessInsurance) && !data['controlName'].includes(insuredControls.otherCriticalIllnesspolicy),
      'col-sm-6 form-group': !data['hide'] && data['controlName'].includes(insuredControls.pendingCriticalIllnessInsurance),
      'col-sm-8 form-group': !data['hide'] && data['controlName'].includes(insuredControls.otherCriticalIllnesspolicy),
      'd-none': data['hide']
    };
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.removeKeyListener();
  }
}

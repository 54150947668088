export enum userEnums {
  AuthToken = 'AXV0aFRva2VutoYXV0aFRv',
  UopqksmlN = 'UZV0sdasdfaaszAZV0sdasv',
  UnxkdjT = 'UTygsygsuyguysg',
  Pqsxkpo = 'PDsssshhsuhjhu',
  SessionReference = 'Swedfghjiuhgcxzxcvb',
  BefitHome = 'BHdsfewwqefwf',
  HealthFormData = 'Hwqasxcvgtyghbnjkiokm',
  LoginCredential = "Lrwsdcvgyhnkkkk",
  SwapParameters = 'SAfDbvQA9a7CxsufRq0Vew==',
  HomeAuthToken = 'Hokiiitokkkkk',
  SendPaymentLinkSummaryData = 'SPLSDjisndwnwopxnwn',
  ProposalNumber = 'PNuygyeygeyee',
  PolicyNumber = 'PNjiftyuonvcfwsr',
  PolicyId = 'PIrfqiocllsbgemzjw',
  EPolicyId = 'EPIfvlyndkwcdjoqzs',
  TransFor = 'TFghncjdueitosmghc',
  PaymentConfirmationData = 'Pdtttaggatagg',
  PendingPaymentData = 'PPcdmkcsndjal',
  TransactionId = 'TrsfgshbjknId',
  SidenavStatus = 'Sinbdheguyghgehg',
  AgentDetails = "Agdgdgygyvfgyvyg",
  AgentDetailsLocal = "AdfggDdfghhghL",
  QcFlow = "Qnjhnnhnftrtrdjdh",
  MyPolicyDetails = 'MPDkjygkliiueyfr5dewwnjnijg',
  StepperDetails = 'Sdergfsghsvhsvbbsbbsuhuink',
  TotalPremium = 'TPmounkqwtfvdcbxmam',
  StartDate = 'SDkskxcmlpllksmc',
  EndDate = 'EDldmksmckdwsxsm',
  NRIApplicable = 'NRIhsjkwpmocspwncj', 
  SwapParametersLogout = 'SL0NMDvTRRS5wXWj9woc=',
  ChannelData = 'CD0NMDvTRdwsxsm5wXWj',
  Plutus = 'PLTyrtshtusntremhop',
  IsDeveloper = 'Ikjvabakjdbaskjb',
  ProductType = 'PTjiurmkkjdbaskjb',
  SubProductCode = 'subj1MgzZHLmtJIwde',
  EncryptionText = 'Ensdfghjk',
  RenewalFormData = 'Rokasjcfdgvsnc',
  PreviousUrl = 'PUesndskdlkdld',
  CurrentUrl = 'CUesndskdlkdld',
  RnFetchData = 'Rngkliiueyfr5def',
  ConverToProposal = 'CTPjkkohuhjmnjk',
  MappedValueHB = 'madbbjhdbhdvghvhg',
  UserType = 'Uaksjdfhksdhbvlisv',
  RenewalDue = 'RDjhsfbjhefjvjujolws',
  RenewalValue = 'RVhfgebdusmevsbrhebf',
  PendingPaymentValue = 'PPVlkjhgfdsawertyuip',
  DiseaseList = 'DIsjdlstwertsyojhg',
  InsuredRelationList = 'INswsrellstmquzpslfg',
  NomineeRelationList = 'NOmptrellstdehjalkxt',
  addonList = 'ADnhmtonlstdehpdfoyr',
  occupationList = 'OCckfgptnonlstmyhqwfjnr',
  Relationships = 'Rshipsfatbhhhghv',
  IsOrionJourney = 'ISdfagadgdfagafg',
  EuropeanStates = 'USnjknkjnjknbh',
  SchengenStates = 'SSKNKNKJNHNM',
  QuotePDFData = 'QPDFDnbcgfhtr',
  AgentProductMap = 'APMhgjnvmsirotpyk'
}
import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/authentication/auth.service';
import { SettingsService } from './shared/services/settings.service';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { LoaderService } from './layout/loader/loader.service';
import { SideNavService } from './shared/services/sidenav.service';
import { PopupService } from './shared/services/popup.service';
import { PopupModal } from './layout/popups/popup.modal';
import { popupType, popupImgPath, popupHeader, popupDescriptionMsg, popupButton, popupButtonAction } from './shared/enums/popupEnums';
import { StorageService } from './shared/services/storage.service';
import { userEnums } from './shared/enums/userEnums';
import { NavigationStart, Router } from '@angular/router';
import { ChannelData } from './shared/interface/swapPrameters';
import { environment } from 'src/environments/environment';
import { routeEnums } from './shared/enums/routeEnums';
import { TabCloseService } from './shared/services/tab-close.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pana';
  isAuthenticate: boolean = false;
  loadContent: boolean = false
  subscription: Subscription[] = [];
  options = this.settings.getOptions();
  private isMobileScreen = false;
  isLoaderShow: boolean = false;
  hideDashboard: boolean;
  isFunctionRunning: boolean = false;
  channelData: ChannelData;
  isHeaderhide: boolean = true;
  constructor(
    private settings: SettingsService,
    private authService: AuthService,
    private userIdle: UserIdleService,
    private loaderservice: LoaderService,
    private sidenavService: SideNavService,
    private popupService: PopupService,
    private storageService: StorageService,
    private router: Router,private tabCloseService: TabCloseService
  ) {
    this.router.events
      .subscribe((e) => {
        if (e instanceof NavigationStart) {
          if (e.url == '/calculate-elevate') {
            this.isHeaderhide = false;
          } else {
            this.isHeaderhide = true;
          }
          if (e.url == '/health/dashboard') {
            this.hideDashboard = true;
          } else {
            this.hideDashboard = false;
          }
        }
        // } else if (e instanceof NavigationStart) {
        //   if (e.url == '/login') {
        //     this.hideDashboard = false;
        //   } else {
        //     this.hideDashboard = true;
        //   }
        // }
      });
  }

  ngOnInit() {
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    // Spinner show hide event capture
    this.subscription.push(this.loaderservice.loaderShow.subscribe(
      () => {
        this.isLoaderShow = true;
        document.body.style.overflow = "hidden";
      }
    ));
    this.subscription.push(this.loaderservice.loaderHide.subscribe(
      () => {
        this.isLoaderShow = false;
        document.body.style.overflow = "visible";
      }
    ));

    // Authenticate and not authenticate event capture
    this.isAuthenticate = this.authService.check();

    this.subscription.push(this.authService.isNotAuthenticated.subscribe(
      () => {
        this.isAuthenticate = false;
        this.showSideNav();
      }
    ));

    this.subscription.push(this.authService.isAuthenticated.subscribe(
      () => {
        this.isAuthenticate = true;
        this.showSideNav();
        if (!this.storageService.getAESDecryptedData(userEnums.IsDeveloper)) {
          this.sessionTimeout();
        }
      }
    ));


    // Below functionality use for unsubscribing service worker and it won't cause continuous refresh
    let isRunningLocally = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    if (isRunningLocally) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      }
    }

    this.loadContent = true;

  }

  get isOver(): boolean {
    return this.isMobileScreen;
  }
  showSideNav() {
    let userAuthenticationStatus = this.isAuthenticate;
    this.sidenavService.setUserAuthenticatedStatus((userAuthenticationStatus).toString());
    this.loadContent = true;
  }

  // Session timeout popup for Idle screen
  sessionTimeout() {

    //Start watching for user inactivity.
    this.popupService.closeAllDialog(false);
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.subscription.push(this.userIdle.onTimerStart().subscribe(count => {
      this.userIdle.stopWatching();
      this.isFunctionRunning = false;
      setTimeout(() => {
        if (!this.isFunctionRunning) {
          this.popupService.closeAllDialog(false);
          let popupData: PopupModal = {
            popupType: popupType.generic,
            imgName: popupImgPath.alertGif,
            header: popupHeader.sessionExpired,
            description: popupDescriptionMsg.sessionExpired,
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.close
          }
          this.popupService.openGenericPopup(popupData);
          if(this.popupService.baseURI.includes('https://panaapi.icicilombard.com/apigateway/health/')){
            sessionStorage.clear();
            if (this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) {
              window.location.href = environment.nysaMotorLoginRedirection;
              return;
            } else if (this.channelData.CN.includes('BANCA')) {
              window.location.href = environment.nysaBancaLoginRedirection;
              return;
            }
          }else if(this.popupService.baseURI == 'https://pana.insurancearticlez.com/apigateway/health/' || this.popupService.baseURI == 'https://panaapi.ilgicdash.com/apigateway/health/'){
              sessionStorage.clear();
              this.router.navigate(['/', routeEnums.LOGIN]);
          }
          this.userIdle.resetTimer();
          this.isFunctionRunning = true;
        }
      }, 0)
    }));
    // Start watch when time is up.
    this.subscription.push(this.userIdle.onTimeout().subscribe());
  }
  ngOnDestroy() {
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }
}

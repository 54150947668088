// Please place all labels in alphabetical order
//Place only those elements which can be used in multiple places in typescript file

export class Constants {
    static agent_ID = '100002862';
    static befitScope = 'esbzerotat'
    //Channel Types
    static channelNameMSITE = "NYSAHEALTH";
    static channelNameIpartner = "NYSAHEALTH";
    static channelNameMFA = "MFA";
    static homeScope = 'esbhome';
    static healthProposalProductCode = 'HADV';
    static healthProposalProductCodeChi = 'HEP';
    static healthProposalProductCodeHB = 'HBOOSTER';
    static healthProposalProductCodeAsp = 'ASP';
    static healthProposalProductCodeMP = 'CHI';
    static healthProposalProductCodeElevate = 'ELEV';
    static health = 'Health';
    static travel = 'Travel';
    static esbKycScope = 'esb-kyc';
    static kycScope = 'kyc'
    //Subproducts
    static subProductHap = '42';
    static subProductGS = '40';
    static subProductHEP = '33';
    static subProductASP = '22';
    static subProductMAX = '33';
    static subProductHB = '20';
    static subProductFS =  '23';
    static subProductCS =  '38';
    static pana = 'PANA';

    // error colors
    static color_error: string = 'red';
    static color_success: string = 'green';
    static color_warning: string = 'orange';

    // label
    static label_iciciLombard: string = "ICICI Lombard";

    // error
    static invalid_imId: string = 'Please provide IM ID';
    static errorLabel_invalidUnameAndPwd: string = 'Kindly enter correct username and password';
    static errorLabel_somethingWentWrong: string = "Something went wrong. Please try again later";

    //status codes
    static statusCode_success: number = 1;
    static statusCode_failure: number = 0;
    static statusCode_server_error: number = 500;
    static statusCode_unauthorized: number = 401;
    static statusCode_externalAPI: number = -1;
    static statusCode_Unhandeled: number = -2;
    static statusCode_Paylod: number = -3;
    static statusCode_Special_Char: number = -4;
    static statusCode_UnAuthorized: number = -5;
    static statusCode_Bad_Gateway: number = 502;
    static statusCode_service_unavailable: number = 503;
    static otherStatusCode: number = 8;
    static lnk_expire_logout_Code: number = 7;

    // Regular expressions
    static numberPatternRegExp = /^[\d\t\r\n\n]+$/;
    static textPatternRegExp = /^[a-zA-Z\t\r\n\n ]+$/;
    static heightPatternRegExp = /^([1-7]|7\.)?(\.(\d)?)?$/;
    static weightPatternRegExp = /^[1-9]\d{0,2}$/;
    static namePatternRegExp = /^[a-zA-Z\s]*$/;
    static emailIdPatternRegExp = '\\S+@\\S+\\.\\w+';
    static mobileNumberPatternRegExp = '[6-9]\\d{9}';
    static panNumberPatternRegExp = '[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}';
    static dateFormatPattern = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    static pinCodePatternRegExp = '^[1-9][0-9]{5}$';

    // status messages
    static statusMsg_Success: string = 'success';
    static statusMsg_Manual: string = 'ACCEPTED_FOR_MANUAL_QC';
    static statusMsg_Closed: string = 'closed';
    static statusMsg_Unauthorized: string = '401';
    static statusMsg_Forbidden: string = '403';
    static statusMsg_BadRequest: string = '400';
    static statusMsg_SuccessCode: string = '200';
    static statusMsg_ServerError: string = '500';
    static statusMsg_NotImplemented: string = '501';
    static statusMsg_BadGateway: string = '502';
    static statusMsg_ServiceUnavailable: string = '503';


    // Session Time
    static idle =  7200;
    static timeOut = 30;
    static ping = 0;

    // Dashbaord
    static panaDashboard = 'Dashboard';
    static utilityDashboard = 'Logs';

    static iOS = 'iOS';
    static Android = 'Android';
    static Windows = 'Windows';
    static MacOS = 'MacOS';
    static Linux = 'Linux';
    static Others = 'Others';

    static NysaTravel = 'NYSA_TRAVEL';
    static NysaHome = 'NYSA_HOME';
    static NysaHealth = 'NYSA_HEALTH';
}

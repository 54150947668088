import { Component, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { popupType } from 'src/app/shared/enums/popupEnums';
import { BookedBusinessReportService } from '../services/booked-business-report.service';
import { Constants } from 'src/app/shared/constants/constants';
import { DownloadService } from 'src/app/shared/services/download.service';
import { BookedBusinessReport, Policy, DownloadPDFGeneric } from '../interfaces/booked-business-report';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { Subscription, fromEvent } from 'rxjs';
import { PopupService } from 'src/app/shared/services/popup.service';
import { DownloadpdfRequestPayload } from 'src/app/shared/interface/file';

@Component({
  selector: 'app-booked-business-report',
  templateUrl: './booked-business-report.component.html',
  styleUrls: ['./booked-business-report.component.scss']
})
export class BookedBusinessReportComponent {

  private interval: any;
  bookFormGroup: FormGroup;
  agentIMID: string;
  productTypeList = [];
  isSelected: boolean = false;
  isSubmitted: boolean = false;
  isSearchVisible: boolean = false;
  toggleValue: 'table' | 'card' = 'card';
  minMax: { minDate: Date, maxDate: Date };
  reportList: Policy[] = [];
  filteredData: Policy[] = [];
  product: string = '';
  startDate: Date;
  endDate: Date;
  phText: string[] = ['Search for Business Type',
    'Search for Policy Endorsement Type',
    'Search for Policy Number',
    'Search for Policy Cover Note No',
    'Search for Customer Name']
  searchPlaceHolder: string;
  i: number = 0;
  inputValue: string = '';
  businessFilter: string[] = [];
  policyFilter: string[] = [];
  businessFilterList = ['New Business', 'Renewal Business', 'Rollover Business'];//['NEW BUSINESS', 'RENEWAL BUSINESS', 'ROLLOVER BUSINESS']
  policyFilterList = ['Issued', 'Renewed', 'Endorsed', 'Cancelled'];//['ISSUED', 'RENEWED', 'ENDORSED', 'CANCELLED'];
  scrolling: boolean = false;
  showCancel: boolean = false;
  policyCount: string;
  businessAmount: string;
  cardBtnInfo = [{
    label: 'Download Policy',
    imgPath: '../../../assets/images/myPolicy/downloadPolicy.svg'
  }]
  tableBtnInfo = [{
    label: 'Download Policy',
    imgPath: '../../../../../../../assets/images/BBR/download.svg',
    tooltip: 'Download'
  }]
  displayCol = [
    {
      columnDef: 'ProductClass',
      header: 'Product Class',
      cell: (element: any) => `${element.ProductClass}`,
    },
    {
      columnDef: 'ProductSubClass',
      header: 'Product Sub Class',
      cell: (element: any) => `${element.ProductSubClass}`,
    },
    {
      columnDef: 'PolicyBusinessType',
      header: 'Business Type',
      cell: (element: any) => `${element.PolicyBusinessType}`,
    },
    {
      columnDef: 'PolicyNumber',
      header: 'Policy Number',
      cell: (element: any) => `${element.PolicyNumber}`,
    },
    {
      columnDef: 'PolicyCoverNoteNo',
      header: 'Policy Cover Note No',
      cell: (element: any) => `${element.PolicyCoverNoteNo}`,
    },
    {
      columnDef: 'CustomerFullName',
      header: 'Customer Name',
      cell: (element: any) => `${element.CustomerFullName}`,
    },
    {
      columnDef: 'NetGwp',
      header: 'Net GWP',
      cell: (element: any) => `${element.NetGwp}`,
    },
    {
      columnDef: 'IndicativePayoutRate',
      header: 'Commission (%)',
      cell: (element: any) => `${element.IndicativePayoutRate}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    },
    {
      columnDef: 'PolicyEndorsementType',
      header: 'Policy Endorsement Type',
      cell: (element: any) => `${element.PolicyEndorsementType}`,
    },
    {
      columnDef: 'PolicyReportingDate',
      header: 'Policy Reporting Date',
      cell: (element: any) => `${element.PolicyReportingDate}`,
    },
    {
      columnDef: 'PolicyStartDate',
      header: 'Policy Start Date',
      cell: (element: any) => `${element.PolicyStartDate}`,
    },
    {
      columnDef: 'PolicyEndorsementDate',
      header: 'Policy Endorsement Date',
      cell: (element: any) => `${element.PolicyEndorsementDate}`,
    }
  ]
  subscription: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private storageService: StorageService,
    public utilityService: UtilityService,
    private dialog: MatDialog,
    private bookedBusinessReportService: BookedBusinessReportService,
    private downloadService: DownloadService,
    private excelDonwloadService: ExcelDownloadService,
    private popupService: PopupService) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.getProductList();
    this.initFormGroup();
    this.getInitialDates();
    this.setProductType();
    this.getReportList();
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  downloadExcel() {
    const excelData = this.reportList.map(x => {
      return {
        'Policy No': x.PolicyNumber,
        'Start Date': x.PolicyStartDate,
        'Customer Name': x.CustomerFullName,
        'Amount (₹)': this.utilityService.convertStringtoCurrency(x.NetGwp),
        'Commission (%)': x.IndicativePayoutRate,
      }
    })
    this.excelDonwloadService.exportAsExcelFile(excelData, 'Booked_Business_Report');
  }

  setProductType() {
    if (sessionStorage.getItem(userEnums.AgentProductMap)) {
      const productList = this.storageService.getAESDecryptedData(userEnums.AgentProductMap);
      this.bookFormGroup.controls['productType'].setValue(productList[0].productName);
    }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  queryData() {
    const query = this.inputValue.trim();

    if (!query && (this.utilityService.isEmptyOrNull(this.businessFilter) && this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.reportList;
    } else if (!query && (!(this.utilityService.isEmptyOrNull(this.businessFilter)) || !(this.utilityService.isEmptyOrNull(this.policyFilter)))) {
      this.filterLogic();
    } else if (query && (this.utilityService.isEmptyOrNull(this.businessFilter) && this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.reportList.filter(item => {
        return Object.values(item).some(val =>
          val.toString().toLowerCase().includes(query.toLowerCase())
        );
      });
    } else {
      this.filteredData = this.filteredData.filter(item => {
        return Object.values(item).some(val =>
          val.toString().toLowerCase().includes(query.toLowerCase())
        );
      });
    }
  }

  filterLogic() {
    if (!(this.utilityService.isEmptyOrNull(this.businessFilter)) && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.reportList.filter(item => this.businessFilter.includes(item['PolicyBusinessType'].toLowerCase()))
    } else if (this.utilityService.isEmptyOrNull(this.businessFilter) && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.reportList.filter(item => this.policyFilter.includes(item['PolicyEndorsementType'].toLowerCase()))
    } else if (!(this.utilityService.isEmptyOrNull(this.businessFilter)) && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.reportList.filter(item => this.businessFilter.includes(item['PolicyBusinessType'].toLowerCase())
        && this.policyFilter.includes(item['PolicyEndorsementType'].toLowerCase()))
    }
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  getReportList() {
    const payload = {
      IMID: this.bookFormGroup.value.IMID,
      StartDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.startDate),
      EndDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.endDate),
      productClass: this.bookFormGroup.value.productType.toUpperCase()
    }
    this.bookedBusinessReportService.getBookedBusinessReport(payload).subscribe({
      next: (response: BookedBusinessReport) => {
        if (response["Data"]["StatusCode"] == Constants.statusCode_success) {
          this.policyCount = String(response.Data["Data"]["TotalBookedBusinessPolicyCount"]);
          this.businessAmount = String(response.Data["Data"]["TotalBookedBusinessAmount"]);
          this.reportList = response.Data["Data"]["policies"];
          this.reportList.forEach(data => {
            data.CustomerFullName = data.CustomerFullName.trim().split(/[\s,\t,\n]+/).join(' ');
          })
          this.filteredData = this.reportList;
        }
        else {
          this.filteredData = [];
          this.policyCount = "0";
          this.businessAmount = "0";
        }
      },
    })
    this.updateInfoCard();
  }

  getProductList() {
    const loginCredentials = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
    this.agentIMID = loginCredentials.Uowkjsdjssrme;
    // this.productTypeList = [
    //   {
    //     productName: "Health",
    //     productCode: 2
    //   },
    //   {
    //     productName: "Home",
    //     productCode: 4
    //   },
    //   {
    //     productName: "Travel",
    //     productCode: 3
    //   },
    // ]

    this.bookedBusinessReportService.getProductsForBBR().subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          console.log(response);
          this.productTypeList = response.Data;
          let healthdata = this.productTypeList.find(x => x.Product_Name == "HEALTH");
          this.bookFormGroup.get('productType').setValue(this.utilityService.isUndefinedORNull(healthdata) ? this.productTypeList[0].Product_Name : healthdata.Product_Name);
        }
      },
    })
  }

  getInitialDates() {
    const currentDate = new Date();
    const min = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
    const max = new Date();
    max.setMonth(new Date().getMonth() + 3);
    this.minMax = { minDate: min, maxDate: max };
  }

  initFormGroup() {
    const SD = this.utilityService.subtractDays(new Date(), 15);
    this.bookFormGroup = this.formBuilder.group({
      IMID: [this.agentIMID],
      productType: ["HEALTH"],
      startDate: [SD],
      endDate: [new Date()]
    })
    this.updateInfoCard();
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {

    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }

    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'input',
            label: 'IMID',
            readonly: true,
            value: this.agentIMID
          }, {
            type: 'dropdown',
            label: 'Product Type',
            list: this.productTypeList,
            value: this.bookFormGroup.value.productType
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.bookFormGroup.value.startDate,
            endDate: this.bookFormGroup.value.endDate,
          }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'dropdown') {
          this.bookFormGroup.controls['productType'].setValue(i.value);
        } else if (i.type == 'rangepicker') {
          this.bookFormGroup.controls['startDate'].setValue(i.startDate);
          this.bookFormGroup.controls['endDate'].setValue(i.endDate);
        }
      }
      this.getReportList();
    }
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [{
          label: 'Business Type',
          list: this.businessFilterList,
          filter: this.businessFilter
        }, {

          label: 'Endorsement Type',
          list: this.policyFilterList,
          filter: this.policyFilter
        }]
      },
    }
    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.businessFilter = data[0].filter;
      this.policyFilter = data[1].filter;
    }
    this.applyFilter();
  }

  applyBusinessFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.businessFilter.includes(value)) {
        this.businessFilter.push(value);
      }
    } else {
      const index = this.businessFilter.indexOf(value);
      if (index >= 0) {
        this.businessFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  updateInfoCard() {
    this.product = this.bookFormGroup.value.productType;
    this.startDate = this.bookFormGroup.value.startDate;
    this.endDate = this.bookFormGroup.value.endDate
  }

  onSubmit() {
    this.isSubmitted = true;
    this.getReportList();
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  downloadPolicy(event) {

    if (event.data.Source === 'BANCS') {
      let data: DownloadpdfRequestPayload = {
        type: 'BBRPOLICY',
        value: event.data.PolicyNumber,
        isBancsPolicy: event.data.Source === 'BANCS'
      }

      this.downloadService.downloadPDfRequest(data).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.downloadService.downloadPdf(response.Data, event.data.PolicyNumber);
          }
        },
        error: error => {
          let errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
          this.popupService.openGenericPopup(errorPopupData);
        }
      });

    } else {
      const payload: DownloadPDFGeneric = {
        PolicyNo: event.data.PolicyNumber,
        DealId: event.data.DealID
      }
      this.downloadService.downloadPDFGeneric(payload).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success && response.StatusMsg == "Success") {
            this.downloadService.downloadPdf(response.Data, event.data.PolicyNumber);
          }
        }
      })
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  handleMatSelectClick() {
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  onRefresh(status) {
    if (status) {
      this.getReportList();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }
}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { RenewalDataStorage } from '../renewal-storage.modal';
import { RenewalDataStorageService } from '../renewal.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { healthMembers, healthToggle, patterns } from 'src/app/shared/enums/healthEnums';
import { MatSelect } from '@angular/material/select';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { HealthAilmentResponseStructure, InsuredRelationship, RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { Subscription } from 'rxjs';
import { NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { RenewalDataEmitService } from 'src/app/shared/services/renewal-emit-service';
import { RenewalInsuredDateService } from 'src/app/shared/services/renewal-insured-date.service';
import { RenewalAddOnService } from 'src/app/shared/services/renewal-addon.service';
import { RenewalModal } from '../renewal-modal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { popupButton, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { Router } from '@angular/router';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { Constants } from 'src/app/shared/constants/constants';
@Component({
  selector: 'app-renewal-insured-details',
  templateUrl: './renewal-insured-details.component.html',
  styleUrls: ['./renewal-insured-details.component.scss']
})
export class RenewalInsuredDetailsComponent {
  tabGroup = [];
  renewalFormData: RenewalDataStorage;
  memberLabel: string;
  insuredForm: FormGroup;
  private subscription: Subscription[] = [];
  diseaseList: HealthAilmentResponseStructure[];
  insuredRelationsList: InsuredRelationship[];
  insuredAdultRelationsList: InsuredRelationship[];
  insuredChildRelationsList: InsuredRelationship[];
  addonList: NewAddOnCoverDetails[];
  occupationList = [];
  title = [{ id: '', val: '' }];
  isSubmit: boolean = false;
  renewalModal: RenewalModal = new RenewalModal();
  healthDataModal: HAPModal = new HAPModal();
  errorPopupData: PopupModal;
  befitAddon: NewAddOnCoverDetails[] = [];
  insuredBefit: string = '';
  minMaxDate: { minDate: Date; maxDate: Date; };
  showBefit: boolean = true;
  coPayArray = [20, 30, 40];
  vdValue: any;
  @Output() copayChangeEvent = new EventEmitter<number>();
  @Output() formValueChange = new EventEmitter<any>();
  hideAddon: boolean = false;
  classicToPremium: boolean = false;

  constructor(private renewalStorageService: RenewalDataStorageService,
    private formBuilder: FormBuilder,
    public utilityService: UtilityService,
    private renderer: Renderer2, private healthMasterService: HealthMasterService,
    private renewal_emit_service: RenewalDataEmitService,
    private rnAddonService: RenewalAddOnService,
    private renewal_date_service: RenewalInsuredDateService, private popupService: PopupService,
    private route: Router, private cdr: ChangeDetectorRef

  ) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    this.insuredRelationsList = this.renewalFormData.InsuredRelationList;
    this.diseaseList = this.renewalFormData.diseaseList;
    this.addonList = this.renewalFormData.addonList;
    this.occupationList = this.renewalFormData.occupationList;
  }

  ngOnInit() {
    this.setInitialData();
    this.constructMemberDetails();
    this.setDefaultTabSelection(0);
    this.eventEmitters();
  }

  setInitialData() {

    this.insuredForm = this.formBuilder.group({
      insuredDetails: this.formBuilder.array([])
    })
    this.occupationList = this.renewalFormData.occupationList;
    this.showBefit = this.renewalFormData.IsBefitApplicable;
    if (!this.utilityService.isEmptyOrNull(this.insuredAdultRelationsList) || !this.utilityService.isEmptyOrNull(this.insuredChildRelationsList)) {
      this.insuredAdultRelationsList = this.insuredRelationsList?.filter(x => x.KidAdultType == 'Adult');
      this.insuredChildRelationsList = this.insuredRelationsList?.filter(x => x.KidAdultType == 'Child' && x.RelationshipID != '1258' && x.RelationshipID != '1260');
    } else {
      this.relationshipData();
    }
    this.patchExistingAddon();
    this.minMaxDate = this.renewal_date_service.initialDate();
  }
  relationshipData() {
    let productType = this.healthDataModal.ProductType.find(x => x.Key === this.renewalFormData.SubProductType);
    let product: RelationshipRequestPayload = {
      product: productType.value
    }
    this.subscription.push(this.healthMasterService.getHealthProposalRelationships(product).subscribe({
      next: (data: RelationshipResponsePayload) => {
        if (data.StatusCode == Constants.statusCode_success) {
          this.insuredAdultRelationsList = data.Data.InsuredRelationship?.filter(x => x.KidAdultType == 'Adult');
          this.insuredChildRelationsList = data.Data.InsuredRelationship?.filter(x => x.KidAdultType == 'Child' && x.RelationshipID != '1258' && x.RelationshipID != '1260');
        }
      },
      error: (err) => {
      }
    })
    );
  }
  setDefaultTabSelection(tabIndex) {
    if (tabIndex == 0) {
      let AdultMembers = this.getMembersLength(healthMembers.Adult);
      if (AdultMembers > 0) {
        this.memberLabel = "Adult1";
      } else {
        this.memberLabel = ""
      }
    } else {
      let ChildMembers = this.getMembersLength(healthMembers.Child);
      if (ChildMembers > 0) {
        this.memberLabel = "Child1";
      }
      else {
        this.memberLabel = ""
      }
    }
  }
  constructMemberDetails() {
    this.tabGroup = [];
    if (this.renewalFormData.SubProductType == 40) {
      this.tabGroup.push({
        'tabLabel': 'Adult',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/adults.png",
        'memberDetails': this.formBuilder.array([])
      })
      this.createMemberFormArray();
    }
    else {
      this.tabGroup.push({
        'tabLabel': 'Adult',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/adults.png",
        'memberDetails': this.formBuilder.array([])
      })
      this.tabGroup.push({
        'tabLabel': 'Child',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/images/child1.png",
        'memberDetails': this.formBuilder.array([])
      });
      this.createMemberFormArray();
    }
  }
  createMemberFormArray() {
    let adultCount = 0, childcount = 0;
    if (this.renewalFormData.multiPlanDtls) {
      this.renewalFormData.multiPlanDtls.forEach((plan, i) => {
        if (!this.utilityService.isUndefinedORNull(plan.insuredDetails)) {
          plan.insuredDetails.forEach((insured, j) => {
            let memberCount = insured.KidAdultType == 2 ? ++adultCount : ++childcount;
            this.addInsuredData(insured, memberCount);

            if (!this.utilityService.isUndefinedORNull(this.diseaseList)) {
              for (let k = 0; k < this.diseaseList.length; k++) {
                this.addPEDData(this.diseaseList[k], j, k, insured.PedIllness);
              }
            }
            if (!this.utilityService.isUndefinedORNull(this.addonList)) {
              this.reArrangeAddonList();
              for (let l = 0; l < this.addonList.length; l++) {
                this.addAddonData(this.addonList[l], j, l, insured);
              }
            }
          })
        }
      })
    }
    this.getInsuredArray.controls.forEach((insured: any) => {
      this.setAddonConditions(insured, 'createForm');
    })
    this.updateAdultchildButtons();
  }
  eventEmitters() {
    //insured valid form
    this.renewal_emit_service.insuredFormValid$.subscribe(values => {
      if (values) {
        this.insuredForm.markAllAsTouched();
        this.isSubmit = true;
        let adultCount = 0;
        if (!this.insuredForm.valid) {
          let memberDetails = this.insuredForm.controls['insuredDetails']['controls']
          for (let i = 0; i < memberDetails.length; i++) {
            let memberDetailsStatus = this.insuredForm.controls['insuredDetails']['controls'][i].valid;
            // let internalKeys = Object.keys(this.insuredForm.controls['insuredDetails']['controls'][i].value);
            // let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
            let type = memberDetails[0].value.memberType == 'Adult' ? 'Adult' : 'Child';
            if (type == 'Adult') {
              adultCount = adultCount + 1;
            }
            let typeLen = memberDetails.length > 2 ? ((type == 'Adult') ? i + 1 : (adultCount == 1) ? i : i - 1) : ((type == 'Adult') ? i + 1 : (adultCount == 0) ? i + 1 : i);
            if (memberDetailsStatus) {
            } else {
              this.utilityService.popUpDialog(`Please fill details for ${type} ${typeLen}`);
              return;
            }
          }
        }
      }
    })
    this.subscription.push(
      this.renewal_emit_service.hapAddon$.subscribe(siStatus => {
        this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
        if (this.renewalFormData.SubProductType == 42) {
          if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
            if (this.addonList.length > 0) {
              let existingAddon = [];
              this.renewalFormData.multiPlanDtls.forEach(x => {
                x.insuredDetails.forEach(y => {
                  existingAddon = y.NewAddOnCoverDetails;
                })
              })
              let claimProtectorAddon = existingAddon.find(x => (x.CoverId == 73 && x.IsAddonSelected))
              let CompansitateVisitAddon = existingAddon.find(x => (x.CoverId == 74 && x.IsAddonSelected))
              let NursingAtHomeAddon = existingAddon.find(x => (x.CoverId == 75 && x.IsAddonSelected))
              //addon list selected addon check
              let claimProtectorSAddon = this.addonList.find(x => (x.CoverId == 73 && x.IsAddonSelected))
              let CompansitateVisitSAddon = this.addonList.find(x => (x.CoverId == 74 && x.IsAddonSelected))
              let NursingAtHomeSAddon = this.addonList.find(x => (x.CoverId == 75 && x.IsAddonSelected))
              this.getInsuredArray.controls.forEach((insured: any) => {
                this.rnAddonService.setClaimProtectorConditions(insured, claimProtectorAddon, claimProtectorSAddon);
                this.rnAddonService.setCompansitateVisitConditions(insured, CompansitateVisitAddon, CompansitateVisitSAddon);
                this.rnAddonService.setNursingAtHomeConditions(insured, NursingAtHomeAddon, NursingAtHomeSAddon);
              })
            }
          }
          else {
            if (this.addonList.length > 0) {
              this.getInsuredArray.controls.forEach((insured: any) => {
                this.rnAddonService.setWorldWideCoverAddonConditions(insured, siStatus);
              })
            }
          }

        }
        if (this.renewalFormData.SubProductType == 40) {
          this.getInsuredArray.controls.forEach((insured: any) => {

          })
        }
      }))
    this.subscription.push(
      this.renewal_emit_service.hepAddon$.subscribe(data => {
        if (data) {
          if (data.addon.length > 0 && this.renewalFormData.SubProductType == 33 || this.renewalFormData.SubProductType == 32 || this.renewalFormData.SubProductType == 31 || this.renewalFormData.SubProductType == 30) {
            this.getInsuredArray.controls.forEach((insured: any) => {
              this.renewalFormData.addonList = data.addon;
              this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
              this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
              //check if world wide cover is added in addon list or not
              let worldwide = insured.get('insuredAddOn').value.find(x => x.CoverId == 63);
              if (this.utilityService.isEmptyOrNull(worldwide)) {
                let worldCoverldwide = data.addon.find(x => x.CoverId == 63);
                if (!this.utilityService.isEmptyOrNull(worldCoverldwide)) {
                  this.addonList.push(worldCoverldwide);
                  let addonIndex = this.addonList.findIndex(x => x.CoverId == 63);
                  let addonCover = this.addonList.find(x => x.CoverId == 63);
                  (this.insuredForm.get('insuredDetails') as FormArray).controls.forEach((x, index) => {
                    this.addAddonData(addonCover, index, addonIndex, x);
                  })
                  // Manually trigger change detection to update the view
                  this.cdr.detectChanges();
                }
              }
              //world wide cover select and deselect
              this.rnAddonService.setWorldWideAddonConditions(insured, data.si);
              // this.rnAddonService.setPersonalAccidentAddonConditionsForCHI(insured,data.si);
              //check befit cover is added in addon list or not
              let addonBefit = data.addon.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
              if (!this.utilityService.isEmptyOrNull(addonBefit)) {
                let addons = data.addon.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
                let existingBefit = this.addonList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId))
                if (this.utilityService.isEmptyOrNull(existingBefit)) {
                  addons.forEach(x => {
                    this.addonList.push(x);
                  })
                  let befitAddons = this.addonList.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
                  this.befitAddon = befitAddons;
                  befitAddons.forEach(xy => {
                    let addonIndex = this.addonList.findIndex(x => xy.CoverId == x.CoverId);
                    let addonCover = this.addonList.find(x => xy.CoverId == x.CoverId);
                    (this.insuredForm.get('insuredDetails') as FormArray).controls.forEach((x, index) => {
                      this.addAddonData(addonCover, index, addonIndex);
                    })
                  })
                }
                let insuredBefit = this.addonList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsAddonSelected);
                this.insuredBefit = insuredBefit.CoverText;
                this.onBefitSelectAddon(insured, insuredBefit.CoverText);
                // Manually trigger change detection to update the view
                this.cdr.detectChanges();
              } else {
                this.befitAddon = [];
              }
              this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
              // }
            })
          }
        }
      })
    )
    this.subscription.push(
      this.renewal_emit_service.onSubmitClickEvent$.subscribe(isSubmitClicked => {
        if (isSubmitClicked) {
          this.isSubmit = true;
          this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
        }
      })
    )
    this.subscription.push(
      this.renewal_emit_service.isBefitApplicableEvent$.subscribe(isBefitApplicable => {
        this.showBefit = isBefitApplicable;
        this.renewalFormData.IsBefitApplicable = isBefitApplicable;
        this.removeBefitAddons();
      })
    )
    this.subscription.push(
      this.renewal_emit_service.vdAddOnData$.subscribe(vdAddOnData => {
        if (!this.utilityService.isEmptyOrNull(vdAddOnData.vdValue)) {
          this.renewalFormData.VoluntaryDeductible = vdAddOnData.vdValue[0].value;

          this.getInsuredArray.controls.forEach((insured: any) => {
            //VD addon condition
            let insuredAddonArray = insured.get('insuredAddOn');
            let vdAddonIndex = insuredAddonArray.value.findIndex(x => [29].includes(x.CoverId));
            if (!this.utilityService.isEmptyOrNull(vdAddonIndex)) {
              let isAddonSelected = insured.get("insuredAddOn").at(vdAddonIndex).get("IsAddonSelected" + vdAddonIndex).value;
              if (isAddonSelected) {
                insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").patchValue(this.renewalFormData.VoluntaryDeductible);
              }
            }
          })
        }
      })
    )
    this.subscription.push(
      this.renewal_emit_service.occupation$.subscribe(occupationLst => {
        if (occupationLst) {
          this.occupationList = occupationLst;
          this.renewalFormData.occupationList = occupationLst;
          this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
        }
      })
    )
    this.insuredForm.valueChanges.subscribe(value => {
      this.renewal_emit_service.emitInsuredFormValueChange(value);
    })

    //max addon
    this.subscription.push(
      this.renewal_emit_service.maxProductType$.subscribe(data => {
        this.classicToPremium = true;
        this.hideAddon = false;
        this.renewalFormData.addonList = data.addon;
        this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
        this.renewalFormData.SubProductCode = data.productCode;
        this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
        this.getInsuredArray.controls.forEach((insured: any) => {
          //if premium is selected 64 and 65 addon will be removed
          if (data.productCode == '1135') {
            let premiumCOver = this.addonList.find(x => x.CoverId == 66);
            if (!premiumCOver) {
              let premiumAddon = data.addon.find(x => x.CoverId == 66);
              this.addonList.push(premiumAddon);
              let addonIndex = this.addonList.findIndex(x => x.CoverId == 66);
              let addonCover = this.addonList.find(x => x.CoverId == 66);
              (this.insuredForm.get('insuredDetails') as FormArray).controls.forEach((x, index) => {
                this.addAddonData(addonCover, index, addonIndex, x);
              })
            }
            this.addonList.forEach(x => {
              if ([65, 64].includes(x.CoverId)) {
                x.IsVisible = false;
                x.IsAddonSelected = false;
              }
            })
            // this.getInsuredArray.controls.forEach((insured: any, index: any) => {
            //   let insuredAddonsArray = insured.get('insuredAddOn');
            //   insuredAddonsArray.controls.map((addon: any, index: any) => {
            //     if (([64, 65].includes(addon.value.CoverId))) {
            //       if (addon.controls['IsAddonSelected' + index].value) {
            //         addon.controls['IsAddonSelected' + index].patchValue(false);
            //         addon.controls['IsVisible'].patchValue(false);
            //       }
            //     }
            //   })
            // })
          } else {
            this.addonList.forEach(x => {
              if ([66].includes(x.CoverId)) {
                x.IsVisible = false;
              }
            })
            // this.getInsuredArray.controls.forEach((insured: any, index: any) => {
            //   let insuredAddonsArray = insured.get('insuredAddOn');
            //   insuredAddonsArray.controls.map((addon: any, index: any) => {
            //     if (([66].includes(addon.value.CoverId))) {
            //       if (addon.controls['IsAddonSelected' + index].value) {
            //         addon.controls['IsAddonSelected' + index].patchValue(false);
            //         addon.controls['IsVisible'].patchValue(false);
            //       }
            //     }
            //   })
            // })
            let classicCOver = this.addonList.find(x => [64, 65].includes(x.CoverId));
            if (!classicCOver) {
              data.addon.forEach(x => {
                if ([64, 65].includes(x.CoverId)) {
                  this.addonList.push(x);
                }
              })
              let classicAddons = this.addonList.filter(x => [64, 65].includes(x.CoverId));
              classicAddons.forEach(xy => {
                let addonIndex = this.addonList.findIndex(x => xy.CoverId == x.CoverId);
                let addonCover = this.addonList.find(x => xy.CoverId == x.CoverId);
                (this.insuredForm.get('insuredDetails') as FormArray).controls.forEach((x, index) => {
                  this.addAddonData(addonCover, index, addonIndex);
                })
              })
            }
          }
          this.hideAddon = this.rnAddonService.setMAXAddonCondition(insured, data.productCode, this.classicToPremium, '');
        })
      })
    )
  }
  removeBefitAddons() {
    if (!this.showBefit) {
      this.getInsuredArray.controls.forEach((insured: any, index: any) => {
        let insuredAddonsArray = insured.get('insuredAddOn');
        insuredAddonsArray.controls.forEach((addon: any, index: any) => {
          if (([76, 77, 78, 79, 80, 81, 82, 83].includes(addon.value.CoverId) || [47, 48, 49, 50, 51, 52, 53].includes(addon.value.CoverId))) {
            if (addon.controls['IsAddonSelected' + index].value) {
              addon.controls['IsAddonSelected' + index].patchValue(false);
            }
          }
        })
      })

      this.addonList.forEach(x => {
        if (([76, 77, 78, 79, 80, 81, 82, 83].includes(x.CoverId) || [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId)) && x.IsAddonSelected) { x.IsAddonSelected = false; }
      });
      this.renewalFormData.addonList = this.addonList;
      this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
    }
    else {
      this.getInsuredArray.controls.forEach((insured: any, index: any) => {
        let insuredAddonsArray = insured.get('insuredAddOn');
        insuredAddonsArray.controls.forEach((addon: any, index: any) => {
          if (([76].includes(addon.value.CoverId) || [47].includes(addon.value.CoverId))) {
            addon.controls['IsAddonSelected' + index].patchValue(true);
          }
        })
      })

      let befit = this.addonList?.find(x => ([76].includes(x.CoverId) || [47].includes(x.CoverId)));
      this.addonList.forEach(x => {
        if (([76].includes(x.CoverId) || [47].includes(x.CoverId))) { x.IsAddonSelected = true; }
      });

      this.insuredBefit = this.utilityService.isEmptyOrNull(befit) ? '' : befit.CoverText;
    }
  }
  //insured details
  addInsuredData(insuredData, insuredIndex) {
    this.getInsuredFormArray().push(this.newInsuredData(insuredData, insuredIndex));
  }
  getInsuredFormArray(): FormArray {
    return this.insuredForm.get('insuredDetails') as FormArray;
  }
  get InsuredFormArray(): FormArray {
    return this.insuredForm.get('insuredDetails') as FormArray;
  }
  get getInsuredArray(): FormArray {
    return this.insuredForm.get('insuredDetails') as FormArray;
  }
  newInsuredData(insuredData, insuredIndex) {
    let Dob = insuredData.DateofBirth ? new Date(insuredData.DateofBirth) : '';
    let DateOfJoining = insuredData.DateOfJoining ? this.utilityService.formatDate(insuredData.DateOfJoining) : '';
    let vaccineDate = insuredData.VaccinationDate ? insuredData.VaccinationDate : '';
    // let insuredType = insuredData.KidAdultType? insuredData.KidAdultType == 2 ? 'adult' : 'child' : '';
    let memberName = `${insuredData.MemberType}${insuredIndex}`;
    let uwPedIllness = insuredData.UWPedIlness ? insuredData.UWPedIlness : null;
    let heightInFeet = this.utilityService.isUndefinedORNull(insuredData.HeightInFeet) ? '' : insuredData.HeightInFeet == 0 ? 0 : Number(insuredData.HeightInFeet);
    let heightInInches = this.utilityService.isUndefinedORNull(insuredData.HeightInInches) ? '' : insuredData.HeightInInches == 0 ? 0 : Number(insuredData.HeightInInches);
    let weight = this.utilityService.isUndefinedORNull(insuredData.Weight) ? '' : insuredData.Weight == 0 ? 0 : insuredData.Weight;
    let genderData = !this.utilityService.isEmptyOrNull(insuredData.RelationShipName) ? this.insuredAdultRelationsList?.find(x => x.RelationshipName == insuredData.RelationShipName) : { Gender: '' };
    return this.formBuilder.group({
      memberType: [insuredData.MemberType],
      insuredRelationWithAppl: [{ value: insuredData ? insuredData.RelationShipName ? insuredData.RelationShipName : '' : '', disabled: insuredData.IsExisting }, [Validators.required]],
      insuredTitle: [{ value: insuredData ? insuredData.TitleId ? insuredData.TitleId.toString() : '' : '', disabled: insuredData.IsExisting }, [Validators.required]],
      insuredName: [{ value: insuredData ? insuredData.InsuredName ? insuredData.InsuredName : '' : '', disabled: insuredData.IsExisting }, [Validators.required, Validators.pattern(patterns.alphabetsOnly)]],
      insuredDOB: [{ value: Dob || '', disabled: insuredData.IsExisting }, [Validators.required]],
      insuredHeightInFeet: [{ value: heightInFeet, disabled: insuredData.IsExisting }, [Validators.required]],
      insuredHeightInInches: [{ value: heightInInches, disabled: insuredData.IsExisting }, [Validators.required]],
      insuredWeight: [{ value: weight, disabled: insuredData.IsExisting }, [Validators.required, Validators.pattern(patterns.weight)]],
      isPreExistingDisease: [{ value: insuredData ? insuredData.PedIllness == null || insuredData.PedIllness == undefined || insuredData.PedIllness.length == 0 || insuredData.PedIllness == 'None' ? false : true : false, disabled: insuredData.IsExisting }],//
      insuredPED: this.formBuilder.array([]),
      insuredAddOn: this.formBuilder.array([]),
      insuredDateOfJoining: [DateOfJoining || ''],
      insuredMemberName: memberName,
      isExistingInsured: [insuredData ? insuredData.IsExisting ? insuredData.IsExisting : false : false],
      // insuredGender: [{ value: insuredData ? insuredData.Gender ? insuredData.Gender : '' : '', disabled: insuredData.IsExisting }, [Validators.required]],
      insuredVaccineDate: [vaccineDate],
      isVaccineTaken: [insuredData.IsPneumococcalVaccination ? insuredData.IsPneumococcalVaccination : false],
      insuredUWPedIllness: [uwPedIllness],
      insuredGender: [!this.utilityService.isEmptyOrNull(genderData) ? genderData.Gender.toLowerCase() : 'mf'],
      InsuredLoading: [insuredData && this.renewalFormData.SubProductType == 42 ? insuredData.IsExisting ? insuredData.InsuredLoading : 0 : undefined],
    })
  }
  //PED Details
  getPEDDetailsFormArray(insuredIndex: number): FormArray {
    return this.getInsuredFormArray()
      .at(insuredIndex)
      .get('insuredPED') as FormArray;
  }
  newPEDData(PEDItem, PEDIndex, SelectedPEDArray?) {
    const fields = {};
    fields['PEDName'] = [PEDItem.Name];
    fields['PEDCode'] = [PEDItem.Value].toString();
    if (this.utilityService.isUndefinedORNull(SelectedPEDArray)) {
      fields['PEDSelected' + PEDIndex] = [false];
    }
    else {
      let selectedPED = SelectedPEDArray.find(x => x.PedCode == PEDItem.Value);
      if (this.utilityService.isUndefinedORNull(selectedPED)) {
        fields['PEDSelected' + PEDIndex] = [false];
      }
      else {
        fields['PEDSelected' + PEDIndex] = [true];
      }
    }
    return this.formBuilder.group(fields);
  }
  addPEDData(PEDItem, insuredIndex, PEDIndex, SelectedPEDArray?) {
    //on select of ped check emi if yes then show pop-up and redirect to summary
    this.getPEDDetailsFormArray(insuredIndex).push(this.newPEDData(PEDItem, PEDIndex, SelectedPEDArray));
  }
  //addon details
  getAddonDetailsFormArray(insuredIndex: number): FormArray {
    return this.getInsuredFormArray()
      .at(insuredIndex)
      .get('insuredAddOn') as FormArray;
  }


  newAddonData(addonItem, addonIndex, insured?) {
    let tempCoPay: number = 0;
    tempCoPay = typeof (this.renewalFormData.VoluntaryCopay) == 'string' ? parseInt(this.renewalFormData.VoluntaryCopay) : this.renewalFormData.VoluntaryCopay;

    let selectedAddonList = this.utilityService.isUndefinedORNull(insured) ? [] : insured.NewAddOnCoverDetails;
    const fields: any = {
      CoverCode: [addonItem.CoverCode ? addonItem.CoverCode : ''],
      CoverId: [addonItem.CoverId ? addonItem.CoverId : 0],
      CoverText: [this.renewalFormData.SubProductType == 34 ? addonItem.Description ? addonItem.Description.replaceAll('_', ' ') : '' : addonItem.CoverText ? addonItem.CoverText : ''],
      CoverVariantType: [addonItem.CoverVariantType ? addonItem.CoverVariantType : ''],
      Description: [addonItem.Description ? addonItem.Description : ''],
      IsForAdult: [addonItem.IsForAdult ? addonItem.IsForAdult : false],
      IsForChild: [addonItem.IsForChild ? addonItem.IsForChild : false],
      IsForFemaleAdult: [addonItem.IsForFemaleAdult ? addonItem.IsForFemaleAdult : false],
      IsVisible: [addonItem.IsVisible ? addonItem.IsVisible : false],
      SiLimit: [addonItem.SiLimit ? addonItem.SiLimit : 0.0],
      maxAgeAllowed: [addonItem.maxAgeAllowed ? addonItem.maxAgeAllowed : 0],
      insuredOccupation: [{ value: '', disabled: true }],
      insuredCoPay: [tempCoPay],
      insuredVDValue: [this.renewalFormData.VoluntaryDeductible ? Number(this.renewalFormData.VoluntaryDeductible) : 0],
    };
    if (this.renewalFormData.SubProductType == 34) {
      fields['ApplicableSubProductList'] = [addonItem.ApplicableSubProductList ? addonItem.ApplicableSubProductList : []]
    }
    if (this.utilityService.isUndefinedORNull(selectedAddonList)) {
      fields['IsAddonSelected' + addonIndex] = [addonItem.IsAddonSelected];

    }
    else {
      let addon = selectedAddonList.find(x => x.CoverId == addonItem.CoverId)
      if (this.utilityService.isUndefinedORNull(addon)) {
        fields['IsAddonSelected' + addonIndex] = [addonItem.IsAddonSelected]
      }
      else {
        fields['IsAddonSelected' + addonIndex] = [addon.IsAddonSelected]
        if (addonItem.CoverId == 39 || addonItem.CoverId == 71) {
          fields['insuredOccupation'] = insured.MemberType == healthMembers.Adult ? [insured.Occupation] : ['Student'];
        }
      }
    }
    return this.formBuilder.group(fields);
  }
  addAddonData(addonItem, insuredIndex, addonIndex, insured?) {
    this.getAddonDetailsFormArray(insuredIndex).push(this.newAddonData(addonItem, addonIndex, insured));
  }
  //update adult and child buttons
  updateAdultchildButtons() {
    this.tabGroup.forEach(tabdata => {
      tabdata['memberDetails'] = this.getInsuredArray.controls.filter((data: any) => data.controls['memberType'].value == tabdata.tabLabel);
    })
  }
  //rearrage member names
  reArrangeMemberName() {
    let adultCount = 0, childCount = 0;
    this.getInsuredArray.controls.forEach(data => {
      let memberCount = data['controls']['memberType'].value == healthMembers.Adult ? ++adultCount : ++childCount;
      data['controls']['insuredMemberName'].patchValue(data['controls']['memberType'].value + memberCount);
    })
  }
  //rearrange addonlist for UI
  reArrangeAddonList() {
    switch (this.renewalFormData.SubProductType) {
      case 42:
        if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
          //personal accident and maternity covers
          let addonset = [68, 69, 70, 71];
          addonset.forEach((coverId: number) => this.setAddonAtLastOfArray(coverId));
        } else {
          let addonset = [34, 35, 36, 39];
          addonset.forEach((coverId: number) => this.setAddonAtLastOfArray(coverId));
        }
        break;
      case 30:
        //personal accident and critical illness,
        let addons = [59, 60];
        addons.forEach((coverId: number) => this.setAddonAtLastOfArray(coverId));
        break;
      case 32:
        //personal accident and maternity covers, critical illness,
        let addonset = [59, 61, 60];
        addonset.forEach((coverId: number) => this.setAddonAtLastOfArray(coverId));
        break;
      default:
        break;
    }

  }
  setAddonAtLastOfArray(coverId: number) {
    let cover = this.addonList.find(addon => addon.CoverId == coverId);
    if (!this.utilityService.isUndefinedORNull(cover)) {
      this.addonList.splice(this.addonList.indexOf(cover), 1);
      this.addonList.push(cover);
    }
  }
  //Min max date
  getInsuredMinMaxDate(member, relationshipName) {
    let adultLength = this.getMembersLength(healthMembers.Adult)
    //GS
    if (this.renewalFormData.SubProductType == 40) {
      this.minMaxDate = this.renewal_date_service.gsInsuredDate();
      return this.minMaxDate;
    }
    //HAAP
    if (this.renewalFormData.SubProductType == 42 && this.renewalFormData.ZoneRefilingflag.toLowerCase() == 'no') {
      this.minMaxDate = this.renewal_date_service.hapInsuredDate(member, adultLength);
      return this.minMaxDate;
    }
    //HAAP refiling 
    if (this.renewalFormData.SubProductType == 42 && this.renewalFormData.ZoneRefilingflag.toLowerCase() == 'yes') {
      this.minMaxDate = this.renewal_date_service.hapRefilingInsuredDate(member, adultLength, relationshipName);
      return this.minMaxDate;
    }
    //asp
    if (this.renewalFormData.SubProductType == 22) {
      this.minMaxDate = this.renewal_date_service.aspInsuredDate(member, adultLength);
      return this.minMaxDate;
    }
    //hep
    if ((this.renewalFormData.SubProductType == 33 || this.renewalFormData.SubProductType == 32 || this.renewalFormData.SubProductType == 31 || this.renewalFormData.SubProductType == 30)) {
      this.minMaxDate = this.renewal_date_service.hepInsuredDate(member, adultLength);
      return this.minMaxDate;
    }
    //max
    if (this.renewalFormData.SubProductType == 34) {
      this.minMaxDate = this.renewal_date_service.maxInsuredDate(member, adultLength);
      return this.minMaxDate;
    }
    //HB
    if (this.renewalFormData.SubProductType == 20 || this.renewalFormData.SubProductType == 19) {
      return this.minMaxDate = this.renewal_date_service.hbInsuredDate(member, adultLength);
    }
    else {
      this.minMaxDate = { minDate: new Date(), maxDate: new Date() };
      return this.minMaxDate;
    }
  }
  //on button click events
  memberTypeSelected(event) {
    this.setDefaultTabSelection(event.index);
  }
  insuredChanged(type: string, index, memLabel: string) {
    this.memberLabel = memLabel;
    this.getInsuredMinMaxDate(type, this.getInsuredArray.at(index).value.insuredRelationWithAppl)
  }
  onclickDeleteMember(memberName: string) {
    if (!this.utilityService.isUndefinedORNull(memberName)) {
      let insured = this.getInsuredArray.value.find(x => x.insuredMemberName == memberName);
      let memberType = insured.memberType;

      let AdultLength = this.getMembersLength(healthMembers.Adult);
      let ChildLength = this.getMembersLength(healthMembers.Child);
      let totalMembers = AdultLength + ChildLength;
      if (totalMembers > 1) {
        if (this.renewalFormData.ZoneRefilingflag == healthToggle.No) {
          if (memberType == healthMembers.Adult) {
            switch (AdultLength) {
              case 1:
                if (ChildLength == 2) {
                  let popupData: PopupModal = {
                    popupType: popupType.generic,
                    imgName: popupImgPath.alertGif,
                    header: popupHeader.confirmdelete,
                    description: 'Please delete 1 child before deleting adult, because As per policy only 1 independent child is allowed.',
                    buttonLabel: popupButton.ok
                  }
                  this.popupService.openGenericPopup(popupData);

                } else if (ChildLength == 1) {
                  this.getInsuredArray.controls.forEach((member: FormGroup) => {
                    let isExistingInsured = member.get('isExistingInsured').value;
                    if (!isExistingInsured) {
                      member.get('insuredDOB').patchValue('');
                      member.get('insuredRelationWithAppl').patchValue('');
                      this.deleteMemberFromInsuredArray(memberName);
                    } else {
                      let popupData: PopupModal = {
                        popupType: popupType.generic,
                        imgName: popupImgPath.alertGif,
                        header: popupHeader.confirmdelete,
                        description: 'Existing insured cannot be deleted.',
                        buttonLabel: popupButton.ok
                      }
                      this.popupService.openGenericPopup(popupData);
                    }
                  })
                }
                else {
                  let popupData: PopupModal = {
                    popupType: popupType.generic,
                    imgName: popupImgPath.alertGif,
                    header: popupHeader.confirmdelete,
                    description: 'Please add 1 child before deleting adult, because As per policy atleast 1 member should be added.',
                    buttonLabel: popupButton.ok
                  }
                  this.popupService.openGenericPopup(popupData);
                }
                break;
              case 2:
                if (ChildLength == 3) {
                  // this.deleteMemberFromInsuredArray(healthMembers.Child + ChildLength);
                  // this.deleteMemberFromInsuredArray(memberName);
                  let popupData: PopupModal = {
                    popupType: popupType.generic,
                    imgName: popupImgPath.alertGif,
                    header: popupHeader.confirmdelete,
                    description: 'Please delete 1 child before deleting adult, because As per policy for 1 Adult only 2 Childs are allowed.',
                    buttonLabel: popupButton.ok
                  }
                  this.popupService.openGenericPopup(popupData);
                }
                else {
                  this.deleteMemberFromInsuredArray(memberName);
                }
                break;
              default:
                break;
            }
          } else {
            this.deleteMemberFromInsuredArray(memberName);
          }
        } else {
          if (memberType == healthMembers.Adult) {
            switch (AdultLength) {
              case 1:
                if (ChildLength > 1) {
                  let popupData: PopupModal = {
                    popupType: popupType.generic,
                    imgName: popupImgPath.alertGif,
                    header: popupHeader.confirmdelete,
                    description: 'Please delete 1 child before deleting adult, because As per policy only 1 independent child is allowed.',
                    buttonLabel: popupButton.ok
                  }
                  this.popupService.openGenericPopup(popupData);
                }
                else {
                  this.getInsuredArray.controls.forEach((member: FormGroup) => {
                    let isExistingInsured = member.get('isExistingInsured').value;
                    if (!isExistingInsured) {
                      member.get('insuredDOB').patchValue('');
                      member.get('insuredRelationWithAppl').patchValue('');
                      this.deleteMemberFromInsuredArray(memberName);
                    } else {
                      let popupData: PopupModal = {
                        popupType: popupType.generic,
                        imgName: popupImgPath.alertGif,
                        header: popupHeader.confirmdelete,
                        description: 'Existing insured cannot be deleted.',
                        buttonLabel: popupButton.ok
                      }
                      this.popupService.openGenericPopup(popupData);
                    }
                  })
                }
                break;
              case 2:
                if (ChildLength > 2) {
                  let popupData: PopupModal = {
                    popupType: popupType.generic,
                    imgName: popupImgPath.alertGif,
                    header: popupHeader.confirmdelete,
                    description: 'Please delete 1 child before deleting adult, because As per policy for 1 Adult only 2 Childs are allowed.',
                    buttonLabel: popupButton.ok
                  }
                  this.popupService.openGenericPopup(popupData);
                }
                else {
                  this.deleteMemberFromInsuredArray(memberName);
                }
                break;
              default:
                this.deleteMemberFromInsuredArray(memberName);
                break;
            }
          }
          else {
            //Child delete
            this.deleteMemberFromInsuredArray(memberName);
          }

        }
      } else {
        const popupString = 'Atleast 1 insured is required';
        this.utilityService.popUpDialog(popupString);
        if (AdultLength <= 0 && ChildLength >= 1) {
          this.getInsuredArray.controls.forEach((member: FormGroup) => {
            let isExistingInsured = member.get('isExistingInsured').value;
            let realtionship = member.get('insuredRelationWithAppl').value;
            if (!isExistingInsured && !this.utilityService.isUndefinedORNull(realtionship)) {
              this.getInsuredMinMaxDate(member, realtionship.toLowerCase());
            }

          })
        }
      }
    }
  }
  deleteMemberFromInsuredArray(memberName) {
    let memberIndex: number = this.getInsuredArray.value.findIndex(x => x.insuredMemberName == memberName);
    let insured = this.getInsuredArray.value.find(x => x.insuredMemberName == memberName);
    let tabIndex = insured.memberType == healthMembers.Adult ? 0 : 1;
    if (memberIndex != -1) {
      this.getInsuredArray.removeAt(memberIndex);
    }
    this.reArrangeMemberName();
    this.updateAdultchildButtons();
    this.setDefaultTabSelection(tabIndex);
  }
  onclickAddMember(memberType: string) {
    let AdultLength = this.getMembersLength(healthMembers.Adult);
    let ChildLength = this.getMembersLength(healthMembers.Child);
    let totalMembers = AdultLength + ChildLength;

    if (this.renewalFormData.SubProductType == 42 && this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
      //Renewal refiling
      if (totalMembers < 7) {
        if (memberType == healthMembers.Adult) {
          if (AdultLength < 6) {
            this.pushMemberToInsuredArray(memberType);
          } else {
            const popupString = 'Maximum 6 Adults are allowed to add';
            this.utilityService.popUpDialog(popupString);
          }
        } else {
          if (ChildLength < 3) {
            switch (AdultLength) {
              case 0:
                if (ChildLength >= 1) {
                  const popupString = 'Maximum 1 child are allowed to add';
                  this.utilityService.popUpDialog(popupString);
                } else {
                  this.pushMemberToInsuredArray(memberType);
                }
                break;
              case 1:
                if (ChildLength >= 2) {
                  const popupString = 'Maximum 2 child allowed to add for 1 adult';
                  this.utilityService.popUpDialog(popupString);
                }
                else {
                  this.pushMemberToInsuredArray(memberType);
                }
                break;
              default:
                this.pushMemberToInsuredArray(memberType);
                break;
            }
          } else {
            const popupString = 'Maximum 3 Childs are allowed to add';
            this.utilityService.popUpDialog(popupString);
          }
        }
      } else {
            const popupString = 'Maximum 7 insured are allowed to add';
            this.utilityService.popUpDialog(popupString);
      }
    }
    else {
      //Renewals
      if (memberType == healthMembers.Adult) {
        if (AdultLength < 2) {
          this.pushMemberToInsuredArray(memberType);
        } else {
          const popupString = 'Maximum 2 Adults are allowed to add';
          this.utilityService.popUpDialog(popupString);
        }
      } else {
        switch (AdultLength) {
          case 0:
            if (ChildLength >= 1) {
              const popupString = 'Maximum 1 child allowed to add';
              this.utilityService.popUpDialog(popupString);
            } else {
              this.pushMemberToInsuredArray(memberType);
            }
            break;
          case 1:
            if (ChildLength >= 2) {
              const popupString = 'Maximum 2 child allowed to add';
              this.utilityService.popUpDialog(popupString);
            }
            else {
              this.pushMemberToInsuredArray(memberType);
            }
            break;
          case 2:
            if (ChildLength >= 3) {
              const popupString = 'Maximum 3 child allowed to add';
              this.utilityService.popUpDialog(popupString);
            }
            else {
              this.pushMemberToInsuredArray(memberType);
            }
            break;
          default:
            break;
        }
      }
    }
  }
  pushMemberToInsuredArray(memberType: string) {
    let dataRequired = {
      'DateOfJoining': this.utilityService.formatDate1(new Date()),
      'isExistingInsured': false,
      'MemberType': memberType
    }
    let insuredLength = this.getInsuredArray.value.length;
    let memberTypeLength = this.getMembersLength(memberType);
    this.addInsuredData(dataRequired, memberTypeLength + 1);
    if (this.diseaseList) {
      for (let i = 0; i < this.diseaseList.length; i++) { this.addPEDData(this.diseaseList[i], insuredLength, i); }
    }
    if (this.renewalFormData.SubProductType == 42) {
      this.reArrangeAddonList();
    }
    if (this.addonList) {
      for (let j = 0; j < this.addonList.length; j++) { this.addAddonData(this.addonList[j], insuredLength, j); }
    }
    let newInsured = this.getInsuredArray.at(insuredLength);
    this.setAddonConditions(newInsured, 'newMember');
    this.reArrangeMemberName();
    this.updateAdultchildButtons();
    this.memberLabel = `${memberType}${memberTypeLength + 1}`
  }
  onClickSelectAddon(insuredMember, addOnIndex, selectedCover, event) {
    switch (this.renewalFormData.SubProductType) {
      case 42:
        if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
          if (selectedCover.value.CoverId == 68) {
            //Maternity cover
            insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
            this.rnAddonService.setNewBornAndVaccinationAddonConditions(insuredMember);
          }
          else if (selectedCover.value.CoverId == 67) {
            //critical illness
            insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          }
          else if (selectedCover.value.CoverId == 71) {
            //personal accident
            insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
            this.setOccupationInput(insuredMember, event.checked, addOnIndex)
          }
          else {
            this.getInsuredArray.controls.forEach((insured: any) => {
              insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
            });
            this.addonList.forEach(addon => {
              if (addon.CoverId == selectedCover.value.CoverId) {
                addon.IsAddonSelected = event.checked;
              }
            })
          }
        }
        else {
          if (selectedCover.value.CoverId == 34) {
            //Maternity cover
            insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
            this.rnAddonService.setNewBornAndVaccinationAddonConditions(insuredMember);
          }
          else {
            this.getInsuredArray.controls.forEach((insured: any) => {
              insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
              if (selectedCover.value.CoverId == 39) {
                this.setOccupationInput(insured, event.checked, addOnIndex)
              }
            });
            this.addonList.forEach(addon => {
              if (addon.CoverId == selectedCover.value.CoverId) {
                addon.IsAddonSelected = event.checked;
              }
            })
          }
        }
        break;
      case 40:
        this.getInsuredArray.controls.forEach((insured: any) => {
          insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          if (selectedCover.value.CoverId == 29 || selectedCover.value.CoverId == 28) {
            this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
            this.rnAddonService.setGSAddonCondition(selectedCover.value.CoverId, insured);
            this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

          }
        });
        if (!event.checked) {
          if (selectedCover.value.CoverId == 29) {
            this.renewalFormData.VoluntaryDeductible = this.renewalStorageService.rnFetchVoluntaryDeductible;
          }
          if (selectedCover.value.CoverId == 28) {
            this.renewalFormData.VoluntaryCopay = this.renewalStorageService.rnFetchVoluntaryCopay;
            this.onVoluntaryCopayChange(this.renewalStorageService.rnFetchVoluntaryCopay);
          }
        }
        break;
      case 20:
        if ([15, 16, 17, 18].includes(selectedCover.value.CoverId)) {

          insuredMember.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          let membertype = insuredMember.get('memberType').value;
          if (membertype == healthMembers.Adult) {
            if (selectedCover.value.CoverId == 15 && insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).value) {
              let silverIndex = insuredMember.get("insuredAddOn").value.findIndex(x => x.CoverId == 16);
              insuredMember.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
            }
            if (selectedCover.value.CoverId == 16 && insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).value) {
              let silverIndex = insuredMember.get("insuredAddOn").value.findIndex(x => x.CoverId == 15);
              insuredMember.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
            }
            if (selectedCover.value.CoverId == 17 && insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).value) {
              let silverIndex = insuredMember.get("insuredAddOn").value.findIndex(x => x.CoverId == 18);
              insuredMember.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
            }
            if (selectedCover.value.CoverId == 18 && insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).value) {
              let silverIndex = insuredMember.get("insuredAddOn").value.findIndex(x => x.CoverId == 17);
              insuredMember.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
            }
          }
        } else {
          this.getInsuredArray.controls.forEach((insured: any) => {
            let insuredAddonsArray = insured.get('insuredAddOn');
            if ([13, 14].includes(selectedCover.value.CoverId)) {
              insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);

              insuredAddonsArray.controls.forEach((addon: any, index) => {
                if (selectedCover.value.CoverId == addon.value.CoverId) {
                  if (addon.value.CoverId == 13 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
                    let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 14);
                    insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
                  }
                  if (addon.value.CoverId == 14 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
                    let goldIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 13);
                    insured.get("insuredAddOn").at(goldIndex).get("IsAddonSelected" + goldIndex).patchValue(false);
                  }

                }
              })
            }
          });
        }
        break;
      case 30:
        if (selectedCover.value.CoverId == 59) {
          //critical illness
          insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        }
        else if (selectedCover.value.CoverId == 60) {
          //personal accident
          insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          this.setOccupationInput(insuredMember, event.checked, addOnIndex)
        }
        else {
          this.getInsuredArray.controls.forEach((insured: any) => {
            insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          });
        }
        break;
      case 32:
        if (selectedCover.value.CoverId == 61) {
          //Maternity cover
          insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        }
        else if (selectedCover.value.CoverId == 59) {
          //critical illness
          insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        }
        else if (selectedCover.value.CoverId == 60) {
          //personal accident
          insuredMember.get("insuredAddOn").at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          this.setOccupationInput(insuredMember, event.checked, addOnIndex)
        }
        else {
          this.getInsuredArray.controls.forEach((insured: any) => {
            insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
          });
        }
        break;
      case 31:
      case 33:
        this.getInsuredArray.controls.forEach((insured: any) => {
          insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        });
        break;
      case 34:
        this.getInsuredArray.controls.forEach((insured: any) => {
          insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        });
        break;
      default:
        this.getInsuredArray.controls.forEach((insured: any) => {
          insured.get('insuredAddOn').at(addOnIndex).get("IsAddonSelected" + addOnIndex).patchValue(event.checked);
        });
        break;
    }
    this.addonList.forEach(addon => {
      if (addon.CoverId == selectedCover.value.CoverId) {
        addon.IsAddonSelected = event.checked;
      }
    })
  }
  onBefitSelectAddon(insuredMember, selectedCover) {
    let insuredAddonArray = insuredMember.get('insuredAddOn');
    //selected befit cover index extact
    let befitId = this.addonList.find(x => x.CoverText == selectedCover).CoverId;
    let befitAddonIndex = insuredAddonArray.value.findIndex(x => x.CoverId == befitId);
    //already select befit cover index extact
    let alreadySelectedBefitCover = this.addonList.find(x => ([76, 77, 78, 79, 80, 81, 82, 83].includes(x.CoverId) || [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId)) && x.IsAddonSelected);
    if (alreadySelectedBefitCover != undefined) {
      let alreadyBefitAddonIndex = insuredAddonArray.value.findIndex(x => x.CoverId == alreadySelectedBefitCover.CoverId);
      //change the value of already selected befit cover to false and selected befit cover to true
      this.getInsuredArray.controls.forEach((insured: any) => {
        insured.get('insuredAddOn').at(alreadyBefitAddonIndex).get("IsAddonSelected" + alreadyBefitAddonIndex).patchValue(false);
        insured.get('insuredAddOn').at(befitAddonIndex).get("IsAddonSelected" + befitAddonIndex).patchValue(true);
      });
    } else {
      this.getInsuredArray.controls.forEach((insured: any) => {
        insured.get('insuredAddOn').at(befitAddonIndex).get("IsAddonSelected" + befitAddonIndex).patchValue(true);
      });
    }
    this.addonList.forEach(addon => {
      if (([76, 77, 78, 79, 80, 81, 82, 83].includes(addon.CoverId) || [47, 48, 49, 50, 51, 52, 53].includes(addon.CoverId))) {
        if (addon.CoverId == befitId) {
          addon.IsAddonSelected = true;
        } else {
          addon.IsAddonSelected = false;
        }
      }
    })
    this.renewalFormData.addonList = this.addonList;
    this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
    this.insuredBefit = selectedCover;
  }
  onVoluntaryCopayChange(copay) {
    this.getInsuredArray.controls.forEach((insured: any) => {
      let insuredAddonArray = insured.get('insuredAddOn');
      let copayAddonIndex = insuredAddonArray.value.findIndex(x => [28].includes(x.CoverId));
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").patchValue(copay);
    });
    this.renewalFormData.VoluntaryCopay = copay;
    this.copayChangeEvent.emit(copay);
  }
  onclickIsPEDSelected(value, insuredIndex, patchAddonValue) {
    if (!value) {
      ((this.getInsuredArray.at(insuredIndex) as FormGroup).get(`insuredPED`) as FormArray).controls.forEach((element, diseaseIndex) => {
        if (element.value[`PEDSelected${diseaseIndex}`]) {
          element.get(`PEDSelected${diseaseIndex}`).patchValue(false);
        }
      });
    }
    switch (this.renewalFormData.SubProductType) {
      case 42:
        if (this.renewalFormData.InstallmentApplicable && value) {
          this.renewalFormData.emiValidationPopup = true;
          this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
          this.popupService.openGenericPopup(this.errorPopupData);
          this.route.navigate([routeEnums.RenewalSummary]);
        } else {
          this.renewalFormData.emiValidationPopup = false;
          let insured = this.getInsuredArray.controls[insuredIndex];
          if (this.renewalFormData.IsHaapRefiling) {
            this.rnAddonService.setCriticalIllnessAddonConditions(insured, value, patchAddonValue);
          }
        }
        break;
      case 30:
      case 31:
      case 32:
      case 33:
        if (this.renewalFormData.InstallmentApplicable && value) {
          this.renewalFormData.emiValidationPopup = true;
          this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
          this.popupService.openGenericPopup(this.errorPopupData);
          this.route.navigate([routeEnums.RenewalSummary]);
        } else {
          this.renewalFormData.emiValidationPopup = false;
        }
        break;
      default:
        break;
    }
  }
  onClickDisease(insuredIndex, diseaseIndexVal, flag) {
    ((this.getInsuredArray.at(insuredIndex) as FormGroup).get(`insuredPED`) as FormArray).controls.forEach((element, diseaseIndex) => {
      if (diseaseIndexVal == diseaseIndex) {
        element.get(`PEDSelected${diseaseIndex}`).patchValue(JSON.parse(flag));
      }
    });
  }
  patchExistingAddon() {
    let selectedList = [];
    this.renewalFormData.multiPlanDtls[0].insuredDetails.forEach((insured, index) => {
      if (!this.utilityService.isUndefinedORNull(insured.NewAddOnCoverDetails)) {
        insured.NewAddOnCoverDetails.forEach(addon => {
          if (addon['IsAddonSelected'])
            selectedList.push(addon)
        })
      }
    });

    switch (this.renewalFormData.SubProductType) {
      case 42:
        let addon;
        if (this.renewalFormData.ZoneRefilingflag.toLowerCase() == 'yes') {
          addon = selectedList.filter(x => [72, 76, 77, 78, 79, 80, 81, 82, 83].includes(x.CoverId) && x.IsAddonSelected);
        } else {
          addon = selectedList.filter(x => [42, 43, 44].includes(x.CoverId) && x.IsAddonSelected);
        }
        if (!this.utilityService.isEmptyOrNull(addon) && addon.length > 0) {
          addon.forEach(x => {
            this.addonList?.map((addonItem, index) => {
              if (addonItem.CoverId == x.CoverId) {
                addonItem.IsAddonSelected = true;
              }
            })
          })
        }
        break;
      case 20:
        let selectedAddon = selectedList.filter(x => [13, 14].includes(x.CoverId) && x.IsAddonSelected);
        if (!this.utilityService.isEmptyOrNull(selectedAddon) && selectedAddon.length > 0) {
          selectedAddon.forEach(x => {
            this.addonList?.map((addonItem, index) => {
              if (addonItem.CoverId == x.CoverId) {
                addonItem.IsAddonSelected = true;
              }
            })
          })
        }
        break;
      case 30:
      case 31:
      case 32:
      case 33:
        let selectedsAddon = selectedList.filter(x => ![60].includes(x.CoverId));
        let befitAddon = selectedList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsAddonSelected)
        console.log(befitAddon);
        selectedsAddon.forEach(x => {
          this.addonList?.map((insured, index) => {
            if (insured.CoverId == x.CoverId) {
              insured.IsAddonSelected = true;
            }
          })
        })
        if (!this.utilityService.isUndefinedORNull(befitAddon)) {
          this.addonList.forEach((x) => {
            if ([47, 48, 49, 50, 51, 52, 53].includes(x.CoverId)) {
              if (x.CoverId == befitAddon.CoverId) {
                x.IsAddonSelected = true;
              } else {
                x.IsAddonSelected = false;
              }
            }
          })
        }
        break;
      case 34:
        if (!this.classicToPremium) {
          if (parseInt(this.renewalFormData.SubProductCode) == 1135 && this.utilityService.isEmptyOrNull(selectedList)) {
            this.hideAddon = true;
          } else {
            this.hideAddon = false;
          }
          selectedList.forEach(x => {
            this.addonList?.map((insured, index) => {
              if (insured.CoverId == x.CoverId) {
                insured.IsAddonSelected = true;
              }
            })
          })
        } else {
          if (!this.hideAddon) {
            this.getInsuredArray.controls.forEach((insured: any, index: any) => {
              let insuredAddonsArray = insured.get('insuredAddOn');
              insuredAddonsArray.controls.map((addon: any, index: any) => {
                if (([66].includes(addon.value.CoverId))) {
                  let addonindex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 66);
                  insured.get("insuredAddOn").at(addonindex).get("IsAddonSelected" + addonindex).patchValue(true);
                }
              })
            })
          }
        }
        break;
      default:
        selectedList.forEach(x => {
          this.addonList?.map((insured, index) => {
            if (insured.CoverId == x.CoverId) {
              insured.IsAddonSelected = true;
            }
          })
        })
        break;
    }
  }

  onChangeInsuredDob(insuredIndex: number, patchAddonValue) {
    if (this.renewalFormData.SubProductType === 42) {
      if (this.renewalFormData.ZoneRefilingflag === healthToggle.Yes) {
        let insured = this.getInsuredArray.controls[insuredIndex];
        let selectedPed = this.getPedDataForRenewalObject(insured.get('insuredPED'));
        this.rnAddonService.setCriticalIllnessAddonConditions(insured, selectedPed.length > 0 ? true : false, patchAddonValue);
        this.rnAddonService.setMaternityAddonConditions(insured);
        this.rnAddonService.setPersonalAccidentAddonConditions(insured);
      }
    } 
  }
  onChangeInsuredTitle(insured) {
    switch (this.renewalFormData.SubProductType) {
      case 42:
        this.rnAddonService.setMaternityAddonConditions(insured);
        break;
      case 32:
        this.rnAddonService.setMaternityAddonConditionsForCHI(insured);
        break;
      default:
        break;
    }
  }
  onChangeRelationship(member, insured, index, value, patchAddonValue) {
    //check self relationship cannot be opt 2 times
    let selectedRelationship = [];
    this.getInsuredArray.controls.forEach((x: FormGroup) => {
      selectedRelationship.push(x.controls['insuredRelationWithAppl'].value);
    })
    selectedRelationship = selectedRelationship.filter(x => x.toLowerCase() == 'self');
    if (selectedRelationship.length > 1) {
      insured.get('insuredRelationWithAppl').patchValue('');
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
      this.popupService.openGenericPopup(this.errorPopupData);
    }
    else {
      switch (this.renewalFormData.SubProductType) {

        case 32:
          this.rnAddonService.setMaternityAddonConditionsForCHI(insured);
          break;
        case 42:
          this.rnAddonService.setMaternityAddonConditions(insured);
          break;
        default:
          break;
      }
      this.titleChangeAsPerRelationshipGender(insured, index, value);
      this.getInsuredMinMaxDate(member, value.toLowerCase());
      insured.get('insuredDOB').patchValue('');
      this.onChangeInsuredDob(index, patchAddonValue);
    }
  }
  titleChangeAsPerRelationshipGender(insured, index, value) {
    this.title = [];
    let data = this.insuredAdultRelationsList.find(x => x.RelationshipName == value);
    this.insuredForm.controls['insuredDetails']['controls'][index].controls['insuredGender'].patchValue(data.Gender.toLowerCase());
  }
  onselectOccupationOption(occupation, insuredMember, addonIndex) {
    if (insuredMember.value.memberType == healthMembers.Adult) {
      let risk = this.occupationList.find(d => d.value === occupation).key;
      if (risk == '3' || risk == '4') {
        const popupString = 'Selected occupation is not applicable for PA cover';
        this.utilityService.popUpDialog(popupString);
        insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").patchValue('');
        insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").updateValueAndValidity();
      }
    }
  }
  //utility methods
  getMembersLength(memberType: string) {
    let AdultMembers = this.getInsuredArray.controls.filter((data: any) => data.controls['memberType'].value == memberType);
    return AdultMembers ? AdultMembers.length : 0;
  }
  getPedDataForRenewalObject(pedData: any) {
    let pedDetails = []
    pedData.controls.forEach((d, i) => {
      if (d.get(`PEDSelected${i}`).value) {
        pedDetails.push({
          'OtherPedName': d.get('PEDName').value,
          'PedCode': d.get('PEDCode').value,
          'PedName': d.get('PEDName').value,
        })
      }
    });
    return pedDetails || [];
  }
  //HAAP renewal addon conditions

  setOccupationInput(insuredMember, value, addonIndex) {
    if (value) {
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").patchValue('');
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").enable();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").setValidators([Validators.required]);
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").updateValueAndValidity();
    }
    else {
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").patchValue('');
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").disable();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").clearValidators();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").updateValueAndValidity();
    }
  }
  setAddonConditions(insured, tag) {
    this.patchExistingAddon();
    switch (this.renewalFormData.SubProductType) {
      case 42:
        if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
          let selectedPed = this.getPedDataForRenewalObject(insured.get('insuredPED'));
          this.rnAddonService.setCriticalIllnessAddonConditions(insured, selectedPed.length > 0 ? true : false);
          // rnfecth addon check
          let existingAddon = [];
          this.renewalFormData.multiPlanDtls.forEach(x => {
            x.insuredDetails.forEach(y => {
              existingAddon = y.NewAddOnCoverDetails;
            })
          })
          let claimProtectorAddon = existingAddon.find(x => (x.CoverId == 73 && x.IsAddonSelected))
          let CompansitateVisitAddon = existingAddon.find(x => (x.CoverId == 74 && x.IsAddonSelected))
          let NursingAtHomeAddon = existingAddon.find(x => (x.CoverId == 75 && x.IsAddonSelected))
          //addon list selected addon check
          let claimProtectorSAddon = this.addonList.find(x => (x.CoverId == 73 && x.IsAddonSelected))
          let CompansitateVisitSAddon = this.addonList.find(x => (x.CoverId == 74 && x.IsAddonSelected))
          let NursingAtHomeSAddon = this.addonList.find(x => (x.CoverId == 75 && x.IsAddonSelected))
          this.rnAddonService.setMaternityAddonConditions(insured);
          this.rnAddonService.setClaimProtectorConditions(insured, claimProtectorAddon, claimProtectorSAddon);
          this.rnAddonService.setCompansitateVisitConditions(insured, CompansitateVisitAddon, CompansitateVisitSAddon);
          this.rnAddonService.setNursingAtHomeConditions(insured, NursingAtHomeAddon, NursingAtHomeSAddon);
          this.befitAddon = this.rnAddonService.setHAPAddonCondition(insured);
          let befit = this.addonList?.find(x => [76, 77, 78, 79, 80, 81, 82, 83].includes(x.CoverId) && x.IsAddonSelected);
          this.insuredBefit = this.utilityService.isEmptyOrNull(befit) ? '' : befit.CoverText;
        }
        else {
          this.rnAddonService.setMaternityAddonConditions(insured);
          this.rnAddonService.setNewBornAndVaccinationAddonConditions(insured);
          this.rnAddonService.setDomesticAirAndTeleConsultAddonConditions(insured);
          let worldWideFlag = this.renewalFormData.SumInsured >= this.healthDataModal.maxSumInsured ? true : false;
          this.rnAddonService.setWorldWideCoverAddonConditions(insured, worldWideFlag);
          this.rnAddonService.setPersonalAccidentAddonConditions(insured);
        }
        break;

      case 30:
        this.rnAddonService.setPersonalAccidentAddonConditionsForCHI(insured, this.renewalFormData.SumInsured);
        this.befitAddon = this.rnAddonService.setHEPAddonCondition(insured);
        let insuredBef = this.addonList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsAddonSelected);
        this.insuredBefit = this.utilityService.isEmptyOrNull(insuredBef) ? '' : insuredBef.CoverText;
        this.befitAddon = this.utilityService.isEmptyOrNull(insuredBef) ? [] : this.addonList.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
        let worldWidCover = this.addonList.find(x => x.CoverId == 63);
        if (!this.utilityService.isEmptyOrNull(worldWidCover)) {
          this.rnAddonService.setWorldWideAddonConditions(insured, this.renewalFormData.SumInsured);
        }
        break;
      case 32:
        this.rnAddonService.setMaternityAddonConditionsForCHI(insured);
        this.rnAddonService.setPersonalAccidentAddonConditionsForCHI(insured, this.renewalFormData.SumInsured);
        this.befitAddon = this.rnAddonService.setHEPAddonCondition(insured);
        let insurBefit = this.addonList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsAddonSelected);
        this.insuredBefit = this.utilityService.isEmptyOrNull(insurBefit) ? '' : insurBefit.CoverText;
        this.befitAddon = this.utilityService.isEmptyOrNull(insurBefit) ? [] : this.addonList.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
        let worldWdCover = this.addonList.find(x => x.CoverId == 63);
        if (!this.utilityService.isEmptyOrNull(worldWdCover)) {
          this.rnAddonService.setWorldWideAddonConditions(insured, this.renewalFormData.SumInsured);
        }
        break;
      case 31:
      case 33:
        this.befitAddon = this.rnAddonService.setHEPAddonCondition(insured);
        let insuredBefit = this.addonList.find(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsAddonSelected);
        this.insuredBefit = this.utilityService.isEmptyOrNull(insuredBefit) ? '' : insuredBefit.CoverText;
        this.befitAddon = this.utilityService.isEmptyOrNull(insuredBefit) ? [] : this.addonList.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId));
        let worldWideCover = this.addonList.find(x => x.CoverId == 63);
        if (!this.utilityService.isEmptyOrNull(worldWideCover)) {
          this.rnAddonService.setWorldWideAddonConditions(insured, this.renewalFormData.SumInsured);
        }
        break;
      case 20:
        this.rnAddonService.setHealthBoosterAddonConditions(insured);
        break;
      case 40:
        this.rnAddonService.setGSAddonCondition(28, insured);
        this.rnAddonService.setGSAddonCondition(29, insured);
        break;
      case 34:
        this.hideAddon = this.rnAddonService.setMAXAddonCondition(insured, this.renewalFormData.SubProductCode, this.classicToPremium, tag);
        break;
      default:
        break;
    }
  }

  validationAlpha(eventData) {
    return /^[a-zA-Z]$/i.test(eventData.key);
  }

  validationNumber(eventData) {
    this.utilityService.numberOnly(eventData);
  }

  //event listners
  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscpt => {
      subscpt.unsubscribe();
    })
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayNowRequestPayload, PaymentLinkRequestPayload, PaymentResponsePayload, CommonPayRequestPayload, PIDRequestPayload, PIDResponsePayload, TravelPIDRequestPayload, TravelPIDResponsePayload, FetchOrionDetailsAsPerTransactionIDRequestPayload, DownloadBancsPayInSlipPayload, ChequeStatusPayload, ChequeStatusResponse } from '../interface/payment';
import { GenericService } from '../services/generic.service';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private generic: GenericService, private storageService: StorageService) {

  }
  readonly baseURI = `${environment.baseURL}Payment/`;
  readonly orionURI = `${environment.baseURL}BancsPayment/`
  readonly payNow = 'PaymentInit';
  readonly paymentLink = 'SendCustomerPaymentLinkSMS';
  readonly commonPayLink = 'CreateBundle';
  readonly commonPayment = 'CommonPayment';
  readonly getPIDList = 'GetCustPIDList';
  readonly getTravelPIDList = 'GetCustPIDList'
  readonly fetchOrionPolicyDetails = 'BancsPayPolicyDetails';
  readonly downloadBancsPayInSlipURL = 'BancsDownloadPayInSlip';
  readonly getBancsChequeStatusURL = 'BancsGetInstrumentPaymentStatus';

  getParentURI(): string {
    return this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? this.orionURI : this.baseURI;
  }

  payNowRequest(data: PayNowRequestPayload): Observable<any> {
    const endpoint = `${this.getParentURI()}${this.payNow}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  paymentLinkRequest(data: PaymentLinkRequestPayload): Observable<PaymentResponsePayload> {
    const endpoint = `${this.getParentURI()}${this.paymentLink}`;
    return this.generic.genericService<PaymentResponsePayload>(endpoint, data);
  }

  commonPayRequest(data: CommonPayRequestPayload): Observable<PaymentResponsePayload> {
    const endpoint = `${this.baseURI}${this.commonPayLink}`;
    return this.generic.genericService<PaymentResponsePayload>(endpoint, data);
  }

  commonPaymentRequest(data: CommonPayRequestPayload): Observable<PaymentResponsePayload> {
    const endpoint = `${this.baseURI}${this.commonPayment}`;
    return this.generic.genericService<PaymentResponsePayload>(endpoint, data);
  }

  getCustomerPIDList(data: PIDRequestPayload): Observable<PIDResponsePayload> {
    const endpoint = `${this.baseURI}${this.getPIDList}`;
    return this.generic.genericService<PIDResponsePayload>(endpoint, data);
  }

  getTravelerPIDList(data: TravelPIDRequestPayload): Observable<TravelPIDResponsePayload> {
    const endpoint = `${this.baseURI}${this.getTravelPIDList}`;
    return this.generic.genericService<TravelPIDResponsePayload>(endpoint, data);
  }

  getBancsPolicyDetails(data: FetchOrionDetailsAsPerTransactionIDRequestPayload): any {
    const endpoint = `${this.orionURI}${this.fetchOrionPolicyDetails}`;
    return this.generic.genericService(endpoint, data);
  }

  downloadBancsPayInSlip(data: DownloadBancsPayInSlipPayload) {
    const endpoint = `${this.orionURI}${this.downloadBancsPayInSlipURL}`;
    return this.generic.genericService(endpoint, data);
  }

  getBancsChequeStatus(data: ChequeStatusPayload): Observable<ChequeStatusResponse>{
    const endpoint = `${this.orionURI}${this.getBancsChequeStatusURL}`;
    return this.generic.genericService(endpoint, data);
  }
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetBefitApplicabityPayload, GetQuestionnaireForOrionRequestPayload, HealthAilmentRequestPayload, HealthAilmentResponsePayload, RelationshipRequestPayload, RelationshipResponsePayload } from '../interface/healthMaster';
import { GenericService } from '../services/generic.service';
import { HomeRenewalRequestPayload, RenewalDataStorageRequestPayload, ZerotatDataStorageRequestPayload } from '../components/sidemenu/renewals/renewal-storage.modal';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';
import { GetConstitutionOfBusinessResponsePayLoad, GetGSTCustomerTypeResponsePayLoad } from '../interface/health-advantedge';
import { ProposalStatusRequestPayload, ProposalStatusResponsePayload } from '../interface/mypolicy';

@Injectable({
  providedIn: 'root'
})
export class HealthMasterService {

  constructor(private generic: GenericService,
    private storageService: StorageService) {
  }
  readonly baseURI = `${environment.baseURL}`;
  readonly HealthPlanMasterV1 = 'HealthMaster/HealthPlanMasterV1';
  readonly HealthProposalRelationships = '/GetHealthProposalRelationships';
  readonly HealthAilmentList = '/GetHealthAilmentList';
  readonly QuestionnaireForOrion = '/GetQuestionnaire';
  readonly RenewalFetch = 'RenewalHealth/HealthRenewalFetch';
  readonly ZerotatFetch = 'RenewalHealth/RenewalFetch';
  readonly ConstitutionOfBusiness = '/GetConstitutionOfBusiness';
  readonly GSTCustomerTypes = '/GetGSTCustomerType';
  readonly HomeFetch = 'HomeRenewal/HomeFetchRenewal';
  readonly BefitApplicability = '/checkIsBefitApplicable';
  readonly PolicyUWStatus = '/GetPolicyStatus';
  readonly CompanyName = '/BancsInsurerCompanyDetails';
  //readonly zerotatped ='ZeroTatPed'

  getParentURI(): string {
    console.log(this.storageService.getAESDecryptedData(userEnums.IsOrionJourney));
    
    return this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `OrionHealthMaster` : `HealthMaster`;
  }

  getHealthMasterPlannerV1(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.HealthPlanMasterV1}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getHealthProposalRelationships(data: RelationshipRequestPayload): Observable<RelationshipResponsePayload> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.HealthProposalRelationships}`;
    return this.generic.genericService<RelationshipResponsePayload>(endpoint, data);
  }

  getHealthAilmentList(data: HealthAilmentRequestPayload): Observable<HealthAilmentResponsePayload> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.HealthAilmentList}`;
    return this.generic.genericService<HealthAilmentResponsePayload>(endpoint, data);
  }

  getQuestionnaireForOrion(data: GetQuestionnaireForOrionRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.QuestionnaireForOrion}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getRenewalFetch(data: RenewalDataStorageRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.RenewalFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getZerotatFetch(data: ZerotatDataStorageRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.ZerotatFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getConstitutionOfBusiness(): Observable<GetConstitutionOfBusinessResponsePayLoad> {
    const endPoint = `${this.baseURI}${this.getParentURI()}${this.ConstitutionOfBusiness}`;
    return this.generic.genericGetService<GetConstitutionOfBusinessResponsePayLoad>(endPoint);
  }

  getGSTCustomerType(): Observable<GetGSTCustomerTypeResponsePayLoad> {
    const endPoint = `${this.baseURI}${this.getParentURI()}${this.GSTCustomerTypes}`;
    return this.generic.genericGetService<GetGSTCustomerTypeResponsePayLoad>(endPoint);
  }

  getHomeFetch(data: HomeRenewalRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.HomeFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  // getRenewalFetch(data: RenewalDataStorageRequestPayload): Observable<any> {
  //   const endpoint = `https://pana.insurancearticlez.com/health/api/renewalhealth/HealthHAPRenewalFetch`;
  //   return this.generic.genericService<RenewalDataStorage>(endpoint, data);
  // }
  // ZeroTatPed(data:any):Observable<>{
  //   const endpoint =`${this.baseURI}${this.zerotatped}`;
  //   return this.generic.genericService<>(endpoint,data);
  // }

  getBefitApplicability(payload: GetBefitApplicabityPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.BefitApplicability}`;
    return this.generic.genericService<any>(endpoint, payload);
  }

  getPolicyUWStatus(data: ProposalStatusRequestPayload, isOrionPolicy: boolean): Observable<ProposalStatusResponsePayload> {
    const endpoint = `${this.baseURI}${isOrionPolicy ? 'OrionHealthMaster' : ''}${this.PolicyUWStatus}`;
    return this.generic.genericService<ProposalStatusResponsePayload>(endpoint, data);
  }

  getCompanyName() {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.CompanyName}`;
    return this.generic.genericService<any>(endpoint);
  }
}

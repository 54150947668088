import { Component, EventEmitter, Inject, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupModal } from './popup.modal';
import { popupButton, popupButtonAction, popupChequeStatus, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AgentServiceA } from 'src/app/shared/proxy-services/AuthService/agent.service';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { HealthDataStorage, PremiumDetailsData } from 'src/app/modules/health/health.modal';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FetchPropToModifyResponse } from 'src/app/shared/interface/mypolicy';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { TravelDataStorage, TravelPremiumDetails } from 'src/app/modules/health/products/travel/travel.modal';
import { TravelDataStorageService } from 'src/app/modules/health/products/travel/travel.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { MatSelect } from '@angular/material/select';
import { RenewalDataStorageService } from 'src/app/shared/components/sidemenu/renewals/renewal.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { DownloadService } from 'src/app/shared/services/download.service';
import { Constants } from 'src/app/shared/constants/constants';
import { ProductCode } from 'src/app/shared/enums/healthEnums';
import { MatRadioChange } from '@angular/material/radio';
import { AgentMasterStructure, AmlRequestPayload } from 'src/app/shared/interface/agentV1';
import { environment } from 'src/environments/environment';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})

export class PopupComponent implements OnInit {
  @Output() NextButtonValueEmitter = new EventEmitter<{ Type: string, Button: boolean, ButtonType: string }>();
  popUpForm: FormGroup
  popupType = popupType;
  popupImgPath = popupImgPath;
  popupHeader = popupHeader;
  popupChequeStatus = popupChequeStatus;
  healthFormData: HealthDataStorage;
  premiumDetailsData: PremiumDetailsData;
  travelFormData: TravelDataStorage;
  travelPremiumDetailsData: TravelPremiumDetails;
  finalAddonList: any[];
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  insuredForm: FormGroup;
  portabilityWavierArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  insuredMembersObj = [];
  apiKitProducts = [ProductCode.HAP, ProductCode.Elevate];
  insuredMemberControl: FormArray;
  portabilityPolicyDetails: FetchPropToModifyResponse;
  minPortabilityDOJ;
  maxPortabilityDOJ;
  showSegment: number = 0;
  gst: string = "GST@18%";
  formSubmitted: boolean = false;
  critishieldCoversList = [];
  critishieldSelectedCover;
  private subscription: Subscription[] = [];
  footerData: any;
  toggleCopy: boolean = false;
  activateScroll: boolean = false;
  emitMemberIndex = '';
  options = [];
  sliceIndex: number = 4;
  bookFormGroup: FormGroup;
  isSelected: boolean = false;
  hapRefillingAddons: any;
  elevateGroupDesc = ['Mostly Bought', 'Enhanced Sum Insured', 'Reduced Waiting Period', 'Helpful during hospitalisation', 'Coverage at Home', 'Maternity Coverage', 'Lumpsum Payments', 'Cost Optimising', 'All']
  hideElevateFilter: boolean = false;
  isDropdownOpen = false;
  productType: string = '';
  schengenStates: any = [];
  europeanStates: any = [];
  pBusinessFilter: string[] = [];
  pPolicyFilter: string[] = [];
  channelData: ChannelData;
  showPayLater: boolean = true;
  showPayNow: boolean = true;
  showPaymentLink: boolean = true;
  renewalDataStorage: any;
  private eventHandled: boolean = false;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  dataFilter: any;
  dataSearch: any;
  bgColor: string = '#EC6608';
  textColor: string = '#282828';
  isOrionJourney: string;
  OS: string;
  isCarousel: boolean = false;
  private interval: any;
  isIOS: boolean = false
  paymentEmail: string;
  paymentMobileno: number;
  tempdata: string = '';
  policyId: string = '';
  agentDetails: AgentMasterStructure;
  showValidationError: boolean = false;
  isSubmitted: boolean = false;
  offlineElevateCalculator: boolean = false;
  isButtonDisabled = false;
  isCheckboxDisabled = true;
  docLinkClicked = false;
  cocLinkClicked = false;
  gstString: string = 'GST@18%';
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: PopupModal,
    private popupService: PopupService,
    private dialog: MatDialog, public utilityService: UtilityService, private agentService: AgentServiceA,
    private healthDataStorageService: HealthDataStorageService,
    private travelDataStorageService: TravelDataStorageService,
    private storageService: StorageService,
    private router: Router, private footerService: CustomFooterService,
    private renewalStorageService: RenewalDataStorageService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<any>,
    private downloadService: DownloadService,
    private agentv1Sevice: AgentV1Service,
  ) { }


  ngOnInit(): void {
    this.createPopUpForm();
    this.offlineElevateCalculator = (this.router.url).includes(routeEnums.CalculateEevate);

    this.offlineElevateCalculator = (this.router.url).includes(routeEnums.CalculateEevate);
    this.productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
    this.isOrionJourney = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney);
    this.popupService.closeDialog$.subscribe(data => {
      if (data) {
        this.closeAllDialog();
      }
    })
    if (this.productType?.toUpperCase() == 'TRAVEL') {
      this.constructTravelPremiumData();

      this.paymentEmail = this.travelFormData.kycFormData.permanentAddress.applicantEmailAddress;
      this.paymentMobileno = this.travelFormData.kycFormData.permanentAddress.applicantMobileNumber;
      this.schengenStates = this.storageService.getAESDecryptedData(userEnums.SchengenStates);
      this.europeanStates = this.storageService.getAESDecryptedData(userEnums.EuropeanStates);
      // }
    } else if (this.productType?.toUpperCase() == 'HEALTH') {
      this.constructHealthPremiumData();
    }
    if (this.dialogData.popupType == popupType.search) {
      this.dataSearch = this.dialogData.data;
    }
    if (this.dialogData.popupType == popupType.filter) {
      this.dataFilter = this.dialogData.data;
    }
    //renewal data emit
    this.subscription.push(this.footerService.showRenewalFooterData$.subscribe(data => {
      if (!this.utilityService.isEmptyOrNull(data)) {
        this.footerData = data;
      }
    }));
    //travel send paylink
    this.subscription.push(this.popupService.travelPaymentValue$.subscribe(data => {
      if (data['name'] == 'pendingpayment') {
        this.tempdata = data['name'];
        this.policyId = data['PolicyID']
        this.paymentEmail = data['name'] == 'pendingpayment' ? data['emailId'] : this.travelFormData.kycFormData.permanentAddress.applicantEmailAddress;
        this.paymentMobileno = data['name'] == 'pendingpayment' ? data['mobileNo'] : this.travelFormData.kycFormData.permanentAddress.applicantMobileNumber;
      }
    }))
    this.createPopUpForm();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  isAndroidOrIOS() {
    if (this.OS == Constants.iOS) {
      this.isIOS = true;
    } else if (this.OS == Constants.Android) {
      this.isIOS = false;
    }
  }

  setCarouselInterval() {
    this.isCarousel = !this.isCarousel;
  }

  setCarouselPWA() {
    this.isCarousel = true;
  }

  setCarouselIPRO() {
    this.isCarousel = false;
  }

  constructTravelPremiumData() {
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    this.travelPremiumDetailsData = this.travelDataStorageService.initializePremiumData();
    this.travelPremiumDetailsData.basicPremium = Math.floor(parseFloat(this.travelFormData.premiumFormData.basicPremium)).toString();
    this.travelPremiumDetailsData.totalTax = Math.floor(parseFloat(this.travelFormData.premiumFormData.totalTax)).toString();
    this.travelPremiumDetailsData.totalPremium = this.travelFormData.premiumFormData.totalPremium;
    this.travelPremiumDetailsData.numberOfTraveller = this.travelFormData.premiumFormData.numberOfTraveller;
    this.travelPremiumDetailsData.totalDays = this.travelFormData.quoteFormData.policyDuration ? parseInt(this.travelFormData.quoteFormData.policyDuration) :
      this.travelFormData.quoteFormData.policyDurationInDays;
    this.travelPremiumDetailsData.productName = this.travelFormData.quoteFormData.productName;
    this.finalAddonList = this.travelFormData.quoteFormData.quoteSummary;
  }

  constructHealthPremiumData() {
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails && this.quoteDetails.Data) {
      this.updateSavedQuotesPremiumData();
    }
    this.premiumDetailsData = this.healthDataStorageService.initializePremiumData();
    this.premiumDetailsData.basicPremium = this.isOrionJourney == 'true' ? this.healthFormData.premiumFormData.basicPremium : Math.floor(parseFloat(this.healthFormData.premiumFormData.basicPremium)).toString();
    this.premiumDetailsData.totalPremium = this.healthFormData.premiumFormData.totalPremium;
    this.premiumDetailsData.totalTax = this.isOrionJourney == 'true' ? this.healthFormData.premiumFormData.totalTax : Math.floor(parseFloat(this.healthFormData.premiumFormData.totalTax)).toString();
    this.premiumDetailsData.adult = this.healthFormData.quoteFormData.adultDetails.length;
    this.premiumDetailsData.child = this.healthFormData.quoteFormData.childDetails.length;
    this.premiumDetailsData.tenure = this.healthFormData.premiumFormData.tenure;
    this.premiumDetailsData.productName = this.healthFormData.quoteFormData.productName;
    this.premiumDetailsData.coverRange = this.healthFormData.quoteFormData.applicantAnnualSum;
    this.premiumDetailsData.installmentApplicable = this.healthFormData.quoteFormData.InstallmentApplicable == 'Yes';
    this.premiumDetailsData.installmentFrequency = this.healthFormData.quoteFormData.InstallmentFrequency;
    this.premiumDetailsData.installmentTotalPremium = this.healthFormData.premiumFormData.InstallmentTotalPremium;
    this.premiumDetailsData.zone = this.healthFormData.premiumFormData.zone;

    this.renewalDataStorage = this.renewalStorageService.getRenewalDataStorage();

    if (this.healthFormData.quoteFormData.subProductType == 20) {
      this.fatchPremiumDetailsForHealthBooster();
    } else if (this.healthFormData.quoteFormData.subProductType == 23) {
      this.fetchPremiumdataForZeroTat();

    }
    else if (this.healthFormData.quoteFormData.subProductType == 38) {
      this.fetchPremiumDetailsForCritishield()
    }
    else if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) || this.isOrionJourney == 'true') {
      this.hapRefillingAddons = this.healthFormData.quoteFormData.hapRefillingSummary;
      // Check for elevate filter if it comes from recommended or diy journey
      if (this.healthFormData.quoteFormData.recommendedJourneySelected) {
        this.hideElevateFilter = true;
        this.activateScroll = true;
      }
      else {
        this.activateScroll = false;
        this.hideElevateFilter = false;
      }
    }
    else {
      this.fatchPremiumDetails();
    }

    if (this.dialogData.popupType == popupType.search) {
      this.dataSearch = this.dialogData.data;
    }
    if (this.dialogData.popupType == popupType.filter) {
      this.dataFilter = this.dialogData.data;
    }
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    if (this.channelData) {
      if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG || this.channelData.BFLAG == "true")) {
        this.showPayLater = false;
        this.showPayNow = true;
        this.showPaymentLink = false;
      }
    }
  }

  applyMonthYear(option, i: number, type: string) {
    if (type == 'month') {
      this.dataSearch.controls[i].month = option;
    } else if (type == 'year') {
      this.dataSearch.controls[i].year = option;
    }
  }
  // Elevate check box changes
  elevateFilterSelected(checkedValue: MatRadioChange) {
    let selectedValue = checkedValue.value;
    // If All selected
    if (selectedValue == this.elevateGroupDesc[8]) {
      this.activateScroll = true;
      this.hapRefillingAddons.summaryAddons = this.healthFormData.quoteFormData.elevateGroupAddons;
    }
    else {
      this.activateScroll = false;
      this.hapRefillingAddons.summaryAddons = this.healthFormData.quoteFormData.elevateGroupAddons.filter(data => data['group'] == selectedValue);
    }
  }

  applyDropdownValue(option, i: number) {
    this.dataSearch.controls[i].value = option;
    if (this.dataSearch.controls[i].type == 'myDropdown') {
      const x = this.dataSearch.controls[i].list.filter(item => item.productCode == this.dataSearch.controls[i].value);
      this.dataSearch.controls[i + 1].list = x[0].productList;
      if (option != 4) {
        this.dataSearch.controls[i + 1].value = x[0].productList[0].productCode;
      } else {
        this.dataSearch.controls[i + 1].value = 4;
      }
    }
  }

  selectDate(event, i: number) {
    if (event) {
      this.dataSearch.controls[i].startDate = event;
    }
  }

  applyDate(event, i: number, type: string) {
    if (event && type == 'start') {
      this.dataSearch.controls[i].startDate = event;
    }
    else if (event && type == 'end') {
      this.dataSearch.controls[i].endDate = event;
    }
  }

  // getSelectList(event) {
  //   if (event.value == 0) {
  //     this.selectList = this.dialogData.data.healthProductList;
  //     this.isSelected = true;
  //   } else if (event.value == 2) {
  //     this.selectList = this.dialogData.data.travelList;
  //     this.isSelected = true;
  //   } else {
  //     this.isSelected = false;
  //     this.selectList = [];
  //   }
  // }

  updateSavedQuotesPremiumData() {
    this.healthFormData.premiumDataSet = true;
    this.healthFormData.premiumFormData.basicPremium = this.quoteDetails.Data.BasicPremium;
    this.healthFormData.premiumFormData.totalTax = this.quoteDetails.Data.TotalTax;
    this.healthFormData.quoteFormData.adultDetails = this.quoteDetails.Data.AdultDetails;
    this.healthFormData.quoteFormData.childDetails = this.quoteDetails.Data.ChildDetails;
    this.healthFormData.premiumFormData.tenure = this.quoteDetails.Data.Tenure;
    this.healthFormData.quoteFormData.productName = this.quoteDetails.Data.ProductName;
    this.healthFormData.quoteFormData.applicantAnnualSum = this.quoteDetails.Data.Members[0].SumInsured ? this.quoteDetails.Data.Members[0].SumInsured.toString() : '';
    if (this.quoteDetails.Data.SubProductType == 34) {
      if (this.quoteDetails.Data.Zone == 'Zone A') {
        this.healthFormData.premiumFormData.zone = 'A';
      } else if (this.quoteDetails.Data.Zone == 'Zone B') {
        this.healthFormData.premiumFormData.zone = 'B';
      } else if (this.quoteDetails.Data.Zone == 'Zone C') {
        this.healthFormData.premiumFormData.zone = 'C';
      } else {
        this.healthFormData.premiumFormData.zone = 'D';
      }
    } else if (this.quoteDetails.Data.SubProductType == 42 || this.quoteDetails.Data.SubProductType == 33 || this.quoteDetails.Data.SubProductType == 40) {
      if (this.quoteDetails.Data.Zone === 'Zone_I') {
        this.healthFormData.premiumFormData.zone = 'A';
      } else if (this.quoteDetails.Data.Zone === 'Zone_II') {
        this.healthFormData.premiumFormData.zone = 'B';
      } else if (this.quoteDetails.Data.Zone === 'Zone_III') {
        this.healthFormData.premiumFormData.zone = 'C';
      } else {
        this.healthFormData.premiumFormData.zone = '';
      }
    }
    this.healthFormData.quoteFormData.InstallmentApplicable = this.quoteDetails.Data.SubProductType == 34 ? 'No' : this.quoteDetails.Data.InstallmentApplicable;
    this.healthFormData.quoteFormData.InstallmentFrequency = this.quoteDetails.Data.InstallmentFrequency;
    this.healthFormData.premiumFormData.InstallmentTotalPremium = this.quoteDetails.Data.InstallmentTotalPremium ? parseInt(this.quoteDetails.Data.InstallmentTotalPremium) : 0;
    if (this.quoteDetails.Data.SubProductType == 33) {
      this.healthFormData.premiumFormData.totalPremium = this.quoteDetails.Data.TotalPremium;
      this.healthFormData.quoteFormData.quoteSummary = this.quoteDetails.Data.Quotesummary;
    }
  }

  closeSearchBottomSheet(value: string) {
    if (value == 'close') {
      this.dialog.closeAll();
    } else if (value == 'search') {
      let data = [];
      for (let i of this.dataSearch.controls) {
        if (i.type == 'dropdown') {
          data.push({ type: i.type, value: i.value });
        }
        else if (i.type == 'myDropdown') {
          data.push({ type: i.type, value: i.value });
        }
        else if (i.type == 'myDependentDropdown') {
          data.push({ type: i.type, value: i.list ? i.value : '' });
        }
        else if (i.type == 'datepicker') {
          data.push({ type: i.type, startDate: i.startDate });
        }
        else if (i.type == 'rangepicker') {
          data.push({ type: i.type, startDate: i.startDate, endDate: i.endDate });
        }
        else if (i.type == 'renewalDueDate') {
          data.push({ type: i.type, month: i.month, year: i.year });
        }
      }
      this.matDialogRef.close(data);
    }
  }

  applyFilter(isChecked: boolean, value: string, i: number) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.dataFilter[i].filter.includes(value)) {
        this.dataFilter[i].filter.push(value);
      }
    } else {
      const index = this.dataFilter[i].filter.indexOf(value);
      if (index >= 0) {
        this.dataFilter[i].filter.splice(index, 1);
      }
    }
  }

  closeFilterBottomSheet(value: string) {
    if (value == 'close') {
      this.dialog.closeAll();
    } else if (value == 'filter') {
      let data = [];
      for (let i of this.dataFilter) {
        data.push({ filter: i.filter });
      }
      this.matDialogRef.close(data);
    }
  }

  toggleCopyFunction() {
    this.toggleCopy = true;
    setTimeout(() => {
      this.toggleCopy = false;
    }, 2000);
  }

  fatchPremiumDetailsForHealthBooster() {
    let addonsData: any = this.healthFormData.quoteFormData.addonListHB;
    let request = this.healthDataStorageService.getHealthDataStorage();
    const addonList = addonsData.map((element, index) => {
      const addonDetails = this.quoteDetails ? this.quoteDetails.Data.hbAddonsFormData : request?.quoteFormData?.addonsHB;
      const id = `AddOn${index + 1}`;
      const obj = {
        id,
        addonName: element.CoverCode,
        addonCoverId: index + 1,
        addonCoverCode: element.CoverCode,
        coverId: index + 1,
        coverText: element.CoverCode,
        coverCode: element.CoverCode
      };

      addonDetails.forEach((selectedElement, selectedElementIndex) => {

        for (let key in selectedElement) {
          if (element['CoverCode'] == key) {
            if (!selectedElement['iMember']) {
              if (selectedElement[element['CoverCode']]) {
                obj['isAddonMemberBasis'] = false;
                obj[key] = true;
              } else {
                obj['isAddonMemberBasis'] = false;
                obj[key] = false;
              }
            } else {
              if (selectedElement[element['CoverCode']]) {
                let memberKey = `Member${selectedElementIndex + 1}`;
                let memberValue = selectedElement[memberKey];
                let memberId;

                if (memberValue === 'Adult1') {
                  memberId = 1;
                } else if (memberValue === 'Adult2') {
                  memberId = 2;
                } else {
                  memberId = 3;
                }

                if (memberId == 3) {
                  obj['isAddonMemberBasis'] = false;
                  obj[key] = true;
                } else {
                  let finalKey = 'AddOn' + key + `Member${memberId}`;
                  obj[finalKey] = true;
                }
              }
            }
          }
        }

      })
      return { ...element, ...obj }
    })

    this.finalAddonList = addonList.filter((item) => item !== null);
  }

  fatchPremiumDetails() {
    this.finalAddonList = this.healthFormData.quoteFormData.quoteSummary;
  }

  CoverAddonList: any[] = [];
  totalDataTbl: any[] = [];
  quoteSummaryData: any[] = [];

  filterlist = [];
  fetchPremiumdataForZeroTat() {
    this.CoverAddonList = [];
    this.totalDataTbl = [];
    this.filterlist = [];
    this.quoteSummaryData = [];
    this.CoverAddonList = this.healthFormData.quoteFormData.quoteSummary
    this.totalDataTbl = this.constructAddonDetailsForSummary_ZeroTat();
    this.quoteSummaryData = this.totalDataTbl;
    this.filter_Coverlist(this.quoteSummaryData[0].Cover);

  }
  constructAddonDetailsForSummary_ZeroTat() {
    let mergedArray = [];
    //Merged All Members covers;
    for (const addon of this.CoverAddonList) {
      mergedArray = [...mergedArray, ...addon.CoverDetails];
    }
    const coverNameOccurrences = {};
    mergedArray.forEach(item => {
      const coverName = item.CoverName;
      coverNameOccurrences[coverName] = (coverNameOccurrences[coverName] || 0) + 1;
    });
    // Convert occurrences to key-value pairs
    const coverNameKeyValuePairs = Object.entries(coverNameOccurrences);
    const arrayOfObjects = coverNameKeyValuePairs.map(([coverName, count]) => ({ CoverName: coverName, Count: count, Cover: '', allInsured: false, AdultAtatus: [] }));
    this.CoverAddonList.forEach((element, i) => {
      arrayOfObjects.forEach(coverName => {
        const hasDeathBenefit = element.CoverDetails.some(item => item.CoverName === coverName.CoverName)
        coverName['AdultAtatus'] = coverName['AdultAtatus'] || [];
        coverName['AdultAtatus'].push(hasDeathBenefit);
        coverName['Cover'] = '';
      })
    })
    this.CoverAddonList.forEach(element => {
      element.CoverDetails.forEach(item => {
        arrayOfObjects.forEach(m => {
          if (item.CoverName === m.CoverName)
            m.Cover = item.Cover;
        })
      })
    });
    const updatedData = arrayOfObjects.map(item => ({
      ...item,
      allInsured: item.AdultAtatus.every(status => status === true)
    }));
    arrayOfObjects.forEach(ele => {
      this.filterlist.push(ele.Cover)
    })
    this.filterlist = [...new Set(this.filterlist)];
    return updatedData.map(item => ({
      ...item,
      AdultAtatus: item.allInsured ? Array(item.AdultAtatus.length).fill(false) : item.AdultAtatus,
    }));
  }
  selectedButton: string;
  filter_Coverlist(event) {
    let cover;
    if (event.hasOwnProperty('value')) {
      cover = event.value;
    } else {
      cover = this.filterlist[0];
    }
    this.selectedButton = cover;
    this.quoteSummaryData = [];
    this.totalDataTbl.forEach(item => {
      if (item.Cover === cover) {
        this.quoteSummaryData.push(item);
      }
    })

  }


  manageVisibility(request, element, addonDetails) {
    let policyTenure = request?.quoteFormData?.policyTenure == "3 Years"
    let applicantAnnualSum = (Number(request?.quoteFormData?.applicantAnnualSum) >= this.hapDataModal?.maxSumInsured && element.CoverId == 11);
    let c1 = policyTenure && (element.CoverId == 3 || element.CoverId == 4 || element.CoverId == 5) || element.CoverId == 11;
    let c2 = policyTenure && !addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5);
    let result: boolean;

    if (policyTenure && element.CoverId == 3) {
      result = true;
    } else if (applicantAnnualSum) {
      result = true;
    } else if (addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5)) {
      result = true;
    } else if (c1) {
      result = false;
    } else if (c2) {
      result = false;
    } else {
      result = true;
    }

    return result;

  }

  close() {
    if (!this.utilityService.isEmptyOrNull(this.dialogData.buttonAction)) {
      if (this.dialogData.buttonAction == popupButtonAction.logout) {
        this.agentService.logout();
      }
      else if (this.dialogData.buttonAction == popupButtonAction.dashboard) {
        this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
      }
      // Initializing myPolicy component again after save edit portability
      else if (this.dialogData.description.includes('Proposal No. in Omniflow')) {
        this.popupService.emitMyPolicyOnLoadApi(true);
      }
      else if (this.dialogData.buttonAction == popupButtonAction.memberIndex) {
        this.popupService.emitRenewalMemberIndex('');
      }
      else if (this.dialogData.buttonAction == popupButtonAction.quote) {
        this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
      }
      this.closeAllDialog();
    }
    else {
      this.closeAllDialog();
    }
  }

  apply() {
    if (!this.utilityService.isEmptyOrNull(this.emitMemberIndex)) {
      this.popupService.emitRenewalMemberIndex(this.emitMemberIndex);
      this.closeAllDialog();
    }
  }

  emitAdult(value) {
    this.emitMemberIndex = value;
  }

  cancelClicked() {
    if (this.dialogData.header == popupHeader.refreshData) {
      this.popupService.emitRefreshFormData(false);
    } else if (this.dialogData.header == popupHeader.confirmlogout || this.dialogData.header == popupHeader.confirmDashboard) {
      this.popupService.emitLogoutData(false);
    } else if (this.dialogData.header == popupHeader.confirmdelete) {
      this.popupService.emitDeleteData(false);
    } else if (this.dialogData.header == popupHeader.backbuttonpressed) {
      this.popupService.emitBackButtonData(false);
    } else {
      this.popupService.emitPEDData(false);
    }
    this.closeAllDialog();
  }

  proceedClicked() {
    if (this.dialogData.header == popupHeader.refreshData) {
      this.popupService.emitRefreshFormData(true);
    } else if (this.dialogData.header == popupHeader.confirmlogout || this.dialogData.header == popupHeader.confirmDashboard) {
      this.popupService.emitLogoutData(true);
    } else if (this.dialogData.header == popupHeader.confirmdelete) {
      this.popupService.emitDeleteData(true);
    } else if (this.dialogData.header == popupHeader.backbuttonpressed) {
      this.popupService.emitBackButtonData(true);
    }
    else {
      // If procced clicked we need to emit that user is okay if no ped is selected
      this.popupService.emitPEDData(true);
    }
    this.closeAllDialog();
  }

  emitEmail(value) {
    this.popupService.emitEmailValue(value);
    this.closeAllDialog();
  }

  closeAllDialog() {
    if (this.dialogData.description.includes('EMI')) {
      this.popupService.emitEmiData(true);
    }
    else if (this.dialogData.description.includes('Cibil & KYC')) {
      this.popupService.emitCibilData(true);
    }
    setTimeout(() => {
      this.dialog.closeAll();
      this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);  
      let BFlag = (!this.utilityService.isUndefinedORNull(this.channelData.BFLAG) && this.channelData.BFLAG == "true") ? true : false;
      if(!this.agentDetails.AMLFLAG && !BFlag && this.channelData.CN !='NYSAPRO')
      {
          let popupData: PopupModal = {
            popupType: popupType.AML,
            imgName: '',
            header: '',
            description: '',
            buttonLabel: popupButtonAction.memberIndex,
            buttonAction: popupButtonAction.logout,
          }
          this.popupService.openGenericPopup(popupData);
   
      }
      this.ngOnDestroy();
    }, 0);
    
  }


  JourneyEnd() {
    this.agentService.logout();
  }

  handleEvent(data) {
    // This will execute the event handling logic
    this.dialog.closeAll();
    if (!this.eventHandled) {
      let route = (this.router.url).split('/')[1];
      if (route == routeEnums.TRAVEL && data == 'PaymentLink') {
        let popupData: PopupModal = {
          popupType: popupType.travelPaymentLink,
          imgName: popupImgPath.successGif,
          header: popupHeader.success,
          description: '',
          buttonLabel: popupButton.ok,
          buttonAction: popupButtonAction.emitSendPaymentLink
        }
        this.popupService.openGenericPopup(popupData);
      } else {
        this.eventHandled = true;
        this.popupService.emitPaymentData(data);
        this.closeAllDialog();
      }
    }
  }

  updateSendPaymentLink() {
    if (this.popUpForm.invalid) {
      return;
    }
    if (this.utilityService.isEmptyOrNull(this.paymentEmail) && this.utilityService.isEmptyOrNull(this.paymentMobileno)) {
      this.showValidationError = true;
      return;
    }
    this.showValidationError = false;
    if (this.tempdata == 'pendingpayment') {
      let data = { emailId: this.paymentEmail, mobileNo: this.paymentMobileno, PolicyID: this.policyId };
      this.popupService.emitTravelPaymentDetails(data);
      this.closeAllDialog();
    } else {
      this.travelFormData.tempEmail = this.paymentEmail;
      this.travelFormData.tempMobileNo = this.paymentMobileno;
      this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
      this.popupService.emitPaymentData('PaymentLink');
      this.closeAllDialog();
    }
  }
  createPopUpForm() {
    this.popUpForm = this.formBuilder.group({
      email: ['', [Validators.pattern(Constants.emailIdPatternRegExp)]],
      mobile: ['', [Validators.maxLength(10), Validators.pattern(Constants.mobileNumberPatternRegExp)]]
    })
  }
  fetchPremiumDetailsForCritishield() {
    let adultArray = this.healthFormData.quoteFormData['memberCoverDetails'];
    let childArray = this.healthFormData.quoteFormData['memberCoverDetailsChild'];
    this.getCoverList(adultArray, 'Adult');
    this.getCoverList(childArray, 'Child');
  }

  getCoverList(data, flag) {
    if (data.length) {
      data.map((member, memberInd) => {
        if (member['covers'].length) {
          member['covers'].map((cover, coverIndex) => {
            if (cover['coverSelected']) {
              this.critishieldSelectedCover = cover['coverName'];
              cover['AdultStatusFlag'] = false;
              cover['ChildStatusFlag'] = false;
              cover['AllInsuredFlag'] = true;
              this.critishieldCoversList.push(cover);
            }
          })
        }
      })
    }
  }

  remove(option: string) {
    const index = this.options.indexOf(option);
    if (index >= 0) {
      this.options.splice(index, 1);
    }
    this.emitAdult(option);
    this.apply();
    this.dialog.closeAll();
  }

  showMore() {
    this.sliceIndex = this.options.length;
  }


  compareTick(tickSpace, addonNumber) {
    tickSpace = tickSpace.toString();
    if (this.hapRefillingAddons.summaryAddons[addonNumber].members.includes(tickSpace)) {
      return true;
    } else {
      return false;
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  handleMatSelectClick() {
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  getagentAml(){
    let req: AmlRequestPayload = {
      FinancialYear: new Date().getFullYear(),
      AMLFlag: true
    }
    this.agentv1Sevice.agentAml(req).subscribe({
      next: (data: any) => {
        if (data.StatusCode == Constants.statusCode_success) {
          console.log('success')
          this.closeAllDialog();
          this.agentDetails.AMLFLAG = true;
        this.storageService.setAESEncryptedData(userEnums.AgentDetails, this.agentDetails);          
        }
      }
    })
  }

  redirectToDoc(linkType: string) {
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    if (linkType === 'aml') {
      window.open(`${environment.amlBaseURL}`+'Brochures/AML.pdf', '_blank', 'noopener');
      this.docLinkClicked = true;
    } else if (linkType === 'coc') {
      if (!this.agentDetails.isPOSUser) {
        window.open(`${environment.amlBaseURL}` + 'Brochures/Agent_COC_New.doc', '_blank', 'noopener')
        this.cocLinkClicked = true;
      } else {
        window.open(`${environment.amlBaseURL}` + 'Brochures/POS_COC_New.doc', '_blank', 'noopener')
        this.cocLinkClicked = true;
      }
    }
    this.checkLinksClicked()
  }

  checkLinksClicked() {
    if (this.docLinkClicked && this.cocLinkClicked) {
      this.isCheckboxDisabled = false;
    }
  }

  onDownload() {
    const data = this.storageService.getAESDecryptedData(userEnums.QuotePDFData);
    const quotePdfApi = data?.SubProductType == 45 ? this.downloadService.downloadElevateQuotePDF(data) : this.downloadService.downloadQuotePDF(data);
    quotePdfApi.subscribe(response => {
      if (response.StatusCode == Constants.statusCode_success && response.StatusMsg == "Success") {  
        const fileName = data?.SubProductType == 45 ? "Elevate_Quote" : "HAAP_Quote";
        this.downloadService.downloadPdf(response.Data, fileName);
      }
    });
  }

  toggleButtonState(event: Event) {
    this.isButtonDisabled = (event.target as HTMLInputElement).checked;
  }

  ngOnDestroy() {
    this.dialogData = {
      popupType: '',
      imgName: '',
      header: '',
      description: '',
      buttonLabel: '',
      buttonAction: '',
      data: '',
    }
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}


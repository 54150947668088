import { Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Constants } from '../../constants/constants';
import { environment } from 'src/environments/environment';
import { HealthAdvantedgeService } from '../../proxy-services/health-advantedge.service';
import { HealthAdvantageResponsePayload, ZoneResponsePayload, HealthMemberAilments, SaveEditProposalV1RequestPayload } from '../../interface/health-advantedge';
import { UtilityService } from '../../services/utility.service';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { KycUserType, PortabilityReason, ProductCode, fieldTypeDetails, gender, healthMembers, healthToggle, insuredDiseaseID, kycControls, maritalSatus, patterns, policyType, remarkList, zoneDetails, zoneRefillingDetails } from '../../enums/healthEnums';
import { Router } from '@angular/router';
import { routeEnums } from '../../enums/routeEnums';
import { RelationshipResponsePayload, RelationshipRequestPayload } from '../../interface/healthMaster';
import { HealthMasterService } from '../../proxy-services/health-master.service';
import { RTOListRequestPayload, RTOListResponsePayload } from '../../interface/rto-list';
import { RTOListService } from '../../proxy-services/rto-list.service';
import { CustomerService } from '../../proxy-services/customer.service';
import { SaveEditCustomerResponseStructure, SaveEditCustomerRequestPayload, FetchExistingOrionCustomerRequestPayload, SearchOrionCustomerResponseStructure, FetchCustomerDetailsV1RequestPayload, FetchCustomerDetailsV1ResponseStructure, SearchCustomerRequestPayload, SearchCustomerResponseStructure } from '../../interface/customer';
import { KycDetails, FormFields, ButtonData } from '../../interface/formFieldsModal';
import { StorageService } from '../../services/storage.service';
import { userEnums } from '../../enums/userEnums';
import kycDetailsControls from "src/assets/json/kyc-details.json";
import { PopupService } from '../../services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType, popupImgPath, popupDescriptionMsg, popupHeader, popupButton, popupButtonAction } from '../../enums/popupEnums';
import { ApiTokenResponsePayload, AryaAPIKycResp, logKYCData } from '../../interface/utilityApi';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { MaxProtectModal } from 'src/app/modules/health/products/max-protect/max-protect.modal';
import { MatSelect } from '@angular/material/select';
import { CustomStepperService } from '../../services/custom-stepper.service';
import { EmitButtonData } from '../../interface/custom-footer';
import { KycDetailsResponse, KycData, KycDetailsData } from '../../interface/kycDetails';
import { WrapperService } from '../../proxy-services/wrapper.service';
import { HealthService } from '../../proxy-services/health.service';
import { Member } from '../../interface/max-protect';
import { DatePipe } from '@angular/common';
import { CSMaster, ZeroTatRelationships } from '../../interface/cs-master';
import { FamilyShieldService } from '../../proxy-services/familyshield.service';
import { ConvertSavedQuotesResponsePayload } from '../../interface/savedQuotes';
import { CustomFooterService } from '../../services/custom-footer.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { UtilityApiService } from '../../proxy-services/utility-api.service';
import { IsKycPilotUserResponsePayload } from '../../interface/wrapper';
declare const $: any;
declare const AJL: any;

@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.scss'],
  providers: [DatePipe],

})

export class KycDetailsComponent implements OnInit, OnDestroy {
  kycForm: FormGroup;
  searchCustomerForm: FormGroup;
  customerType: string = 'New';
  showEKYC: boolean = false;
  newCustomer: boolean = true;
  showSearchPopup: boolean = false;
  searchFormvalidationSuccess: boolean = false;
  isKYCDoneSuccessfully: boolean = false;
  showFormContent: boolean = false;
  initialServiceLoaded: boolean = false;
  ekycMandatory: boolean = false;
  showApplicantPanNo: boolean = false;
  self: boolean = false;
  normal: boolean = false;
  normalSelf: boolean = false;
  ovd: boolean = false;
  ovdSelf: boolean = false;
  disableApplicantPanCard: boolean = false;
  pepFlag: boolean = false;
  isSubmit: boolean = false;
  multipleServiceError: boolean = false;
  showAppointee: boolean = false;
  existingDataPresent: boolean = false;
  displayCustomerList: boolean = false;
  existingPresent: boolean = false;
  apiKitProducts = [ProductCode.HAP, ProductCode.Elevate, new HAPModal().subProductType.orionHap];
  agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
  customerSelected = [];
  nomineeMinMaxDate: { maxDate: Date; minDate: Date; };
  appointeeMinMaxDate: { maxDate: Date; minDate: Date; };
  maritalStatusMaster: { label: string; value: string; disabled: boolean }[] = [];
  private subscription: Subscription[] = [];
  existingKycData: FetchCustomerDetailsV1ResponseStructure;
  kycControls = [];
  selfIndex: number = 0;
  searchForCustomerControls: FormFields[] = [];
  customerTypeDetails: FormFields;
  kycButtonData = [];
  searchButtonData = [];
  apointeeRelationshipData = [];
  nomineeRelationshipData = [];
  permanentCity = []
  correspondenceCity = [];
  applicantDetailsFormGroup: Array<KycDetails> = [];
  correspondenceFormGroup: Array<KycDetails> = [];
  permanentFormGroup: Array<KycDetails> = [];
  otherDetailsFormGroup: Array<KycDetails> = [];
  nomineeDetailsFormGroup: Array<KycDetails> = [];
  apointeeDetailsFormGroup: Array<KycDetails> = [];
  apointeeDetailsControls: Array<FormFields> = [];
  nomineeDetailsControls: Array<FormFields> = [];
  applicantDetailsControls: Array<FormFields> = [];
  correspondenceControls: Array<FormFields> = [];
  permanentControls: Array<FormFields> = [];
  otherDetailsControls: Array<FormFields> = [];
  customerData = [];
  applicantKycButtonData = {};
  buttonType: string;
  routeData: string = '';
  buttonData: ButtonData;
  errorPopupData: PopupModal;
  selectedPfCustomerId: string = '';
  correspondenceSingleCityId: string = '';
  permanentSingleCityId: string = '';
  saveCustomerRequest: SaveEditCustomerRequestPayload;
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  maxProtectDataModal: MaxProtectModal = new MaxProtectModal();
  stateDetails = this.hapDataModal.stateDetails;
  OldNomineeGender: string = '';
  OldAppointeeGender: string = '';
  isOrionJourney: string;
  titles = [
    { "value": "0", "label": "Mrs.", "alternateLabel": "MRS" },
    { "value": "1", "label": "Mr.", "alternateLabel": "MR" },
    { "value": "2", "label": "Ms.", "alternateLabel": "MISS" },
    { "value": "2", "label": "Ms.", "alternateLabel": "MS" },
    { "value": "21", "label": "Mx.", "alternateLabel": "MX" }];
  originalTitles = [
    {
      "value": "0",
      "label": "Mrs.",
      "gender": "FMALE"
    },
    {
      "value": "1",
      "label": "Mr.",
      "gender": "MALE"
    },
    {
      "value": "2",
      "label": "Ms.",
      "gender": "FMALE"
    },
    {
      "value": "21",
      "label": "Mx.",
      "gender": "MF"
    }
  ];
  masterDetails: HealthAdvantageResponsePayload;
  permanentStateID: string = '';
  correspondenceStateID: string = '';
  permanentPincodeId: number = 0;
  correspondencePincodeID: number = 0;
  eKycId: string = '';
  openKycComponent: boolean = false;
  ilKycReferenceNumber: string = '';
  invalidPinCodeCorrespondence: string = '';
  invalidPinCodePermanent: string = '';
  cKycId: string = '';
  eKycPan: string = '';
  healthFormData: HealthDataStorage;
  customerListData = [];
  isStatusCode: boolean = false;
  patchingDone: boolean = false;
  SIinsured: string = '';
  datepickerMobile: boolean = false;
  zeroTatMasterDetails: CSMaster;
  familyShieldRiskDateControl = []
  healthBoosterOtherDetails = [
    {
      "controlName": "insuredHIP",
      "label": "Is any of the Insured currently having Health Insurance Policy with ICICI Lombard?",
      "type": "radio",
      "value": [
        {
          "label": "Yes",
          "value": "Yes",
          "disabled": false
        },
        {
          "label": "No",
          "value": "No",
          "disabled": false
        }
      ],
      "hide": false,
      "disabled": false,
      "placeholder": "",
      "defaultValue": "No",
      "validations": [],
      "id": "i18"
    },
    {
      "controlName": "taxExemptionHB",
      "label": "Do you want tax exemption certificate(under section 80D) to be availed as a lump sum or  on an annual basis(bifurcated as per the policy tenure)?",
      "type": "radio",
      "value": [
        {
          "label": "Lump Sum",
          "value": "Lump Sum",
          "disabled": false
        },
        {
          "label": "Annual",
          "value": "Annual",
          "disabled": false
        }
      ],
      "validations": [],
      "hide": false,
      "placeholder": "",
      "defaultValue": "Annual",
      "id": "i19"
    }
  ]
  otherDetailsMapped = false;
  numberOfRecords: number;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  channelData: ChannelData;
  selectedCustomerId: number = 0;
  eventListnerActivated: number = 0;
  cibilError: boolean = false;
  selfPopupActivated: boolean = false;
  maternityTitlError: boolean = false;
  criticalIllnessError: boolean = false;
  isCreateCustomer = false;
  isPilotUser: boolean = false;
  isKYCDoneSuccessfullyForPilotuser: boolean = true;
  customerID: string = '';

  constructor(private fb: FormBuilder,
    private ngZone: NgZone,
    private wrapperService: WrapperService,
    private healthDataStorageService: HealthDataStorageService,
    public router: Router,
    private healthService: HealthService,
    private customerService: CustomerService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private popupService: PopupService,
    public utilityService: UtilityService,
    private customStepperService: CustomStepperService,
    private el: ElementRef,
    private renderer: Renderer2,
    private utilityApiService: UtilityApiService,
    private storageService: StorageService,
    private familyShieldService: FamilyShieldService,
    private customFooterService: CustomFooterService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.isOrionJourney = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney);
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);

    // Below event listener is added to receive data from kyc 
    // Uncomment for flutter kyc

    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));

    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.routeData = (this.router.url).includes('health') ? (this.router.url).split('/')[2] : (this.router.url).split('/')[1];
    if (!this.healthFormData.kycDataSet) {
      this.customerType = 'New';
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
      // remove hb key
      // this.sessionService.remove(userEnums.MappedValueHB);
    }

    this.fetchKycControls();
    this.fetchIsPilotUser();
    if (this.routeData != routeEnums.Create_Customer) {
      if (this.routeData == routeEnums.CritiShield || this.routeData == routeEnums.Familyshield) {
        this.fetchKycDetailsFromZeroTatService();
      }
      else {
        this.fetchKycDetailsFromService();
      }
    } else {
      this.isCreateCustomer = true;
      this.showFormContent = true;
      this.initialServiceLoaded = true;
      this.showEKYC = true;
      this.customFooterService.showPremiumDetails(false);
      this.addServiceDataInFormForCreateCustomer();
    }

    if (this.quoteDetails && this.quoteDetails.Data) {
      this.updateSavedQuotesKYCData();
      this.patchExistingKycData();
      this.customFooterService.showPremiumDetails(true);
    }

    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  fetchIsPilotUser() {
    this.wrapperService.IsKycPilotUser(Constants.NysaHealth).subscribe({
      next: (resp: IsKycPilotUserResponsePayload) => {
        if (resp.StatusCode == Constants.statusCode_success) {
          this.isPilotUser = this.utilityService.isEmptyOrNull(resp.Data.is_pilot_user) ? false : resp.Data.is_pilot_user;
        }
        else {
          this.isPilotUser = false;
        }
      }
    })
  }

  receiveMessage(event) {
    if (this.eventListnerActivated == 0) {
      this.eventListnerActivated++;
      $('#externalUrlModal').modal('hide');
      if (!this.utilityService.isEmptyOrNull(event.data)) {
        let res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
        if (!this.utilityService.isEmptyOrNull(res)) {
          this.getKYCDetails(res);
          this.setKycDataForLog(res.data);
          this.logKYCDataAPI(res);
        }
      }
    }
  }

  FSDetails() {
    if (this.healthFormData.quoteFormData.subProductType == 23) {
      this.kycControls.forEach((data) => {
        if (data.tabLabel === 'Other Details') {
          const today = new Date();
          const maxDate = new Date(today);
          maxDate.setDate(today.getDate() + 45);

          this.familyShieldRiskDateControl = [{
            "controlName": "riskStartDate",
            "label": "Risk Start Date",
            "type": "datepicker",
            "minDate": today,
            "maxDate": maxDate,
            "hide": false,
            "defaultValue": "",
            "placeholder": "DD/MM/YYYY",
            "disabled": false,
            "validations": [{ required: true }],
            "position": 9,
            "id": "o9"
          }];

          const riskDateControlsExist = data.controls.filter(ele => ele.controlName === kycControls.riskStartDate);

          if (riskDateControlsExist.length === 0) {
            this.familyShieldRiskDateControl.forEach((riskDateDetails) => {
              data.controls.push(riskDateDetails);
            });
          }
        }
      });
    }
    else {
      this.kycControls.forEach((data) => {
        let indexId = this.findControlsByIndex(data.controls, kycControls.riskStartDate);
        if (indexId != -1) {
          data.controls.splice(indexId, 1);
        }
      });
    }
  }

  // fetching kyc form controls
  fetchKycControls() {
    this.kycControls = [];
    this.kycControls = kycDetailsControls.kycDetailsTabsData;
    this.healthBoosterDetails();
    this.FSDetails();
    this.customerTypeDetails = kycDetailsControls.customerTypeData;
    this.searchForCustomerControls = kycDetailsControls.searchForCustomerData;

    // Changed Label for ORION journey
    if (this.isOrionJourney == 'true') {
      this.searchForCustomerControls.forEach(customerControl => {
        if (customerControl.controlName == 'pfCustomerID') {
          customerControl.label = 'Customer ID';
        }
      })
    }

    // Filtering Buttons
    if (kycDetailsControls.buttonData.length > 0) {
      this.kycButtonData = kycDetailsControls.buttonData.filter(data => data.buttonFor == 'kyc');
      this.searchButtonData = kycDetailsControls.buttonData.filter(data => data.buttonFor == kycControls.searchCustomer);
      let tempButtonData = kycDetailsControls.buttonData.filter(data => data.buttonFor == 'applicantKyc');
      this.applicantKycButtonData = tempButtonData[0]
    }
    // Sorting tabs of kyc 
    this.utilityService.sortDetails(this.kycControls, 'tabPosition');
    // Sorting fields based on position
    if (this.kycControls.length > 0) {
      this.kycControls.forEach(data => {
        data.showControls = true;
        if (data.formGroupLabel == kycControls.appointeeDetails) {
          data.hide = true;
        }
        if (data['controls'].length > 0) {
          this.utilityService.sortDetails(data['controls'], 'position');
        }

      })
    }

    // Sorting search form
    this.utilityService.sortDetails(this.searchForCustomerControls, 'position');
    // form construction started
    this.createSearchCustomerForm();
    this.construcKYCForm();

    if (this.healthFormData.kycDataSet && this.healthFormData.kycStatus.ekycMandatory) {
      this.existingDataPresent = true;
      this.patchExistingKycData();
    }
  }

  healthBoosterDetails() {
    // Only for hb
    if (this.healthFormData.quoteFormData.subProductType == 20) {
      this.kycControls.forEach((ele, ind) => {
        if (ele.tabLabel == 'Other Details') {
          let hbControlsExist = [];
          hbControlsExist = ele.controls.filter(data => data.controlName == kycControls.taxExemptionHB || data.controlName == kycControls.insuredHIP);
          if (hbControlsExist.length == 0) {
            this.healthBoosterOtherDetails.forEach((hbOtherDetails, ind) => {
              ele['controls'].push(hbOtherDetails);
            })
          }
        }
      });

      this.otherDetailsMapped = true;
    } else {
      this.kycControls.forEach((ele) => {
        if (ele.tabLabel == 'Other Details') {
          const indexesToRemove: number[] = [];
          ele.controls.forEach((data, index) => {
            if (data.controlName == kycControls.taxExemptionHB || data.controlName == kycControls.insuredHIP) {
              indexesToRemove.push(index);
            }
          });
          indexesToRemove.reverse();
          indexesToRemove.forEach(index => {
            ele.controls.splice(index, 1);
          });
        }
      }
      )
    }
  }

  // IPAA-126: Update healthFormData from of saved quotes page
  updateSavedQuotesKYCData() {
    this.isSubmit = false;
    this.healthFormData.kycDataSet = true;
    this.isKYCDoneSuccessfully = true;
    this.ekycMandatory = true;
    let permanentSameAdress: boolean = false;
    let convertData = this.quoteDetails.Data;
    let kycData = this.healthFormData.kycFormData;
    this.healthFormData.kycStatus.ekycMandatory = true;
    this.healthFormData.kycStatus.isKYCDoneSuccessfully = true;
    if (!this.utilityService.isEmptyOrNull(convertData.CustomerDetails.PermanentSameAddress)) {
      permanentSameAdress = (convertData.CustomerDetails.PermanentSameAddress.toLocaleLowerCase()) == 'false' ? false : true;
    }
    kycData.applicantDetails.applicantPanCardNumber = convertData.CustomerDetails.PANCardNo;
    kycData.applicantDetails.applicantTitle = convertData.GenericData.kycApplicantTitleID;
    kycData.applicantDetails.applicantFullName = convertData.CustomerDetails.CustomerName;
    if (convertData.CustomerDetails.ILKYCReferenceNumber != null && convertData.CustomerDetails.CKYCId == null && convertData.CustomerDetails.EKYCid == null) {
      kycData.applicantDetails.applicantDOB = (convertData.CustomerDetails.DateOfBirth);
    }
    else {
      kycData.applicantDetails.applicantDOB = this.utilityService.formatDate(convertData.CustomerDetails.DateOfBirth);
    }
    this.healthFormData.quoteFormData.productCode = this.quoteDetails.Data.GenericData.productCode;
    kycData.permanentAddress.permanentSameAddress = permanentSameAdress;
    this.kycForm.controls[kycControls.permanentAddress]['controls'][kycControls.permanentSameAddress].setValue(kycData.permanentAddress.permanentSameAddress);
    if (convertData.CustomerDetails.CorrespondingAddress) {
      this.resetFormGroup(kycControls.correspondenceAddress);
      kycData.correspondenceAddress.correspondenceState = convertData.CustomerDetails.CorrespondingAddress.CorrespondenceState;
      kycData.correspondenceAddress.correspondenceCity = convertData.CustomerDetails.CorrespondingAddress.CorrespondenceCity;
      kycData.correspondenceAddress.correspondenceLandmark = convertData.CustomerDetails.CorrespondingAddress.CorrespondenceLandmark;
      kycData.correspondenceAddress.correspondencePincode = convertData.CustomerDetails.CorrespondingAddress.CorrespondencePincode;
      kycData.correspondenceAddress.correspondenceAddress1 = convertData.CustomerDetails.CorrespondingAddress.CorrespondenceAddressLine1;
      kycData.correspondenceAddress.correspondenceAddress2 = convertData.CustomerDetails.CorrespondingAddress.CorrespondenceAddressLine2;
      kycData.CoCityId = convertData.GenericData.CoCityId;
      kycData.CoStateId = convertData.GenericData.CoStateId;
    }
    kycData.KYCCityId = convertData.GenericData.KYCCityId;
    kycData.KYCStateId = convertData.GenericData.KYCStateId;
    kycData.CKYCID = convertData.CustomerDetails.CKYCId;
    kycData.EKYCID = convertData.CustomerDetails.EKYCid;
    kycData.ilKycReferenceNumber = convertData.CustomerDetails.ILKYCReferenceNumber;
    kycData.permanentAddress.permanentAddress1 = convertData.CustomerDetails.AddressLine1;
    kycData.permanentAddress.permanentAddress2 = convertData.CustomerDetails.AddressLine2;
    kycData.permanentAddress.permanentLandmark = convertData.CustomerDetails.Landmark;
    kycData.permanentAddress.permanentPincode = convertData.CustomerDetails.PinCode;
    kycData.permanentAddress.permanentEmail = convertData.CustomerDetails.Email;
    kycData.permanentAddress.permanentMobileNumber = convertData.CustomerDetails.MobileNumber;
    kycData.permanentAddress.permanentState = convertData.CustomerDetails.State;
    kycData.permanentAddress.permanentCity = convertData.CustomerDetails.City;
    kycData.permanentAddress.permanentMaritalStatus = convertData.CustomerDetails.MaritalStatus;
    if (convertData.CustomerDetails.GSTDetails != null) {
      kycData.otherDetails.gstRegistered = convertData.CustomerDetails.GSTDetails.GSTRegistered;
      kycData.otherDetails.business = convertData.CustomerDetails.GSTDetails.ConstitutionOfBusiness;
      kycData.otherDetails.panNumber = convertData.CustomerDetails.GSTDetails.PanDetails;
      kycData.otherDetails.otherCustomerType = convertData.CustomerDetails.GSTDetails.CustomerType;
      kycData.otherDetails.gstStatus = convertData.CustomerDetails.GSTDetails.GSTRegistrationStatus;
      kycData.otherDetails.gstApplicable = convertData.CustomerDetails.GSTDetails.GSTExemptionApplicable;
      kycData.otherDetails.gstin = convertData.CustomerDetails.GSTDetails.GSTInNumber;
      kycData.otherDetails.uin = convertData.CustomerDetails.GSTDetails.UINNumber;
      kycData.otherDetails['insuredHIP'] = convertData.CustomerDetails.GSTDetails.insuredHIP;
      kycData.otherDetails['taxExemptionHB'] = convertData.CustomerDetails.GSTDetails.taxExemptionHB;
    } else {
      kycData.otherDetails.gstRegistered = healthToggle.No;
    }
    if (this.healthFormData.quoteFormData.subProductType == 23) {
      this.healthFormData.kycFormData.otherDetails.riskStartDate = convertData.FamilyShield.kycRiskDate;
    }
    kycData.nomineeDetails.nomineeTitle = this.titleIDConvert(convertData.NomineeTitleID);
    kycData.nomineeDetails.nomineeFullName = convertData.NomineeName;
    kycData.nomineeDetails.nomineeRelationship = convertData.NomineeRelationShipText;
    kycData.nomineeDetails.nomineeDOB = new Date(convertData.NomineeDOB).toISOString();
    kycData.nomineeDetails.NomineeTitleID = convertData.NomineeTitleID;
    kycData.nomineeDetails.NomineeRelationshipID = convertData.NomineeRelationShipID;
    this.healthFormData.showAppointee = convertData.GenericData.kycAppointee;
    if (this.healthFormData.showAppointee) {
      kycData.appointeeDetails.appointeeTitle = this.titleIDConvert(convertData.AppointeeTitleID);
      kycData.appointeeDetails.appointeeFullName = convertData.AppointeeName;
      kycData.appointeeDetails.appointeeRelationship = convertData.AppointeeRelationShip;
      kycData.appointeeDetails.appointeeDOB = convertData.AppointeeDOB ? new Date(convertData.AppointeeDOB).toISOString() : '';
      kycData.appointeeDetails.appointeeRelationShipID = convertData.AppointeeRelationShipID;
      kycData.appointeeDetails.appointeeTitleID = convertData.AppointeeTitleID;
    }
    kycData.customerType = convertData.CustomerDetails.CustomerType;
    kycData.CustomerID = convertData.CustomerID;
    kycData.PFCustomerID = convertData.PfCustomerId;
    kycData.pepFlag = convertData.CustomerDetails.PEPFlag;
    this.pepFlag = convertData.CustomerDetails.PEPFlag;
    this.healthFormData.kycStatus.ovd = this.quoteDetails.Data.GenericData.kycOvd;
    this.healthFormData.kycStatus.ovdSelf = this.quoteDetails.Data.GenericData.kycOvdSelf;
    this.healthFormData.kycStatus.idType = this.quoteDetails.Data.GenericData.kycIDType;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  titleIDConvert(id: number) {
    let titleName: string;
    if (id == 0) {
      titleName = "Mrs.";
    } else if (id == 1) {
      titleName = "Mr.";
    } else if (id == 2) {
      titleName = "Ms.";
    } else {
      titleName = "Mx.";
    }
    return titleName;
  }

  onHideTable() {
    this.displayCustomerList = !this.displayCustomerList;
  }

  getCustomerRecord(row) {
    this.fetchCustomerFromPfId(row.value);
  }
  // on load service calls
  fetchKycDetailsFromService() {
    let maxProtect: boolean = this.routeData == routeEnums.MaxProtect;
    let proposalData: RelationshipRequestPayload = { product: !maxProtect ? this.healthFormData.quoteFormData.healthProposalProductType : Constants.healthProposalProductCodeMP };
    this.subscription.push(this.healthMasterService.getHealthProposalRelationships(proposalData).subscribe({
      next: (responseList: RelationshipResponsePayload) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList);
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
        this.initialServiceLoaded = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  requestDataFromMultipleSources(): Observable<RelationshipResponsePayload> {
    let maxProtect: boolean = this.routeData == routeEnums.MaxProtect;
    let proposalData: RelationshipRequestPayload = { product: !maxProtect ? this.healthFormData.quoteFormData.healthProposalProductType : Constants.healthProposalProductCodeMP };

    return this.healthMasterService.getHealthProposalRelationships(proposalData);
  }

  handleMultipleResponse(responseList: RelationshipResponsePayload) {
    if (responseList.StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
      this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
    }
    else {
      this.showEKYC = this.agentDetails.IsKycFlag;
      this.multipleServiceError = false;
      this.manageHealthRelationShipsData(responseList)
    }

  }

  manageHealthMasterData(responseData: HealthAdvantageResponsePayload) {
    if (responseData.StatusCode == Constants.statusCode_success) {
      this.masterDetails = responseData;
      this.showEKYC = this.masterDetails.Data.IsKycFlag;
    }
    else {
      this.initialServiceLoaded = false;
    }
  }

  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    if (response.StatusCode == Constants.statusCode_success) {
      if (response.Data.NomineeAppointeeRelationship.length > 0) {
        this.apointeeRelationshipData = this.utilityService.sortDetails(response.Data.NomineeAppointeeRelationship, 'RelationshipName');
        let relationData = response.Data.NomineeAppointeeRelationship.length > 0 ? response.Data.NomineeAppointeeRelationship.filter(data => !data.RelationshipName.includes('EMPLOYEE')) : [];
        this.nomineeRelationshipData = this.utilityService.sortDetails(relationData, 'RelationshipName');
        this.initialServiceLoaded = true;
      }
      else {
        this.multipleServiceError = true;
        this.initialServiceLoaded = false;
      }
    }
    else {
      this.initialServiceLoaded = false;
    }
    this.addServiceDataInForm();
  }

  patchExistingKycData() {
    if (this.kycControls.length > 0) {
      this.kycControls.forEach((data, tabIndex) => {
        let formGroupLabel = data.formGroupLabel;
        data.controls.forEach((controls, controlsIndex) => {
          controls.defaultValue = this.healthFormData.kycFormData[formGroupLabel][controls.controlName];
          this.setFormGroupValue(formGroupLabel, controls.controlName, this.healthFormData.kycFormData[formGroupLabel][controls.controlName]);

          // If pan card value exists or not
          if (controls.controlName == kycControls.applicantPanCardNumber) {
            // 1. check whether user has entered pan card or not
            if (!this.utilityService.isEmptyOrNull(this.healthFormData.kycFormData[formGroupLabel][controls.controlName]) && parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000) {
              // If the value is present so by default we need to showcase the field
              controls.hide = false;

              // If the case is ovd or ovd self pan card block will be editable and set it to editable
              // Also if id type is empty thatand value exists  willthat mean the id type is selected as aadhar in that scenario also it will be disabled
              if (this.healthFormData.kycStatus.ovd || this.healthFormData.kycStatus.ovdSelf || !this.healthFormData.kycStatus.idType) {
                controls.disabled = false;
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
              }
              // To check whether user while filling kyc got pan details or not
              // if there was no kyc present but user has already filled details
              else if (this.healthFormData.kycStatus.idType && (!this.healthFormData.kycStatus.ovd) && (!this.healthFormData.kycStatus.ovdSelf)) {
                controls.disabled = true;
                // Even if pan card exist but the premium is now different
                if (parseInt(this.healthFormData.premiumFormData.totalPremium) < 80000) {
                  controls.hide = true;
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValue('');
                }
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
              }
            }

            // If there is no value present and also no id type
            else if (parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000 && !this.healthFormData.kycStatus.idType && this.utilityService.isEmptyOrNull(this.healthFormData.kycFormData[formGroupLabel][controls.controlName])) {
              // Check for pan card condition with omkar
              controls.hide = false;
              controls.disabled = false;
              this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
              this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
            }

            // If any condition is not met then simoly hide all the fields
            else {
              // If any condition is not met and if premium is greater than 80k then show pancard block
              if (parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000) {
                controls.hide = false;
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
                // Patch pan card
                if (this.healthFormData.kycStatus.idType) {
                  controls.disabled = true;
                  this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, this.healthFormData.kycStatus.certNumber);
                  // extra check
                  if (this.utilityService.isEmptyOrNull(this.healthFormData.kycStatus.certNumber)) {
                    controls.disabled = false;
                    this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
                    this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
                  }
                }
                else if (!this.healthFormData.kycStatus.idType) {
                  controls.disabled = false;
                  this.healthFormData.kycStatus.certNumber = '';
                  this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, '');
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
                }
              }
              else {
                controls.hide = true;
                controls.disabled = true;
                this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, '');
                this.healthFormData.kycStatus.certNumber = '';
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
                this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
              }
            }
          }

          else if (controls.controlName == kycControls.permanentSameAddress || controls.controlName == kycControls.gstRegistered || controls.controlName == kycControls.gstApplicable) {
            // SO whenever the value is No always patch permanent state and city because in radio button function we need the value
            if (controls.controlName == kycControls.permanentSameAddress && !this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentSameAddress]) {
              this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentState, this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentState]);
              this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity, this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentCity]);
            }
            //  then call the change in radio button function

            this.changeInRadioButton(controls.controlName, tabIndex, controlsIndex, formGroupLabel);
          }

          // else if (controls.controlName == kycControls.correspondencePincode || (controls.controlName == kycControls.permanentPincode && (this.healthFormData.kycFormData[kycControls.correspondenceAddress][kycControls.correspondencePincode] != this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentPincode]))) {
          else if (controls.controlName == kycControls.correspondencePincode || controls.controlName == kycControls.permanentPincode) {
            //  Now as we are not storing city value we are calling pincode method
            //  Don't call unecessary pincode method call only for correspondence pincode  and when permanent pincode is not equal to correspondence pincode
            let stateControlName = controls.controlName == kycControls.correspondencePincode ? kycControls.correspondenceState : kycControls.permanentState;
            // If both pincode are not equal
            if (controls.controlName == kycControls.permanentPincode || controls.controlName == kycControls.correspondencePincode) {
              this.fetchDataAsPerPincode(null, data, controls.controlName, stateControlName, this.healthFormData.kycFormData[formGroupLabel][controls.controlName]);
            }
            // Now set explicityly the pincode value
            this.setFormGroupValue(formGroupLabel, controls.controlName, this.healthFormData.kycFormData[formGroupLabel][controls.controlName]);

            // When you call pincode service there could be high chances permanent city value gets reset. SO try again patching permanent city value
            if (controls.controlName == kycControls.permanentPincode && !this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentSameAddress]) {
              this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity, this.healthFormData.kycFormData[kycControls.permanentAddress][kycControls.permanentCity]);
            }
          }

          else if (controls.controlName == kycControls.applicantDOB || controls.controlName == kycControls.nomineeDOB) {
            this.changeInDatePicker(new Date(this.healthFormData.kycFormData[formGroupLabel][controls.controlName]), formGroupLabel, controls.controlName)
          }
        })
        if (tabIndex == this.kycControls.length - 1) {
          this.patchingDone = true;
        }
      })
    }
    this.self = (this.healthFormData.kycStatus.ovdSelf || this.healthFormData.kycStatus.normalSelf) ? true : false;
    this.ekycMandatory = this.healthFormData.kycStatus.ekycMandatory;
    this.normal = this.healthFormData.kycStatus.normal;
    this.normalSelf = this.healthFormData.kycStatus.normalSelf;
    this.ovd = this.healthFormData.kycStatus.ovd;
    this.ovdSelf = this.healthFormData.kycStatus.ovdSelf;
    this.isKYCDoneSuccessfully = this.healthFormData.kycStatus.isKYCDoneSuccessfully;
    this.selfIndex = this.healthFormData.kycStatus.selfIndex;
    this.eKycPan = this.healthFormData.kycStatus.idType ? 'PAN' : '';
    this.customerType = this.healthFormData.kycFormData.customerType;
    this.kycForm.controls['customerType'].setValue(this.customerType);
    this.isPilotUser = this.healthFormData.kycStatus.isPilotUser;
    this.customerID = this.healthFormData.kycFormData.CustomerID?.toString();
    this.checkExistingStatus();
  }

  checkExistingStatus() {
    let applicantDetailsFormGroup = this.findControlsByFormGroup(kycControls.applicantDetails, this.kycControls);
    let correspondenceFormGroup = this.findControlsByFormGroup(kycControls.correspondenceAddress, this.kycControls);
    let permanentFormGroup = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
    let applicantDOBIndex = 0;

    // Fetching all controls required for request payload 
    let applicantDetailsControls = applicantDetailsFormGroup[0]?.controls.length > 0 ? applicantDetailsFormGroup[0].controls : [];
    let correspondenceControls = correspondenceFormGroup[0]?.controls.length > 0 ? correspondenceFormGroup[0]?.controls : [];
    if (this.healthFormData.kycStatus.normal && this.healthFormData.kycStatus.normalSelf) {
      applicantDetailsControls.forEach((controls) => {
        if (controls.controlName == kycControls.applicantDOB || controls.controlName == kycControls.applicantFullName) {
          controls.disabled = true;
        }
      })
    }

    else if (this.healthFormData.kycStatus.ovd || this.healthFormData.kycStatus.ovdSelf) {
      applicantDetailsControls.forEach((controls, index) => {
        applicantDOBIndex = controls.controlName == kycControls.applicantDOB ? index : 0;
        if (controls.controlName == kycControls.applicantDOB || controls.controlName == kycControls.applicantFullName) {
          controls.hide = false;
          controls.disabled = false;
        }
      })
      this.changeFieldStructure(fieldTypeDetails.datepicker, applicantDetailsControls[applicantDOBIndex]);

      permanentFormGroup[0].controls.forEach(controlData => {
        // All will be enabled
        if (!(controlData.controlName == kycControls.permanentState || controlData.controlName == kycControls.permanentCity)) {
          controlData.hide = false;
          controlData.disabled = false;
        }
      })
    }

    else if (this.healthFormData.kycStatus.ovdSelf) {
      correspondenceControls.forEach(controls => {
        if (controls.controlName == kycControls.correspondencePincode || controls.controlName == kycControls.correspondenceState || controls.controlName == kycControls.correspondenceCity) {
          controls.disabled = true;
        }
      })
    }
    this.showFormContent = true;
  }

  patchDataInControls(controls: FormFields[], existingData: {}) {
    controls.forEach(data => {
      data.defaultValue = existingData[data.controlName];
    })
  }

  construcKYCForm() {
    let dynamicControls = {};
    let dynamicGroups = {};

    // Construction of dynamic form Groups
    if (this.kycControls.length > 0) {
      this.kycControls.forEach((groupData) => {
        // If form group has a condition to show case the  controls nelow block get's executed
        if (groupData['showControls'] && groupData['formGroupPresent'] && groupData['controls'].length > 0) {
          // To extract control from each form group
          groupData['controls'].forEach((formControls) => {
            // Adding dynamic validations
            let dynamicValidations = [];
            if (formControls.validations.length > 0) {
              let validations = formControls.validations;
              // Construct dynamic validations
              this.utilityService.constructDynamicValidation(validations[0], dynamicValidations)
            }
            dynamicControls[formControls['controlName']] = new FormControl(formControls['defaultValue'], dynamicValidations);
          })

          // Patching form group with form controls
          dynamicGroups[groupData['formGroupLabel']] = this.fb.group(dynamicControls);

          dynamicControls = {};
        }
      })
      this.kycForm = new FormGroup(dynamicGroups);
    }

    this.kycForm.addControl(this.customerTypeDetails.controlName, this.fb.control(this.customerTypeDetails.defaultValue, [this.utilityService.requiredFieldValidation()]));
    if (!this.healthFormData.kycDataSet) {
      this.showFormContent = true;
    }
    this.subscribeToFormGroups(this.kycForm);
  }

  private subscribeToFormGroups(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);
      if (control instanceof AbstractControl) {
        // Subscribe to valueChanges for each control
        control.valueChanges.subscribe(value => {
          if (this.patchingDone) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
          }
        });
      }
    });
  }

  createSearchCustomerForm() {
    if (this.searchForCustomerControls.length > 0) {
      let dynamicControls = {};
      this.searchForCustomerControls.forEach(controls => {
        if (controls['controlName'] != kycControls.or) {
          dynamicControls[controls['controlName']] = new FormControl(controls['defaultValue'], []);
        }
      })
      this.searchCustomerForm = this.fb.group(dynamicControls)
    }
  }

  addServiceDataInFormForCreateCustomer() {
    if (this.kycControls.length > 0) {
      this.kycControls.forEach((response, index) => {
        if (response.controls.length > 0) {
          response.controls.forEach(data => {
            // Setting marital status drop down value
            if (response.formGroupLabel == kycControls.permanentAddress) {
              if (data.controlName == kycControls.permanentMaritalStatus) {
                this.maritalStatusMaster = [
                  { "label": "SINGLE", "value": "1", "disabled": false },
                  { "label": "MARRIED", "value": "2", "disabled": false }
                ];
                data.value = this.maritalStatusMaster;
              }
            }
            // Setting other details data
            // All this data are hardcoded it should come from service

            // Setting of constitution of business
            else if (response.formGroupLabel == kycControls.otherDetails) {
              if (data.controlName == kycControls.business) {
                let businessData = [];
                if (this.isOrionJourney == 'true') {
                  this.healthMasterService.getConstitutionOfBusiness().subscribe(response => {
                    response.Data.forEach(element => {
                      businessData.push({ 'value': element.constitutionOfBusinessValue, 'label': element.constitutionOfBusinessName })
                    });
                  })
                } else {
                  businessData = [
                    { "value": "1", "label": "Non Resident Entity" },
                    { "value": "2", "label": "Foreign company registered in India" },
                    { "value": "3", "label": "Foreign LLP" },
                    { "value": "4", "label": "Government Department" },
                    { "value": "5", "label": "Hindu Undivided Family" },
                    { "value": "6", "label": "LLP Partnership" },
                    { "value": "7", "label": "Local Authorities" },
                    { "value": "8", "label": "Partnership" },
                    { "value": "9", "label": "Private Limited Company" },
                    { "value": "10", "label": "Proprietorship" },
                    { "value": "11", "label": "Others" }]
                }
                data.value = businessData;
              }
              // Setting cutomer dropdown
              else if (data.controlName == kycControls.otherCustomerType) {
                let customerTypeData = [];
                if (this.isOrionJourney == 'true') {
                  this.healthMasterService.getGSTCustomerType().subscribe(response => {
                    response.Data.forEach(element => {
                      customerTypeData.push({ 'value': element.customerTypeValue, 'label': element.customerTypeName })
                    });
                  })
                } else {
                  customerTypeData = [
                    { "value": "21", "label": "General" },
                    { "value": "22", "label": "EOU/STP/EHTP" },
                    { "value": "23", "label": "Government" },
                    { "value": "24", "label": "Overseas" },
                    { "value": "25", "label": "Related parties" },
                    { "value": "26", "label": "SEZ" },
                    { "value": "27", "label": "Others" }]
                }

                data.value = customerTypeData;
              }
              // Setting gst registeration status data
              else if (data.controlName == kycControls.gstStatus) {
                let gstStatusData = [
                  { "value": "41", "label": "ARN Generated" },
                  { "value": "42", "label": "Provision ID Obtained" },
                  { "value": "43", "label": "To be commenced" },
                  { "value": "44", "label": "Enrolled" },
                  { "value": "45", "label": "Not applicable" }]
                data.value = gstStatusData;
              }
            }

          })
        }
      })
    }
  }

  // adding service data
  addServiceDataInForm() {
    if (this.kycControls.length > 0) {
      this.kycControls.forEach((response, index) => {
        if (response.controls.length > 0) {
          response.controls.forEach(data => {
            // Setting marital status drop down value
            if (response.formGroupLabel == kycControls.permanentAddress) {
              if (data.controlName == kycControls.permanentMaritalStatus) {
                this.maritalStatusMaster = [
                  { "label": "SINGLE", "value": "1", "disabled": false },
                  { "label": "MARRIED", "value": "2", "disabled": false }
                ];
                data.value = this.maritalStatusMaster;
              }
            }
            // Apointee date criteria
            else if (response.formGroupLabel == kycControls.appointeeDetails) {
              if (data.controlName == kycControls.appointeeDOB) {
                let appointeeMaxYear = new Date().getFullYear() - 18;
                this.appointeeMinMaxDate = this.utilityService.getMinMaxDate(appointeeMaxYear);
                data.maxDate = this.appointeeMinMaxDate.maxDate;
              }
              // Setting relationships for apointee
              else if (data.controlName == kycControls.appointeeRelationship) {
                let apointeeRelationData = []
                apointeeRelationData = this.apointeeRelationshipData.map((data) => {
                  let tempRelationData = {};
                  if (this.routeData == routeEnums.CritiShield || this.routeData == routeEnums.Familyshield) {
                    tempRelationData = {
                      'label': data.Name,
                      'value': data.Id
                    }
                  }
                  else {
                    tempRelationData = {
                      'label': data.RelationshipName,
                      'value': data.RelationshipID
                    }
                  }
                  return tempRelationData
                })
                data.value = apointeeRelationData;
              }
            }
            // Nominee date criteria
            else if (response.formGroupLabel == kycControls.nomineeDetails) {
              if (data.controlName == kycControls.nomineeDOB) {

                if (this.routeData == routeEnums.CritiShield || this.routeData == routeEnums.Familyshield) {
                  this.nomineeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear() - 18);
                }
                else {
                  this.nomineeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
                }
                data.minDate = this.nomineeMinMaxDate.minDate;
                data.maxDate = this.nomineeMinMaxDate.maxDate;
              }
              // Setting relationships for nominee
              else if (data.controlName == kycControls.nomineeRelationship) {
                let nomineeRelationData = []
                nomineeRelationData = this.nomineeRelationshipData.map((data) => {
                  let tempRelationData = {};
                  if (this.routeData == routeEnums.CritiShield || this.routeData == routeEnums.Familyshield) {
                    tempRelationData = {
                      'label': data.Name,
                      'value': data.Id
                    }
                  }
                  else {
                    tempRelationData = {
                      'label': data.RelationshipName,
                      'value': data.RelationshipID
                    }
                  }
                  return tempRelationData
                })
                data.value = nomineeRelationData;
              }

            }
            // Setting other details data
            // All this data are hardcoded it should come from service

            // Setting of constitution of business
            else if (response.formGroupLabel == kycControls.otherDetails) {
              if (data.controlName == kycControls.business) {
                let businessData = [];
                if (this.isOrionJourney == 'true') {
                  this.healthMasterService.getConstitutionOfBusiness().subscribe(response => {
                    response.Data.forEach(element => {
                      businessData.push({ 'value': element.constitutionOfBusinessValue, 'label': element.constitutionOfBusinessName })
                    });
                  })
                } else {
                  businessData = [
                    { "value": "1", "label": "Non Resident Entity" },
                    { "value": "2", "label": "Foreign company registered in India" },
                    { "value": "3", "label": "Foreign LLP" },
                    { "value": "4", "label": "Government Department" },
                    { "value": "5", "label": "Hindu Undivided Family" },
                    { "value": "6", "label": "LLP Partnership" },
                    { "value": "7", "label": "Local Authorities" },
                    { "value": "8", "label": "Partnership" },
                    { "value": "9", "label": "Private Limited Company" },
                    { "value": "10", "label": "Proprietorship" },
                    { "value": "11", "label": "Others" }]
                }
                data.value = businessData;
              }
              // Setting cutomer dropdown
              else if (data.controlName == kycControls.otherCustomerType) {
                let customerTypeData = [];
                if (this.isOrionJourney == 'true') {
                  this.healthMasterService.getGSTCustomerType().subscribe(response => {
                    response.Data.forEach(element => {
                      customerTypeData.push({ 'value': element.customerTypeValue, 'label': element.customerTypeName })
                    });
                  })
                } else {
                  customerTypeData = [
                    { "value": "21", "label": "General" },
                    { "value": "22", "label": "EOU/STP/EHTP" },
                    { "value": "23", "label": "Government" },
                    { "value": "24", "label": "Overseas" },
                    { "value": "25", "label": "Related parties" },
                    { "value": "26", "label": "SEZ" },
                    { "value": "27", "label": "Others" }]
                }

                data.value = customerTypeData;
              }
              // Setting gst registeration status data
              else if (data.controlName == kycControls.gstStatus) {
                let gstStatusData = [
                  { "value": "41", "label": "ARN Generated" },
                  { "value": "42", "label": "Provision ID Obtained" },
                  { "value": "43", "label": "To be commenced" },
                  { "value": "44", "label": "Enrolled" },
                  { "value": "45", "label": "Not applicable" }]
                data.value = gstStatusData;
              }
            }

          })
        }
      })
    }
  }
  //used same
  // This method is created to find controls and pass a comparison value so this method can return the index of that control
  findControlsByIndex(controls: Array<{}>, value: string): number {
    let sumIndex: number = controls.findIndex(data => data['controlName'] == value);
    return sumIndex;
  }
  //used same
  // find controls by form group nanme
  findControlsByFormGroup(formGroupName: string, data: Array<{}>): Array<KycDetails> {
    let formGroupData = [];
    formGroupData = data.length > 0 ? data.filter(formData => formData['formGroupLabel'] == formGroupName) : [];
    return formGroupData;
  }
  //used same
  // find controls by control name this works only if you have fetched formgroup
  findControlByControlName(controlName: string, controlsData: Array<{}>): FormFields {
    let controlData = controlsData.length > 0 ? controlsData.filter(formData => formData['controlName'] == controlName) : {};
    return controlData[0];
  }

  // set validation and reset validation
  formGroupDynamicValidation(fomGroupName: string, validationStatus: boolean, controls: Array<{}>) {
    // add the validations
    if (validationStatus) {
      if (controls.length > 0) {
        controls.forEach(data => {
          if (data['controlName'] == kycControls.gstin) {
            this.kycForm.controls[fomGroupName]['controls'][kycControls.gstin].setValidators([Validators.required, Validators.pattern(patterns.GSTIN)]);
            this.kycForm.controls[fomGroupName]['controls'][kycControls.gstin].updateValueAndValidity();
          }

          else if (data['controlName'] == kycControls.uin) {
            this.kycForm.controls[fomGroupName]['controls'][kycControls.uin].clearValidators();
            this.kycForm.controls[fomGroupName]['controls'][kycControls.uin].updateValueAndValidity();
          }

          else if (data['controlName'] == kycControls.panNumber) {
            this.kycForm.controls[fomGroupName]['controls'][data['controlName']].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
            this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
          }

          else if (!(data['controlName'] == kycControls.permanentAddress2 || data['controlName'] == kycControls.permanentLandmark || data['controlName'] == kycControls.correspondenceAddress2 || data['controlName'] == kycControls.correspondenceLandmark || data['controlName'] == kycControls.uin)) {
            if (data['controlName'] == kycControls.correspondencePincode) {
              // We need to add pincode validations
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].setValidators([Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6), Validators.maxLength(6)]);
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
            }
            else if (data['controlName'] == kycControls.permanentMobileNumber) {
              // We need to add mobile number validations
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].setValidators([Validators.required, Validators.pattern(patterns.phoneNumber), Validators.minLength(10), Validators.maxLength(10)]);
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
            }
            else if (data['controlName'] == kycControls.permanentEmail) {
              // We need to add mobile number validations
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].setValidators([Validators.required, Validators.pattern(patterns.email)]);
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
            }
            else {
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].setValidators(Validators.required);
              this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
            }
          }
        })
      }
    }
    // Clear the validation
    else if (!validationStatus) {
      if (controls.length > 0) {
        controls.forEach(data => {
          if (data['controlName'] == 'insuredHIP' && data['controlName'] == 'taxExemptionHB') {
            this.kycForm.controls[fomGroupName]['controls'][data['controlName']].clearValidators();
            this.kycForm.controls[fomGroupName]['controls'][data['controlName']].updateValueAndValidity();
          }
        })
      }
    }
  }
  //used same
  // Get form groupe value
  getFormGroupValue(formGroupName: string, controlName: string): string {
    return this.kycForm.controls[formGroupName]['controls'][controlName].value ? this.kycForm.controls[formGroupName]['controls'][controlName].value : '';
  }
  //used same
  // Setting Value in formgroup form
  setFormGroupValue(formGroupName: string, controlName: string, value: string | Date | Object | boolean) {
    value = this.utilityService.isEmptyOrNull(value) ? '' : value;
    if (!this.utilityService.isEmptyOrNull(this.kycForm.controls[formGroupName])) {
      this.kycForm.controls[formGroupName]['controls'][controlName].setValue(value);
    }
  }
  //used same
  // Disable form fields
  disableFormGroupValue(formGroupName: string, controlName: string) {
    this.kycForm.controls[formGroupName]['controls'][controlName].disable();
  }
  //used same
  // enable form fields
  enableFormGroupValue(formGroupName: string, controlName: string) {
    this.kycForm.controls[formGroupName]['controls'][controlName].enable();
  }
  //used same
  hasError(controlName: string, errorCode: string, groupName: string): boolean {
    if (groupName != '' && groupName != kycControls.searchCustomer) {
      return !this.kycForm.valid && this.kycForm.controls[groupName].hasError(errorCode, [controlName])
    }
    else if (groupName == '') {
      return !this.kycForm.valid && this.kycForm.hasError(errorCode, [controlName]);
    }
    else if (groupName == kycControls.searchCustomer) {
      return !this.searchCustomerForm.valid && this.searchCustomerForm.hasError(errorCode, [controlName])
    }
    return false;
  }

  //used same
  resetFormGroup(formGroupName: string) {
    if (!this.utilityService.isEmptyOrNull(this.kycForm.controls[formGroupName])) {
      for (let keys in this.kycForm.controls[formGroupName]['controls']) {
        if ((keys != 'insuredHIP') && (keys !== 'taxExemptionHB') && (keys !== 'riskStartDate')) {
          this.kycForm.controls[formGroupName]['controls'][keys].clearValidators();
          this.kycForm.controls[formGroupName]['controls'][keys].updateValueAndValidity();
          this.kycForm.controls[formGroupName]['controls'][keys].setValue('');
        }
      }
    }
  }

  //set selected drop down id
  getSelectedDropDownId(controlName: string, controls: FormFields[], label: string): number {
    let controlValue;
    let finalValue: number = 0;
    let selectedControls = controls.filter(data => data.controlName == controlName)[0];
    if (!this.utilityService.isEmptyOrNull(selectedControls)) {
      if (selectedControls.type == fieldTypeDetails.input) {
        if (selectedControls.controlName == kycControls.correspondenceCity || selectedControls.controlName == kycControls.permanentCity) {
          controlValue = selectedControls.controlName == kycControls.correspondenceCity ? this.correspondenceSingleCityId : this.permanentSingleCityId;
          finalValue = controlValue != '' ? parseInt(controlValue) : null;
        } else if (selectedControls.controlName == kycControls.correspondenceState || selectedControls.controlName == kycControls.permanentState) {
          controlValue = selectedControls.controlName == kycControls.correspondenceState ? this.correspondenceStateID : this.permanentStateID;
          finalValue = controlValue != '' ? parseInt(controlValue) : null;
        }
      }

      else if (selectedControls.type == fieldTypeDetails.dropdown) {
        if (selectedControls['value'].length > 0)
          controlValue = selectedControls['value'].find(data => data.label == label)?.value;
        finalValue = !this.utilityService.isEmptyOrNull(controlValue) ? parseInt(controlValue) : null;
      }
    }
    return finalValue;
  }

  // To handle search button click
  searchButtonClick(type: string) {
    if (type == 'close') {
      this.showSearchPopup = false;
      this.kycForm.controls[kycControls.customerType].setValue('New');
      this.customerType = 'New';
      this.healthFormData.kycFormData.customerType = 'New';
      this.searchCustomerForm.reset();
    }
    else if (type == 'proceed') {
      this.fetchCustomerDetails();
    }
  }

  resetEverything() {
    this.resetNomineeTitle();
    this.resetAppointeeTitle();
    if (!this.isCreateCustomer) {
      this.resetFormControls(this.searchCustomerForm);
    }
    this.resetKycFormGroups();
    this.backToOriginalKycControls();
    this.construcKYCForm();
  }

  changeInCustomerType(type) {
    this.eventListnerActivated = 0;
    this.isSubmit = false;
    this.isKYCDoneSuccessfullyForPilotuser = true;
    // Below steps are done because when kyc form gets filled once. It's unable to reset it's state.
    // So while changing customer type first reset all controls
    // second patch orignal kyc controls . Like it was present on first on load
    // Then construct kyc form

    if (type == 'New') {
      this.displayCustomerList = false;
      this.showSearchPopup = false;
      this.customerType = 'New';
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
      this.kycForm.controls[kycControls.customerType].setValue('New');
      this.healthFormData.kycFormData.customerType = 'New';
      this.customerListData = [];
    }

    else if (type == 'Existing') {
      this.resetEverything();
      this.customerListData = [];
      this.displayCustomerList = false;
      this.showSearchPopup = true;
      this.customerType = 'Existing';
      this.kycForm.controls[kycControls.customerType].setValue('Existing');
      this.healthFormData.kycFormData.customerType = 'Existing';
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
    }
  }

  // fetch customer details if user type is selected as existing 
  fetchCustomerDetails() {
    if (this.searchCustomerForm.pristine || (this.searchCustomerForm.value[kycControls.customerName] == '' && this.searchCustomerForm.value[kycControls.customerMobile] == '' && this.searchCustomerForm.value[kycControls.customerEmail] == '' && this.searchCustomerForm.value[kycControls.pfCustomerID] == '')) {
      this.searchCustomerForm.controls[kycControls.customerName].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      const popupString = 'Please fill all the fields';
      this.utilityService.popUpDialog(popupString);

    }

    else {
      // Call api service only when pf is not null or
      // either any one combination is not null which is name and email or name and mobile
      this.searchFormValidation();

      if ((!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) || (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerName]) && (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile]) || !this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])))) {
        if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
          let pfCustomerId = this.searchCustomerForm.value.pfCustomerID ? this.searchCustomerForm.value.pfCustomerID : ''
          this.fetchCustomerFromPfId(pfCustomerId);
        }
        else if (this.searchCustomerForm.valid) {
          this.searchCustomer();
        }
      }
    }
  }


  fetchZoneData(source: string) {
    if (this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress) && source == kycControls.permanentAddress) {
      this.getZoneData(this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity), this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentState), this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode));
    }
    // If it's not same then it will be called by both permanent and correspondence api
    // getzone data works only for correspondence pincode
    else if (source == kycControls.correspondenceAddress) {
      this.getZoneData(this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceCity), this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceState), this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondencePincode));
    }
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, kycFormGroup: KycDetails, controlName: string, stateControlName: string, patchedValue?: string) {
    this.multipleServiceError = false;
    if ((event != null ? event.target.value.length == 6 : patchedValue.length == 6) && !this.kycForm.controls[kycFormGroup.formGroupLabel]['controls'][controlName].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": event != null ? event.target.value : patchedValue,
        "CityID": "",
      }
      // Everytime resetting the state name and city name whenever api is called
      let cityControlName = kycFormGroup.formGroupLabel == kycControls.correspondenceAddress ? kycControls.correspondenceCity : kycControls.permanentCity;
      this.kycForm.controls[kycFormGroup.formGroupLabel]['controls'][stateControlName].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails['stateName'] = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails['stateId'] = response.Data[0].StateId ? response.Data[0].StateId : '';
            }
            // Patching state name for correspondence state and permanent state
            this.kycForm.controls[kycFormGroup.formGroupLabel]['controls'][stateControlName].setValue(this.stateDetails['stateName']);
            if (kycFormGroup.formGroupLabel == kycControls.correspondenceAddress) {
              this.correspondenceStateID = this.stateDetails['stateId'];
              this.invalidPinCodeCorrespondence = '';
            }
            else if (kycFormGroup.formGroupLabel == kycControls.permanentAddress) {
              this.permanentStateID = this.stateDetails['stateId'];
              this.invalidPinCodePermanent = '';
            }
            // Patching city name if any of the pincode value is selected whether in correspondence address or permanent address
            if (kycFormGroup.controls.length > 0) {
              kycFormGroup.controls.forEach((controls, controlsIndex) => {
                if (controls.controlName == kycControls.correspondenceCity || controls.controlName == kycControls.permanentCity) {
                  controls.value = [];
                  if (response.Data.length > 0) {
                    // patch dropdown or input field
                    let cityFieldType = response.Data.length == 1 ? 'input' : 'dropdown';
                    this.changeFieldStructure(cityFieldType, controls);
                    if (response.Data.length > 1) {
                      response.Data.forEach(cityList => {
                        controls.value.push({ 'label': cityList['CityName'], 'value': cityList['CityID'] })
                      })
                    }
                    else if (response.Data.length == 1) {
                      if (controls.controlName == kycControls.correspondenceCity) {
                        this.correspondenceSingleCityId = response.Data[0].CityID;
                      }
                      else if (controls.controlName == kycControls.permanentCity) {
                        this.permanentSingleCityId = response.Data[0].CityID;
                      }
                      this.setFormGroupValue(kycFormGroup.formGroupLabel, cityControlName, response.Data[0].CityName)
                    }
                  }
                }
                if (controls.controlName == kycControls.permanentCity || controls.controlName == kycControls.permanentState || controls.controlName == kycControls.correspondenceState || controls.controlName == kycControls.correspondenceCity) {
                  controls.hide = false;
                }
              })
            }
          }
          else {
            // If pincode is invalid we need to empty city []. That's why we are looping again
            if (kycFormGroup.controls.length > 0) {
              kycFormGroup.controls.forEach((controls, controlsIndex) => {
                if (controls.controlName == kycControls.permanentCity || controls.controlName == kycControls.permanentState || controls.controlName == kycControls.correspondenceState || controls.controlName == kycControls.correspondenceCity) {
                  controls.hide = true;
                  if (controls.controlName == kycControls.correspondenceCity || controls.controlName == kycControls.permanentCity) {
                    controls.value = [];
                  }
                }
              })
            }
            // setting error msg returned from service to respective pincodes
            if (kycFormGroup.formGroupLabel == kycControls.correspondenceAddress) {
              this.invalidPinCodeCorrespondence = response.StatusDesc;
              this.correspondenceStateID = '';
            }
            else if (kycFormGroup.formGroupLabel == kycControls.permanentAddress) {
              this.invalidPinCodePermanent = response.StatusDesc;
              this.permanentStateID = '';
            }
            this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
          this.invalidPinCodeCorrespondence = '';
          this.invalidPinCodePermanent = '';
          this.correspondenceStateID = '';
          this.permanentStateID = '';
        }
      })
      )

    }
    // if the input field changes hide the state and city name
    else if (event != null ? event.target.value.length < 6 : patchedValue.length < 6 && kycFormGroup.controls.length > 0) {
      kycFormGroup.controls.forEach(data => {
        if (data.controlName == kycControls.correspondenceState || data.controlName == kycControls.correspondenceCity || data.controlName == kycControls.permanentCity || data.controlName == kycControls.permanentState) {
          data.hide = true;
        }
      })
    }
  }

  //check Zone as per pincode
  getZoneData(cityValue, stateValue, correspondancePincode) {
    let hepProduct, elevateProduct: boolean = false;
    let route = this.routeData;
    hepProduct = this.routeData == routeEnums.HealthElitePlus ? true : false;
    elevateProduct = this.routeData == routeEnums.Elevate ? true : false;
    let params;
    params = {
      "productcode": hepProduct ? (this.healthFormData.quoteFormData.productCode).toString() : elevateProduct ? '3837' : this.hapDataModal.zoneCode,
      "State": stateValue,
      "City": cityValue,
      "subproductcode": this.healthFormData.quoteFormData.subProductCode,
      "pincode": correspondancePincode
    };
    if (route == routeEnums.MaxProtect) {
      params.productcode = this.maxProtectDataModal.zoneCode;
    }

    if (this.isOrionJourney == 'true') {
      params = {
        "State": stateValue,
        "City": cityValue,
        "productcode": this.healthFormData.quoteFormData.subProductType.toString(),
        "Pincode": correspondancePincode.toString()
      }
    }

    else if (this.healthFormData.quoteFormData.subProductType == 42) {
      params.subproductcode = this.healthFormData.quoteFormData.subProductCode;
      // params.IsOrion = this.storageService.getAESDecryptedData('IsOrion') == 'true' ? true : false
    }

    else if (route == routeEnums.MaxProtect) {
      params.productcode = this.maxProtectDataModal.zoneCode;
    }

    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          if (this.healthFormData.quoteFormData.healthProposalProductType != "ASP" && this.healthFormData.quoteFormData.zoneUpgrade == false && this.healthFormData.quoteFormData.healthProposalProductType != "FS" && this.healthFormData.quoteFormData.healthProposalProductType != "CS") {
            let c1 = '';
            let zone = '';
            if (route == routeEnums.HAP) {
              zone = zoneData ? ((zoneData == this.hapDataModal.zoneRefillingData[0]) ? zoneRefillingDetails.Zone_1 : ((zoneData == this.hapDataModal.zoneRefillingData[1]) ? zoneRefillingDetails.Zone_2 : ((zoneData == this.hapDataModal.zoneRefillingData[2]) ? zoneRefillingDetails.Zone_3 : zoneRefillingDetails.Zone_4))) : '';
            }

            else if (route == routeEnums.Elevate || route == routeEnums.MaxProtect || route == routeEnums.HealthElitePlus || this.isOrionJourney == 'true') {
              zone = zoneData;
            }


            else if (route == routeEnums.MaxProtect) {
              zone = zoneData;
            }

            else {
              c1 = ((zoneData == this.hapDataModal.zoneData[1]) ? zoneDetails.Zone2 : zoneDetails.Zone3);
              zone = zoneData ? ((zoneData == this.hapDataModal.zoneData[0]) ? zoneDetails.Zone1 : c1) : '';
            }


            if (zone.toLowerCase() != this.healthFormData.quoteFormData.zone.toLowerCase()) {
              this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.zonePopupError);
              this.popupService.openGenericPopup(this.errorPopupData);
            } else {
              this.constructSaveRequest();
            }
          } else {
            this.constructSaveRequest();
          }
        }
      },
      error: error => {
      }
    }))
  }

  //used same
  changeFieldStructure(fieldType: string, controls: FormFields) {
    // Create a copy of the controls object
    switch (fieldType) {
      case fieldTypeDetails.input: {
        controls.type = fieldTypeDetails.input;
        controls.disabled = true;
        controls.inputType = "text";
        controls.maxLength = "";
        controls.maxNumber = "";
        controls.minNumber = "";
        controls.onlyAlphabets = false;
        controls.onlyNumbers = false;
        delete controls.value
        if (controls?.maxDate) {
          delete controls?.maxDate
        }
        break;
      }
      case fieldTypeDetails.dropdown: {
        controls.value = [];
        controls.type = fieldTypeDetails.dropdown;
        controls.disabled = false;
        delete controls.maxLength;
        delete controls.maxNumber;
        delete controls.minNumber;
        delete controls.onlyAlphabets;
        delete controls.onlyNumbers;
        delete controls.inputType
        break;
      }
      case fieldTypeDetails.datepicker: {
        let applicantMaxYear = new Date().getFullYear() - 18;
        let tempDate = this.utilityService.getMinMaxDate(applicantMaxYear);
        controls['maxDate'] = tempDate.maxDate;
        controls['type'] = 'datepicker';
        delete controls['inputType'];
        delete controls['maxLength'];
        delete controls['maxNumber'];
        delete controls['minNumber'];
        delete controls['onlyAlphabets'];
        delete controls['onlyNumbers'];
        break;
      }
      default: {
        break;
      }
    }
  }

  // If any change occurs in radio button data
  changeInRadioButton(controlName, tabIndex, controlIndex, formGroupLabel) {
    // If the permanent address is same as correspondence address below correspondence address field gets activated/deactivated
    if (controlName == kycControls.permanentSameAddress) {
      let controlsData: KycDetails[] = this.findControlsByFormGroup(kycControls.correspondenceAddress, this.kycControls)
      let correspondenceDynamicValidation: boolean = false;
      if (this.kycControls.length > 0) {
        // If permanent same address is true
        if (this.kycForm.controls[formGroupLabel]['controls'][controlName].value) {
          // Hide correspondence data
          controlsData[0].hide = true;
          controlsData[0].showControls = false;
          // If again clicked yes then disable the validation
          correspondenceDynamicValidation = false;
          this.resetFormGroup(kycControls.correspondenceAddress);
        }
        // If it's not same address
        else if (!this.kycForm.controls[formGroupLabel]['controls'][controlName].value) {
          // Correspondence address will be visible
          controlsData[0].hide = false;
          controlsData[0].showControls = true;
          this.resetFormGroup(kycControls.correspondenceAddress);
          // If permanent same as correspondence address clicked no then reset correspondence address field
          correspondenceDynamicValidation = true;
          if (!this.existingDataPresent) {
            let correspondenceControlsData = this.findControlByControlName(kycControls.correspondenceCity, controlsData[0]['controls']);
            correspondenceControlsData['value'] = [];
            correspondenceControlsData.disabled = false;
          }
        }
        // })
      }
      // Whether it's no or yes in correspondence address block state city will be hidden
      if (controlsData[0]['controls'].length > 0) {
        controlsData[0]['controls'].forEach(data => {
          if (data.controlName == kycControls.correspondenceState || data.controlName == kycControls.correspondenceCity) {

            // Then if the value is present in state and city then only show the controls state and city
            if (!(this.utilityService.isEmptyOrNull(this.kycForm.controls[kycControls.correspondenceAddress]['controls'][data.controlName].value) && !this.kycForm.controls[kycControls.permanentAddress]['controls'][kycControls.permanentSameAddress].value)) {
              data.hide = false;
            }
            else {
              data.hide = true;
            }

          }
        })
      }
      this.formGroupDynamicValidation(kycControls.correspondenceAddress, correspondenceDynamicValidation, controlsData[0]['controls']);
    }

    // If other details tab get clicked by default it's NO
    else if (controlName == kycControls.gstRegistered) {
      let controlsData = this.findControlsByFormGroup(kycControls.otherDetails, this.kycControls)
      let otherDetailsValidationFlag: boolean = false;
      if (this.kycControls[tabIndex].controls.length > 0) {
        this.kycControls[tabIndex].controls.forEach(data => {
          // If other details tab is clicked as yes
          if (this.kycForm.controls[formGroupLabel]['controls'][controlName].value == healthToggle.Yes) {
            if (!(data.controlName == kycControls.gstRegistered || data.controlName == kycControls.uin || data.controlName == kycControls.riskStartDate)) {
              data.hide = false;
            }
            otherDetailsValidationFlag = true;
          }
          // If other details tab is clicked as no
          else if (this.kycForm.controls[formGroupLabel]['controls'][controlName].value == healthToggle.No) {
            // Reset values
            // Then uin needs to get hide set the gst applicable as yes
            // Is registered with gst option will always be visible so don't hide it. Hide only in the case when data comes from
            if (data.controlName == kycControls.uin || data.controlName == kycControls.gstRegistered) {
              data.controlName == kycControls.uin ? data.hide = true : data.hide = false;
            }
            // all other fields needs to be hidden
            else if (!(data.controlName == kycControls.uin || data.controlName == kycControls.gstRegistered || data.controlName == kycControls.riskStartDate)) {
              // make healthbooster other details tab two fields visible
              if ((this.healthFormData.quoteFormData.subProductType == 20) && (data.controlName == kycControls.insuredHIP || data.controlName == kycControls.taxExemptionHB)) {
                data.hide = false;
              } else {
                data.hide = true;
              }
            }

            otherDetailsValidationFlag = false;
          }
        })
      }
      this.formGroupDynamicValidation(kycControls.otherDetails, otherDetailsValidationFlag, controlsData[0]['controls'])
      // Reset all form fields w.r.t other details if it is clicked as no
      if (this.kycForm.controls[formGroupLabel]['controls'][controlName].value == healthToggle.No) {
        this.resetFormGroup(formGroupLabel);
        this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstApplicable].setValue(healthToggle.Yes);
        this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstRegistered].setValue(healthToggle.No);
      }
    }

    // Now if gst applicable is clicked we need to show hide gstin or uin 
    else if (controlName == kycControls.gstApplicable) {
      if (this.kycControls[tabIndex].controls.length > 0) {
        this.kycControls[tabIndex].controls.forEach(data => {
          // If gst applicable is clicked as yes then we need to show gstin and hide uin
          if ((this.kycForm.controls[formGroupLabel]['controls'][controlName].value == healthToggle.Yes) && this.kycForm.controls[kycControls.otherDetails]['controls'][kycControls.gstRegistered].value == healthToggle.Yes) {
            if (data.controlName == kycControls.gstin || data.controlName == kycControls.uin) {
              data.controlName == kycControls.gstin ? data.hide = false : data.hide = true;
              this.kycForm.controls[formGroupLabel]['controls'][data.controlName].reset();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstin].setValidators([Validators.required, Validators.pattern(patterns.GSTIN)]);
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstin].updateValueAndValidity();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.uin].clearValidators();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.uin].updateValueAndValidity();
            }
          }
          // If gst applicable is clicked as no  then we need to hide gstin and show uin
          else if ((this.kycForm.controls[formGroupLabel]['controls'][controlName].value == healthToggle.No) && this.kycForm.controls[kycControls.otherDetails]['controls'][kycControls.gstRegistered].value == healthToggle.Yes) {
            if (data.controlName == kycControls.gstin || data.controlName == kycControls.uin) {
              data.controlName == kycControls.gstin ? data.hide = true : data.hide = false;
              this.kycForm.controls[formGroupLabel]['controls'][data.controlName].reset();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.uin].setValidators([Validators.required, Validators.pattern(patterns.UIN)]);
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.uin].updateValueAndValidity();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstin].clearValidators();
              this.kycForm.controls[formGroupLabel]['controls'][kycControls.gstin].updateValueAndValidity();
            }
          }
        })
      }
    }
  }

  resetNomineeTitle() {
    this.kycControls.forEach((tabObj, index) => {
      if (tabObj.formGroupLabel == kycControls.nomineeDetails) {
        tabObj.controls.forEach((control, controlsIndex) => {
          if (control.controlName == kycControls.nomineeTitle) {
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.kycControls[index].controls[controlsIndex] };
            tempTitle.value = titles;
            this.kycControls[index].controls.splice(controlsIndex, 1, tempTitle);
            this.kycControls[index].controls = [...this.kycControls[index].controls];
          }
        })
      }
    })
    this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].reset();
    this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].setValidators([Validators.required]);
    this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].updateValueAndValidity();
  }

  resetAppointeeTitle() {
    this.kycControls.forEach((tabObj, index) => {
      if (tabObj.formGroupLabel == kycControls.appointeeDetails) {
        tabObj.controls.forEach((control, controlsIndex) => {
          if (control.controlName == kycControls.appointeeTitle) {
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.kycControls[index].controls[controlsIndex] };
            tempTitle.value = titles;
            this.kycControls[index].controls.splice(controlsIndex, 1, tempTitle);
            this.kycControls[index].controls = [...this.kycControls[index].controls];
          }
        })
      }
    })
    this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].reset();
    this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].setValidators([Validators.required]);
    this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].updateValueAndValidity();
  }

  setAppointeeTitle(value: string) {
    let gender = this.apointeeRelationshipData.find(relation => relation.RelationshipName === value).Gender;
    this.kycControls.forEach((tabObj, index) => {
      if (tabObj.formGroupLabel == kycControls.appointeeDetails) {
        tabObj.controls.forEach((control, controlsIndex) => {
          if (control.controlName == kycControls.appointeeTitle) {
            // filter control.value
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.kycControls[index].controls[controlsIndex] };
            tempTitle.value = titles.filter(titleItem => {
              if (titleItem.gender == 'MF' || gender == 'MF' || titleItem.gender == '' || gender == '') return true;
              return titleItem.gender === gender;
            });
            this.kycControls[index].controls.splice(controlsIndex, 1, tempTitle);
            this.kycControls[index].controls = [...this.kycControls[index].controls];
            if (this.OldAppointeeGender != gender) {
              if (this.OldAppointeeGender != '') {
                this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].reset();
                this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].setValidators([Validators.required]);
                this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].updateValueAndValidity();
              } else {
                let titleValue = this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].value;
                let titleGender = this.originalTitles.find(title => (title.label == titleValue)).gender;
                if (titleGender != gender) {
                  this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].reset();
                  this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].setValidators([Validators.required]);
                  this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeTitle].updateValueAndValidity();
                }
              }
            }
            this.OldAppointeeGender = gender;
          }
        })
      }
    })
  }

  setNomineeTitle(value: string) {
    let gender = this.nomineeRelationshipData.find(relation => relation.RelationshipName === value).Gender;
    this.kycControls.forEach((tabObj, index) => {
      if (tabObj.formGroupLabel == kycControls.nomineeDetails) {
        tabObj.controls.forEach((control, controlsIndex) => {
          if (control.controlName == kycControls.nomineeTitle) {
            // filter control.value
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.kycControls[index].controls[controlsIndex] };
            tempTitle.value = titles.filter(titleItem => {
              if (titleItem.gender == 'MF' || gender == 'MF' || titleItem.gender == '' || gender == '') return true;
              return titleItem.gender === gender;
            });
            this.kycControls[index].controls.splice(controlsIndex, 1, tempTitle);
            this.kycControls[index].controls = [...this.kycControls[index].controls];
            if (this.OldNomineeGender != gender) {
              if (this.OldNomineeGender != '') {
                // Not first time
                this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].reset();
                this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].setValidators([Validators.required]);
                this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].updateValueAndValidity();
              } else {
                // First time
                let titleValue = this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].value;
                let titleGender = this.originalTitles.find(title => (title.label == titleValue)).gender;
                if (titleGender != gender) {
                  this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].reset();
                  this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].setValidators([Validators.required]);
                  this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeTitle].updateValueAndValidity();
                }
              }
            }
            this.OldNomineeGender = gender;
          }
        })
      }
    })
  }

  // change in drop down data
  changeOfDropdown(value: string, controlName: string) {
    if (controlName == kycControls.applicantTitle && this.self) {
      this.checkWhetherSelfExists(controlName);
    }
    // customer list dropdown Value
    if (controlName == kycControls.customerList) {
      if (!this.utilityService.isEmptyOrNull(value)) {
        this.fetchCustomerFromPfId(parseInt(value))
      }
      else {
        // If by any case Pf customer id is not present for selected value then show below msg
        this.searchCustomerForm.controls[kycControls.customerList].setValue('');
        this.utilityService.popUpDialog('Please select different customer. Kyc does not exist for them');

      }
    }

    if (controlName == kycControls.nomineeRelationship) {
      this.setNomineeTitle(this.kycForm.controls[kycControls.nomineeDetails]['controls'][kycControls.nomineeRelationship].value);
    }

    if (controlName == kycControls.appointeeRelationship) {
      this.setAppointeeTitle(this.kycForm.controls[kycControls.appointeeDetails]['controls'][kycControls.appointeeRelationship].value);
    }
  }

  // change in any input field value
  changeInInputField(event, controlName: string, kycFormGroup: KycDetails, alphaCheck: boolean, numberCheck: boolean) {
    if (controlName == kycControls.correspondencePincode) {
      this.invalidPinCodeCorrespondence = '';
      this.existingDataPresent = false;
      this.fetchDataAsPerPincode(event, kycFormGroup, controlName, kycControls.correspondenceState)
    }
    else if (controlName == kycControls.permanentPincode) {
      this.invalidPinCodePermanent = '';
      this.existingDataPresent = false;
      this.fetchDataAsPerPincode(event, kycFormGroup, controlName, kycControls.permanentState)
    }
    else if (controlName == kycControls.applicantFullName && this.self) {
      this.checkWhetherSelfExists(controlName);
    }
    else if (controlName == kycControls.applicantPanCardNumber || controlName == kycControls.panNumber || controlName == kycControls.gstin) {
      this.setFormGroupValue(kycFormGroup.formGroupLabel, controlName, ((event.target.value).toUpperCase()))
    }
    this.validationOfInputField(event, controlName, alphaCheck, numberCheck);
  }

  // Change in date picker value
  changeInDatePicker(event, formGroupLabel: string, controlName: string) {
    if (controlName == kycControls.applicantDOB && this.self) {
      this.checkWhetherSelfExists(controlName);
    }

    if (controlName == kycControls.nomineeDOB) {
      let apointeeData = this.findControlsByFormGroup(kycControls.appointeeDetails, this.kycControls);
      let nomineeAge = this.utilityService.calculateAge(event);
      let apointeeValidationFlag: boolean = false;
      if (nomineeAge < 18) {
        apointeeData[0].hide = false;
        apointeeData[0].showControls = true;
        this.showAppointee = true;
        apointeeValidationFlag = true;
      }
      else {
        apointeeData[0].hide = true;
        apointeeData[0].showControls = false;
        apointeeValidationFlag = false;
        this.showAppointee = false;
        this.resetAppointeeTitle();
        this.resetFormGroup(kycControls.appointeeDetails);

      }
      this.formGroupDynamicValidation(kycControls.appointeeDetails, apointeeValidationFlag, apointeeData[0].controls)
    }
  }

  checkWhetherSelfExists(controlName: string) {
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      let selfIndex = -1;
      if (this.healthFormData.quoteFormData.subProductType == 38 || this.healthFormData.quoteFormData.subProductType == 23) {
        selfIndex = this.healthFormData.insuredFormData.insuredMemberDetails.findIndex((data, index) => data['applicantRelationshipAdult' + (index + 1)] == 'SELF');
      }
      else {
        selfIndex = this.healthFormData.quoteFormData.adultDetails.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
      }
      if (selfIndex != -1) {
        this.selfIndex = selfIndex;
        let adultControlName = (selfIndex + 1);

        switch (controlName) {
          case kycControls.applicantDOB: {
            this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['dobAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
            this.healthFormData.quoteFormData.adultDetails[selfIndex]['dobAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
            break;
          }
          case kycControls.applicantFullName: {
            this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['fullNameAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName);
            break;
          }
          case kycControls.applicantTitle: {
            this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['titleAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle);
            break;
          }
          default: {
            break;
          }
        }

      }
    }
  }

  // logic yet to be discussed from service
  searchFormValidation() {
    // If pf field is present then there should not be require of any field make it empty
    if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.customerName].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerName].setValue('');
      this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerMobile].setValue('');
      this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
    }
    // if name is not empty but pf field is empty then we need mobile number field that's a priority
    // But we also need to check if email value is already entered or not
    if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerName]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If no email is present then set vathis.utilitylidation for mobile
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
        this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
        this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      // There could be chances that we have entered email so in that case we don't need mobile
      // But we need to check whether we have netered data for mobile or not
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
        // If user has not eneterd mobile number then no validation needed
        if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
          this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
          this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
        }
        // but If user has entered mobile number we need to apply validation
        else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
          this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
          this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
        }
      }
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
    }

    // If mobile field is selected to add value then customer name is mandate
    else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If there is no value of email we don't need to check validation
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
        this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      }
      // If there is value of email we  need to check validation
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      }
      this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
    }

    // If email is selected then customer name is mandate
    else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If there is no value of mobile number we don't need to check validation
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
        this.searchCustomerForm.controls[kycControls.customerMobile].setValue('');
        this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      // If there is value of mobile we  need to check validation
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
        this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
    }
  }

  searchCustomer() {
    let request: SearchCustomerRequestPayload = {
      CustomerName: this.searchCustomerForm.value.customerName ? this.searchCustomerForm.value.customerName : '',
      CustomerEmail: this.searchCustomerForm.value.customerEmail ? this.searchCustomerForm.value.customerEmail : '',
      CustomerMobile: this.searchCustomerForm.value.customerMobile ? this.searchCustomerForm.value.customerMobile : '',
      PfCustomerId: this.searchCustomerForm.value.pfCustomerID ? this.searchCustomerForm.value.pfCustomerID : ''
    }
    this.customerSelected = [];

    if (this.isOrionJourney == 'true') {
      delete request['PfCustomerId'];
    }

    this.subscription.push(this.customerService.searchCustomerData(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          if (response.Data) {
            this.numberOfRecords = response.Data.length;
            this.patchCustomers(response.Data);
          }
          else {
            this.utilityService.popUpDialog('Customer unable to find');
          }
        }
        else if (response.StatusCode != Constants.statusCode_success) {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
        }

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
      }
    }))
  }

  // So if the customer list is present it will patch the value in dropdown
  // And it will make it visible
  // or else it will hide the fields
  patchCustomers(data: SearchCustomerResponseStructure[] | SearchOrionCustomerResponseStructure[]) {
    if (data.length > 0) {
      let i = 0;
      this.customerListData = data.map((data, i) => {
        let tempCustomerData = {
          'id': i + 1,
          'label': data.CustomerName,
          'value': this.isOrionJourney == 'true' ? (data.ProposerCode).toString() : (data.PfCustomerId).toString(),
          'email': data.CustomerEmail,
          'mobileNumber': data.CustomerMobile
        }
        return tempCustomerData;
      });
      this.displayCustomerList = true;
    }
    else {
      this.displayCustomerList = false;
      this.utilityService.popUpDialog('No record found');
    }


  }

  fetchCustomerFromPfId(id: number) {
    let request: FetchCustomerDetailsV1RequestPayload | FetchExistingOrionCustomerRequestPayload;

    if (this.isOrionJourney == 'true') {
      request = {
        customerID: (id).toString()
      }
    } else {
      request = {
        PfCustomerID: (id).toString()
      }
    }


    this.subscription.push(this.customerService.fetchCustomerDetailsV1(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.healthFormData.kycFormData.CKYCID = response.Data.CKYCID;
          this.healthFormData.kycFormData.EKYCID = response.Data.EKYCID;
          this.customerID = response.Data.ProposerCode;
          this.cKycId = response.Data.CKYCID;
          this.eKycId = response.Data.EKYCID;
          // Check whether kyc is applicable or not
          // if (!this.utilityService.isEmptyOrNull(response.Data.CKYCID) || !this.utilityService.isEmptyOrNull(response.Data.EKYCID)) {
          this.selectedPfCustomerId = response.Data.PfCustomerID;
          // Setting entire existing kyc data
          this.existingKycData = response.Data;
          this.patchExistingUserDetails(response.Data);

        }
        else if (response.StatusCode != Constants.statusCode_success) {
          // Check this when to show the popup of completing kyc
          this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
          this.showEKYC = true;
          this.isKYCDoneSuccessfully = false;
          this.ekycMandatory = false;
        }
      },
      error: error => {
        // this.spinner.hide();
        this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
      }
    }))
  }


  callKYC() {
    // kyc controls reset when click on update
    this.eventListnerActivated = 0;
    this.isKYCDoneSuccessfullyForPilotuser = true;
    this.resetEverything();
    // this.aryaApiKycCalling();
    this.newKycCalling();
  }

  newKycCalling() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          let source = this.utilityService.identifyEnvironmentType(kycToken);
          let resourceParams = JSON.stringify(source);
          const externalUrlFrame = document.getElementById('externalUrlFrame') as HTMLIFrameElement;
          externalUrlFrame.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        }
      }
    }));
  }

  logKYCDataAPI(data) {
    let reqData: logKYCData = {
      response: JSON.stringify(data),
      correlation_id: data.correlation_id,
      action: "kyc"
    }
    this.subscription.push(this.utilityApiService.logDataDetails(reqData).subscribe({
      next: (res) => {
        if (res.StatusCode == Constants.statusCode_success) {
          console.log(res.Data);
        }
      }
    }));
  }

  setKycDataForLog(data) {
    this.healthFormData.kycFormData.certificateType = data.kyc_details.certificate_type;
    this.healthFormData.kycFormData.kycCorrelationId = data.kyc_details.correlation_id;
    this.healthFormData.kycFormData.certificateType = data.kyc_details.certificate_type;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        // setTimeout(() => {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
        this.setKycDataForLog(kycData.data);
        this.logKYCDataAPI(res);
        // }, 0);
      }
      else {
        this.isKYCDoneSuccessfully = false;
        this.ekycMandatory = false;
        this.isKYCDoneSuccessfullyForPilotuser = false;
        // this.utilityService.showSnackbar('Kindly complete E-KYC/C-KYC to proceed further.');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1 : '',
            address_line_2: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_2 : '',
            pin_code: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.pin_code : '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city : '',
            state: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.state : '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  getKYCDetails(res: KycDetailsResponse) {
    if ((res != null) && (res.StatusCode == Constants.statusCode_success)) {
      let tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      this.ekycMandatory = true;
      this.ilKycReferenceNumber = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.il_kyc_ref_no : '';
      this.cKycId = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.ckyc_number : '';
      this.customerID = '';
      this.isKYCDoneSuccessfully = tempResponse.data.hasOwnProperty('kyc_details') && ((!this.utilityService.isEmptyOrNull(this.cKycId) || !this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) || !this.utilityService.isEmptyOrNull(this.eKycId))) ? true : false;
      if (this.ilKycReferenceNumber !== "" || this.utilityService.isEmptyOrNull(this.cKycId)) {
        if (tempResponse.data.statusMessage == Constants.statusMsg_Manual) {
          if (!this.isPilotUser) {
            this.patchValuesOnSelf();
          }
          // For pilot user kyc needs to be restricted
          else {
            this.isKYCDoneSuccessfully = false;
            this.cKycId = '';
            this.ilKycReferenceNumber = '';
            this.ekycMandatory = false;
            this.isKYCDoneSuccessfullyForPilotuser = false;
            // this.utilityService.showSnackbar('Kindly complete E-KYC/C-KYC to proceed further.');
          }
        }

        else if ((tempResponse.data.statusMessage).toLowerCase() == Constants.statusMsg_Success) {
          this.existingPresent = false;
          this.existingKycData = null;
          this.selectedPfCustomerId = '';
          this.kycForm.controls[kycControls.customerType].setValue('New');
          this.customerType = 'New';
          this.setKYCDetails(tempResponse.data);

        }
      }
      else {
        this.existingPresent = false;
        this.existingKycData = null;
        this.selectedPfCustomerId = '';
        this.kycForm.controls[kycControls.customerType].setValue('New');
        this.customerType = 'New';
        this.setKYCDetails(tempResponse.data);
      }
    }
    else {
      this.isKYCDoneSuccessfully = false;
      this.cKycId = '';
      this.ilKycReferenceNumber = '';
      this.ekycMandatory = false;
      this.isKYCDoneSuccessfullyForPilotuser = true;
      // this.utilityService.showSnackbar('Kindly complete E-KYC/C-KYC to proceed further.');

    }
  }

  setKYCDetails(response: KycData) {
    if (response) {
      this.eKycId = '';
      let customer_type = response.customer_type;
      this.eKycPan = response.kyc_details.certificate_type != 'PAN' ? '' : response.kyc_details.certificate_number;
      this.patchDataInKycForm(customer_type, response.kyc_details);
    }
  }

  patchDataInKycForm(customerType: string, kycDetailsData: KycDetailsData) {
    let ovdManualSelfCase: boolean = false;
    let alternateAddress = kycDetailsData.alternate_address;
    let permanentAddress = kycDetailsData.permanent_address;
    let addressFlag: boolean = true;

    // Check whether permanent address is same as correspondece address 
    let correspondenceAddressEkyc = !this.utilityService.isEmptyOrNull(alternateAddress) ? alternateAddress.address_line_1 + ' ' + alternateAddress.pin_code : '';
    let permanendAddressEkyc = permanentAddress.address_line_1 + ' ' + permanentAddress.pin_code;
    addressFlag = correspondenceAddressEkyc == permanendAddressEkyc ? true : false;

    const [day, month, year] = !this.utilityService.isEmptyOrNull(kycDetailsData['dob']) ? kycDetailsData['dob'].split("-") : ['', '', ''];

    let title: string = '', fullName: string = '', finalTitle = {}, finalTitleId: string = '', tempTitle: string = '', isTitleAvailable: boolean = false;
    const completeName: string = kycDetailsData['full_name'].toUpperCase();
    const chars = ['MR', 'MRS', 'MISS', 'MS'];
    let titlePresent: boolean = false;
    titlePresent = completeName.includes('MR') || completeName.includes('MRS') || completeName.includes('MISS') || completeName.includes('MS');
    tempTitle = titlePresent ? completeName.split(' ').slice(0, 1).join() : '';
    title = tempTitle != '' ? tempTitle : '';
    let titleMatchFound = title != '' ? chars.find(d => d === title) : '';
    isTitleAvailable = titleMatchFound != '' ? true : false;

    if (customerType == KycUserType.Coorperate) {
      let titleArray = this.titles.filter(x => x.alternateLabel == 'Ms.');
      if (titleArray.length > 0) {
        finalTitle['label'] = titleArray[0].label;
        finalTitle['value'] = titleArray[0].value;
        finalTitle['disabled'] = false;
      }
    }

    else {
      let titleArray = this.titles.filter(x => x.alternateLabel == title);
      if (titleArray.length > 0) {
        finalTitle['label'] = titleArray[0].label;
        finalTitle['value'] = titleArray[0].value;
        finalTitle['disabled'] = false;
        finalTitleId = titleArray[0].value
      }
    }
    let correspondenceFormGroupData = this.findControlsByFormGroup(kycControls.correspondenceAddress, this.kycControls);
    let permanenetFormGroupData = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
    //  If the permanent address and correspondence address is same . Hide the correspondence block
    this.resetFormGroup(kycControls.correspondenceAddress);
    if (!addressFlag) {
      correspondenceFormGroupData[0].hide = false;
      correspondenceFormGroupData[0].showControls = true;
      // If correspondence address need to be showcased then we need to add dynamic validations
      this.formGroupDynamicValidation(kycControls.correspondenceAddress, true, correspondenceFormGroupData[0]['controls']);
    }
    else {
      correspondenceFormGroupData[0].hide = true;
      correspondenceFormGroupData[0].showControls = false;
      this.formGroupDynamicValidation(kycControls.correspondenceAddress, false, correspondenceFormGroupData[0]['controls']);
    }

    fullName = isTitleAvailable ? completeName.replace(`${title} `, "") : completeName;

    // Patching Of Data
    let panCardFormGroup = this.findControlsByFormGroup(kycControls.applicantDetails, this.kycControls);
    let panCardControls = panCardFormGroup[0]['controls'] && panCardFormGroup[0]['controls'].length > 0 ? this.findControlByControlName(kycControls.applicantPanCardNumber, panCardFormGroup[0]['controls']) : {};
    if (parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000) {
      this.showApplicantPanNo = true;
      // Enable pan card control
      panCardControls['hide'] = false;
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
      this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, this.eKycPan);
    }
    else {
      this.showApplicantPanNo = false;
      panCardControls['hide'] = true;
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
    }
    panCardControls['disabled'] = this.eKycPan != '' ? true : false;

    let finalDate;
    if (!this.utilityService.isEmptyOrNull(day) && !this.utilityService.isEmptyOrNull(month) && !this.utilityService.isEmptyOrNull(year)) {
      finalDate = new Date(+year, parseInt(month) - 1, +day);
    }
    else {
      finalDate = '';
    }
    // applicant details
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle, finalTitle['label']);
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName, fullName);

    // correspondence address
    if (!this.utilityService.isEmptyOrNull(alternateAddress)) {
      this.setFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceAddress1, alternateAddress['address_line_1']);
      this.setFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceAddress2, alternateAddress['address_line_2']);
      this.setFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceLandmark, '');
      this.setFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondencePincode, alternateAddress['pin_code']);
    }

    // permanent address
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress1, permanentAddress['address_line_1']);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress2, permanentAddress['address_line_2']);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentLandmark, '');
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode, permanentAddress['pin_code']);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentMaritalStatus, '');
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentEmail, kycDetailsData['email']);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentMobileNumber, kycDetailsData['mobile_number']);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress, addressFlag);

    // When you upload docs in ovd . And if that doc has address then we get certificate type  and enable those fields 

    if (this.utilityService.isEmptyOrNull(kycDetailsData['ckyc_number'])) {
      ovdManualSelfCase = true;
      let applicantFormGroup = this.findControlsByFormGroup(kycControls.applicantDetails, this.kycControls);
      let permanentFormGroup = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
      if (applicantFormGroup[0].controls.length > 0) {
        applicantFormGroup[0].controls.forEach(controls => {
          // if the case is ovd then we need to change the DOB control object to datepicker value
          // enable full name 
          if (controls.controlName == kycControls.applicantFullName) {
            controls.disabled = false;
          }
          else if (controls.controlName == kycControls.applicantDOB) {
            controls.disabled = false;
            this.changeFieldStructure(fieldTypeDetails.datepicker, controls);
            this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB, (finalDate));
          }
        })

        // Permanent address block will also be enabled
        permanentFormGroup[0].controls.forEach(controlData => {
          if (!(controlData.controlName == kycControls.permanentState || controlData.controlName == kycControls.permanentCity)) {
            controlData.hide = false;
            controlData.disabled = false;
          }
        })
      }
    }
    //  And if if it's normal value then disable those fields
    else {
      ovdManualSelfCase = false;
      panCardFormGroup[0].controls.forEach(data => {
        if (data.controlName == kycControls.applicantFullName || data.controlName == kycControls.applicantDOB) {
          data.disabled = true;
        }
      })
      this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB, this.utilityService.formatDate(finalDate));
    }

    // If ovd self manual case is true hide state and city
    if (ovdManualSelfCase) {
      correspondenceFormGroupData[0].controls.forEach(data => {
        if (data.controlName == kycControls.correspondenceState || data.controlName == kycControls.correspondenceCity) {
          data.hide = true;
        }
      })
    }

    // Permanent address pincode api call
    if (!this.utilityService.isEmptyOrNull(permanentAddress['pin_code'])) {
      this.fetchDataAsPerPincode(null, permanenetFormGroupData[0], kycControls.permanentPincode, kycControls.permanentState, permanentAddress['pin_code']);
    }

    // If permannet address pincode is same as corresppondence pincode don't call pincode api for correspondence addres
    if (!this.utilityService.isEmptyOrNull(alternateAddress)) {
      if (!addressFlag && !this.utilityService.isEmptyOrNull(alternateAddress['pin_code'])) {
        this.fetchDataAsPerPincode(null, correspondenceFormGroupData[0], kycControls.correspondencePincode, kycControls.correspondenceState, alternateAddress['pin_code']);
      }
    }

    this.pepFlag = !this.utilityService.isEmptyOrNull(kycDetailsData.pep_flag) ? kycDetailsData.pep_flag : false;
    // Check for self relationship
    this.checkForSelfRelationship(ovdManualSelfCase);

  }

  checkForSelfRelationship(ovdManualSelfCase: boolean) {
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      let selfIndex = -1;
      if (this.healthFormData.quoteFormData.subProductType == 38 || this.healthFormData.quoteFormData.subProductType == 23) {
        selfIndex = this.healthFormData.insuredFormData.insuredMemberDetails.findIndex((data, index) => data['applicantRelationshipAdult' + (index + 1)] == 'SELF');
      }
      else {
        selfIndex = this.healthFormData.quoteFormData.adultDetails.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
      }
      if (selfIndex != -1) {
        this.selfIndex = selfIndex;
        let adultControlName = (selfIndex + 1);
        this.self = true;
        this.normal = false
        this.normalSelf = !ovdManualSelfCase ? true : false;
        this.ovd = false;
        this.ovdSelf = ovdManualSelfCase ? true : false;
      }
      else {
        this.selfIndex = -1;
        this.self = false;
        this.normal = !ovdManualSelfCase ? true : false;
        this.normalSelf = false;
        this.ovd = ovdManualSelfCase ? true : false;
        this.ovdSelf = false;
      }
    }
  }

  patchSelfData(selfIndex, patchingRequired: boolean) {
    // If relation is self update title dob and full name in insured block
    if (patchingRequired && selfIndex != -1) {
      let adultControlName = (selfIndex + 1);
      this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['titleAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle);
      this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['fullNameAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName);
      this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex]['dobAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
      this.healthFormData.quoteFormData.adultDetails[selfIndex]['dobAdult' + adultControlName] = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
    }
  }

  patchValuesOnSelf() {
    this.isSubmit = false;
    this.kycForm.controls[kycControls.customerType].setValue('New');
    this.checkForSelfRelationshipOVD();
    if (this.existingPresent) {
      this.patchExistingUserDetails(this.existingKycData);
      this.isKYCDoneSuccessfully = true;
    }
    else if (!this.existingPresent) {
      this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress, false);
      this.setFormGroupValue(kycControls.otherDetails, kycControls.gstRegistered, 'No');
      this.setFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable, 'Yes');
    }

    this.kycControls.forEach(kycFormGroup => {
      if (kycFormGroup.formGroupLabel == kycControls.permanentAddress || kycFormGroup.formGroupLabel == kycControls.correspondenceAddress || kycFormGroup.formGroupLabel == kycControls.applicantDetails) {
        // if the case is ovd then we need to change the DOB control object to datepicker value
        if (kycFormGroup.formGroupLabel == kycControls.applicantDetails) {
          if (kycFormGroup.controls.length > 0) {
            kycFormGroup.controls.forEach(controls => {
              // enable full name 
              if (controls.controlName == kycControls.applicantFullName) {
                controls.disabled = false;
                // If it is ovd self then patch applicant full name
                if (this.ovdSelf) {
                  if (!this.existingPresent) {
                    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName, this.healthFormData.insuredFormData.insuredMemberDetails[this.selfIndex]['fullNameAdult' + (this.selfIndex + 1)]);
                  }
                }
              }
              else if (controls.controlName == kycControls.applicantDOB) {
                controls.disabled = false;
                this.changeFieldStructure(fieldTypeDetails.datepicker, controls);
                if (this.ovdSelf || this.existingPresent) {
                  let dob = !this.existingPresent ? this.healthFormData.insuredFormData.insuredMemberDetails[this.selfIndex]['dobAdult' + (this.selfIndex + 1)] : this.existingKycData.DOB;
                  let [day, month, year] = !(dob instanceof Date) ? dob.includes("/") ? dob.split("/") : dob.split("-") : '';
                  let tempMonth = !month.match(/\d+/g) ? this.utilityService.getMonthInNumber(month) : month;
                  let finalDate = dob instanceof Date ? new Date(dob) : new Date(+year, (tempMonth - 1), +day);

                  this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB, finalDate);
                }
              }
              // Enable disable pan card control
              else if (controls.controlName == kycControls.applicantPanCardNumber && !this.existingDataPresent) {
                if (parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000) {
                  this.showApplicantPanNo = true;
                  // Enable pan card control
                  controls.hide = false;
                  controls.disabled = false;
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
                  this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, '');
                }
                else {
                  this.showApplicantPanNo = false;
                  controls.hide = true;
                  controls.disabled = false;
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
                  this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
                }
              }
              else if (controls.controlName == kycControls.applicantTitle) {
                if (this.ovdSelf && !this.existingPresent) {
                  this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle, this.healthFormData.insuredFormData.insuredMemberDetails[this.selfIndex]['titleAdult' + (this.selfIndex + 1)]);
                }
              }
            })
          }
        }
        // For ovd we need to show correspondence address block and hide the state and city block
        // For permanent address all the fields will be enabled as we don't have data
        else if (kycFormGroup.formGroupLabel == kycControls.correspondenceAddress && !this.existingPresent) {
          this.kycForm.controls[kycFormGroup.formGroupLabel]['controls'][kycControls.correspondencePincode].reset();
          kycFormGroup.hide = false;
          this.formGroupDynamicValidation(kycControls.correspondenceAddress, true, kycFormGroup.controls);
          if (kycFormGroup.controls.length > 0) {
            kycFormGroup.controls.forEach(correspondenceControls => {
              if (correspondenceControls.controlName == kycControls.correspondenceState || correspondenceControls.controlName == kycControls.correspondenceCity) {
                correspondenceControls.hide = true;
                if (correspondenceControls.controlName == kycControls.correspondenceCity) {
                  correspondenceControls.value = [];
                }
              }
            })
          }
        }
        else if (kycFormGroup.formGroupLabel == kycControls.permanentAddress) {
          this.formGroupDynamicValidation(kycControls.permanentAddress, true, kycFormGroup.controls);
        }

        else if (kycFormGroup.formGroupLabel == kycControls.permanentAddress) {
          this.formGroupDynamicValidation(kycControls.permanentAddress, true, kycFormGroup.controls);
        }

      }
    })
    this.existingPresent = false;
    this.existingKycData = null;
  }

  resetFormControls(formName: FormGroup) {
    const controls = formName.controls;
    Object.keys(controls).forEach(controlName => {
      const control = controls[controlName];
      control.reset();
    });
  }

  resetKycFormGroups() {
    this.resetFormControls(this.kycForm.get(kycControls.applicantDetails) as FormGroup);
    this.resetFormControls(this.kycForm.get(kycControls.permanentAddress) as FormGroup);
    this.resetFormControls(this.kycForm.get(kycControls.correspondenceAddress) as FormGroup);
    this.resetFormControls(this.kycForm.get(kycControls.otherDetails) as FormGroup);
    this.resetFormControls(this.kycForm.get(kycControls.nomineeDetails) as FormGroup);
    this.resetFormControls(this.kycForm.get(kycControls.otherDetails) as FormGroup);
    this.ekycMandatory = false;
  }

  backToOriginalKycControls() {
    // Reseting back to orignal json
    this.kycControls.forEach(data => {
      // APointee details should be hidden initially
      data.hide = false;
      if (data.formGroupLabel === kycControls.appointeeDetails || data.formGroupLabel == kycControls.correspondenceAddress || (data.formGroupLabel === kycControls.nomineeDetails && this.isCreateCustomer)) {
        data.showControls = true;
        if (data.formGroupLabel === kycControls.appointeeDetails || (data.formGroupLabel === kycControls.nomineeDetails && this.isCreateCustomer)) {
          data.hide = true;
        }
      }

      // Default Value setting of orignal controls
      data.controls.forEach((controls: FormFields) => {
        switch (controls.controlName) {
          case kycControls.permanentSameAddress:
            controls.defaultValue = true;
            break;
          case kycControls.gstRegistered:
            controls.defaultValue = 'No';
            break;
          case kycControls.gstApplicable:
            controls.defaultValue = 'Yes';
            break;
          case kycControls.taxExemptionHB:
            controls.defaultValue = 'Annual';
            break;
          case kycControls.insuredHIP:
            controls.defaultValue = 'No';
            break;
          default:
            controls.defaultValue = '';
            break
        }

        // Below block i sused to declare controls that needs to be hidden
        if (this.shouldHideControl(controls.controlName)) {
          controls.hide = true;
          if (controls.controlName != kycControls.permanentState && controls.controlName != kycControls.correspondenceState) {
            controls.disabled = false;
          }
        } else {
          controls.hide = false;
          // Below block is used to hide unhide controls
          controls.disabled = this.isDisabledControl(controls.controlName);
          // If we need to change field structure use below block
          if (this.shouldChangeFieldStructure(controls)) {
            this.changeFieldStructure(fieldTypeDetails.input, controls);
          }
        }

        if (this.healthFormData.quoteFormData.subProductType == 23) {
          if (data.formGroupLabel == kycControls.otherDetails) {
            data.controls.map((x) => {
              if (x.controlName == kycControls.riskStartDate) {
                x.hide = false;
                x.disabled = false;
                x.validations = [{ "required": true }];
                let MaxDate = this.utilityService.addDays(new Date(), 45);
                x.maxDate = MaxDate;
                x.minDate = new Date();
              }
            });
          }

        }
        else {
          if (data.formGroupLabel == kycControls.otherDetails) {
            data.controls.map((x) => {
              if (x.controlName == kycControls.riskStartDate) {
                x.hide = true;
                x.disabled = true;
                x.validations = [];
              }
            });
          }
        }
      });
    });
  }

  private shouldHideControl(controlName: string): boolean {
    //Register controls hat needs to be hidden 
    const hiddenControls: string[] = [
      kycControls.applicantPanCardNumber,
      kycControls.permanentState,
      kycControls.permanentCity,
      kycControls.business,
      kycControls.panNumber,
      kycControls.otherCustomerType,
      kycControls.gstStatus,
      kycControls.gstApplicable,
      kycControls.gstin,
      kycControls.uin
    ];
    return hiddenControls.includes(controlName);
  }

  private isDisabledControl(controlName: string): boolean {
    // Register controls that needs to be disabled
    const disabledControls: string[] = [
      kycControls.applicantFullName,
      kycControls.applicantDOB,
      kycControls.permanentAddress1,
      kycControls.permanentAddress2,
      kycControls.permanentPincode,
      kycControls.correspondenceState
    ];
    return disabledControls.includes(controlName);
  }

  // Below method can be used to change field structure if needed
  private shouldChangeFieldStructure(controls: FormFields): boolean {
    return controls.controlName === kycControls.applicantDOB && controls.type === fieldTypeDetails.datepicker;
  }


  patchExistingUserDetails(response: FetchCustomerDetailsV1ResponseStructure) {
    this.healthFormData.kycFormData.customerType = 'Existing';
    this.customerType = 'Existing';
    this.kycForm.controls[kycControls.customerType].setValue('Existing');
    let tempMonth;
    let applicantTitle = !this.utilityService.isEmptyOrNull(response.Gender) ? (response.Gender).toLowerCase() == gender.Male ? 'Mr.' : '' : '';
    let maritalStatus = !this.utilityService.isEmptyOrNull(response.MARITAL_STATUS) ? (response.MARITAL_STATUS).toLowerCase() == maritalSatus.Married ? (maritalSatus.Married).toUpperCase() : (maritalSatus.Single).toUpperCase() : '';
    let correspondenceFormGroupData = this.findControlsByFormGroup(kycControls.correspondenceAddress, this.kycControls);
    let permanentFormGroupData = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
    let applicantFormGroupData = this.findControlsByFormGroup(kycControls.applicantDetails, this.kycControls);
    let applicantNameIndex = this.findControlsByIndex(applicantFormGroupData[0].controls, kycControls.applicantFullName);
    let applicantDOBIndex = this.findControlsByIndex(applicantFormGroupData[0].controls, kycControls.applicantDOB);

    let [day, month, year] = response.DOB.includes("/") ? response.DOB.split("/") : response.DOB.split("-");
    tempMonth = !month.match(/\d+/g) ? this.utilityService.getMonthInNumber(month) : month;

    // Applicant Details
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber, response.PANNumber);
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle, applicantTitle)
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName, response.UserName);
    // Patch applicant dob data
    applicantFormGroupData[0].controls[applicantNameIndex].disabled = true;
    applicantFormGroupData[0].controls[applicantDOBIndex].disabled = true;
    this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB, response.DOB);

    //Permanent Address
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress1, response.Address1);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress2, response.Address2);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentLandmark, '');
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode, response.PinCode);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentState, response.State);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentEmail, response.EmailID);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentMobileNumber, response.Mobile);
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentMaritalStatus, maritalStatus);

    if (!this.utilityService.isEmptyOrNull(response.PinCode)) {
      this.fetchDataAsPerPincode(null, permanentFormGroupData[0], kycControls.permanentPincode, kycControls.permanentState, response.PinCode);
      this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity, response.City);
    }

    // As permanent address is by default No then we need to unhide correspondence address and set Validators
    this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress, false); // by default
    correspondenceFormGroupData[0].hide = false;
    // Hide state and city
    correspondenceFormGroupData[0].controls.forEach(data => {
      if (data.controlName == kycControls.correspondenceState || data.controlName == kycControls.correspondenceCity) {
        data.hide = true;
        if (data.controlName == kycControls.correspondenceCity) {
          if (data.type == fieldTypeDetails.dropdown) {
            data.value = [];
          }
        }
      }
    })
    this.formGroupDynamicValidation(kycControls.correspondenceAddress, true, correspondenceFormGroupData[0]['controls']);
    if (!this.existingPresent) {
      // Check for self condition
      if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
        let selfIndex = -1;
        if (this.healthFormData.quoteFormData.subProductType == 38 || this.healthFormData.quoteFormData.subProductType == 23) {
          selfIndex = this.healthFormData.insuredFormData.insuredMemberDetails.findIndex((data, index) => data['applicantRelationshipAdult' + (index + 1)] == 'SELF');
        }
        else {
          selfIndex = this.healthFormData.quoteFormData.adultDetails.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
        }
        //  If self exists then disable all the fields and patch applicant title, name and dob in insured block
        if (selfIndex != -1) {
          this.selfIndex = selfIndex;
          this.checkWhetherSelfExists(kycControls.applicantTitle);
          this.checkWhetherSelfExists(kycControls.applicantFullName);
          this.checkWhetherSelfExists(kycControls.applicantDOB);
          this.normal = false;
          this.normalSelf = true; // So the purpose of variable normal self is that when self exists and dob,title should get patched in insured block. That's why same variable is used for existing
          this.ovd = false;
          this.ovdSelf = false;
          this.self = true;
        }
        // If relation is not self then all the values will be editable
        else {
          this.selfIndex = -1;
          this.normal = true;
          this.normalSelf = false;
          this.ovd = false; // So the purpose of variable ovd is that when dob,title should be editable. That's why same variable is used for existing
          this.ovdSelf = false;
          this.self = false;
        }
      }
    }

    // Check for pancard if pan card value comes from response simply disable the pancard and if there is no value enable the pancard
    let panCardControls = this.findControlByControlName(kycControls.applicantPanCardNumber, applicantFormGroupData[0].controls)
    if (parseInt(this.healthFormData.premiumFormData.totalPremium) > 80000) {
      this.showApplicantPanNo = true;
      // Enable pan card control
      panCardControls['hide'] = false;
      if (!this.utilityService.isEmptyOrNull(response.PANNumber)) {
        this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
        this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
        panCardControls.disabled = true;
      }
      else {
        panCardControls.disabled = false;
        this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
        this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
      }
    }
    else {
      this.showApplicantPanNo = false;
      panCardControls['hide'] = true;
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].clearValidators();
      this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].updateValueAndValidity();
    }

    this.eKycPan = !this.utilityService.isEmptyOrNull(response.PANNumber) ? response.PANNumber : '';

    let otherDetailsIndex: number = 0;
    let gstApplicableIndex: number = 0;
    let gstRegisteredIndex: number = 0;
    let gstInIndex: number = 0;
    let uinIndex: number = 0;
    let businessDropDown = [];
    let customerDropDown = [];
    let gstStatusDropdown = [];
    // checking gst applicable & Uin applicable
    this.kycControls.forEach((tabs, tabIndex) => {
      if (tabs.formGroupLabel == kycControls.otherDetails) {
        otherDetailsIndex = tabIndex;
        tabs.controls.forEach((controls, controlsIndex) => {
          if (controls.controlName == kycControls.gstin) {
            gstInIndex = controlsIndex;
          }
          else if (controls.controlName == kycControls.uin) {
            uinIndex = controlsIndex;
          }
          else if (controls.controlName == kycControls.gstApplicable) {
            gstApplicableIndex = controlsIndex;
          }
          else if (controls.controlName == kycControls.gstRegistered) {
            gstRegisteredIndex = controlsIndex;
          }
          // Business dropdown
          else if (controls.controlName == kycControls.business) {
            businessDropDown = controls.value;
            gstRegisteredIndex = controlsIndex;
            businessDropDown = businessDropDown.map((data) => {
              let tempBusiness = {
                'primaryLabel': data.label,
                'secondaryLabel': (data.label).toLowerCase(),
                'value': data.value,
              }
              return tempBusiness;
            })
          }

          // Customer DropDown
          else if (controls.controlName == kycControls.otherCustomerType) {
            customerDropDown = controls.value;
            customerDropDown = customerDropDown.map((data) => {
              let tempCustomer = {
                'primaryLabel': data.label,
                'secondaryLabel': (data.label).toLowerCase(),
                'value': data.value,
              }
              return tempCustomer;
            })
          }

          // Gst status dropdown
          else if (controls.controlName == kycControls.gstStatus) {
            gstStatusDropdown = controls.value;
            gstStatusDropdown = gstStatusDropdown.map((data) => {
              let tempGstStatus = {
                'primaryLabel': data.label,
                'secondaryLabel': (data.label).toLowerCase(),
                'value': data.value,
              }
              return tempGstStatus;
            })
          }
        })
      }
    })

    // Other Details
    this.setFormGroupValue(kycControls.otherDetails, kycControls.gstRegistered, (response.GSTApplicable ? 'Yes' : 'No'));
    this.changeInRadioButton(kycControls.gstRegistered, otherDetailsIndex, gstRegisteredIndex, kycControls.otherDetails);

    if (response.GSTApplicable) {
      if (response.IS_UIN_APPLICABLE) {
        this.setFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable, healthToggle.No);
        this.changeInRadioButton(kycControls.gstApplicable, otherDetailsIndex, gstApplicableIndex, kycControls.otherDetails);
        this.setFormGroupValue(kycControls.otherDetails, kycControls.uin, response.GSTIN_UIN_NO);
      }
      else if (!response.IS_UIN_APPLICABLE) {
        this.setFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable, healthToggle.Yes);
        this.changeInRadioButton(kycControls.gstApplicable, otherDetailsIndex, gstApplicableIndex, kycControls.otherDetails);
        this.setFormGroupValue(kycControls.otherDetails, kycControls.gstin, response.GSTIN_UIN_NO);
      }
    }

    else if (!response.GSTApplicable) {
      this.setFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable, healthToggle.Yes);
      this.setFormGroupValue(kycControls.otherDetails, kycControls.gstin, response.GSTIN_UIN_NO);
    }

    if (response.GSTApplicable) {
      this.setFormGroupValue(kycControls.otherDetails, kycControls.panNumber, response.PAN_NO);
      if (businessDropDown.length > 0 && !this.utilityService.isEmptyOrNull(response.CONSTITUTION_OF_BUSINESS)) {
        let businessValue: string = businessDropDown.find(data => data.secondaryLabel == (response.CONSTITUTION_OF_BUSINESS).toLowerCase()).primaryLabel;
        this.setFormGroupValue(kycControls.otherDetails, kycControls.business, businessValue);
      }

      if (customerDropDown.length > 0 && !this.utilityService.isEmptyOrNull(response.CUSTOMER_TYPE)) {
        let customerValue: string = customerDropDown.find(data => data.secondaryLabel == ((response.CUSTOMER_TYPE).toLowerCase())).primaryLabel;
        this.setFormGroupValue(kycControls.otherDetails, kycControls.otherCustomerType, customerValue);
      }

      if (gstStatusDropdown.length > 0 && !this.utilityService.isEmptyOrNull(response.GST_REGISTRATION_STATUS)) {
        let gstStatusValue: string = gstStatusDropdown.find(data => data.secondaryLabel == ((response.GST_REGISTRATION_STATUS).toLowerCase())).primaryLabel;
        this.setFormGroupValue(kycControls.otherDetails, kycControls.gstStatus, gstStatusValue);
      }
    }

    this.showSearchPopup = false;
    this.ekycMandatory = true;

    if (!this.utilityService.isEmptyOrNull(response.CKYCID) || !this.utilityService.isEmptyOrNull(response.EKYCID) ||
      !this.utilityService.isEmptyOrNull(response?.ReferenceNo)) {
      this.isKYCDoneSuccessfully = true;
    }
    else {
      this.showEKYC = true;
      this.isKYCDoneSuccessfully = false;
      this.existingPresent = true;
    }

  }

  checkForSelfRelationshipOVD() {
    let permanentFormGroup = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
    let selfIndex = -1;
    if (this.healthFormData.quoteFormData.subProductType == 38 || this.healthFormData.quoteFormData.subProductType == 23) {
      selfIndex = this.healthFormData.insuredFormData.insuredMemberDetails.findIndex((data, index) => data['applicantRelationshipAdult' + (index + 1)] == 'SELF');
    }
    else {
      selfIndex = this.healthFormData.quoteFormData.adultDetails.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
    }
    // Enable pincode and city of permanent address block
    permanentFormGroup[0].controls.forEach(controlData => {
      // All will be enabled
      controlData.hide = false;
      controlData.disabled = false;
      if (controlData.controlName == kycControls.permanentCity || controlData.controlName == kycControls.permanentState) {
        this.changeFieldStructure(fieldTypeDetails.input, controlData);
      }
    })

    // In OVD either for self or normal relation patch pincode state and city from quote
    // Patch it in personal address block
    if (!this.existingPresent) {
      this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode, this.healthFormData.quoteFormData.pincode);
      this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentState, (this.healthFormData.quoteFormData.cityState).split(" ")[0]);
      this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity, this.healthFormData.quoteFormData.cityName);
      this.permanentStateID = this.healthFormData.quoteFormData.stateId;
      this.fetchDataAsPerPincode(null, permanentFormGroup[0], kycControls.permanentPincode, kycControls.permanentState, this.healthFormData.quoteFormData.pincode)
    }

    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      if (selfIndex != -1) {
        this.selfIndex = selfIndex;
        this.self = true;
        this.normal = false;
        this.normalSelf = false;
        this.ovd = false;
        this.ovdSelf = true;
        // In Ovd self case we need to enter data into title, fname and dob. and patch same in insured block.
        // Also, in case of self patch pincode,state and city from quote page

        // Patch it in personal address block
        // this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode, this.healthFormData.quoteFormData.pincode);
        // this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentState, (this.healthFormData.quoteFormData.cityState).split(" ")[0]);
        // this.setFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity, this.healthFormData.quoteFormData.cityName);
        // this.permanentStateID = this.healthFormData.quoteFormData.stateId;

        // permanentFormGroup[0].controls.forEach(data => {
        //   if (data.controlName == kycControls.permanentState || data.controlName == kycControls.permanentCity) {
        //     data.hide = false;
        //     if (data.controlName == kycControls.permanentCity) {
        //       this.changeFieldStructure(fieldTypeDetails.input, data);
        //       data.disabled = false;
        //     }
        //     else {
        //       data.disabled = true;
        //     }
        //   }
        // })

        // this.disableFormGroupValue(kycControls.permanentAddress, kycControls.permanentState);
        // this.enableFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity);


        // Push city value in controls
        // Enable state and city of permanent address block
        // permanentFormGroup[0].controls.forEach(controlData => {
        //   if (controlData.controlName == kycControls.permanentState || controlData.controlName == kycControls.permanentCity) {
        //     controlData.hide = false;
        //   }
        // Need to check
        //   if (controlData.controlName == kycControls.permanentCity) {
        //     let tempCity = [];
        //     tempCity.push({ 'label': this.healthFormData.quoteFormData.cityName, 'value': this.healthFormData.quoteFormData.cityId, 'disabled': false })
        //     controlData.value = tempCity;
        //   }
        // })
      }

      else if (selfIndex == -1) {
        this.selfIndex = -1;
        this.self = false;
        this.normal = false;
        this.normalSelf = false;
        this.ovd = true;
        this.ovdSelf = false;
      }
    }
  }


  kycButtonClicked(event: EmitButtonData) {

    if (event.label == 'Save') {
      this.saveDetails('save');
    }

    else {
      if (this.isKYCDoneSuccessfully) {
        this.saveDetails('submit');
      }

      else if (!this.isKYCDoneSuccessfully) {
        let popupData: PopupModal = {
          popupType: popupType.generic,
          imgName: popupImgPath.warningGif,
          header: popupHeader.error,
          description: popupDescriptionMsg.kycAgain,
          buttonLabel: popupButton.ok,
          buttonAction: popupButtonAction.close
        };
        this.popupService.openGenericPopup(popupData);
      }
    }
  }

  saveDetails(type: string) {
    this.kycForm.markAllAsTouched();
    this.isSubmit = true;
    if (this.isCreateCustomer && type == 'save') {
      this.resetFormGroup(kycControls.nomineeDetails);
    }
    if (!this.kycForm.valid) {
      for (const formGroupName of Object.keys(this.kycForm.controls)) {
        const formGroup = this.kycForm.get(formGroupName) as FormGroup;

        if (formGroup.invalid) {
          for (const controlName of Object.keys(formGroup.controls)) {
            if (formGroupName == kycControls.otherDetails) {
              let gstRegistered = this.getFormGroupValue(kycControls.otherDetails, kycControls.gstRegistered);

              if (gstRegistered == healthToggle.No && controlName != kycControls.riskStartDate) {
                this.kycForm.controls[kycControls.otherDetails]['controls'][controlName].clearValidators();
                this.kycForm.controls[kycControls.otherDetails]['controls'][controlName].updateValueAndValidity();
              }
            }

            else if (formGroup.get(controlName).invalid) {
              const invalidControl = this.el.nativeElement.querySelector('#' + controlName + '.ng-invalid');
              if (!this.utilityService.isEmptyOrNull(invalidControl)) {
                invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
                invalidControl.focus();
              }
              break;
            }
          }
          break;
        }
      }
    }
    else {
      if (this.checkValidation()) {
        this.buttonType = type;
        this.isSubmit = false;

        this.applicantDetailsFormGroup = this.findControlsByFormGroup(kycControls.applicantDetails, this.kycControls);
        this.correspondenceFormGroup = this.findControlsByFormGroup(kycControls.correspondenceAddress, this.kycControls);
        this.permanentFormGroup = this.findControlsByFormGroup(kycControls.permanentAddress, this.kycControls);
        this.otherDetailsFormGroup = this.findControlsByFormGroup(kycControls.otherDetails, this.kycControls);
        this.apointeeDetailsFormGroup = this.findControlsByFormGroup(kycControls.appointeeDetails, this.kycControls);
        this.nomineeDetailsFormGroup = this.findControlsByFormGroup(kycControls.nomineeDetails, this.kycControls);

        // Fetching all controls required for request payload 
        this.applicantDetailsControls = this.applicantDetailsFormGroup[0]?.controls.length > 0 ? this.applicantDetailsFormGroup[0].controls : [];
        this.correspondenceControls = this.correspondenceFormGroup[0]?.controls.length > 0 ? this.correspondenceFormGroup[0]?.controls : [];
        this.permanentControls = this.permanentFormGroup[0]?.controls.length > 0 ? this.permanentFormGroup[0]?.controls : [];
        this.otherDetailsControls = this.otherDetailsFormGroup[0]?.controls.length > 0 ? this.otherDetailsFormGroup[0]?.controls : [];
        this.apointeeDetailsControls = this.apointeeDetailsFormGroup[0]?.controls.length > 0 ? this.apointeeDetailsFormGroup[0]?.controls : [];
        this.nomineeDetailsControls = this.nomineeDetailsFormGroup[0]?.controls.length > 0 ? this.nomineeDetailsFormGroup[0]?.controls : [];

        let correspondenceCityId = this.correspondenceControls.length > 0 ? this.getSelectedDropDownId(kycControls.correspondenceCity, this.correspondenceControls, this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceCity)) : null;
        let permanentCityId = this.permanentControls.length > 0 ? this.getSelectedDropDownId(kycControls.permanentCity, this.permanentControls, this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity)) : null;

        if (!this.isCreateCustomer) {

          if (this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode) != '' && (permanentCityId != null && !isNaN(permanentCityId))) {
            this.fetchZoneData(kycControls.permanentAddress);
          }

          // fetch correspondence pincode id
          if (!this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress))
            if (this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondencePincode) != '' && (correspondenceCityId != null && !isNaN(correspondenceCityId))) {
              this.fetchZoneData(kycControls.correspondenceAddress);
            }

        }
        else {
          this.constructSaveRequest();
        }
      }
    }
  }

  checkValidation(): boolean {
    let allowFunctionality: boolean = false;
    if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType)) {
      this.maternityTitlError = false;
      this.criticalIllnessError = false;
      this.cibilError = false;
      this.selfPopupActivated = false;

      if (this.healthFormData.quoteFormData.isCibilApplicable == 'Yes') {
        let cibilStatus = ((this.healthFormData.quoteFormData.proposerName).split(" ").filter(val => val != '').join(" ").toUpperCase() == this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName).split(" ").filter(val => val != '').join(" ").toUpperCase()) && (this.healthFormData.quoteFormData.mobileNo == this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentMobileNumber)) ? true : false;

        if (!cibilStatus) {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.cibilKYC);
          this.popupService.openGenericPopup(this.errorPopupData);
          this.cibilError = true;
          this.subscription.push(this.popupService.cibilScore$.subscribe(data => {
            if (data) {
              this.cibilError = false;
              this.popupService.emitCibilData(false);
              this.customStepperService.redirectToStep('f1', false);
            }
          }))
        }
      }

      // Check for maternity condition only hap product and relation is self
      if (!this.cibilError && this.healthFormData.quoteFormData.adultDetails.length > 0 && this.selfIndex != -1 && this.healthFormData.quoteFormData.maternityForAdult.length > 0) {
        // check whether self relation user has taken maternity or not
        let member = (healthMembers.Adult + ' ' + (this.selfIndex + 1));
        let maternityTakenMember = this.healthFormData.quoteFormData.maternityForAdult.includes(member);
        this.maternityTitlError = false;
        // User has taken maternity
        // let selfSelectedTitle = tempFormData.insuredFormData.insuredMemberDetails[this.selfIndex]['titleAdult' + (this.selfIndex + 1)];
        let applicantTitle = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle);
        if (!this.utilityService.isEmptyOrNull(applicantTitle) && maternityTakenMember) {
          // If title is not same. Show popup to select only female title
          if (applicantTitle == 'Mr.') {
            this.setFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle, '');
            this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantTitle].setValidators([Validators.required]);
            this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantTitle].updateValueAndValidity();
            this.maternityTitlError = true;
            this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.maternityKycPopup);
            this.popupService.openGenericPopup(this.errorPopupData);
          }
        }
      }

      // Check if critical illness is opted then proposer dob should be same with applicant dob
      if (!this.maternityTitlError && !this.cibilError && this.healthFormData.quoteFormData.criticalIllnessForAdult.length > 0 && !this.healthFormData.quoteFormData.selfMemberExists) {
        this.criticalIllnessError = false;
        if (!this.utilityService.isEmptyOrNull(this.healthFormData.quoteFormData.proposerDobCi)) {
          let applicantDOB = this.utilityService.formatDateWithDash_dmy(this.kycForm.value.applicantDetails['applicantDOB']);
          let proposerDOB = this.utilityService.formatDateWithDash_dmy(this.healthFormData.quoteFormData.proposerDobCi);

          if (applicantDOB != proposerDOB) {
            this.criticalIllnessError = true;
            this.utilityService.popUpDialog('Critical Illness Proposer DOB should match with Applicant DOB');
          }
        }
      }
    }

    // Below condition to be executed only for self. If dob mismatched show warning
    let checkHapFlagsActivation: boolean = false;
    // If in hap product any of the popup is activated then don't show self popup. Not valid for other products

    if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType)) {
      if ((!this.cibilError && !this.maternityTitlError && !this.criticalIllnessError)) {
        checkHapFlagsActivation = false;
      }
      else {
        checkHapFlagsActivation = true;
      }
    }

    if (!checkHapFlagsActivation && (this.normalSelf || this.self || this.ovdSelf)) {
      let tempFormData = this.healthDataStorageService.getHealthDataStorage();
      let selfDOB = tempFormData.insuredFormData.insuredMemberDetails[this.selfIndex][`dobAdult${this.selfIndex + 1}`];
      selfDOB = selfDOB.includes('T') ? this.utilityService.formatDateWithDash_dmy(selfDOB) : selfDOB;
      let applicantDOB = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
      let formattedDOB = this.utilityService.formatDateWithDash_dmy(applicantDOB);
      if (selfDOB != formattedDOB) {
        this.selfPopupActivated = true;
        this.utilityService.popUpDialog('Applicant DOB and Insured DOB must be same in case of SELF');
      }
      else {
        this.selfPopupActivated = false;
      }
    }
    this.cibilError = false;
    return allowFunctionality = (checkHapFlagsActivation || this.selfPopupActivated || this.criticalIllnessError || this.maternityTitlError || this.cibilError) ? false : true;
  }

  constructSaveRequest() {
    this.multipleServiceError = false;
    let permanentAddressSame = this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress);
    let otherDetailsSelected = this.getFormGroupValue(kycControls.otherDetails, kycControls.gstRegistered) == 'Yes' ? true : false;
    let correspondenceCityId = this.correspondenceControls.length > 0 ? this.getSelectedDropDownId(kycControls.correspondenceCity, this.correspondenceControls, this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceCity)) : null;
    let permanentCityId = this.permanentControls.length > 0 ? this.getSelectedDropDownId(kycControls.permanentCity, this.permanentControls, this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity)) : null;
    if (!(this.routeData == routeEnums.MaxProtect || this.routeData == routeEnums.HAP || this.routeData == routeEnums.Elevate)) {
      this.saveCustomerRequest = {
        "CustomerID": this.utilityService.isEmptyOrNull(parseInt(this.customerID)) ? 0 : parseInt(this.customerID), // no point of sending this,
        "PfCustomerID": !this.utilityService.isEmptyOrNull(this.selectedPfCustomerId) ? this.selectedPfCustomerId : '',
        "HasAddressChanged": true,// This is going by default true
        "SetAsDefault": true,// This is going by default true
        "TitleText": this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle),
        "TitleValue": this.applicantDetailsControls.length > 0 ? this.getSelectedDropDownId(kycControls.applicantTitle, this.applicantDetailsControls, this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantTitle)) : null,
        "Name": this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantFullName),
        "DateOfBirth": this.utilityService.formatDate(this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB)),
        "MaritalStatusValue": this.permanentControls.length > 0 ? this.getSelectedDropDownId(kycControls.permanentMaritalStatus, this.permanentControls, this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentMaritalStatus)) : null,
        "MaritalStatusText": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentMaritalStatus),
        // Unused params starts
        "OccupationValue": 0,
        "OccupationText": "",
        "AnnualIncomeValue": 0,
        "AnnualIncomeText": "",
        "IdentityProofText": "",
        "IdentityNumber": "",
        "IdentityProofValue": 0,
        // unused params end
        "PresentAddrLine1": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceAddress1) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress1),
        "PresentAddrLine2": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceAddress2) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress2),
        "PresentAddrLandmark": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceLandmark) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentLandmark),
        "PresentAddrCityValue": !permanentAddressSame ? correspondenceCityId : permanentCityId,
        "PresentAddrCityText": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceCity) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity),
        "PresentAddrStateValue": !permanentAddressSame ? parseInt(this.correspondenceStateID) : parseInt(this.permanentStateID),
        "PresentAddrStateText": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceState) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentState),
        "PresentAddrPincode": !permanentAddressSame ? this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondencePincode) : this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode),
        "EmailAddress": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentEmail),
        "MobileNumber": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentMobileNumber),
        "LandlineNumber": null, // not required,
        "PANNumber": this.showApplicantPanNo ? this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantPanCardNumber) : '',
        "isPermanentAsPresent": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress) ? true : false,
        "PermanentAddrLine1": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress1),
        "PermanentAddrLine2": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentAddress2),
        "PermanentAddrLandmark": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentLandmark),
        "PermanentAddrPincode": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentPincode),
        "PermanentAddrStateValue": parseInt(this.permanentStateID),
        "PermanentAddrStateText": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentState),
        "PermanentAddrCityValue": permanentCityId,
        "PermanentAddrCityText": this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity),
        //  By default setting false
        "IsPanNumberExist": false,
        "isUINApplicable": this.getFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable) == 'No' ? true : false,
        // ends
        "isGSTINApplicable": this.getFormGroupValue(kycControls.otherDetails, kycControls.gstApplicable) == 'Yes' ? true : false,
        "CKYCID": this.cKycId,
        "SkipDedupeLogic": "true", // by default,
        "pepFlag": this.pepFlag,
        "ilkycReferenceNumber": this.ilKycReferenceNumber,
        "EKYCid": "",
        "GSTDetails": otherDetailsSelected ? {
          "CONSTITUTION_OF_BUSINESS": otherDetailsSelected ? this.otherDetailsControls.length > 0 ? this.getSelectedDropDownId(kycControls.business, this.otherDetailsControls, this.getFormGroupValue(kycControls.otherDetails, kycControls.business)) : null : null,
          "CONSTITUTION_OF_BUSINESS_TEXT": otherDetailsSelected ? this.getFormGroupValue(kycControls.otherDetails, kycControls.business) : '',
          "CUSTOMER_TYPE": otherDetailsSelected ? this.otherDetailsControls.length > 0 ? this.getSelectedDropDownId(kycControls.otherCustomerType, this.otherDetailsControls, this.getFormGroupValue(kycControls.otherDetails, kycControls.otherCustomerType)) : null : null,
          "CUSTOMER_TYPE_TEXT": otherDetailsSelected ? this.getFormGroupValue(kycControls.otherDetails, kycControls.otherCustomerType) : '',
          "PAN_NO": otherDetailsSelected ? this.getFormGroupValue(kycControls.otherDetails, kycControls.panNumber) : '',
          "GST_REGISTRATION_STATUS": otherDetailsSelected ? this.getSelectedDropDownId(kycControls.gstStatus, this.otherDetailsControls, this.getFormGroupValue(kycControls.otherDetails, kycControls.gstStatus)) : null,
          "GST_REGISTRATION_STATUS_TEXT": otherDetailsSelected ? this.getFormGroupValue(kycControls.otherDetails, kycControls.gstStatus) : '',
          "GSTIN_NO": otherDetailsSelected ? this.getFormGroupValue(kycControls.otherDetails, kycControls.gstin) : '',
          "GST_STATE_NAME": ''
        } : undefined
      }
    }

    // this.spinner.show();
    if (!this.isCreateCustomer) {
      this.setHealthData();
    }
    if (this.buttonType == 'save' || this.buttonType == 'submit') {
      if (this.routeData == routeEnums.MaxProtect || this.routeData == routeEnums.HAP || this.isOrionJourney == 'true' || this.routeData == routeEnums.Elevate) {

        if (this.buttonType == 'submit' && !this.cibilError) {
          this.patchingDone = true;
          this.customStepperService.emitStepData({ status: true, buttonId: 'f4' });
        }
      }
      else {
        this.subscription.push(this.customerService.saveEditCustomer(this.saveCustomerRequest).subscribe({
          next: response => {
            if (response.StatusCode == Constants.statusCode_success) {
              this.isStatusCode = true;
              this.healthFormData.kycFormData.PFCustomerID = response.Data.PFCustomerID;
              this.healthFormData.kycFormData.CustomerID = response.Data.CustomerID;
              this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
              if (this.buttonType == 'submit') {
                this.patchingDone = true;
                this.customStepperService.emitStepData({ status: true, buttonId: 'f4' });
              } else if (this.buttonType == 'save') {
                let popupData: PopupModal = {
                  popupType: popupType.generic,
                  imgName: popupImgPath.successGif,
                  header: popupHeader.customerCreated,
                  description: popupDescriptionMsg.createCustomer + response.Data.PFCustomerID,
                  buttonLabel: popupButton.ok,
                  buttonAction: popupButtonAction.dashboard
                };
                this.popupService.openGenericPopup(popupData);
              }
              else {
                let request1 = this.constructProposalRequest(response.Data);
                this.subscription.push(this.healthService.saveEditProposalV1(request1).subscribe({
                  next: response => {
                    if (response.StatusCode == Constants.statusCode_success) {
                      this.isStatusCode = true;
                      let popupData: PopupModal = {
                        popupType: popupType.generic,
                        imgName: popupImgPath.successGif,
                        header: popupHeader.quoteSaved,
                        description: popupDescriptionMsg.pfQuoteId + response.Data.PfQuoteId,
                        buttonLabel: popupButton.ok,
                        buttonAction: popupButtonAction.dashboard
                      };
                      this.popupService.openGenericPopup(popupData);
                    }
                    else if (response.StatusCode != Constants.statusCode_success) {
                      this.isStatusCode = false;
                      this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
                    }
                  },
                  error: error => {
                    this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
                  }
                }))
              }
            }
            else if (response.StatusCode != Constants.statusCode_success) {
              this.isStatusCode = false;
              this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
            }
          }
        }))
      }
    }
  }

  setHealthData() {
    if (this.self || this.normalSelf || this.ovdSelf) {
      this.patchSelfData(this.selfIndex, true)
    }
    this.healthFormData.kycFormData = this.kycForm.value;
    this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode = this.kycForm.controls[kycControls.correspondenceAddress]['controls'][kycControls.correspondencePincode].getRawValue();

    this.healthFormData.kycFormData.KYCStateId = String(this.getSelectedDropDownId(kycControls.permanentState, this.permanentControls, this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentState)));
    this.healthFormData.kycFormData.KYCCityId = String(this.getSelectedDropDownId(kycControls.permanentCity, this.permanentControls, this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentCity)));
    this.healthFormData.kycFormData.CoStateId = String(this.getSelectedDropDownId(kycControls.correspondenceState, this.correspondenceControls, this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceState)));
    this.healthFormData.kycFormData.CoCityId = String(this.getSelectedDropDownId(kycControls.correspondenceCity, this.correspondenceControls, this.getFormGroupValue(kycControls.correspondenceAddress, kycControls.correspondenceCity)));
    // If permanent and correspondence is same patch permanent address in correspondence address
    if (this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress)) {
      this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1 = this.healthFormData.kycFormData.permanentAddress.permanentAddress1;
      this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2 = this.healthFormData.kycFormData.permanentAddress.permanentAddress2;
      this.healthFormData.kycFormData.correspondenceAddress.correspondenceLandmark = this.healthFormData.kycFormData.permanentAddress.permanentLandmark;
      this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode = this.healthFormData.kycFormData.permanentAddress.permanentPincode;
      this.healthFormData.kycFormData.correspondenceAddress.correspondenceState = this.healthFormData.kycFormData.permanentAddress.permanentState;
      this.healthFormData.kycFormData.correspondenceAddress.correspondenceCity = this.healthFormData.kycFormData.permanentAddress.permanentCity;
      this.healthFormData.kycFormData.CoStateId = this.healthFormData.kycFormData.KYCStateId;
      this.healthFormData.kycFormData.CoCityId = this.healthFormData.kycFormData.KYCCityId;
    }
    this.healthFormData.kycFormData.applicantDetails.applicantFullName = this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantFullName].value;
    this.healthFormData.kycFormData.applicantDetails.applicantDOB = this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantDOB].value;
    this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber = this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].value ? this.kycForm.controls[kycControls.applicantDetails]['controls'][kycControls.applicantPanCardNumber].value : '';
    this.healthFormData.kycStatus.ekycMandatory = this.ekycMandatory;
    this.healthFormData.kycFormData.customerType = this.kycForm.controls[kycControls.customerType].value;
    this.healthFormData.kycStatus.normal = this.normal;
    this.healthFormData.kycStatus.ovd = this.ovd;
    this.healthFormData.kycStatus.ovdSelf = this.ovdSelf;
    this.healthFormData.kycStatus.normalSelf = this.normalSelf;
    this.healthFormData.kycStatus.selfIndex = this.selfIndex;
    this.healthFormData.kycStatus.isKYCDoneSuccessfully = this.isKYCDoneSuccessfully;
    this.healthFormData.kycStatus.idType = this.eKycPan != '' ? true : false;
    this.healthFormData.kycStatus.certNumber = this.healthFormData.kycStatus.certNumber != '' ? this.healthFormData.kycStatus.certNumber : '';
    this.healthFormData.kycDataSet = true;
    this.healthFormData.kycFormData.CKYCID = this.cKycId;
    this.healthFormData.showAppointee = this.showAppointee;
    this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID = this.getSelectedDropDownId(kycControls.appointeeRelationship, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeRelationship));
    this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID = this.getSelectedDropDownId(kycControls.appointeeTitle, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeTitle));
    this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID = this.getSelectedDropDownId(kycControls.nomineeTitle, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeTitle));
    this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID = this.getSelectedDropDownId(kycControls.nomineeRelationship, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeRelationship));
    this.healthFormData.kycFormData.nomineeDetails.nomineeDOB = this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeDOB);
    this.healthFormData.kycFormData.ilKycReferenceNumber = this.ilKycReferenceNumber;
    this.healthFormData.kycFormData.pepFlag = !this.utilityService.isEmptyOrNull(this.pepFlag) ? this.pepFlag : false;
    this.healthFormData.kycStatus.isPilotUser = this.isPilotUser;
    this.healthFormData.kycFormData.CustomerID = this.utilityService.isEmptyOrNull(parseInt(this.customerID)) ? 0 : parseInt(this.customerID);
    if (this.healthFormData.quoteFormData.subProductType == 23) {
      this.healthFormData.kycFormData.otherDetails.riskStartDate = this.getFormGroupValue(kycControls.otherDetails, kycControls.riskStartDate);
    }
    if (this.ovdSelf || this.normalSelf) {
      let selfDOB: Date | any = this.getFormGroupValue(kycControls.applicantDetails, kycControls.applicantDOB);
      if (selfDOB instanceof Date) {
        if (this.isOrionJourney == 'true') {
          selfDOB = this.utilityService.formatDate(selfDOB);
        } else {
          selfDOB = this.utilityService.formatDateWithDash(selfDOB);
        }
      }
      this.healthFormData.quoteFormData.totalMemberData[this.selfIndex]['DOB'] = selfDOB;
    }
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  constructProposalRequest(saveEditResponse: SaveEditCustomerResponseStructure) {
    let permanentAddressSame = this.getFormGroupValue(kycControls.permanentAddress, kycControls.permanentSameAddress)
    let request: SaveEditProposalV1RequestPayload = {
      CKYCId: this.healthFormData.kycFormData.CKYCID,
      EKYCid: this.healthFormData.kycFormData.EKYCID,
      ILKYCReferenceNumber: this.healthFormData.kycFormData.ilKycReferenceNumber,
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      IpAddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      AppointeeDOB: this.showAppointee ? this.utilityService.formatDateWithSpace(this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeDOB)) : '',
      AppointeeName: this.showAppointee ? this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeFullName) : '',
      AppointeeRelationShip: this.showAppointee ? this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeRelationship) : '',
      AppointeeRelationShipID: this.showAppointee ? this.getSelectedDropDownId(kycControls.appointeeRelationship, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeRelationship)) : 0,
      AppointeeTitleID: this.showAppointee ? this.getSelectedDropDownId(kycControls.appointeeTitle, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeTitle)) : 0,
      CustomerID: saveEditResponse.CustomerID,
      HUF: "Y", //by default
      PayMode: '',
      InstallmentApplicable: this.healthFormData.quoteFormData.InstallmentApplicable,
      InstallmentFrequency: this.healthFormData.quoteFormData.InstallmentFrequency,
      isAppointeeRequired: this.showAppointee,
      IsSoftCopyDiscountApplicable: this.healthFormData.quoteFormData.softCopyDiscount == 'Yes' ? true : false,
      Members: this.constructMemberDetails(), // need to write logic
      NomineeDOB: this.utilityService.formatDateWithSpace(this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeDOB)),
      NomineeName: this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeFullName),
      // NomineeTitleID: this.getSelectedDropDownId(kycControls.nomineeTitle, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeTitle)),
      NomineeRelationShipText: this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeRelationship),
      NomineeRelationShipID: this.getSelectedDropDownId(kycControls.nomineeRelationship, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeRelationship)),
      PfCustomerId: saveEditResponse.PFCustomerID,
      PfQuoteId: this.healthFormData.premiumFormData.PfQuoteId,
      Pincode: !permanentAddressSame ? (this.correspondencePincodeID != 0 ? this.correspondencePincodeID.toString() : '') : (this.permanentPincodeId != 0 ? this.permanentPincodeId.toString() : ''),
      ProductType: this.healthFormData.quoteFormData.healthProposalProductType, // in pana we don't set this
      QuoteId: this.healthFormData.premiumFormData.QuoteId,
      SubProductCode: this.healthFormData.premiumFormData.SubProductCode,
      SubLimit: null,// By default no point
      SubProductType: this.healthFormData.quoteFormData.subProductType, // By default we are sending
      SaveProposal: this.buttonType == 'save' ? true : false,
      PreHospitalization: this.healthFormData.quoteFormData.preHospitalDuration,
      PostHospitalization: this.healthFormData.quoteFormData.postHospitalDuration,
      PEDWaitingPeriod: this.healthFormData.quoteFormData.pedWaitingPeriod,
      SpecificConditionWaiting: this.healthFormData.quoteFormData.conditionWaitingPeriod,
      ProposalAnnualIncome: this.healthFormData.quoteFormData.applicantAnnualIncome, // check this with existing
      VoluntaryCopaymentPercentage: this.healthFormData.quoteFormData.copaymentPercentTaken == 'No' ? null : this.healthFormData.quoteFormData.copaymentPercentTaken.replace('%', ''),
      ProposalAnnualIncomeRange: this.healthFormData.quoteFormData.applicantAnnualIncome,
      ZoneUpgrade: false,
      RevisedZone: this.healthFormData.quoteFormData.zone,
      Zone: this.healthFormData.quoteFormData.zone,
      BancaToken: this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : '',
      SaveQuoteId: this.quoteDetails ? this.quoteDetails.Data.GenericData.QuoteID : 0
    }

    // this.healthFormData.copaymentPercentTaken
    // If user has selected portability
    if (this.healthFormData.quoteFormData.productType == "Portability") {
      let portabilityReason = this.healthFormData.portabilityFormData.portabilityReason == PortabilityReason.ClaimNotHandled ? PortabilityReason.lowerClaimNotHandled : this.healthFormData.portabilityFormData.portabilityReason;
      request.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? 'F' : 'I';
      request.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      request.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: portabilityReason,
      }
    }
    // set product key for healthbooster
    if (this.healthFormData.quoteFormData.subProductType == 20) {
      request['Product24'] = this.healthFormData.kycFormData.otherDetails['taxExemptionHB'];
    }
    this.healthFormData.kycFormData.CustomerID = saveEditResponse.CustomerID;
    this.healthFormData.kycFormData.PFCustomerID = saveEditResponse.PFCustomerID;
    this.healthFormData.showAppointee = this.showAppointee;
    this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID = this.getSelectedDropDownId(kycControls.appointeeRelationship, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeRelationship));
    this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID = this.getSelectedDropDownId(kycControls.appointeeTitle, this.apointeeDetailsControls, this.getFormGroupValue(kycControls.appointeeDetails, kycControls.appointeeTitle));
    this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID = this.getSelectedDropDownId(kycControls.nomineeTitle, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeTitle));
    this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID = this.getSelectedDropDownId(kycControls.nomineeRelationship, this.nomineeDetailsControls, this.getFormGroupValue(kycControls.nomineeDetails, kycControls.nomineeRelationship));

    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    return request;
  }

  constructMemberDetails(): Member[] {
    let membersData = [];
    let adultCount = 0;
    let childCount = 0;

    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let selectedAddons = this.healthFormData.quoteFormData.totalMemberData[0]['AddonCoverDetails'];
      this.healthFormData.quoteFormData.totalMemberData.forEach((members, index) => {
        let memberType: string = members['MemberType'];
        let insuredDetailsObject = this.healthFormData.insuredFormData.insuredMemberDetails[index];
        let memberTypeCount: string = '';
        let occupation = '';

        if (memberType == healthMembers.Adult) {
          adultCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + adultCount;
          // If personal accident is selected then add occupation details
          if (this.healthFormData.quoteFormData.addons['AddOn8']) {
            occupation = adultCount == 1 ? this.healthFormData.quoteFormData.occupationForAdult1 : this.healthFormData.quoteFormData.occupationForAdult2;
          }
        }

        else if (memberType == healthMembers.Child) {
          adultCount = 0;
          childCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + childCount;
        }
        let feetHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[0];
        let inchesHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[1];
        // Below condition applicable for hap,gs and hep
        let propDOJ = "";
        if (this.healthFormData.quoteFormData.subProductType == 40 || this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) || this.healthFormData.quoteFormData.subProductType == 33 || this.healthFormData.quoteFormData.subProductType == 32 || this.healthFormData.quoteFormData.subProductType == 31 || this.healthFormData.quoteFormData.subProductType == 30) {
          propDOJ = insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? this.utilityService.formatDate(insuredDetailsObject['insuredSince' + memberTypeCount]) : this.utilityService.formatDate(new Date());
        }
        else {
          propDOJ = "";
        }
        membersData.push({
          "AddOnCoverDetails": selectedAddons,
          "AddOnName": "", //by default we are sending it
          "AddOnName6": "", //by default we are sending it
          "Ailments": this.getAilments(memberTypeCount),
          "DOB": members['DOB'],
          "Height": `${(feetHeight) + '.' + (inchesHeight)}`,
          "InsuranceCompany": "", // By default
          "PolicyDuration": "",
          "isExisting": this.healthFormData.portabilityFormData.previousPolicyClaims == 'Yes' ? 'true' : 'false', // check with existing
          "MemberType": memberType,
          "Name": insuredDetailsObject['fullName' + memberTypeCount],
          "VaccinationDate": memberType == healthMembers.Adult ? members['VaccinationDate'] : undefined,
          "OtherDisease": "", // Not needed
          "RelationshipID": memberType == healthMembers.Adult ? (this.healthFormData.quoteFormData.adultRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID) : (this.healthFormData.quoteFormData.childRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID),// call api service
          "RelationshipName": members['RelationshipwithApplicant'],
          "SumInsured": this.healthFormData.quoteFormData.SumInsured,// by default
          "TitleID": this.titles.filter(data => data.label == (insuredDetailsObject['title' + memberTypeCount]))[0].value,
          "TypeofPolicy": "",
          "Weight": insuredDetailsObject['weight' + memberTypeCount],
          'PortabilitySI': this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Individual ? (insuredDetailsObject['sumInsured' + memberTypeCount]) == '' ? '1' : (insuredDetailsObject['sumInsured' + memberTypeCount]) : '1',
          "PortabilityDOJ": propDOJ,
          "PortabilityWaiver": insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? insuredDetailsObject['waiverPeriod' + memberTypeCount] : '0',
        });
        // additional fiedls for health booster
        if (this.healthFormData.quoteFormData.subProductType == 20) {
          membersData[index]["ExistingHealthPolicy"] = {};
          membersData[index]["ExistingHealthPolicy"]['IsExisting'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`existingHIP${memberTypeCount}`] == "Yes" ? "true" : "false";
          membersData[index]["ExistingHealthPolicy"]['InsuranceCompany'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`insuranceCompanyNameHB${memberTypeCount}`];
          membersData[index]["ExistingHealthPolicy"]['PolicyDuration'] = Number(this.healthFormData.insuredFormData.insuredMemberDetails[index][`policyPeriodHB${memberTypeCount}`]);
          membersData[index]["ExistingHealthPolicy"]['SumInsured'] = Number(this.healthFormData.insuredFormData.insuredMemberDetails[index][`sumInsuredHB${memberTypeCount}`]);
          membersData[index]["ExistingHealthPolicy"]['TypeOfPolicy'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`typeOfPolicyHB${memberTypeCount}`];
          membersData[index]["ExistingHealthPolicy"]['Member'] = memberTypeCount
        }
        this.SIinsured = (insuredDetailsObject['sumInsured' + memberTypeCount]);
      })
    }
    return membersData;
  }

  getAilments(memberCount: string) {
    let ailmentList: HealthMemberAilments[] = [];
    if (this.healthFormData.insuredFormData.preExistingDisease.length > 0 && this.healthFormData.insuredFormData.existingDisease.length > 0) {
      this.healthFormData.insuredFormData.existingDisease.forEach(disease => {
        this.healthFormData.insuredFormData.preExistingDisease.forEach(preExistingControls => {
          if (preExistingControls[disease.Value + memberCount]) {
            ailmentList.push({
              "AilmentID": 0,  // by default
              "Month": "1", // by default
              "Year": "1", // by default
              "AilmentName": disease.Name,
              "PEDCode": (disease.Value).toString(),
              "PEDRemarks": (memberCount.includes(healthMembers.Adult) && disease.Value == insuredDiseaseID.Fourty || disease.Value == insuredDiseaseID.FourtyOne) ? this.findRemark(preExistingControls, memberCount, disease.Value) : ''
            });
          }
        })
      })
    }
    return ailmentList;
  }

  findRemark(preExistingControls: {}, memberCount: string, id: number): string {
    let remarks = '';
    if (id == insuredDiseaseID.Fourty || id == insuredDiseaseID.FourtyOne) {
      for (let keys in preExistingControls) {
        if (keys == `${remarkList.Remark1 + memberCount}` || keys == `${remarkList.Remark2 + memberCount}`) {
          remarks = (id == insuredDiseaseID.Fourty) ? preExistingControls[remarkList.Remark1 + memberCount] : preExistingControls[remarkList.Remark2 + memberCount]
          return remarks;
        }
      }
    }
    return remarks;
  }

  onRefresh(status) {
    if (status) {
      if (this.routeData == routeEnums.CritiShield || this.routeData == routeEnums.Familyshield) {
        this.fetchKycDetailsFromZeroTatService();
      }
      else {
        this.fetchKycDetailsFromService();
      }
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  validationOfInputField(event: Event, controlName: string, alphaCheck: boolean, NumberCheck: boolean) {
    this.utilityService.dynamicControlsAlphaOnly(event, alphaCheck);
    this.utilityService.dynamicControlsNumberOnly(event, NumberCheck);
    let addSpaceControlName = controlName !== kycControls.customerName && controlName !== kycControls.nomineeFullName && controlName !== kycControls.appointeeFullName && controlName !== kycControls.correspondenceAddress1 && controlName !== kycControls.correspondenceAddress2 && controlName !== kycControls.correspondenceLandmark && controlName !== kycControls.permanentAddress1 && controlName !== kycControls.permanentAddress2 && controlName !== kycControls.permanentLandmark && controlName !== kycControls.applicantFullName;
    if (addSpaceControlName) {
      this.utilityService.restrictSpace(event);
    }
  }
  fetchKycDetailsFromZeroTatService() {
    this.subscription.push(this.requestZeroTatDataFromMultipleSources().subscribe({
      next: (responseList: ZeroTatRelationships) => {
        this.multipleServiceError = false;
        this.handleMultipleZeroTatResponse(responseList);
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
        this.initialServiceLoaded = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  requestZeroTatDataFromMultipleSources(): Observable<ZeroTatRelationships> {
    // CRITI SHIELD: Note : change endpoint for critishield
    return this.familyShieldService.getZeroTatRelationships();
  }

  handleMultipleZeroTatResponse(responseList: ZeroTatRelationships) {
    if (responseList.StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
      this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
    }
    else {
      this.multipleServiceError = false;
      this.manageZeroTatHealthRelationShipsData(responseList)
    }
  }

  manageZeroTatHealthMasterData(responseData: CSMaster) {
    if (responseData.StatusCode == Constants.statusCode_success) {
      this.zeroTatMasterDetails = responseData;
      this.showEKYC = this.zeroTatMasterDetails.Data.IsKycFlag ?? true;
    }
    else {
      this.initialServiceLoaded = false;
    }
  }

  // Handles Member Relationships
  manageZeroTatHealthRelationShipsData(response: ZeroTatRelationships) {
    if (response.StatusCode == Constants.statusCode_success) {
      this.showEKYC = this.agentDetails.IsKycFlag;
      if (response.Data.NomineeRelationShip.length > 0) {
        if (this.routeData == routeEnums.CritiShield) {
          let relationData = response.Data.NomineeRelationShip.length > 0 ? response.Data.NomineeRelationShip : [];
          let filterdata = relationData.filter(data => data.Id == 1 || data.Id == 4 || data.Id == 8 || data.Id == 12 || data.Id == 13 || data.Id == 15 || data.Id == 16)
          this.apointeeRelationshipData = this.utilityService.sortDetails(filterdata, 'RelationshipName');
          this.nomineeRelationshipData = this.utilityService.sortDetails(filterdata, 'RelationshipName');
          this.initialServiceLoaded = true;
        }
        else {
          let relationData = response.Data.NomineeRelationShip.length > 0 ? response.Data.NomineeRelationShip : [];
          this.apointeeRelationshipData = this.utilityService.sortDetails(relationData, 'RelationshipName');
          this.nomineeRelationshipData = this.utilityService.sortDetails(relationData, 'RelationshipName');
          this.initialServiceLoaded = true;
        }

      }
      else {
        this.multipleServiceError = true;
        this.initialServiceLoaded = false;
      }
    }
    else {
      this.initialServiceLoaded = false;
    }
    this.addServiceDataInForm();
  }

  ngOnDestroy() {
    $('#externalUrlModal').modal('hide');
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }

}

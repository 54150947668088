import { Component, OnInit, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatSelect } from '@angular/material/select';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { GetRenewalReminderSearchResponsePayload, GetRenewalSendMailResponsePayload } from 'src/app/shared/interface/renewal';
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { DownloadService } from 'src/app/shared/services/download.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RenewalDataStorageService } from '../renewal.service';

@Component({
  selector: 'app-renewals',
  templateUrl: './renewals.component.html',
  styleUrls: ['./renewals.component.scss']
})
export class RenewalsComponent implements OnInit {
  private interval: any;
  healthModal: HAPModal = new HAPModal();
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  productTypeList: { productName: string; productCode: number }[];
  toggleValue: 'table' | 'card' = 'card';
  policyFilter: string[] = [];
  policyFilterList = ['Open', 'Closed'];
  inputValue: string = '';
  showCancel: boolean = false;
  searchPlaceHolder: string;
  reportList: any = [];
  selectList = [];
  i: number = 0;
  phText: string[] = ['Search for Policy Number',
    'Search for Customer Name'];
  filteredData = [];
  renewalDueForm: FormGroup;
  subscription: Subscription[] = [];
  finalDetails: any = [];
  policyDetails: any = [];
  productList = [];
  renewDueProduct: string;
  previousYears: number[] = [];
  errorPopupData: PopupModal;
  pdfResponse: any;

  tableBtnInfo = [{
    label: 'Renew Now',
    imgPath: '../../../../../../assets/images/Renewal/TableRenewNow.svg',
    tooltip: 'Renew Now',
    expression: "data.status == 'Open'"
  },
  //  {
  //   label: 'Renewal Score',
  //   imgPath: '../../../../../../assets/images/Renewal/TableRenewalScore.svg',
  //   tooltip: 'Renewal Score'
  // }, 
  {
    label: 'View',
    imgPath: '../../../../../../assets/images/Renewal/TableView.svg',
    tooltip: 'View'
  }, {
    label: 'Email',
    imgPath: '../../../../../../assets/images/Renewal/TableEmail.svg',
    tooltip: 'Email'
  }]

  cardBtnInfo = [{
    label: 'Renew Now',
    imgPath: '../../../../../../assets/images/Renewal/RenewNow.svg',
    expression: "data.status == 'Open'"
  },
  //  {
  //   label: 'Renewal Score',
  //   imgPath: '../../../../../../assets/images/Renewal/RenewalScore.svg',
  // }, 
  {
    label: 'View',
    imgPath: '../../../../../../assets/images/Renewal/View.svg',
  }, {
    label: 'Email',
    imgPath: '../../../../../../assets/images/Renewal/Email.svg',
  }]

  displayCol = [
    {
      columnDef: 'customer_name',
      header: 'Customer Name',
      cell: (element: any) => `${element.customer_name}`,
    },
    {
      columnDef: 'policy_number',
      header: 'Policy Number',
      cell: (element: any) => `${element.policy_number}`,
    },
    {
      columnDef: 'policy_end_date',
      header: 'Policy End Date',
      cell: (element: any) => `${element.policy_end_date}`,
    },
    {
      columnDef: 'status',
      header: 'Status',
      cell: (element: any) => `${element.status}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    }
  ]

  constructor(
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private agentV1Service: AgentV1Service,
    private dialog: MatDialog,
    private renewalService: RenewalService,
    private popupService: PopupService,
    private downloadService: DownloadService,
    private storageService: StorageService,
    private renewalStorageService: RenewalDataStorageService) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.getProductList();
    this.createForm();
    this.searchDetails();
    this.productListListner('2');
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  getProductList() {
    this.productList = this.agentV1Service.getAllProducts('renewalDue');
  }

  productListListner(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
        if (i.productCode != 4) {
          this.renewalDueForm.controls['productName'].setValue(this.selectList[0].productCode);
        } else {
          this.renewalDueForm.controls['productName'].setValue(4);
        }
      }
    }
  }

  createForm() {
    this.renewalDueForm = this.formBuilder.group({
      productType: [2, [Validators.required]],
      productName: [42, [Validators.required]],
      month: [this.getTodayMonth()],
      year: [this.getYear()],
    })
    this.patchBanner();
  }

  getTodayMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return currentMonth;
  }

  getYear() {
    const currentYear = new Date().getFullYear();
    this.previousYears = [];
    for (let i = 0; i < 5; i++) {
      this.previousYears.push(currentYear - i);
    }
    return currentYear;
  }

  patchBanner() {
    this.renewDueProduct = this.getProductNameFromCode(this.renewalDueForm.value.productType);
  }

  getProductNameFromCode(x: number) {
    if (x == 2) {
      return 'Health'
    } else if (x == 3) {
      return 'Travel'
    } else if (x == 4) {
      return 'Home'
    }
    return '';
  }

  constructRenewalDuePayload() {
    const data = {
      "StartDate": '',
      "EndDate": '',
      "PolicySubType": this.renewalDueForm.controls['productName'].value,
      "Year": this.renewalDueForm.controls['year'].value,
      "Month": this.renewalDueForm.controls['month'].value,
    }
    return data;
  }

  // searchDetails() {
  //   this.renewalDetails = [];
  //   let data = this.constructRenewalPayload();
  //   this.subscription.push(this.renewalService.getRenewalSearchDetails(data).subscribe({
  //     next: (response: GetRenewalReminderSearchResponsePayload) => {
  //       if (response.StatusCode === Constants.statusCode_success) {
  //         this.renewalDetails = response.Data;
  //       }
  //     },
  //     error: error => {
  //     }
  //   }))
  // }

  searchDetails() {
    this.clearInputField();
    const body = this.constructRenewalDuePayload();

    this.subscription.push(this.renewalService.getRenewalSearchDetails(body).subscribe({
      next: (response: GetRenewalReminderSearchResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.policyDetails = response.Data;
          this.policyDetails.sort((a, b) => {
            if (a.status === 'Open' && b.status === 'Closed - Converted by PF') {
              return -1;
            }
            else if (a.status === 'Closed - Converted by PF' && b.status === 'Open') {
              return 1;
            }
            else {
              return 0;
            }
          });
          this.filteredData = this.policyDetails;
          this.patchBanner();
        } else {
          this.policyDetails = [];
          this.filteredData = [];
        }
      }
    }))
  }

  edit(data) {
    let SubProductType = "";
    const policyNumber = data.policy_number;
    let SubProductInfo = this.renewalStorageService.getSubProductType(policyNumber);
    const eldestDateOfBirth = this.utilityService.formateDateWithMonth(data.eldest_insured_dob)
    if (SubProductInfo.ProductType == 'indimnity') {
      this.renewalStorageService.indenmitySearch(policyNumber, SubProductInfo.SubProductType, eldestDateOfBirth);
    } else if (SubProductInfo.ProductType == 'zerotat') {
      this.renewalStorageService.zeroTatSearch(policyNumber, eldestDateOfBirth);
    } else if ((SubProductInfo.ProductType == 'home')) {
      const policyEndDate = data.policy_end_date;
      this.renewalStorageService.homeSearch(policyNumber, policyEndDate);
    } else {
      return;
    }
  }

  getRenewalScore(renewalDetails) {
    const UserID: string = String(this.storageService.getAESDecryptedData(userEnums.AgentDetails).AgentID);
    this.renewalService.salesGenieComponent({ UserID: UserID, PolicyID: renewalDetails.policy_number }).subscribe(res => {
      if (res.StatusCode === Constants.statusCode_success) {
        let popupData: PopupModal = {
          popupType: popupType.salesGenie,
          imgName: '',
          header: 'Renewal Score',
          description: 'Renewal Score',
          data: {
            token: res.Data,
            policyNumber: renewalDetails.policy_number
          },
          buttonLabel: ''
        }
        this.popupService.openSalesGeniePopup(popupData);
      }
    });
  }

  constructRNRenewalNotificationPayloadHome(reqData) {
    let rnData = {
      "Policy_Number": reqData.policy_number,
      "Policy_Type": "Complete Home Protect",
      "Product": "Home",
      "Policy_Enddate": this.utilityService.formateDateWithMonth(reqData.policy_end_date),
      "SubProduct": "Normal"
    }
    return rnData;
  }

  constructRNRenewalNotificationPayloadHealth(reqData) {
    let rnData = {
      "Policy_Number": reqData.policy_number,
      "Policy_Type": "Health AdvantEdge Plus",
      "Product": "Health",
      "Policy_Enddate": this.utilityService.formateDateWithMonth(reqData.policy_end_date),
      "SubProduct": reqData.product_name
    }
    return rnData;
  }

  webDownload(reqData) {
    let data;
    if (this.renewalDueForm.value.productType == 2){
      data = this.constructRNRenewalNotificationPayloadHealth(reqData);
    } else if (this.renewalDueForm.value.productType == 4){
      data = this.constructRNRenewalNotificationPayloadHome(reqData);
    }
    this.subscription.push(this.renewalService.getRNRenewalNotification(data).subscribe({
      next: (response: any) => {
        this.pdfResponse = response.Data;
        if (this.pdfResponse != null && this.pdfResponse != undefined) {
          this.downloadService.webDownload(this.pdfResponse, reqData.policy_number);
        }
      },
      error: error => {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  constructSendMailPayloadHealth(data) {
    let reqData = {
      "Policy_Number": data.policy_number,
      "Policy_Type": "Health AdvantEdge Plus",
      "Product": "Health",
      "Policy_Enddate": this.utilityService.formateDateWithMonth(data.policy_end_date),
      "SubProduct": data.product_name,
      "MailId": data.email_id
    }
    return reqData;
  }

  constructSendMailPayloadHome(data) {
    let reqData = {
      "Policy_Number": data.policy_number,
      "Policy_Type": "Complete Home Protect",
      "Product": "Home",
      "Policy_Enddate": this.utilityService.formateDateWithMonth(data.policy_end_date),
      "SubProduct": "Normal",
      "MailId": data.email_id
    }
    return reqData;
  }

  sendMail(reqData) {
    let data;
    if (this.renewalDueForm.value.productType == 2){
      data = this.constructSendMailPayloadHealth(reqData);
      console.log(reqData);
    } else if (this.renewalDueForm.value.productType == 4){
      data = this.constructSendMailPayloadHome(reqData);
    }
    this.subscription.push(this.renewalService.renewalSendMail(data).subscribe({
      next: (response: GetRenewalSendMailResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let popupData: PopupModal = {
            popupType: popupType.generic,
            imgName: popupImgPath.successGif,
            header: popupHeader.success,
            description: popupDescriptionMsg.emailSuccess + 'Email Id: ' + data.MailId,
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.close
          }
          this.popupService.openGenericPopup(popupData);
        }
      }
    }))
  }

  receiveDataAndCall(event) {
    if (event.identifier == 'Renew Now') {
      this.edit(event.data);
    } else if (event.identifier == 'Renewal Score') {
      // this.getRenewalScore(event.data);
    } else if (event.identifier == 'View') {
      this.webDownload(event.data);
    } else if (event.identifier == 'Email') {
      console.log("EVENT:");
      console.log(event.data);      
      this.sendMail(event.data);
    }
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }

    const x = this.productList.filter(item => item.productCode == this.renewalDueForm.value.productType);
    const y = x[0].productList;
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '50%',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'myDropdown',
            label: 'Product Type',
            list: this.productList,
            value: this.renewalDueForm.value.productType
          },
          {
            type: 'myDependentDropdown',
            label: 'Product Name',
            list: y,
            value: this.renewalDueForm.value.productName
          },
          {
            type: 'renewalDueDate',
            label: 'Select Month and Year',
            month: this.getTodayMonth(),
            monthsList: this.healthModal.months,
            count: this.policyDetails.length,
            year: this.getYear(),
            yearsList: this.previousYears
          }]
        }
      }
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'myDropdown') {
          this.renewalDueForm.controls['productType'].setValue(i.value);
        }
        else if (i.type == 'myDependentDropdown' && i.value) {
          this.renewalDueForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'renewalDueDate') {
          this.renewalDueForm.controls['month'].setValue(i.month);
          this.renewalDueForm.controls['year'].setValue(i.year);
        }
      }
      this.searchDetails();
    }
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '38%',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [
          {
            label: 'Status Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
      },
    }
    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.policyFilter = data[0].filter;
    }
    this.applyFilter();
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  filterLogic() {
    if (!(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.policyDetails.filter(item => this.policyFilter.includes(this.utilityService.truncateAfterWhiteSpace(item['status']).toLowerCase()))
    } else {
      this.filteredData = this.policyDetails;
    }
  }

  queryData() {
    const query = this.inputValue.trim();
    if (!query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails;
    }
    else if (!query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filterLogic();
    }
    else if (query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails.filter(item => {
        const customerName = item['customer_name'] ? item['customer_name'].toString().toLowerCase() : '';
        const policyNumber = item['policy_number'] ? item['policy_number'].toString().toLowerCase() : '';
        const policyEndDate = item['policy_end_date'] ? item['policy_end_date'].toString().toLowerCase() : '';
        const status = item['status'] ? item['status'].toString().toLowerCase() : '';
        return policyNumber.includes(query.toLowerCase()) || customerName.includes(query.toLowerCase()) ||
          policyEndDate.includes(query.toLowerCase()) || status.includes(query.toLowerCase());
      });
    }
    else if (query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.filteredData.filter(item => {
        const customerName = item['customer_name'] ? item['customer_name'].toString().toLowerCase() : '';
        const policyNumber = item['policy_number'] ? item['policy_number'].toString().toLowerCase() : '';
        const policyEndDate = item['policy_end_date'] ? item['policy_end_date'].toString().toLowerCase() : '';
        const status = item['status'] ? item['status'].toString().toLowerCase() : '';
        return policyNumber.includes(query.toLowerCase()) || customerName.includes(query.toLowerCase()) ||
          policyEndDate.includes(query.toLowerCase()) || status.includes(query.toLowerCase());
      });
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}

<form [formGroup]="SummaryForm">
    <mat-card class="innerbox borderinnerbox plandetailPanel marginbox">
        <h3>Plan Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f1')" class="edit">
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv planrowData">
                <div class="col-lg-3 col-md-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Product Name</mat-label>
                    <p class="titlecase">
                        <i class="health"></i>
                        {{this.healthFormData.quoteFormData.productName.toLowerCase()}}
                    </p>
                </div>
                <div class="col-md-2 col-sm-2 form-group">
                    <mat-label class="mtlabel" for="myInput">Policy Type</mat-label>
                    <p *ngIf="healthFormData.quoteFormData.subProductType == 34; else policyType">
                        {{ healthFormData.quoteFormData.subProductCode == '1132' ||
                        healthFormData.quoteFormData.subProductCode == '1498' ? 'Classic' : 'Premium' }}
                    </p>
                    <ng-template #policyType>
                        <p>{{this.healthFormData.portabilityDataSet == true ? 'Portability' : 'New'}}</p>
                    </ng-template>
                </div>
                <div class="col-md-2 col-sm-2 form-group">
                    <mat-label class="mtlabel" for="myInput">Policy Tenure</mat-label>
                    <p>{{this.healthFormData.quoteFormData.policyTenure }}</p>
                </div>
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.copaymentPercentTaken != null && this.healthFormData.quoteFormData.copaymentPercentTaken?.length > 0">
                    <mat-label class="mtlabel" for="myInput">Co-payment</mat-label>
                    <p>{{this.healthFormData.quoteFormData.copaymentPercentTaken}}</p>
                </div>
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.voluntaryDeductible?.length > 0 && (this.healthFormData.quoteFormData.subProductType != 42 && this.healthFormData.quoteFormData.subProductType != 20)">
                    <mat-label class="mtlabel" for="myInput">Voluntary Deductible</mat-label>
                    <p>{{'₹' +
                        utilitySerice.convertStringtoCurrency(this.healthFormData.quoteFormData.voluntaryDeductible)}}
                    </p>
                </div>
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.subProductType == 42 || this.healthFormData.quoteFormData.subProductType==45 || this.healthFormData.quoteFormData.subProductType == 4193">
                    <mat-label class="mtlabel" for="myInput">Cibil Discount</mat-label>

                    <p> {{this.healthFormData.quoteFormData.isCibilApplicable =='Yes' ?
                        this.healthFormData.quoteFormData.cibilScoreDiscount + ' %':
                        this.healthFormData.quoteFormData.isCibilApplicable}}</p>
                </div>
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.subProductType==45">
                    <mat-label class="mtlabel" for="myInput">NRI Discount</mat-label>

                    <p> {{this.healthFormData.quoteFormData.isNRIApplicable}}</p>
                </div>
                <!-- Room Modifier if applicable -->
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.subProductType==45 && this.healthFormData.quoteFormData.RoomModifier != ''">
                    <mat-label class="mtlabel" for="myInput">Room Modifier</mat-label>
                    <p>{{this.healthFormData.quoteFormData.RoomModifier}}</p>
                </div>
                <div class="col-md-2 col-sm-2 form-group"
                    *ngIf="this.healthFormData.quoteFormData.subProductType == 20">
                    <mat-label class="mtlabel" for="myInput">Voluntary Deductible</mat-label>
                    <p>{{'₹' +
                        utilitySerice.convertStringtoCurrency(this.healthFormData.quoteFormData.voluntaryDeductible)}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-2 col-sm-2 form-group" *ngFor="let memberData of memberDetails">
                    <mat-label class="mtlabel" for="myInput"
                        *ngIf="memberData.memberType !='Child' && memberData.pneumoccalDOB != ''">Pneumococcal
                        vaccination date {{memberData.memberTypeLabel}}</mat-label>
                    <p *ngIf="memberData.memberType !='Child' && memberData.pneumoccalDOB != ''">
                        {{memberData.pneumoccalDOB}}</p>
                </div>
            </div>

        </div>
        <mat-divider></mat-divider>
    </mat-card>

    <mat-card class="innerbox borderinnerbox reverinnerbox">
        <h3 class="pt-3">Insured Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f3')" class="edit">
            </span>
        </h3>
        <!-- Member Details Start -->
        <div class="fieldsdiv ">
            <div class="row  customDiv adultSection" *ngFor="let memberData of memberDetails">
                <div class="col-sm-12">
                    <span class="subheading pb-5">{{memberData.memberTypeLabel}}</span>
                </div>
                <div class="col-sm-3 form-group width100 order-1 order-sm-1">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{memberData.fullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-3 order-sm-3 ">
                    <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                    <p class="titlecase">{{memberData.Relationship.toLowerCase()}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-2 order-sm-2">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <p>{{this.utilitySerice.formattedDate(memberData.dob)}}</p>
                </div>
                <div class="col-lg-1 col-sm-2 form-group width50 order-4 order-sm-4">
                    <mat-label class="mtlabel" for="myInput">Height</mat-label>
                    <p>{{memberData.height + 'in'}}</p>
                </div>
                <div class="col-lg-1 col-sm-2 form-group width50 order-5 order-sm-5 ">
                    <mat-label class="mtlabel" for="myInput">Weight</mat-label>
                    <p>{{memberData.weight + 'Kg'}}</p>
                </div>
                <div class="col-lg-3 form-group width50 order-6 order-sm-6 ">
                    <mat-label class="mtlabel" for="myInput">Pre-existing disease & habit</mat-label>
                    <p class="titlecase">{{memberData.preExistingDisease.toLowerCase()}}</p>
                </div>
                <div class="col-lg-3 form-group width50 MobileField order-7 order-sm-7"
                    *ngIf="memberData.memberType !='Child' && memberData.pneumoccalDOB != ''">
                    <mat-label class="mtlabel" for="myInput">Pneumococcal vaccination date</mat-label>
                    <p>{{memberData.pneumoccalDOB}}</p>
                </div>

            </div>
        </div>
        <mat-divider></mat-divider>
        <!-- Member Details End -->
    </mat-card>

    <mat-card class="innerbox borderinnerbox">
        <!-- Applicant Details -->
        <h3 class="pt-3">Applicant Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f4')" class="edit">
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.applicantDetails.applicantFullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">Mobile No.</mat-label>
                    <p>{{this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber}}
                    </p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Email ID</mat-label>
                    <p>{{this.healthFormData.kycFormData.permanentAddress.permanentEmail.toLowerCase() }}</p>
                </div>
                <div class="col-sm-6 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Address</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.permanentAddress.permanentAddress1.toLowerCase() }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentAddress2.toLowerCase() }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentLandmark.toLowerCase() }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentPincode.toLowerCase() }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentState.toLowerCase() }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentCity.toLowerCase() }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Nominee Details -->
        <h3 class="pt-3">Nominee Details</h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.nomineeDetails.nomineeFullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <p>{{this.utilitySerice.formattedDate(nomineeDOB) }}
                    </p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship.toLowerCase() }}</p>
                </div>

            </div>
        </div>
        <!-- Apointee Details -->
        <h3 class="pt-3" *ngIf="healthFormData.showAppointee">Apointee Details</h3>
        <div class="fieldsdiv" *ngIf="healthFormData.showAppointee">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.appointeeDetails.appointeeFullName.toLowerCase() }}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <p>{{this.utilitySerice.formattedDate(appointeeDOB) }}
                    </p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship.toLowerCase() }}</p>
                </div>

            </div>
        </div>

        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox"
        *ngIf="genericBefitShow && this.SummaryForm.controls['homePlan'].value == 'false' && this.healthFormData.quoteFormData.subProductType != 42">
        <h3>Want to opt for Be-fit rider for your proposal?</h3>

        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-1 form-group">
                    <mat-button-toggle-group name="fontStyle" formControlName="befitPlan" aria-label="Font Style"
                        class="custom-toggle-group" (change)="onCheckBefit($event.value)">
                        <mat-button-toggle class="custom_togglebtn" value="true">Yes</mat-button-toggle>
                        <mat-button-toggle class="custom_togglebtn" value="false">No</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-sm-12 form-group customCheckslist"
                    *ngIf="this.SummaryForm.controls['befitPlan'].value == 'true'">
                    <mat-checkbox class="cstmCheckbox" formControlName="hmpPlan" (click)="checkHMPCondition()">I want to
                        opt for HMP
                        program</mat-checkbox>
                </div>
                <div class="col-sm-6 form-group" *ngIf="this.SummaryForm.controls['befitPlan'].value == 'true'">
                    <div class="summaryplanTable">
                        <table class="summaryPlan">
                            <caption class="hidden-caption">Details</caption>
                            <td class="hidden-caption">Befit Covers</td>
                            <thead>
                                <tr>
                                    <th>Choose plan</th>
                                    <th class="pl-2">Premium</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <mat-select required placeholder="Select Befit Plan"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            formControlName="BefitSelectionPlan"
                                            (selectionChange)="onbefitPanSelection($event.value)">
                                            <ng-container *ngFor="let item of bifitCovers; let last = last">
                                                <mat-option value={{item.CoverName}}>
                                                    {{item.CoverName}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                    </td>
                                    <td>{{BefitSelectedPremium ? '₹ ' +
                                        utilitySerice.convertStringtoCurrency(BefitSelectedPremium) : ' '}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-12 form-group" *ngIf="this.SummaryForm.controls['befitPlan'].value == 'true'">
                    <mat-label for="" class="singlelabel mediTest">Top benefits include:</mat-label>
                    <div class="benefitsTable mb-2">
                        <table class="benefit" *ngFor="let data of BenefitDiscription">
                            <caption class="hidden-caption">Details</caption>
                            <th class="hidden-caption">Befit Plan</th>
                            <td class="hidden-caption">Befit Disciption</td>
                            <tr>
                                <td>
                                    <img class="tickIcon" src="../../../../assets/images/miniTick.svg" alt="">
                                </td>
                                <td>{{data.BenefitName}} -
                                    <span>{{data.BenefitValue}} {{data.BenefitValueType}}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox">
        <h3>Medical Test Guidelines
        </h3>
        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-10 form-group">
                    <label class="mediTest" for="">{{this.healthFormData.quoteFormData.productName}}</label>
                    <table>
                        <caption></caption>
                        <th></th>
                        <td></td>
                        <tr>
                            <td class="tickAlign pr-2" *ngIf="isUnderwritterApproval()"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td *ngIf="isUnderwritterApproval()">Warning! Policy will be insured on the
                                basis of underwriter approval.
                            </td>
                        </tr>
                        <tr>
                            <td class="tickAlign pr-2" *ngIf="isPedSelected()"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td class="tickAlign pr-2" *ngIf="isPedSelected()">Warning! Basis the information
                                shared, you may receive a call from our underwriters. The policy will get issued basis
                                underwriter's approval and additional premium (if any).
                            </td>
                        </tr>
                        <tr class="tickAlign pr-2">
                            <td class="tickAlign pr-2"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td>Medical test at ICICI Lombard GIC Ltd. designated diagnostic center.</td>
                        </tr>
                        <tr class="tickAlign pr-2" *ngIf="!this.utilitySerice.isEmptyOrNull(this.medicaltest)">
                            <td class="tickAlign pr-2"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td>{{this.medicaltest}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox"
        *ngIf="this.healthFormData.quoteFormData.subProductType == 42 && !this.healthFormData.portabilityDataSet && this.healthFormData.quoteFormData.InstallmentApplicable == 'No' && isOrionJourney != 'true'">
        <h3>Do you want to take home insurance?</h3>
        <div class="fieldsdiv mb-3">
            <div class="row">
                <div class="col-sm-12 form-group group_btn">
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
                        formControlName="homePlan" (change)="homeInsuranceSelection($event.value)">
                        <mat-button-toggle class="custom_togglebtn" value="true">Yes</mat-button-toggle>
                        <mat-button-toggle class="custom_togglebtn" value="false">No</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <div class="col-12 plan-radioDiv" *ngIf="this.SummaryForm.controls['homePlan'].value == 'true'">
                    <div class="scrollable-container">
                        <ul class="sumInsRadio">
                            <li *ngFor="let item of homeCoverDetails; let k = index">
                                <input type="radio" id="{{item.Plan_Name}}" name="pickSumIns" [checked]="checkData[k]"
                                    (change)="homeCoverSelected(item)" />

                                <label for="{{item.Plan_Name}}" class="">

                                    <div class="container plansAmountDiv">
                                        <div class="row">
                                            <div class="col-sm-12 form-group">
                                                <span class="planLabel">{{item.Plan_Name}}</span>
                                            </div>
                                            <div class="col-sm-12">
                                                <span class="">Total Premium</span>
                                                <span class="Planamount">{{item.Premium ? '₹ ' +
                                                    utilitySerice.convertStringtoCurrency(item.Premium) : ' '}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <strong class="pt-2">
                                        <ol class="bullet-list" *ngFor="let data of item.Covers">
                                            <li class="listingplan">
                                                <span> <img class="tickIcon"
                                                        src="../../../../../assets/images/miniTick.svg" alt="">
                                                </span>
                                                <p>{{data.Cover_Name }} : {{data.Cover_Description}}</p>
                                            </li>
                                        </ol>
                                    </strong>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox">
        <h3> Declarations </h3>
        <div class="fieldsdiv mb-4">
            <div class="row">
                <div class="col-sm-12">
                    <table class="mediTestTable">
                        <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Declarations</th>
                        <td class="hidden-caption">Agent Declaration</td>
                        <tr>
                            <td class="customCheckslist">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration1"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <p>
                                    I <span class="titlecase">{{rnAgent.toLowerCase()}}</span> accept <a (click)="openDeclarationPopup('Agent Declaration')">
                                        <span class="custom-anchor"> Agent's Declaration
                                        </span> </a>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.SummaryForm.controls['medicalDeclaration1'].value==false">
                        Agent Declaration is required.
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <table class="mediTestTable">
                        <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Declarations</th>
                        <td class="hidden-caption">Agent Declaration</td>
                        <tr>
                            <td class="customCheckslist">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration2"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <p>
                                    I accept the <a (click)="openDeclarationPopup('generalDeclaration')"> <span
                                            class="custom-anchor">Declaration</span></a> and agree to
                                    the <a (click)="openDeclarationPopup('tncDeclaration')"><span
                                            class="custom-anchor">Terms & Conditions</span></a> and
                                    <a (click)="openDeclarationPopup('privacyPolicy')"><span
                                            class="custom-anchor">Privacy
                                            Policy </span></a>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.SummaryForm.controls['medicalDeclaration2'].value==false">
                        Please accept the declaration and agree to the Terms & Conditions and Privacy Policy
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>
</form>

<!-- Saved Quotes button -->
<div class="saved-quotes floatdiv floatingedit" *ngIf="saveButtonShow">
    <img class="imgScss" src="../../../../../assets/images/MyTransactionIcon/save-quote.svg" alt="Save Quote Icon"
        (click)="saveQuotesButtonClick()">
</div>
<app-custom-footer [normalButton]="false" [buttonData]="[
{'label': 'Pay Now', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Pay Later', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Payment Link', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Choose Payment', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
]" (buttonClicked)="paymentModeSelected($event)">
</app-custom-footer>
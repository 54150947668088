<form [formGroup]="critiShieldForm" *ngIf="!multipleServiceError && loadFormContent">
    <!-- Product Details Card Starts -->
    <div class="backhidebox"></div>
    <div>
        <mat-card class="innerbox borderinnerbox producttype marginbox">
            <h3>Product Name</h3>
            <div class="fieldsdiv">
                <div class="row">

                    <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                        <mat-label class="input-group__label" for="myInput"> Product
                            name</mat-label>

                        <input type="text" matInput class="input-group__input health planselect"
                            placeholder="Enter product" type="text" formControlName="productName" id="productName"
                            readonly autocomplete="off">
                    </div>

                    <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                        <mat-label class="input-group__label" for="myInput">PIN
                            code</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="pincode" id="pincode" [minlength]="6" [maxlength]="6"
                            (input)="fetchDataAsPerPincode($event,null)" autocomplete="off">

                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                            Pincode is required
                        </mat-error>

                        <mat-error *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                            Atleast 6 digit pincode is required
                        </mat-error>

                        <mat-error *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                            Please enter only numbers
                        </mat-error>

                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                            {{invalidPinCodeMessage}}
                        </mat-error>

                    </div>

                    <div class="col-sm-3 form-group order-4 order-sm-4 stateCititeswidth" *ngIf="this.stateFetched">
                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                        <!-- If length is 1 -->
                        <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="cityState" readonly *ngIf="cityData.length == 1" autocomplete="off">
                        <mat-select formControlName="cityState" id="cityState" required
                            class="input-group__input  cstm_select" placeholder="Select state and city"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                            <ng-container *ngFor="let item of cityData; let last = last">
                                <mat-option value={{item.value}} selected>
                                    {{item.stateLabel}} - {{item.cityLabel}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                            Please select atleast one option
                        </mat-error>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <!-- Product Details Card Ends -->

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox reverinnerbox pt-2">
        <h3>Member Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <!-- Adult and Child Panel -->
                <div class="col-sm-12 form-group">
                    <div class="memberbtnrefilling">
                        <button class="refilingbtn" *ngFor="let memberData of hapDataModal.memberButtonData"
                            >
                            <!-- Member Icons -->
                            <div class="refilimg">
                                <span>
                                    <img src="{{memberData.imgSrc}}" alt="{{memberData.label}}">
                                </span>
                                <strong>{{memberData.label}}</strong>
                            </div>

                            <!-- Increment Decryment member operations -->
                            <div class="Numberbtn" *ngIf="memberData.incDec">
                                <span (click)="decrementMember(memberData, $event)">
                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                </span>
                                <!-- Member Count -->
                                <strong>{{memberData.label == 'Adult'? adultCount: childCount}}</strong>

                                <span (click)="incrementMember(memberData, $event)">
                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
        <!-- Adult fields Construction -->
        <div class="fieldsdiv " *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
            <div class="row" *ngFor="let data of adultDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12 form-group mb-0">
                    <span class="subheading">Adult {{i+1}}</span>
                </div>
                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                        [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                        [max]="getInsuredMinMaxDate('Adult','max')" [min]="getInsuredMinMaxDate('Adult','min')"
                        (click)="adultDOB.open()" placeholder="DD/MM/YYYY" readonly>
                    <mat-datepicker-toggle matSuffix [for]="adultDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #adultDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} DOB is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" for="myInput">Occupation</mat-label>
                    <mat-select class="input-group__input cstm_select" formControlName="{{'adultOccupation' + (i+1)}}"
                        id="adultDetails" required (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" placeholder="Select Occupation">
                        <ng-container *ngFor="let item of Occupation[i]; let last = last">
                            <mat-option value={{item}} (click)="occupationOptionSelected($event,i)">
                                {{item}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['adultOccupation' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} Occupation is required
                    </mat-error>
                </div>

                <!-- Applicant annual income -->
                <div class="col-sm-3 form-group appIncome"
                    *ngIf="adultDetails.value[i]['adultOccupation' + (i+1)] != 'No Active Source of Income'">
                    <label class="input-group__label" style="top: 13px;">Annual Income</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select applicant annual income"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="{{'adultIncome' + (i+1)}}" id="applicantAnnualIncome" required>
                        <ng-container *ngFor="let item of annualIncome; let last = last">
                            <mat-option value={{item.Name}} (click)="salaryOptionSelected($event)">
                                {{item.Name}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="adultDetails.value[i]['adultIncome' + (i+1)] == '' && formSubmitted">
                        Annual Income is required
                    </mat-error>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <!-- Child Fields Construction -->
        <h3 *ngIf="childDetails.length > 0">Children</h3>
        <div class="fieldsdiv " *ngIf="childDetails.length > 0" formArrayName="childDetails">
            <div class="row " *ngFor="let data of childDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12  form-group mb-2">
                    <span class="subheading">Child {{i+1}}</span>
                </div>
                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" style="top: 13px;">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="childDOB"
                        formControlName="{{'dobChild' + (i+1)}}" id="childDetails" placeholder="DD/MM/YYYY"
                        [max]="getInsuredMinMaxDate('Child','max')" [min]="getInsuredMinMaxDate('Child','min')"
                        (click)="childDOB.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="childDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #childDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="childDetails.value[i]['dobChild' + (i+1)] == '' && formSubmitted">
                        Child {{i+1}} DOB is required
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- Tenure Starts -->

    <mat-card class="innerbox reverinnerbox">
        <h3>Premium Details</h3>
        <div class="fieldsdiv ">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-sm-3 col-lg-3 policyTenureWidth mb-3">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-0" style="width:100%; margin-bottom: 6px !important;">Policy Tenure</label>
                    <div class="policyTenureDiv"><br />
                        <mat-button-toggle-group formControlName="policyTenure" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn" *ngFor="let tenureData of tenurePeriod; let j = index"
                                [value]="tenureData">
                                {{ tenureData }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error
                            *ngIf="critiShieldForm.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- Tenure Ends -->

    <mat-card class="innerbox borderinnerbox"
        *ngIf="(riskCategory.length > 0) && (occupationSelected && salarySelected)">
        <mat-tab-group (selectedTabChange)="memberTypeSelected($event)"
            *ngIf="(riskCategory.length > 0) && (occupationSelected && salarySelected)">
            <mat-tab class="" *ngFor="let tabData  of tabGroup; let tabGroupIndex = index">
                <ng-template mat-tab-label class="tablock" *ngIf="tabData['show']">
                    {{tabData.tabLabel}}
                </ng-template>
                <div class="fieldsdiv" *ngIf="adultDetails.length>0">
                    <mat-card class="innerbox borderinnerbox" formArrayName="riskCategory">
                        <h3>Risk Category</h3>
                        <div class="informationdiv ml-4 mb-2" (click)="openRiskPopup()" style="cursor: pointer;">
                            <span>
                                <img src="../../../assets/images/Waiting Period.svg" alt=""
                                    style="width: 17px; margin: -3px 4px 0 0;">
                            </span>
                            <span style="font-size: 13px;">View your risk category</span>
                        </div>
                        <div class="fieldsdiv"
                            *ngFor="let risk of critiShieldForm.get('riskCategory')['controls']; let i = index"
                            [formGroupName]="i">
                            <div class="row" *ngIf="tabGroupIndex == i">
                                <div class="container renewalDiv">
                                    <div class="categoryScrolldiv">
                                        <div class="category-radio" (click)="value()">
                                            <ul>
                                                <li *ngFor="let item of riskCategoryList; let j = index">
                                                    <input type="radio" id="category_{{i+1}}_{{j+1}}"
                                                        name="adult_category_{{i+1}}"
                                                        [formControlName]="'adult' + (i+1)"
                                                        value="Risk Category {{j+1}}">
                                                    <label for="category_{{i+1}}_{{j+1}}" id="">
                                                        <i><img src="../../../assets/images/Risk Category.svg" alt="">
                                                        </i>
                                                        Category {{j+1}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>

                    <div *ngIf="(critiShieldForm.get('memberCoverDetails')['controls'].length>0) && (showCovers)"
                        formArrayName="memberCoverDetails">
                        <h3>Covers</h3>
                        <mat-accordion class="mb-2">
                            <div *ngFor="let member of memberCoverDetails().controls; let i = index">
                                <div *ngIf="tabIndex == i">
                                    <div [formGroupName]="i">
                                        <div *ngIf="covers(i).controls.length>0" formArrayName="covers">
                                            <div *ngFor="let cover of covers(i).controls; let j = index"
                                                (click)="adultSelectedCover(i,j,cover)">

                                                <div [formGroupName]="j">
                                                    <mat-expansion-panel class="mb-2">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="34"
                                                                    height="34" class="pr-1" viewBox="0 0 54 54">
                                                                    <g transform="translate(-270 -1116)">
                                                                        <g transform="translate(270 1116)" fill="#fff"
                                                                            stroke="#1e1f20" stroke-width="1">
                                                                            <circle cx="27" cy="27" r="27"
                                                                                stroke="none" />
                                                                            <circle cx="27" cy="27" r="26.5"
                                                                                fill="none" />
                                                                        </g>
                                                                        <g transform="translate(290.181 1134.217)">
                                                                            <path
                                                                                d="M146.571,5.886a4.613,4.613,0,0,1,3.5-.085,7.392,7.392,0,0,1-.149,2,2.783,2.783,0,0,0-3.3.138S146.1,6.3,146.571,5.886Z"
                                                                                transform="translate(-141.988 -5.361)"
                                                                                fill="#1e1f20" stroke="#fff"
                                                                                stroke-width="0.3" />
                                                                            <path
                                                                                d="M142.526,2.733c.661-.765,3.488-.688,4.191-.13s1.155,4.181-.179,3.066-2.411-1.094-3.944.212c0,0-.729-2.383-.068-3.148Z"
                                                                                transform="translate(-138.033 -2.172)"
                                                                                fill="#1e1f20" stroke="#fff"
                                                                                stroke-width="0.3" />
                                                                            <path
                                                                                d="M50.129,15.374s-.18-1.491.945,2.644S45.341,31.1,44.643,31.1l-3.389-1.384C48.807,23,50.323,16.507,50.129,15.374Z"
                                                                                transform="translate(-41.254 -14.572)"
                                                                                fill="#1e1f20" stroke="#fff"
                                                                                stroke-width="0.3" />
                                                                            <path
                                                                                d="M117.067,23.982s.2-1.491-1.074,2.644,6.517,13.082,7.31,13.082l2.852-2.344C119.373,33.239,116.847,25.115,117.067,23.982Z"
                                                                                transform="translate(-112.737 -22.821)"
                                                                                fill="#1e1f20" stroke="#fff"
                                                                                stroke-width="0.3" />
                                                                        </g>
                                                                        <path
                                                                            d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.39,15.39,0,0,0,9.9,14.38,15.389,15.389,0,0,0,9.9-14.38V16.1l-9.9-4.952Z"
                                                                            transform="translate(274.678 1118.859)"
                                                                            fill="#1e1f20" />
                                                                    </g>
                                                                </svg>

                                                                {{cover.value['coverName']}}
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <!-- remaining part start -->
                                                        <h2 class="critiHead"
                                                            style="font-weight: bold;display: contents;">Base</h2>
                                                        <div *ngIf="baseCover(j,i).controls.length>0"
                                                            formArrayName="base">
                                                            <div class="p-0"
                                                                *ngFor="let obj of baseCover(j,i).controls; let k = index">
                                                                <div class="row" [formGroupName]="k">
                                                                    <ng-container>
                                                                        <div class="col-sm-12 customCheckslist pl-1">
                                                                            <ul>
                                                                                <li>
                                                                                    <mat-checkbox class="cstmCheckbox"
                                                                                        [checked]="obj.value['baseCoverSelected']"
                                                                                        (change)="baseSelected($event.checked,i,j,k,obj)"
                                                                                        formControlName="baseCoverSelected">
                                                                                        {{obj.value['baseCoverName']}}
                                                                                    </mat-checkbox>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div
                                                                            class="col-sm-12 col-lg-7 col-md-12 form-group">
                                                                            <div class="rangeslider">
                                                                                <mat-slider class="slider-container"
                                                                                    step="{{obj.value['SliderOptionForSI']}}"
                                                                                    min="{{obj.value['MinSI']}}"
                                                                                    max="{{obj.value['MaxSI']}}"
                                                                                    (input)="sliderValue($event,i,j,k,obj.value,cover,'base','adult')">

                                                                                    <input matSliderThumb
                                                                                        id="custom-slider"
                                                                                        [value]="obj.value['baseCoverInputValue']"
                                                                                        formControlName="baseCoverInputValue">
                                                                                    <div class="slider-ticks">
                                                                                        <div class="slider-tick">
                                                                                            {{obj.value['MinSI']}}</div>
                                                                                        <div class="slider-tick">
                                                                                            {{obj.value['MaxSI']}}</div>
                                                                                    </div>
                                                                                </mat-slider>
                                                                                <div class="amount-input">
                                                                                    <div class="amout-input-wrap">
                                                                                        <input type="number"
                                                                                            class="form-range"
                                                                                            [value]="obj.value['baseCoverInputValue']"
                                                                                            placeholder="Enter amount"
                                                                                            formControlName="baseCoverInputValue" autocomplete="off" (input)="sliderValue($event,i,j,k,obj.value,cover,'base','adult')">
                                                                                        <span
                                                                                            class="rupee-symbol">&#x20B9;</span>
                                                                                    </div>
                                                                                    <mat-error
                                                                                        *ngIf="obj.value['baseCoverInputValue'] % obj.value['SliderOptionForSI'] !== 0 && obj.value['baseCoverInputValue'] <= obj.value['MaxSI']">
                                                                                        Enter SI in multiples of {{obj.value['SliderOptionForSI']/1000}}K.
                                                                                    </mat-error>
                                                                                    <mat-error
                                                                                        *ngIf="obj.value['baseCoverInputValue'] > obj.value['MaxSI']">
                                                                                        Enter SI upto {{obj.value['MaxSI']/1000}}K.
                                                                                    </mat-error>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <mat-divider style="margin-bottom: 15px;"></mat-divider>
                                                        <h2 class="critiHead mt-2"
                                                            style="font-weight: bold;display: contents;">Add Ons</h2>
                                                        <div *ngIf="addonCover(j,i).controls.length>0"
                                                            formArrayName="addon">
                                                            <div div class="p-0"
                                                                *ngFor="let obj of addonCover(j,i).controls; let k = index">
                                                                <div class="row" [formGroupName]="k">
                                                                    <ng-container>
                                                                        <div class="col-sm-12 customCheckslist pl-1"
                                                                            *ngIf="obj.value['addonCoverName'] != 'Personal Accident'">
                                                                            <ul>
                                                                                <li>
                                                                                    <mat-checkbox class="cstmCheckbox"
                                                                                        [checked]="obj.value['addonCoverSelected']"
                                                                                        (change)="addonSelected($event.checked,i,j,k,obj)"
                                                                                        formControlName="addonCoverSelected">{{obj.value['addonCoverName']}}
                                                                                    </mat-checkbox>
                                                                                    <p class="ml-5"
                                                                                        *ngIf="obj.value['addonCoverSelected'] == true">
                                                                                        {{obj.value['addonCoverInputValue']}}
                                                                                    </p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="col-sm-12 customCheckslist pl-0"
                                                                            *ngIf="obj.value['addonCoverName'] == 'Personal Accident'">
                                                                            <div
                                                                                class="col-sm-12 customCheckslist pl-1">
                                                                                <ul>
                                                                                    <li>
                                                                                        <mat-checkbox
                                                                                            class="cstmCheckbox"
                                                                                            [checked]="obj.value['addonCoverSelected']"
                                                                                            (change)="addonSelected($event.checked,i,j,k,obj)"
                                                                                            formControlName="addonCoverSelected">{{obj.value['addonCoverName']}}

                                                                                        </mat-checkbox>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="col-sm-12 col-lg-7 col-md-12 form-group"
                                                                                *ngIf="obj.value['addonCoverSelected'] == true">
                                                                                <div class="rangeslider">
                                                                                    <mat-slider class="slider-container"
                                                                                        step="{{obj.value['SliderOptionForSI']}}"
                                                                                        min="{{obj.value['MinSI']}}"
                                                                                        max="{{obj.value['MaxSI']}}">
                                                                                        <input matSliderThumb
                                                                                            id="custom-slider"
                                                                                            [value]="obj.value['addonCoverInputValue']"
                                                                                            formControlName="addonCoverInputValue">
                                                                                        <div class="slider-ticks">
                                                                                            <div class="slider-tick">
                                                                                                {{obj.value['MinSI']}}
                                                                                            </div>
                                                                                            <div class="slider-tick">
                                                                                                {{obj.value['MaxSI']}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </mat-slider>
                                                                                    <div class="amount-input">
                                                                                        <div class="amout-input-wrap">
                                                                                            <input type="number"
                                                                                                class="form-range"
                                                                                                [value]="obj.value['addonCoverInputValue']"
                                                                                                placeholder="Enter amount"
                                                                                                formControlName="addonCoverInputValue" autocomplete="off">
                                                                                            <span
                                                                                                class="rupee-symbol">&#x20B9;
                                                                                            </span>
                                                                                        </div>
                                                                                        <mat-error
                                                                                            *ngIf="obj.value['addonCoverInputValue'] % obj.value['SliderOptionForSI'] !== 0 && obj.value['baseCoverInputValue'] <= obj.value['MaxSI']">
                                                                                            Enter SI in multiples of {{obj.value['SliderOptionForSI']/1000}}K.
                                                                                        </mat-error>
                                                                                        <mat-error
                                                                                            *ngIf="obj.value['addonCoverInputValue'] > obj.value['MaxSI']">
                                                                                            Enter SI upto {{obj.value['MaxSI']/1000}}K.
                                                                                        </mat-error>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </div>
                                                <mat-divider></mat-divider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-accordion>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-tab>
        </mat-tab-group>

        <mat-tab-group (selectedTabChange)="memberTypeSelectedChild($event)">
            <!-- dynamic code for child -->
            <div *ngIf="childDetails.length>0">
                <mat-tab class="" *ngFor="let tabData  of tabGroupChild">
                    <ng-template mat-tab-label class="tablock" *ngIf="tabData['show']">
                        {{tabData.tabLabel}}
                    </ng-template>
                    <div class="fieldsdiv" *ngIf="childDetails.length>0">
                        <!-- dynamic here -->
                        <mat-accordion class="mb-2">
                            <div *ngIf="critiShieldForm.get('memberCoverDetailsChild')['controls'].length>0"
                                formArrayName="memberCoverDetailsChild">
                                <div *ngFor="let member of memberCoverDetailsChild().controls; let i = index">
                                    <!-- {{member.value |json}} -->
                                    <div *ngIf="tabIndexChild == i">
                                        <div [formGroupName]="i">
                                            <div *ngIf="coversChild(i).controls.length>0" formArrayName="covers">
                                                <div *ngFor="let cover of coversChild(i).controls; let j = index"
                                                    (click)="childSelectedCover(i,j,cover)">
                                                    <div [formGroupName]="j">
                                                        <mat-expansion-panel class="mb-2">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34"
                                                                        height="34" class="pr-1" viewBox="0 0 54 54">
                                                                        <g transform="translate(-270 -1116)">
                                                                            <g transform="translate(270 1116)"
                                                                                fill="#fff" stroke="#1e1f20"
                                                                                stroke-width="1">
                                                                                <circle cx="27" cy="27" r="27"
                                                                                    stroke="none" />
                                                                                <circle cx="27" cy="27" r="26.5"
                                                                                    fill="none" />
                                                                            </g>
                                                                            <g transform="translate(290.181 1134.217)">
                                                                                <path
                                                                                    d="M146.571,5.886a4.613,4.613,0,0,1,3.5-.085,7.392,7.392,0,0,1-.149,2,2.783,2.783,0,0,0-3.3.138S146.1,6.3,146.571,5.886Z"
                                                                                    transform="translate(-141.988 -5.361)"
                                                                                    fill="#1e1f20" stroke="#fff"
                                                                                    stroke-width="0.3" />
                                                                                <path
                                                                                    d="M142.526,2.733c.661-.765,3.488-.688,4.191-.13s1.155,4.181-.179,3.066-2.411-1.094-3.944.212c0,0-.729-2.383-.068-3.148Z"
                                                                                    transform="translate(-138.033 -2.172)"
                                                                                    fill="#1e1f20" stroke="#fff"
                                                                                    stroke-width="0.3" />
                                                                                <path
                                                                                    d="M50.129,15.374s-.18-1.491.945,2.644S45.341,31.1,44.643,31.1l-3.389-1.384C48.807,23,50.323,16.507,50.129,15.374Z"
                                                                                    transform="translate(-41.254 -14.572)"
                                                                                    fill="#1e1f20" stroke="#fff"
                                                                                    stroke-width="0.3" />
                                                                                <path
                                                                                    d="M117.067,23.982s.2-1.491-1.074,2.644,6.517,13.082,7.31,13.082l2.852-2.344C119.373,33.239,116.847,25.115,117.067,23.982Z"
                                                                                    transform="translate(-112.737 -22.821)"
                                                                                    fill="#1e1f20" stroke="#fff"
                                                                                    stroke-width="0.3" />
                                                                            </g>
                                                                            <path
                                                                                d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.39,15.39,0,0,0,9.9,14.38,15.389,15.389,0,0,0,9.9-14.38V16.1l-9.9-4.952Z"
                                                                                transform="translate(274.678 1118.859)"
                                                                                fill="#1e1f20" />
                                                                        </g>
                                                                    </svg>
                                                                    {{cover.value['coverName']}}
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <!-- remaining part start -->
                                                            <h2 class="critiHead">Base</h2>
                                                            <div *ngIf="baseCoverChild(j,i).controls.length>0"
                                                                formArrayName="base">
                                                                <div class="fieldsdiv p-0"
                                                                    *ngFor="let obj of baseCoverChild(j,i).controls; let k = index">
                                                                    <div class="row" [formGroupName]="k">
                                                                        <ng-container>
                                                                            <div class="col-sm-12 customCheckslist pl-1">
                                                                                <ul>
                                                                                    <li>
                                                                                        <mat-checkbox
                                                                                            class="cstmCheckbox"
                                                                                            [checked]="obj.value['baseCoverSelected']"
                                                                                            (change)="baseSelectedChild($event.checked,i,j,k,obj)"
                                                                                            formControlName="baseCoverSelected">
                                                                                            {{obj.value['baseCoverName']}}
                                                                                        </mat-checkbox>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div class="col-sm-12 col-lg-7 col-md-12 form-group">
                                                                                <div class="rangeslider">
                                                                                    <mat-slider class="slider-container"
                                                                                        step="{{obj.value['SliderOptionForSI']}}"
                                                                                        min="{{obj.value['MinSI']}}"
                                                                                        max="{{obj.value['MaxSI']}}"
                                                                                        (input)="sliderValue($event,i,j,k,obj.value,cover,'base','child')">
                                                                                        <input matSliderThumb
                                                                                            id="custom-slider"
                                                                                            [value]="obj.value['baseCoverInputValue']"
                                                                                            formControlName="baseCoverInputValue">
                                                                                        <div class="slider-ticks">
                                                                                            <div class="slider-tick">
                                                                                                {{obj.value['MinSI']}}
                                                                                            </div>
                                                                                            <div class="slider-tick">
                                                                                                {{obj.value['MaxSI']}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </mat-slider>
                                                                                    <div class="amount-input">
                                                                                        <input type="number"
                                                                                            class="form-range"
                                                                                            [value]="obj.value['baseCoverInputValue']"
                                                                                            placeholder="Enter amount"
                                                                                            formControlName="baseCoverInputValue" autocomplete="off" (input)="sliderValue($event,i,j,k,obj.value,cover,'base','child')">
                                                                                        <span
                                                                                            class="rupee-symbol">&#x20B9;
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h2 class="critiHead mt-2">Add Ons</h2>
                                                            <div *ngIf="addonCoverChild(j,i).controls.length>0"
                                                                formArrayName="addon">
                                                                <div div class="fieldsdiv p-0"
                                                                    *ngFor="let obj of addonCoverChild(j,i).controls; let k = index">
                                                                    <div class="row" [formGroupName]="k">
                                                                        <ng-container>
                                                                            <div class="col-sm-12 customCheckslist pl-1"
                                                                                *ngIf="obj.value['addonCoverName'] != 'Personal Accident'">
                                                                                <ul>
                                                                                    <li>
                                                                                        <mat-checkbox
                                                                                            class="cstmCheckbox"
                                                                                            [checked]="obj.value['addonCoverSelected']"
                                                                                            (change)="addonSelectedChild($event.checked,i,j,k,obj)"
                                                                                            formControlName="addonCoverSelected">{{obj.value['addonCoverName']}}
                                                                                        </mat-checkbox>
                                                                                        <p class="ml-5"
                                                                                            *ngIf="obj.value['addonCoverSelected'] == true">
                                                                                            {{obj.value['addonCoverInputValue']}}
                                                                                        </p>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="col-sm-12 customCheckslist pl-1"
                                                                                *ngIf="obj.value['addonCoverName'] == 'Personal Accident'">
                                                                                <div
                                                                                    class="col-sm-12 customCheckslist pl-1">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <mat-checkbox
                                                                                                class="cstmCheckbox"
                                                                                                [checked]="obj.value['addonCoverSelected']"
                                                                                                (change)="addonSelectedChild($event.checked,i,j,k,obj)"
                                                                                                formControlName="addonCoverSelected">{{obj.value['addonCoverName']}}

                                                                                            </mat-checkbox>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div class="col-sm-12 col-lg-7 col-md-12 form-group"
                                                                                    *ngIf="obj.value['addonCoverSelected'] == true">
                                                                                    <div class="rangeslider">
                                                                                        <mat-slider
                                                                                            class="slider-container"
                                                                                            step="{{obj.value['SliderOptionForSI']}}"
                                                                                            min="{{obj.value['MinSI']}}"
                                                                                            max="{{obj.value['MaxSI']}}">
                                                                                            <input matSliderThumb
                                                                                                id="custom-slider"
                                                                                                [value]="obj.value['addonCoverInputValue']"
                                                                                                formControlName="addonCoverInputValue">
                                                                                            <div class="slider-ticks">
                                                                                                <div
                                                                                                    class="slider-tick">
                                                                                                    {{obj.value['MinSI']}}
                                                                                                </div>
                                                                                                <div
                                                                                                    class="slider-tick">
                                                                                                    {{obj.value['MaxSI']}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </mat-slider>
                                                                                        <div class="amount-input">
                                                                                            <input type="number"
                                                                                                class="form-range"
                                                                                                [value]="obj.value['addonCoverInputValue']"
                                                                                                placeholder="Enter amount"
                                                                                                formControlName="addonCoverInputValue" autocomplete="off">
                                                                                            <span
                                                                                                class="rupee-symbol">&#x20B9;
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </mat-expansion-panel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- dynamic end here -->
                        </mat-accordion>
                    </div>
                    <mat-divider></mat-divider>
                </mat-tab>
            </div>
            <!-- dynamic code for child end here -->
        </mat-tab-group>
    </mat-card>
</form>

<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer [normalButton]="true"
    [buttonData]="[{'label': 'Get quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>
import { HealthAilmentResponseStructure, InsuredRelationship } from "src/app/shared/interface/healthMaster";
import { AddonStructure, ParentAddonDetails } from "./products/hap/hap-modal";
import { ZeroTatAilmentResponseStructure } from "src/app/shared/interface/critiShield";
import { SubProdDetail } from "src/app/shared/interface/max-protect";
import { NewAddOnCoverDetails } from "src/app/shared/interface/renewal";
import { ElevateAddonStructure, ElevateParentAddonDetails } from "./products/elevate/elevate-modal";

export interface HealthDataStorage {
    quoteDataSet: boolean;
    premiumDataSet: boolean;
    portabilityDataSet: boolean;
    insuredDataSet: boolean;
    renewalDataSet: boolean;
    kycDataSet: boolean;
    quoteFormData: QuoteFormData;
    premiumFormData: PremiumFormData;
    portabilityFormData: PortabilityFormData;
    insuredFormData: InsuredFormData;
    kycStatus: KycFlow;
    kycFormData: KycFormData
    showAppointee: boolean;
    policyDetails: PolicyDetails;
    transactionDetails: TransactionDetails;
    paymentConfirmationDetails: PaymentConfirmationDetails;
    myPolicyDetails: MyPolicyDetails;
    quickquoteData: QuickQuoteData;
    quickquoteDataSet: boolean

}
export interface TransactionDetails {
    EndDate: string;
    PFTransaction: string;
    PolicyNumber: string;
    StartDate: string;
    TotalPremium: number;
}
export interface PolicyDetails {
    PolicyID: number;
    EPolicyID?: any;
    ProposalNumber: string;
    PfProposalNumber: string;
    CustomerId: number;
    PfCustomerId: string;
    PolicySubType: number;
    PolicyType: number;
    PolicyStartDate: string;
    PolicyEndDate: string;
    Tenure: number;
    PolicyStatus: number;
    QuoteId: number;
    PfQuoteId: string;
    BasicPremium: string;
    TotalTax: string;
    TotalPremium: string;
    DealId: string;
}

export interface PaymentConfirmationDetails {
    PolicyNumber: string;
    PolicyId: string;
    EPolicyId: string;
    TransFor: string;
    PfProposalNumber: string;
    TotalPremium: number;
    StartDate: string;
    EndDate: string;
    productType: string;
    PfTransactionId: string;
    NRIApplicable: string;
}

export interface PremiumDetailsData {
    tenure: number;
    adult: number;
    child: number;
    productName: string;
    totalPremium: string;
    basicPremium: string;
    totalTax: string;
    coverRange: string;
    installmentApplicable: boolean;
    installmentFrequency: string;
    installmentTotalPremium: number;
    zone: string;
}
export interface KycFormData {
    applicantDetails: ApplicantDetails;
    correspondenceAddress: CorrespondenceAddress;
    permanentAddress: PermanentAddress;
    otherDetails: OtherDetails;
    nomineeDetails: NomineeDetails;
    appointeeDetails: ApointeeDetails;
    certificateType: string;
    kycCorrelationId: string;
    customerType: string;
    CustomerID: number;
    PFCustomerID: string;
    CKYCID: string;
    EKYCID: string;
    ilKycReferenceNumber: string;
    pepFlag: boolean;
    KYCStateId?: string;
    KYCCityId?: string;
    CoStateId?: string;
    CoCityId?: string;
}

export interface KycFlow {
    ekycMandatory: boolean;
    normal: boolean;
    normalSelf: boolean;
    ovd: boolean;
    ovdSelf: boolean;
    isKYCDoneSuccessfully: boolean;
    idType: boolean;
    certNumber: string;
    selfIndex: number;
    isPilotUser: boolean;
}
export interface ApplicantDetails {
    applicantPanCardNumber: string;
    applicantTitle: string;
    applicantFullName: string;
    applicantDOB: string;

}
export interface CorrespondenceAddress {
    correspondenceAddress1: string;
    correspondenceAddress2: string;
    correspondenceLandmark: string;
    correspondencePincode: string;
    correspondenceState: string;
    correspondenceCity: string;
}

export interface PermanentAddress {
    permanentAddress1: string;
    permanentAddress2: string;
    permanentLandmark: string;
    permanentPincode: string;
    permanentState: string;
    permanentCity: string;
    permanentMaritalStatus: string;
    permanentEmail: string;
    permanentMobileNumber: string;
    permanentSameAddress: boolean;
}
export interface OtherDetails {
    gstRegistered: string;
    business: string;
    panNumber: string;
    otherCustomerType: string;
    gstStatus: string;
    gstApplicable: string;
    gstin: string;
    uin: string;
    riskStartDate: string;
}
export interface NomineeDetails {
    nomineeTitle: string;
    nomineeFullName: string;
    nomineeRelationship: string;
    nomineeDOB: string;
    NomineeTitleID: number;
    NomineeRelationshipID: number;
}
export interface ApointeeDetails {
    appointeeTitle: string;
    appointeeFullName: string;
    appointeeRelationship: string;
    appointeeDOB: string;
    appointeeRelationShipID: number;
    appointeeTitleID: number;
}

export interface QuickQuoteData {
    pincode: string;
    adultDetails: Array<{}>;
    childDetails: Array<{}>;
    cityName: string;
    cityId: string;
    stateId: string;
    policyTenure: string;
    applicantAnnualSum: string;
    cityState: string;
    //applicantAnnualIncome: string;
}

export interface QuoteFormData {
    addonListRenewal: NewAddOnCoverDetails[];
    vdValueAddon: number;
    addonOccupationListRenewal: [{ [key: string]: string }];
    productName: string;
    productType: string;
    productTypeHB: string;
    pincode: string;
    adultDetails: Array<{}>;
    childDetails: Array<{}>;
    occupation: Array<{}>,
    cityName: string;
    cityId: string;
    stateId: string;
    adultRelationShips: InsuredRelationship[];
    childRelationShips: InsuredRelationship[];
    policyTenure: string;
    policyRange: string;
    applicantAnnualSum: string;
    voluntaryDeductible?: string,
    pedWaitingPeriod: string;
    conditionWaitingPeriod: string;
    preHospitalDuration: string;
    postHospitalDuration: string;
    cityState: string;
    applicantAnnualIncome: string;
    selectedBefitData: string;
    softCopyDiscount: string;
    copaymentPercentTaken: string;
    zone: string;
    BIUresponseService: boolean;
    hideBIUservice: boolean;
    hideDIYAddon: boolean;
    elevateBUIAddon: any[];
    plans: any[];
    totalAddonCount: number;
    elevateBUICovertextAddons: any[];
    selectedPlan: any[];
    totalMemberData: Array<{}>;
    addons: Object;
    addonsData: Array<{}>;
    refiledAddons: Array<ParentAddonDetails>;
    personalAccidentAddons: Array<AddonStructure>;
    elevateAddons: Array<ElevateParentAddonDetails>;
    elevateAddonsCopy: Array<ElevateParentAddonDetails>;
    elevateAllAddonsCopy: Array<{}>,
    elevateAddonsFormValue: {};
    PEDSelection: string;
    BiuPlanUniqueId: string;
    biuSaveQuoteID: string;
    maternityAddons: Array<AddonStructure>;
    maternityAdultData: Array<{ 'id': number, 'label': string }>;
    criticalAdultData: Array<{ 'id': number, 'label': string }>;
    occupationdData: [];
    befitAddons: Array<AddonStructure>;
    nonMemberBasedAddons: Array<AddonStructure>;
    addonsFormdata: Object;
    showMaternityDivider: boolean;
    showPersonalAccidentDivider: boolean;
    InstallmentApplicable: string;
    InstallmentFrequency: string;
    installmentTotalPremium: number;
    occupationForAdult1: string;
    occupationForAdult2: string;
    maternityForAdult: Array<string>;
    criticalIllnessForAdult: Array<string>;
    proposerDobCi: string;
    selectedAddons: Array<ElevateAddonStructure>;
    // More Addons Structure
    annualAdultData: Array<{ 'id': number, 'label': string }>;
    showMoreAddonsDatepicker: boolean;
    showMoreAddonsOcc: boolean;
    activeMoreAddonsCount: number;
    moreAddons: Array<{}>;
    moreAddonsFormValue: {};
    moreAddonsOccupation: Array<{}>;
    recommendedJourneySelected: boolean;
    baseCoverSelected: boolean;
    selfMemberExists: boolean;
    addonList: Array<{}>;
    quoteSummary: Array<{}>;
    hapRefillingSummary: {
        'memberArray': string[],
        'summaryAddons': {
            'coverText': string,
            'members': string[],
            'isAllInsuredSelected': boolean,
            'coverId': string
        }[]
    };
    elevateGroupAddons: Array<{}>;
    cibilScoreDiscount: number,
    creditScore: string,
    isNRIDiscountApplicable: string,
    isNRIApplicable: string,
    RoomModifier: string,
    PEDWaitingperiodReduction: string,
    JumpStart: string,
    WorldwideWaitingperiodReduction: string,
    MaternityWaitingPeriodReduction: string,
    NetworkAdvantageApplicable: string,
    InfiniteCover: string,
    GuaranteedSuperBonus: string;
    emiPremium: string,
    netPremium: string,
    taxAmount: string,
    underWritterApproved: string;
    subProductType: number;
    healthProposalProductType: string;
    corelationId?: string;
    TotalPremium?: number;
    BasicPremium?: number;
    TotalTax?: number;
    SumInsured?: number;
    PlanCode?: string;
    PlanName?: string;
    subProductCode?: string;
    PortabilitySubProductCode?: string;
    addonListHB: Array<{}>;
    addonsHB: Array<{}>;
    userID?: string;
    zoneUpgrade?: boolean;
    reviseZone?: string;
    revisedZone?: string;
    zoneUpgradeToggle?: string;
    zoneDowngrade?: boolean,
    zoneDowngradeToggle?: string,
    reviseZoneCheck?: string;
    SubProdDetails?: SubProdDetail[];
    IsOtherLoadingDiscount?: boolean;
    isBefitApplicable: string;
    fsFormData?: FsFormData[];
    checkbox2: boolean;
    checkbox1: boolean;
    productCode?: number;
    bucketList: any[];
    isEnableCoverPart: boolean[];
    correlationId?: string;
    isCibilApplicable: string;
    proposerName: string;
    mobileNo: string;
    DOB: string;
    pinCode: string;
}
export interface FsFormData {
    Pincode: number
    CustomerGstState: string
    CustomerGstStateCode: string
    CityState: string
    MemberDetails: string[],
    CoverDetails: [],
    Product: string,
    Tenure: string
    UserType: string

}
export interface PremiumFormData {
    QuoteId: number;
    SubProductCode: string;
    PortabilitySubProductCode: string;
    productCode?: string,
    PfQuoteId: number;
    totalPremium: string;
    basicPremium: string;
    totalTax: string;
    tenure: number;
    coverRange: string;
    InstallmentNetPremium: string;
    InstallmentTaxAmount: string;
    InstallmentTotalPremium: number;
    zone: string;
}
export interface PortabilityFormData {
    insuranceCompanyName: string;
    insuranceCompanyShortName: string;
    policyEndDate: string;
    policyStartDate: string;
    policyNumber: string;
    portabilityReason: string;
    premiumAmount: string;
    previousPolicyClaims: string;
    previousPolicyPED: string;
    portabilitySumInsured: string;
    typeOfPolicy: string;
}
export interface InsuredFormData {
    insuredMemberDetails: Array<{}>;
    totalMemberDetails: Array<{}>;
    preExistingDisease: Array<{}>;
    existingDisease: HealthAilmentResponseStructure[] | ZeroTatAilmentResponseStructure[];
    befitCovers: Array<{}>;
    BefitApplicableCities: Array<{}>;
    installmentApplicable: string;
    installmentTotalPremium: string;
    installmentFrequency: string;
    totalData: Array<{}>
}

export interface MyPolicyDetails {
    htNumber: string,
    htFolderIndex: number,
    policyId: number,
    TransFor: string,
    NRIApplicable: boolean,
    isBancsPolicy: boolean
}

export interface BefitHomeStorage {
    befitData: any;
    befitSelection: boolean;
    homeInsuranceData: any;
    homeInsuranceSelection: boolean;
    homeInsuranceSelectedCover: boolean[];
    homeCoverDetails: any
}

export class HealthDataModal {
    HealthDataStorage: HealthDataStorage;
    setHealthDataStorage(form: HealthDataStorage) {
        this.HealthDataStorage = form;

    }

    getHealthDataStorage(): HealthDataStorage {
        if (this.HealthDataStorage) {
            return this.HealthDataStorage;
        }
        else {
            this.resetHealthDataStorage();
            return this.HealthDataStorage;
        }
    }

    resetHealthDataStorage(): HealthDataStorage {
        this.HealthDataStorage = {
            quoteDataSet: false,
            quickquoteDataSet: false,
            premiumDataSet: false,
            portabilityDataSet: false,
            insuredDataSet: false,
            renewalDataSet: false,
            kycDataSet: false,
            showAppointee: false,
            quoteFormData: {
                BiuPlanUniqueId: '',
                biuSaveQuoteID: '',
                elevateBUIAddon: [],
                selectedPlan: [],
                elevateBUICovertextAddons: [],
                plans: [],
                totalAddonCount: 0,
                BIUresponseService: false,
                hideBIUservice: true,
                hideDIYAddon: false,
                PEDSelection: 'No',
                productName: '',
                productType: '',
                productTypeHB: '',
                pincode: '',
                adultDetails: [],
                childDetails: [],
                occupation: [],
                elevateAddonsCopy: [],
                elevateAllAddonsCopy: [],
                cityName: '',
                cityId: '',
                stateId: '',
                RoomModifier: '',
                PEDWaitingperiodReduction: '',
                JumpStart: '',
                WorldwideWaitingperiodReduction: '',
                MaternityWaitingPeriodReduction: '',
                NetworkAdvantageApplicable: '',
                GuaranteedSuperBonus: '',
                InfiniteCover: '',
                addonsData: [],
                adultRelationShips: [],
                childRelationShips: [],
                selectedAddons: [],
                policyTenure: '',
                policyRange: '',
                applicantAnnualSum: '',
                pedWaitingPeriod: '',
                conditionWaitingPeriod: '',
                selectedBefitData: '',
                preHospitalDuration: '',
                postHospitalDuration: '',
                cityState: '',
                applicantAnnualIncome: '',
                softCopyDiscount: '',
                copaymentPercentTaken: '',
                zone: '',
                totalMemberData: [],
                refiledAddons: [],
                addons: {},
                InstallmentApplicable: '',
                InstallmentFrequency: '',
                addonList: [],
                annualAdultData: [],
                showMoreAddonsDatepicker: false,
                showMoreAddonsOcc: false,
                moreAddons: [],
                moreAddonsFormValue: {},
                moreAddonsOccupation: [],
                recommendedJourneySelected: false,
                baseCoverSelected: false,
                quoteSummary: [],
                elevateGroupAddons: [],
                hapRefillingSummary: {
                    'memberArray': [],
                    'summaryAddons': []
                },
                cibilScoreDiscount: 0,
                creditScore: '',
                activeMoreAddonsCount: 0,
                emiPremium: '',
                netPremium: '',
                taxAmount: '',
                installmentTotalPremium: 0,
                personalAccidentAddons: [],
                nonMemberBasedAddons: [],
                maternityAdultData: [],
                criticalAdultData: [],
                occupationdData: [],
                maternityAddons: [],
                befitAddons: [],
                addonsFormdata: {},
                proposerDobCi: '',
                selfMemberExists: false,
                showMaternityDivider: false,
                showPersonalAccidentDivider: false,
                occupationForAdult1: '',
                occupationForAdult2: '',
                maternityForAdult: [],
                criticalIllnessForAdult: [],
                underWritterApproved: '',
                subProductType: 0,
                healthProposalProductType: '',
                addonListHB: [],
                addonsHB: [],
                zoneUpgrade: false,
                zoneUpgradeToggle: 'No',
                zoneDowngrade: false,
                zoneDowngradeToggle: 'No',
                reviseZone: '',
                revisedZone: '',
                IsOtherLoadingDiscount: false,
                isBefitApplicable: '',
                addonListRenewal: [],
                addonOccupationListRenewal: [{}],
                elevateAddons: [],
                elevateAddonsFormValue: {},
                //selectedAddonsGS : [],
                fsFormData: [],
                checkbox2: false,
                checkbox1: false,
                vdValueAddon: 0,
                bucketList: [],
                isEnableCoverPart: [],
                //selectedAddonsGS : []
                isCibilApplicable: '',
                proposerName: '',
                mobileNo: '',
                DOB: '',
                pinCode: '',
                isNRIDiscountApplicable: '',
                isNRIApplicable: ''
            },
            premiumFormData: {
                QuoteId: 0,
                SubProductCode: '',
                PortabilitySubProductCode: '',
                PfQuoteId: 0,
                totalPremium: '',
                basicPremium: '',
                totalTax: '',
                tenure: 0,
                coverRange: '',
                InstallmentNetPremium: '',
                InstallmentTaxAmount: '',
                InstallmentTotalPremium: 0,
                zone: ''
            },

            portabilityFormData: {
                insuranceCompanyName: '',
                insuranceCompanyShortName: '',
                policyEndDate: '',
                policyStartDate: '',
                policyNumber: '',
                portabilityReason: '',
                premiumAmount: '',
                previousPolicyClaims: '',
                previousPolicyPED: '',
                portabilitySumInsured: '',
                typeOfPolicy: ''
            },
            insuredFormData: {
                insuredMemberDetails: [],
                preExistingDisease: [],
                totalMemberDetails: [],
                befitCovers: [],
                BefitApplicableCities: [],
                existingDisease: [],
                installmentApplicable: 'false',
                installmentTotalPremium: '',
                installmentFrequency: '',
                totalData: []
            },
            kycStatus: {
                ekycMandatory: false,
                normal: false,
                normalSelf: false,
                ovd: false,
                ovdSelf: false,
                idType: false,
                isKYCDoneSuccessfully: false,
                certNumber: '',
                selfIndex: 0,
                isPilotUser: false
            },

            kycFormData: this.resetKycFormData(),
            policyDetails: {
                PolicyID: 0,
                EPolicyID: '',
                ProposalNumber: '',
                PfProposalNumber: '',
                CustomerId: 0,
                PfCustomerId: '',
                PolicySubType: 0,
                PolicyType: 0,
                PolicyStartDate: '',
                PolicyEndDate: '',
                Tenure: 0,
                PolicyStatus: 0,
                QuoteId: 0,
                PfQuoteId: '',
                BasicPremium: '',
                TotalTax: '',
                TotalPremium: '',
                DealId: '',
            },
            transactionDetails: {
                EndDate: '',
                PFTransaction: '',
                PolicyNumber: '',
                StartDate: '',
                TotalPremium: 0
            },
            paymentConfirmationDetails: {
                PolicyNumber: '',
                PolicyId: '',
                EPolicyId: '',
                TransFor: '',
                PfProposalNumber: '',
                TotalPremium: 0,
                StartDate: '',
                EndDate: '',
                productType: '',
                PfTransactionId: '',
                NRIApplicable: '',
            },
            myPolicyDetails: {
                htNumber: '',
                htFolderIndex: 0,
                policyId: 0,
                TransFor: '',
                NRIApplicable: false,
                isBancsPolicy: false
            },
            quickquoteData: {
                pincode: '',
                adultDetails: [],
                childDetails: [],
                cityName: '',
                cityId: '',
                stateId: '',
                policyTenure: '',
                applicantAnnualSum: '',
                cityState: '',
            },
        }
        return this.HealthDataStorage;
    }

    resetKycFormData(): KycFormData {
        return {
            applicantDetails: {
                applicantPanCardNumber: '',
                applicantTitle: '',
                applicantFullName: '',
                applicantDOB: ''
            },
            correspondenceAddress: {
                correspondenceAddress1: '',
                correspondenceAddress2: '',
                correspondenceLandmark: '',
                correspondencePincode: '',
                correspondenceState: '',
                correspondenceCity: ''
            },
            permanentAddress: {
                permanentAddress1: '',
                permanentAddress2: '',
                permanentLandmark: '',
                permanentPincode: '',
                permanentState: '',
                permanentCity: '',
                permanentMaritalStatus: '',
                permanentEmail: '',
                permanentMobileNumber: '',
                permanentSameAddress: false,
            },
            otherDetails: {
                gstRegistered: '',
                business: '',
                panNumber: '',
                otherCustomerType: '',
                gstStatus: '',
                gstApplicable: '',
                gstin: '',
                uin: '',
                riskStartDate: ''
            },
            nomineeDetails: {
                nomineeTitle: '',
                nomineeFullName: '',
                nomineeRelationship: '',
                nomineeDOB: '',
                NomineeTitleID: 0,
                NomineeRelationshipID: 0,
            },
            appointeeDetails: {
                appointeeTitle: '',
                appointeeFullName: '',
                appointeeRelationship: '',
                appointeeDOB: '',
                appointeeRelationShipID: 0,
                appointeeTitleID: 0
            },
            customerType: '',
            certificateType: '',
            kycCorrelationId: '',
            CustomerID: 0,
            PFCustomerID: '',
            CKYCID: '',
            EKYCID: '',
            ilKycReferenceNumber: '',
            pepFlag: false
        }
    }

}

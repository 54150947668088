<header class="container-fluid fixed-top">
  <div class="row">
    <div class="container ">
      <div class="row">
        <div class="col-6">
          <h1>
            <a title="ICICI Lombard" class="logo">ICICI Lombard</a>
          </h1>
        </div>
      </div>
    </div>
  </div>
</header>

<app-error-screen *ngIf="errorOccured"></app-error-screen>


<div class="container-fluid" *ngIf="loadFormContent">
  <div class="row">
    <div class="container panaMaincontainer">

      <form [formGroup]="paymentLinkForm" class="paymentLinkMainDiv">

        <!-- Policy Review and Confirmation -->
        <mat-card class="innerbox borderinnerbox plandetailPanel marginbox ">
          <h3 class="pt-3">Policy Review & Confirmation</h3>
          <div class="fieldsdiv">
            <div class="row customDiv planrowData">
              <div class="col-lg-3 col-sm-3 form-group">
                <mat-label class="mtlabel" for="myInput">Product Name</mat-label>
                <p>{{patchValue.productName}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="patchValue.planName">
                <mat-label class="mtlabel" for="myInput">Plan Name</mat-label>
                <p>{{utilityService.removeUnderScore(patchValue.planName)}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="travelSubTypes.includes(subType) && subType != 6">
                <mat-label class="mtlabel" for="myInput">Travel Location</mat-label>
                <p class="titlecase">{{destination.toLowerCase()}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="policyType == 3 && subType == 6">
                <mat-label class="mtlabel" for="myInput">Travel From</mat-label>
                <p class="titlecase">{{travelLocations.from.toLowerCase()}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="policyType == 3 && subType == 6">
                <mat-label class="mtlabel" for="myInput">Travel To</mat-label>
                <p class="titlecase">{{travelLocations.to.toLowerCase()}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="policyType == 3 && subType == 6">
                <mat-label class="mtlabel" for="myInput">Travel From</mat-label>
                <p class="titlecase">{{travelLocations.from.toLowerCase()}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="policyType == 3 && subType == 6">
                <mat-label class="mtlabel" for="myInput">Travel To</mat-label>
                <p class="titlecase">{{travelLocations.to.toLowerCase()}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="internationalSubTypes.includes(subType)">
                <mat-label class="mtlabel" for="myInput">Duration Of Trip</mat-label>
                <p>{{tripDuration}} {{tripDuration == "1" ? "day" : "days"}}</p>
              </div>
              <div class=" col-md-3 col-sm-3 form-group" *ngIf="patchValue.sumInsured && this.policyType != 4">
                <mat-label class="mtlabel" for="myInput">Annual Sum Insured</mat-label>
                <p *ngIf="subType != 3">{{utilityService.easyAmountReader(patchValue.sumInsured)}}</p>
                <p *ngIf="subType == 3">{{utilityService.easyAmountReader(patchValue.sumInsured, 'dollar')}}</p>
              </div>
              <div class="col-md-3 col-sm-3 form-group"
                *ngIf="patchValue.policyTenure && !(travelSubTypes.includes(subType))">
                <mat-label class="mtlabel" for="myInput">Policy Tenure</mat-label>
                <p>{{patchValue.policyTenure}} {{ patchValue.policyTenure == "1" ? 'year' : 'years' }}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group"
                *ngIf="patchValue.subLimit && !(travelSubTypes.includes(subType)) && policyType != 4">
                <mat-label class="mtlabel" for="myInput">Sub Limit</mat-label>
                <p>{{utilityService.truncateAfterWhiteSpace(patchValue.subLimit)}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group" *ngIf="productType == 'R'">
                <mat-label class=" mtlabel" for="myInput">Portability</mat-label>
                <p>Yes</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group" *ngIf="policyType == 2 && (subType == 42 || subType == 45)">
                <mat-label class=" mtlabel" for="myInput">CIBIL Applicable</mat-label>
                <p>{{cibilApplicable? 'Yes': 'No'}}</p>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Portability -->
        <mat-card class="innerbox borderinnerbox " *ngIf="productType == 'R'">
          <h3 class="pt-3">Portability details
          </h3>
          <div class="fieldsdiv">
            <div class="row customDiv">
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Previous Insurer</mat-label>
                <p>{{prevInsurer}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Reason of Portability</mat-label>
                <p>{{reasonOfPortability}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Previous Policy Number</mat-label>
                <p>{{prevPolicyNumber}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Premium</mat-label>
                <p>₹{{utilityService.convertStringtoCurrency(prevPolicyPremium)}}</p>
              </div>
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Start Date</mat-label>
                <p>{{prevPolicyStartDate}}</p>
              </div>
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">End Date</mat-label>
                <p>{{prevPolicyEndDate}}</p>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Insured Details -->
        <mat-card class="innerbox borderinnerbox " *ngIf="policyType != 4">
          <h3 class="pt-3">Insured details
          </h3>
          <div *ngFor="let insured of patchValue.insuredMembers; let i = index; let last = last" class="fieldsdiv ">
            <div class="row customDiv mb-0">
              <div class=" col-sm-12">
                <span class="subheading">MEMBER {{i + 1}}</span>
              </div>
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                <p class="titlecase">{{insured.Name.toLowerCase()}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50 ">
                <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                <p class="titlecase">{{insured.Relationship.toLowerCase()}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                <p>{{(insured.DateOfBirth).replaceAll('-','/')}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50" *ngIf="travelSubTypes.includes(subType)">
                <mat-label class="mtlabel" for="myInput">Passport Number</mat-label>
                <p>{{insured.Passport}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50" *ngIf="subType == 3">
                <mat-label class="mtlabel" for="myInput">Mother's Maiden Name</mat-label>
                <p class="titlecase">{{insured.Mother.toLowerCase()}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50" formArrayName="height"
                *ngIf="(!(travelSubTypes.includes(subType)) && subType != 23) && ((insured.Weight>1) && (insured.HeightInFeet>1))">
                <div [formGroupName]="i">
                  <div class="row">
                    <div class="col label-hw-div">
                      <mat-label class="mtlabel" for="myInput">Height</mat-label>
                    </div>
                    <div class="col edit-div" *ngIf="viewHeight(i) && showEdit">
                      <a href="javascript:void(0)">
                        <img src="../../../../../assets/images/edit.png" alt="E" class="edit"
                          (click)="toggleEditHeight(i)">
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" *ngIf="viewHeight(i)">
                      <p>{{insured.HeightInFeet + '.' + insured.HeightInInch}}ft</p>
                    </div>
                    <div class="col" *ngIf="!viewHeight(i)">
                      <div class="inline-group">
                        <input type="text" size="1" [cdkTrapFocusAutoCapture]="!viewHeight(i)"
                          [cdkTrapFocus]="!viewHeight(i)" (blur)="toggleEditHeight(i)"
                          (keydown)="validateHeightInput($event)" formControlName="feetInches" class="custom-border"
                          autocomplete="off" />
                        <span>ft</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50" formArrayName="weight"
                *ngIf="(!(travelSubTypes.includes(subType)) && subType != 23) && ((insured.Weight>1) && (insured.HeightInFeet>1))">
                <div [formGroupName]="i">
                  <div class="row">
                    <div class="col label-hw-div">
                      <mat-label class="mtlabel" for="myInput">Weight</mat-label>
                    </div>
                    <div class="col edit-div" *ngIf="viewWeight(i) && showEdit">
                      <a href="javascript:void(0)">
                        <img src="../../../../../assets/images/edit.png" alt="E" class="edit"
                          (click)="toggleEditWeight(i)">
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" *ngIf="viewWeight(i)">
                      <p>{{insured.Weight}}Kg</p>
                    </div>
                    <div class="col" *ngIf="!viewWeight(i)">
                      <div class="inline-group">
                        <input size="1" type="text" formControlName="kilo" [cdkTrapFocusAutoCapture]="!viewWeight(i)"
                          [cdkTrapFocus]="!viewWeight(i)" (blur)="toggleEditWeight(i)"
                          (keydown)="validateWeightInput($event)" class="custom-border" autocomplete="off" />
                        <span>Kg</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 form-group width50 "
                *ngIf="!(travelSubTypes.includes(subType)) && this.subType != 23">
                <mat-label class="mtlabel" for="myInput">Pre-existing disease & habit</mat-label>
                <p>{{insured.isExistingDisease ? "Yes" : "No" }}</p>
              </div>
              <div class="col-lg-3 form-group width50 " *ngIf="policyType == 3 && subType == 6">
                <mat-label class="mtlabel" for="myInput">Pre-existing disease</mat-label>
                <p>{{insured?.PreExistingDisease}}</p>
              </div>
              <div class="col-lg-3 form-group width50 " *ngIf="internationalSubTypes.includes(subType)">
                <mat-label class="mtlabel" for="myInput">Nominee Name</mat-label>
                <p class="titlecase">{{insured.NomineeName.toLowerCase()}}</p>
              </div>
              <div class="col-lg-3 form-group width50 " *ngIf="internationalSubTypes.includes(subType)">
                <mat-label class="mtlabel" for="myInput">Relationship with nominee</mat-label>
                <p class="titlecase">{{insured.NomineeRelationship.toLowerCase()}}</p>
              </div>
            </div>
            <mat-divider class="mb-3" *ngIf="!last"></mat-divider>
          </div>
        </mat-card>

        <!-- Applicant Details -->
        <mat-card class="innerbox borderinnerbox ">
          <h3 class="pt-3">Applicant details
          </h3>
          <div class="fieldsdiv">
            <div class="row customDiv">
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                <p class="titlecase">{{patchValue.applicantName.toLowerCase()}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50" *ngIf="patchValue.phoneNo">
                <mat-label class="mtlabel" for="myInput">Mobile No.</mat-label>
                <p>{{patchValue.phoneNo}}</p>
              </div>
              <div class="col-lg-3 form-group width100" *ngIf="patchValue.email">
                <mat-label class="mtlabel" for="myInput">Email ID</mat-label>
                <p>{{patchValue.email.toLowerCase()}}</p>
              </div>
              <div *ngIf="patchValue.gstInNo && policyType != 4" class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">GSTIN No.</mat-label>
                <p>{{patchValue.gstInNo}}</p>
              </div>
              <div *ngIf="travelSubTypes.includes(subType) && occupation" class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Occupation</mat-label>
                <p class="titlecase">{{occupation.toLowerCase()}}</p>
              </div>
              <div *ngIf="travelSubTypes.includes(subType) && hobby" class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Hobby</mat-label>
                <p class="titlecase">{{hobby.toLowerCase()}}</p>
              </div>
              <div class="col-sm-6 form-group width100" *ngIf="policyType != 4">
                <mat-label class="mtlabel" for="myInput">Address</mat-label>
                <p class="titlecase">{{(patchValue.address1.toLowerCase() + " "
                  + patchValue.address2.toLowerCase() + " "
                  + patchValue.city.toLowerCase() + ", "
                  + patchValue.state.toLowerCase() + " - "
                  + patchValue.pincode.toLowerCase())}}</p>
              </div>
              <div class="col-sm-6 form-group width100" *ngIf="policyType == 4">
                <mat-label class="mtlabel" for="myInput">Risk Location Address</mat-label>
                <p class="titlecase">{{riskLocationAddress.replaceAll('--', ' ').toLowerCase()}}</p>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Nominee and Appointee -->
        <mat-card class="innerbox borderinnerbox "
          *ngIf="!(internationalSubTypes.includes(subType)) && policyType != 4">
          <div class="row">
            <div class="col-sm-12 custom-col-margin">
              <h3 class="pt-3">Nominee details
              </h3>
              <div class="fieldsdiv" formGroupName="nominee">
                <div class="row customDiv">
                  <div class="col-lg-3 col-sm-6 form-group width100">
                    <div class="row">
                      <div class="col label-name-div">
                        <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                      </div>
                      <div class="col edit-div" *ngIf="nFullName && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit" (click)="updateNName()">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="nFullName">
                        <p class="titlecase">{{patchValue.nomineeName.toLowerCase()}}</p>
                      </div>
                      <div class="col" *ngIf="!nFullName">
                        <input formControlName="fullName" (keydown)="validateNameInput($event)"
                          [cdkTrapFocusAutoCapture]="!nFullName" [cdkTrapFocus]="!nFullName" (blur)="updateNName()"
                          (keyup.enter)="updateNName()" size="15" (paste)="utilityService.onPaste($event, true, false)"
                          class="custom-border" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 form-group width100">
                    <div class="row">
                      <div class="col label-rel-div">
                        <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                      </div>
                      <div class="col edit-rel-div" *ngIf="nRelationship && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit"
                            (click)="nRelationship = !nRelationship">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="nRelationship">
                        <p class="titlecase">{{nRelationshipOption.toLowerCase() }}</p>
                      </div>
                      <div class="col" *ngIf="!nRelationship">
                        <select placeholder="Select" formControlName="relationship" [(ngModel)]="nRelationshipOption"
                          (blur)="nRelationship = !nRelationship" [cdkTrapFocusAutoCapture]="!nRelationship"
                          [cdkTrapFocus]="!nRelationship" class="select-width custom-border">
                          <option value={{item}} *ngFor="let item of relationships" class="titlecase">
                            {{item.toLowerCase()}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class=" col-lg-3 col-sm-3 form-group width50" *ngIf="!(travelSubTypes.includes(subType))">
                    <div class="row">
                      <div class="col label-dob-div">
                        <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                      </div>
                      <div class="col edit-div" *ngIf="nDOB && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit" (click)="nDOB = !nDOB">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="nDOB">
                        <p>{{(patchValue.nomineeDOB).replaceAll('-','/')}}</p>
                      </div>
                      <div class="col" *ngIf="!nDOB">
                        <input (input)="getNomineeAge()" formControlName="dob" type="date"
                          [cdkTrapFocusAutoCapture]="!nDOB" [cdkTrapFocus]="!nDOB" (blur)="nDOB = !nDOB"
                          [min]="minDOBNominee" [max]="maxDOBNominee" class="custom-border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="nomineeAge < 18" class="col-sm-12">
              <h3 class="pt-3">Appointee details</h3>
              <div class="fieldsdiv" formGroupName="appointee">
                <div class="row customDiv">
                  <div class="col-lg-3 col-sm-6 form-group width100">
                    <div class="row">
                      <div class="col label-name-div">
                        <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                      </div>
                      <div class="col edit-div" *ngIf="aFullName && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit" (click)="updateAName()">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="aFullName">
                        <p class="titlecase">{{patchValue.appointeeName.toLowerCase()}}</p>
                      </div>
                      <div class="col" *ngIf="!aFullName">
                        <input formControlName="fullName" (keydown)="validateNameInput($event)"
                          [cdkTrapFocusAutoCapture]="!aFullName" [cdkTrapFocus]="!aFullName" (blur)="updateAName()"
                          (keyup.enter)="updateAName()" size="15" (paste)="utilityService.onPaste($event, true, false)"
                          class="custom-border" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 form-group width100">
                    <div class="row">
                      <div class="col label-rel-div">
                        <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                      </div>
                      <div class="col edit-rel-div" *ngIf="aRelationship && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit"
                            (click)="aRelationship = !aRelationship">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="aRelationship">
                        <p class="titlecase">{{aRelationshipOption.toLowerCase()}}</p>
                      </div>
                      <div class="col" *ngIf="!aRelationship">
                        <select formControlName="relationship" [(ngModel)]="aRelationshipOption"
                          (blur)="aRelationship = !aRelationship" [cdkTrapFocusAutoCapture]="!aRelationship"
                          [cdkTrapFocus]="!aRelationship" class="select-width custom-border">
                          <option value={{item}} *ngFor="let item of relationships" class="titlecase">
                            {{item.toLowerCase() }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-3 form-group width50">
                    <div class="row">
                      <div class="col label-dob-div">
                        <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                      </div>
                      <div class="col edit-div" *ngIf="aDOB && showEdit">
                        <a href="javascript:void(0)">
                          <img src="../../../../../assets/images/edit.png" alt="E" class="edit" (click)="aDOB = !aDOB">
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngIf="aDOB">
                        <p>{{(patchValue.appointeeDOB).replaceAll('-','/')}}</p>
                      </div>
                      <div class="col" *ngIf="!aDOB">
                        <input (input)="updateADate()" formControlName="dob" type="date"
                          [cdkTrapFocusAutoCapture]="!aDOB" [cdkTrapFocus]="!aDOB" (blur)="aDOB = !aDOB"
                          [min]="minDOBAppointee" [max]="maxDOBAppointee" class="custom-border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Policy Details -->
        <mat-card class="innerbox borderinnerbox ">
          <h3 class="pt-3">Policy details
          </h3>
          <div class="fieldsdiv">
            <div class="row customDiv">
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Policy Start Date</mat-label>
                <p>{{(patchValue.startDate).replaceAll('-','/')}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Policy End Date</mat-label>
                <p>{{(patchValue.endDate).replaceAll('-','/')}}</p>
              </div>
              <div class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">Basic Premium</mat-label>
                <p>₹{{utilityService.convertStringtoCurrency((patchValue.basicPremium | number:
                  '1.0-0').replace(',',''))}}</p>
              </div>
              <div *ngIf="patchValue.installmentApplicable && !(travelSubTypes.includes(subType)) && policyType != 4"
                class="col-lg-3 col-sm-3 form-group width50">
                <mat-label class="mtlabel" for="myInput">EMI</mat-label>
                <p>{{patchValue.installmentPremium + '/' + patchValue.installmentFrequency}}</p>
              </div>
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">GST</mat-label>
                <p>₹{{utilityService.convertStringtoCurrency((patchValue.gstTax | number: '1.0-0').replace(',',''))}}
                </p>
              </div>
              <div class="col-sm-3 form-group width100">
                <mat-label class="mtlabel" for="myInput">Total Premium</mat-label>
                <p>₹{{utilityService.convertStringtoCurrency((patchValue.totalPremium | number:
                  '1.0-0').replace(',',''))}}</p>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Addon Covers -->
        <mat-card class="innerbox borderinnerbox " *ngIf="addon[0].length >  0 && !isZeroTat">
          <h3 class="pt-3">Add On Covers</h3>
          <div class="fieldsdiv">
            <div *ngFor="let add of addon; let i = index" class="row ">
              <span class="subheading ml-3 mb-3">MEMBER {{i+1}}</span>
              <div class="col-sm-12 form-group addonsCoversrow">
                <ul class="ul-addons">
                  <li *ngFor="let a of add" class="addonsList">
                    <span><img src="../../../../assets/images/miniTick.svg" alt="" class="add_tick"></span>
                    <label for="">{{a}}</label>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </mat-card>

        <!-- ZeroTat Covers -->
        <mat-card class="innerbox borderinnerbox " *ngIf="isZeroTat">
          <h3 class="pt-3">Covers</h3>
          <div class="fieldsdiv">
            <div *ngFor="let add of addon; let i = index" class="row ">
              <span class="subheading ml-3 mb-3">MEMBER {{i+1}}</span>
              <div class="col-sm-12 form-group addonsCoversrow">
                <ul class="ul-addons">
                  <li *ngFor="let a of add" class="addonsList">
                    <span><img src="../../../../assets/images/miniTick.svg" alt="" class="add_tick"></span>
                    <div class="container">
                      <div class="row"><label for="">{{a.Name}}</label></div>
                      <div class="row">
                        <p for="">{{utilityService.easyAmountReader(a.Value)}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Elevate Covers -->
        <mat-card class="innerbox borderinnerbox " *ngIf="this.elevateCovers.length > 0">
          <h3 class="pt-3">Additional Covers</h3>
          <div class="row addonsCoversrow m-0">
            <div class="col-sm-4 form-group" *ngFor="let el of elevateCovers">
              <div class="row">
                <label class="m-0"><img src="../../../../assets/images/miniTick.svg" alt="" class="add_tick">
                  {{utilityService.getFormattedKey(el.Name)}}</label>
              </div>
              <div class="row">
                <p class="addonValue pl-3">{{el.Value}}</p>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Travel Covers -->
        <mat-card class="innerbox borderinnerbox " *ngIf="travelHomeAddons.length > 0">
          <h3 class="pt-3">Additional Covers</h3>
          <div class="fieldsdiv">
            <div class="row">
              <div class="col-sm-12 form-group addonsCoversrow">
                <ul class="ul-addons">
                  <li *ngFor="let a of travelHomeAddons" class="addonsList">
                    <span><img src="../../../../assets/images/miniTick.svg" alt="" class="add_tick"></span>
                    <label for="">{{a}}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- TripSafe Covers -->
        <mat-card class="innerbox borderinnerbox"
          *ngIf="this.tripSafeAddons.length > 0 && policyType == 3 && subType == 6">
          <h3 class="pt-3">Covers</h3>
          <div class="row addonsCoversrow m-0">
            <div class="col-sm-4 form-group" *ngFor="let ts of tripSafeAddons">
              <div class="row">
                <label class="m-0"><img src="../../../../assets/images/miniTick.svg" alt="" class="add_tick">
                  {{ts.Name}}</label>
              </div>
              <div class="row">
                <p class="addonValue pl-3">Premium - ₹{{ts.Premium != 0 && ts.Premium != 0.0 ?
                  utilityService.convertStringtoCurrency(ts.Premium): 0}}</p>
              </div>
              <!-- <div class="row">
                <p class="addonValue pl-3">Sum Insured - ${{utilityService.convertStringtoCurrency(ts.SI)}}</p>
              </div> -->
            </div>
          </div>
        </mat-card>

      </form>

      <!-- Checkbox and popups -->
      <form [formGroup]="checkBoxForm" class="paymentLinkMainDiv">
        <mat-card class="innerbox borderinnerbox ">
          <h3 class="pt-3">Terms & Condition
          </h3>
          <div class="fieldsdiv">
            <div class="row customDiv">
              <div class="col-sm-12 customCheckslist">
                <ul>
                  <li>
                    <table class="declarationTable">
                      <caption class="hidden-caption">Details</caption>
                      <th class="hidden-caption">Declarations</th>
                      <td class="hidden-caption">Terms and Condition</td>
                      <tr>
                        <td>
                          <mat-checkbox id="checkbox1" class="cstmCheckbox" formControlName="checkbox1"
                            (click)="isCheckBox1Error()"></mat-checkbox>
                        </td>
                        <td>
                          <span>
                            I wish to get policy related communication on my WhatsApp number
                          </span>
                        </td>
                      </tr>
                    </table>
                  </li>
                  <mat-error *ngIf="checkbox1Error">
                    <p style="color: red">Please allow us to send policy related communication on your WhatsApp number
                    </p>
                  </mat-error>
                </ul>
              </div>
              <div class="col-sm-12 customCheckslist"
                [ngClass]="{'form-group': policyType != 3 && !travelSubTypes.includes(subType)}">
                <ul>
                  <li>
                    <table class="declarationTable">
                      <caption class="hidden-caption">Details</caption>
                      <th class="hidden-caption">Declarations</th>
                      <td class="hidden-caption">Term & Condition</td>
                      <tr>
                        <td>
                          <mat-checkbox id="checkbox2" class="cstmCheckbox" formControlName="checkbox2"
                            (click)="isCheckBox2Error()">
                          </mat-checkbox>
                        </td>
                        <td>
                          <span>
                            I accept the <a (click)="openDeclaration()"><span
                                class="custom-anchor">Declarations</span></a>
                            and agree
                            to the <a (click)="openTnc()"><span class="custom-anchor">Terms and Conditions</span></a>
                            and <a (click)="openPrivacy()"><span class="custom-anchor">Privacy
                                Policy</span></a></span>
                        </td>
                      </tr>
                    </table>
                  </li>
                  <mat-error *ngIf="checkbox2Error">
                    <p style="color: red">Please accept the declarations, terms and conditions and privacy policy</p>
                  </mat-error>
                </ul>
              </div>
              <div class="col-sm-12 customCheckslist" *ngIf="policyType != 3 && !travelSubTypes.includes(subType)">
                <ul>
                  <li>
                    <span>Based on the information shared, you may receive call from our medical team. The policy will
                      get issued on the basis of their approval and additional premium (if any)</span>
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 form-group mt-3">
                <strong>Date : {{currentDate | date: 'dd MMM yyyy'}}</strong>
              </div>
            </div>
          </div>
        </mat-card>
      </form>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center mt-3 mb-3">
          <button mat-raised-button class="primary-button" type="submit" value="buyNow" (click)="submitForm()">
            {{paymentLinkForm.dirty && valueCheck() ? "Submit" : payNowAmount }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
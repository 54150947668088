import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service';
import {
  LocalStorageService,
  SessionStorageService,
  StorageService,
} from '../../services/storage.service';
import {
  CreateLoginTokenRequest,
  CreateLoginTokenResponse,
} from './../../interface/token';
import { userEnums } from '../../enums/userEnums';
import { Constants } from '../../constants/constants';
import { Router } from '@angular/router';
import { routeEnums } from '../../enums/routeEnums';
import { GenericService } from '../../services/generic.service';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { ChannelData, SwapParameters } from '../../interface/swapPrameters';
import { MFAService } from '../../services/mfa.service';

@Injectable({
  providedIn: 'root',
})
export class AgentServiceA {
  // @HostListener('window:beforeunload', ['$event'])
  public doSomething($event) {
    // logout functionality starts
    let userdata: SwapParameters = this.storageService.getAESDecryptedData(
      userEnums.SwapParametersLogout
    );
    let encrypt_other: SwapParameters = {
      loginDetails: {
        Uowkjsdjssrme: '',
        Powkjsdjssrd: '',
      },
      productType: '',
      subProductCode: '',
      channelDetails: {
        channelName: Constants.pana,
        details: {
          userType: 'agent',
        },
      },
      flowType: '',
      viewType: 'portal',
    };
    encrypt_other.loginDetails.Uowkjsdjssrme = this.encryptDecryptService.encryptAES(
      userdata.loginDetails.Uowkjsdjssrme
    );
    encrypt_other.loginDetails.Powkjsdjssrd = this.encryptDecryptService.encryptAES(
      userdata.loginDetails.Powkjsdjssrd
    );
    encrypt_other.productType = userdata.productType;
    encrypt_other.subProductCode = userdata.subProductCode;
    this.swapdata = encrypt_other;
    this.mfaLogout(1);// ACTION TYPE 1 FOR BROWSER CLOSE
  }
  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private sessionStorageService: SessionStorageService,
    private storageService: StorageService,
    private router: Router,
    private generic: GenericService,
    private healthDataStorageService: HealthDataStorageService,
    private localStorageService: LocalStorageService,
    private mfaservice: MFAService
  ) {
  }
  swapdata: SwapParameters;
  readonly baseURI = environment.baseURL + 'Auth/';
  readonly serviceName = 'CreateLoginToken';
  channelData: ChannelData;

  setUserHeaders(data?) {
    let headers = new HttpHeaders();
    this.channelData = this.storageService.getAESDecryptedDataLocal(
      userEnums.ChannelData
    );
    let flogData = this.channelData.ST == 'login' ? '1' : '0';
    headers = headers.append('Access-Control-Allow-Methods', 'GET,POST');

    let uid = 'Uowkjsdjssrme';
    let pid = 'Powkjsdjssrd';
    headers = headers.append('AppName', this.encryptDecryptService.finalRsaEncryption('critishield'));
    headers = headers.append(uid, this.encryptDecryptService.finalRsaEncryption(data.Uowkjsdjssrme));
    if (environment.environment != 'sanity') {
      headers = headers.append(pid, this.encryptDecryptService.finalRsaEncryption(data.Powkjsdjssrd));
    }
    headers = headers.append('FLog', this.encryptDecryptService.finalRsaEncryption(flogData));
    headers = headers.append('lnk_dt', this.encryptDecryptService.finalRsaEncryption(this.channelData?.CT_DATE || ''));
    return headers;
  }

  login(data: CreateLoginTokenRequest): Observable<CreateLoginTokenResponse> {
    const headers = this.setUserHeaders(data);
    // Here we need to store password and uname in local storage service.
    //  We need to clear it in payment confirmation screen
    // Below uname and pwd should be set only at time of login. And it should be cleared at journey end
    this.storageService.setAESEncryptedDataLocal(userEnums.UopqksmlN, data.Uowkjsdjssrme);
    this.storageService.setAESEncryptedDataLocal(userEnums.Pqsxkpo, data.Powkjsdjssrd);
    // Also set user Type to hide dashboatd button in paayment confirmation
    const endpoint = `${this.baseURI}${this.serviceName}`;
    if (environment.environment == 'sanity') {
      return this.generic.genericService<CreateLoginTokenResponse>(
        endpoint,
        { sessionId: data.sessionId || '1' },
        'json',
        headers
      );
    } else {
      return this.generic.genericService<CreateLoginTokenResponse>(
        endpoint,
        '',
        // { sessionId: data.sessionId || '1' },
        'json',
        headers
      );
    }
  }

  logout() {
    // logout functionality starts
    let userdata: SwapParameters = this.storageService.getAESDecryptedData(
      userEnums.SwapParametersLogout
    );
    let encrypt_other: SwapParameters = {
      loginDetails:
      {
        Uowkjsdjssrme: '',
        Powkjsdjssrd: ''
      },
      productType: '',
      subProductCode: '',
      channelDetails: {
        channelName: userdata.channelDetails.channelName,
        details: {
          userType: 'agent',
        },
      },
      flowType: '',
      viewType: 'portal',
    };

    encrypt_other.loginDetails.Uowkjsdjssrme = this.encryptDecryptService.encryptAES(userdata.loginDetails.Uowkjsdjssrme);
    encrypt_other.loginDetails.Powkjsdjssrd = this.encryptDecryptService.encryptAES(userdata.loginDetails.Powkjsdjssrd);
    encrypt_other.productType = userdata.productType;
    encrypt_other.subProductCode = userdata.subProductCode;
    this.swapdata = encrypt_other;
    if (encrypt_other.channelDetails.channelName.includes(Constants.channelNameMFA)) {
      this.mfaLogout(2); // ACTION TYPE 2 FOR LOGOUT
      return
    }
    let other = encodeURIComponent(
      this.encryptDecryptService.encryptAES(JSON.stringify(encrypt_other))
    );
    // Below step is done because data is getting cached . So reset form data and clear local.
    this.healthDataStorageService.resetHealthDataStorage();
    this.sessionStorageService.clear();
    this.localStorageService.clearLocal();
    let redirectionUrl = environment.existingUrl + 'swap?other=' + other + '';
    let channelMSite =
      userdata.channelDetails.channelName === Constants.channelNameMSITE;
    let channelIpartner =
      userdata.channelDetails.channelName === Constants.channelNameIpartner;
    if (
      userdata.productType === Constants.health &&
      (channelMSite || channelIpartner)
    ) {
      window.location.href = redirectionUrl;
    }

    // Logout functionality ends

    // Uncomment below line for testing purpose and comment above line from login starts to login end
    // this.router.navigate(['/login'])
    // this.sessionStorageService.clear();
  }
  mfaLogout(action: number, userID?: string, productType?: string) {
    let appId: number;
    switch (productType) {
      case "Health":
        appId = 2;
        break;
      case "Travel":
        appId = 3;
        break;
      case "Home":
        appId = 4;
        break;
      case "Corporate":
        appId = 5;
        break;
      default:
        appId = 2; // Default value
        break;
    }
    let requestpayload = {
      userId: userID,
      appId: appId,
      actionType: action,
    };
    return this.mfaservice.mfaSignout(requestpayload);
  }

  logoutRedirectToLogin() {
    // Logout functionality for session logout
    this.sessionStorageService.clear();
    this.localStorageService.clearLocal();
    this.router.navigate(['/', routeEnums.LOGIN]);
    // Logout fucntionality for sanity and production
    // let redirectionUrl = environment.production ? `${environment.existingUrl}\WebPages\Login.aspx`: `${environment.existingUrl}/home`;
    // window.location.href = redirectionUrl;
  }
}

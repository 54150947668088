import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RenewalDataStorageService } from '../renewal.service';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { Observable, Subscription, concatMap, forkJoin, fromEvent, of, pairwise, startWith } from 'rxjs';
import { HealthAilmentRequestPayload, InsuredRelationship, Relationship, RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { GetAddOnListRequestPayload, GetAddOnListResponsePayload, GetHEPAddOnListRequestPayload, GetHEPAddOnListResponsePayload, GetRenewalRecalculateRequestPayload, NewAddOnCoverDetails, SaveEditCustomerRequestPayload } from "src/app/shared/interface/renewal";
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { Constants } from 'src/app/shared/constants/constants';
import { NavigationEnd, Router } from '@angular/router';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { environment } from 'src/environments/environment';
import { ApiTokenResponsePayload, AryaAPIKycResp } from 'src/app/shared/interface/utilityApi';
import { EmitButtonData, FooterData } from 'src/app/shared/interface/custom-footer';
import { KycData, KycDetailsResponse } from 'src/app/shared/interface/kycDetails';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { BefitResponsePayload, SaveHealthPolicyResponsePayload, SavehealthPolicyRequestPayload } from 'src/app/shared/interface/health';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { MatSelect } from '@angular/material/select';
import { CreateBefitProposalRequestPayload, CreateBefitProposalResponsePayload } from 'src/app/shared/interface/wrapper';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { GSZone, HAPRefilingZone, HAPZone, HEPZone, MaxZone, healthMembers, healthToggle, patterns, rnZones } from 'src/app/shared/enums/healthEnums';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { RenewalDataStorage } from '../renewal-storage.modal';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { popupDescriptionMsg, popupHeader, popupType } from 'src/app/shared/enums/popupEnums';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { RenewalDataEmitService } from 'src/app/shared/services/renewal-emit-service';
import { ZoneRequestPayload, ZoneResponsePayload } from 'src/app/shared/interface/health-advantedge';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { RenewalModal } from '../renewal-modal';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { RenewalInsuredDetailsComponent } from '../renewal-insured-details/renewal-insured-details.component';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
declare const $: any;
declare const AJL: any;

export interface PremiumDataStructure {
  BasicPremium: number,
  TotalTax: number,
  TotalPremium: number,
  PolicyStatus: string,
  SumInsured: number,
  Tenure: number,
  InstallmentEMI: InstallmentEMI
}

export interface InstallmentEMI {
  InstallmentNetPremium: number,
  InstallmentTaxAmount: number,
  InstallmentTotalPremium: number
}

@Component({
  selector: 'app-new-renewal-summary',
  templateUrl: './new-renewal-summary.component.html',
  styleUrls: ['./new-renewal-summary.component.scss']
})
export class NewRenewalSummaryComponent implements OnInit, AfterViewInit {
  @Input() normalButton: boolean;
  rnAgent: any;
  ApplicantDetailsForm: FormGroup;
  PermanentDetailsForm: FormGroup;
  OtherDetailsForm: FormGroup;
  NomineeDetailsForm: FormGroup;
  AppointeeDetailsForm: FormGroup;
  PlanDetailsForm: FormGroup;
  befitForm: FormGroup;
  emiForm: FormGroup;
  correspondenceDetailsForm: FormGroup;
  zoneForm: FormGroup;
  declarationsForm: FormGroup;
  CibilForm: FormGroup;
  apointeeRelationShipDetails: Relationship[];
  renewalDataStorage: RenewalDataStorage;
  renewalDataModal: RenewalModal = new RenewalModal();
  private subscription: Subscription[] = [];
  correspondenceCityData = [];
  permamnentCityData = [];
  showAppointee: boolean;
  nomineeMaxDate: Date;
  nomineeMinDate: Date;
  appointeeMinDate: Date;
  appointeeMaxDate: Date;
  showZoneList: boolean;
  zoneList: string[];
  showEMIOptionList: boolean;
  isPedApplicable: boolean = true;
  emiOptionList: string[] = [];
  premiumData: PremiumDataStructure[] = [];
  selectedSumInsuredIndex: any = 0;
  selectedCoverageIndex: any = 0;
  showEMIPremium: boolean = false;
  nomineeRelationshipDetails: Relationship[];
  insuredRelationshipDetails: InsuredRelationship[];
  @Input() isKYCDoneSuccessfully: boolean = false;
  ekycMandatory: boolean = false;
  ilKycReferenceNumber: string = '';
  cKycId: string = '';
  eKycId: string = '';
  eKycPan: string = '';
  healthFormData: HealthDataStorage;
  isSubmitted: boolean = false;
  bifitCovers = [];
  BenefitDiscription = [];
  selectedCoverId = 0;
  BefitSelectedPremium = 0;
  befitResponse: BefitResponsePayload;
  showBefit: boolean = false;
  zeroTatResponseBefit: CreateBefitProposalResponsePayload;
  saveHeathRes: SaveHealthPolicyResponsePayload;
  finalSelectedPremiumData;
  isApplySuccessfully: boolean = false;
  errorPopupData: PopupModal;
  taxLumsum: string;
  cibilDiscount: boolean = false;
  rnKycDetails: boolean = false;
  childInsured = [];
  adultInsured = [];
  selectedCoverName: string = '';
  paymentButtonData: FooterData[] = [];
  showPaymentButtons: boolean = false
  screenSizeLessThan: boolean = false;
  windowResized: boolean = false;
  previousUrl: string = '';
  currentUrl: string = '';
  channelData: ChannelData;
  @Input() buttonData: Array<FooterData>;
  @Output() buttonClicked = new EventEmitter<EmitButtonData>();
  isEmiClick: boolean = false;
  showZoneCard: boolean = false;
  applicantChange: boolean = false;
  cibilApplicable: boolean = true;
  onLoadCibilFlag: boolean = false;
  selfApplicable: boolean = false;
  isOVDCase: boolean = false;
  isRecalculationNeeded: boolean = false;
  hideGsCoPay: boolean = false;
  hapDataModal: HAPModal = new HAPModal(); // Indemnity modal
  permanentCityZone: string = '';
  correspondanceCityZone: string = '';
  isRecalculated: boolean = false;
  loadInsured: boolean = false;
  addonList: NewAddOnCoverDetails[];
  occupationList: any[] = [];
  occupation: [{ [key: string]: string; }];
  panelOpenState = false;
  panelPermanentOpenState = false;

  @ViewChild(RenewalInsuredDetailsComponent) renewalInsuredComponent: RenewalInsuredDetailsComponent;
  hideEdit: boolean = false;
  permanentZone: string = '';
  corresspondenceZone: string = '';
  initialLoad: boolean = false;
  nomineeTitle: { id: string; val: string; }[];
  appointeeTitle: { id: string; val: string; }[];

  constructor(private el: ElementRef, private fb: FormBuilder, private renewalStorageService: RenewalDataStorageService,
    private healthMasterService: HealthMasterService, public utilityService: UtilityService,
    private renewalService: RenewalService,
    private router: Router, private wrapperService: WrapperService, private popupService: PopupService,
    private paymode_payment_service: PaymodePaymentService,
    private customFooterService: CustomFooterService, private health_service: HealthDataStorageService,
    private renderer: Renderer2, private healthservice: HealthService, private spinner: LoaderService,
    private utilitySerice: UtilityService,
    private storageService: StorageService,
    private declarationService: DeclarationsService,
    private rtoListService: RTOListService,
    private renewal_emit_service: RenewalDataEmitService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private utilityApiService: UtilityApiService
  ) {
    this.previousUrl = userEnums.PreviousUrl ? this.storageService.getAESDecryptedData(userEnums.PreviousUrl) : null;
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.storageService.setAESEncryptedData(userEnums.PreviousUrl, this.currentUrl);
          // Update the previous URL
        }
      }
    );

    this.popupService.paymentValue$.subscribe(payMode => {
      if (!this.utilityService.isEmptyOrNull(payMode)) {
        this.submit(payMode);
      }
    })

  }

  ngOnInit() {
    this.initialLoad = true;
    this.renewalDataStorage = this.renewalStorageService.getRenewalDataStorage();//getdata
    this.setEditHide();//set uv and multiplan value
    this.getHealthAilment();
    this.fetchDetails();

    this.initForms(); // initialize form
    this.patchData(); // patch data from rn fetch
    this.premiumValuePatch(); //premium value patching
    this.onValueChange();
    this.setPanNoVisibility();
    this.emiConditionCheck();
    this.checkEMIApplicablity();
    this.getNomineeMinMaxDate();
    this.getAppointeeMinMaxDate();
    this.calculateNomineeAge();

    //if hep then call addon list else 
    if (this.renewalDataStorage.SubProductType == 30 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 32 || this.renewalDataStorage.SubProductType == 33) {
      this.getAddonList(this.renewalDataStorage.SumInsured, this.renewalDataStorage.customerDetails.MAILINGPINCODE);
    } else {
      this.addonList = this.renewalDataStorage.addonList;
    }
    //only for old hap
    if (this.renewalDataStorage.IsHaapRefiling || this.renewalDataStorage.SubProductType == 22 || this.renewalDataStorage.SubProductType == 20 || this.renewalDataStorage.SubProductType == 19) {
      this.constructBefit(this.renewalDataStorage.customerDetails.MAILINGPINCODE);
    }
    //SubSequentSIPremiums condition
    if (!this.utilitySerice.isEmptyOrNull(this.renewalDataStorage.SubSequentSIPremiums)) {
      this.renewalDataStorage.SubSequentSIPremiums = this.renewalDataStorage.SubSequentSIPremiums?.filter(x => x.SumInsured !== 0);
      this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
    }
    if (this.isApplySuccessfully) {
      if (this.renewalDataStorage.SubSequentSIPremiums) {
        let indexOfRNFetchSI = this.renewalDataStorage.SubSequentSIPremiums.findIndex(x => x.SumInsured == this.renewalDataStorage.SumInsured);
        this.selectedSumInsuredIndex = this.renewalDataStorage.SubSequentSIPremiums ? indexOfRNFetchSI != -1 ? indexOfRNFetchSI : 0 : 0;

        let selectedPremiumData = this.renewalDataStorage.SubSequentSIPremiums ? this.renewalDataStorage.SubSequentSIPremiums.find(x => x.SumInsured == this.renewalDataStorage.SumInsured) : {};

        this.selectSumInsured(selectedPremiumData, this.selectedSumInsuredIndex);
        this.applyPremium();
      }
    }
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);

    // If modification co pay is selected then we need to patch co pay data into it
    this.renewal_emit_service.gsCopayVisiblity$.subscribe(data => {
      // Applicable only for gd product
      if (this.renewalDataStorage.SubProductType == 40) {
        this.hideGsCoPay = data ? true : false;
      }
    })
    // kyc
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));
  }

  setEditHide() {
    this.renewalDataStorage.UWStatusCode = this.utilitySerice.isEmptyOrNull(this.renewalDataStorage.UWStatusCode) ? 'No' : this.renewalDataStorage.UWStatusCode;
    this.renewalDataStorage.IsMultiPlanPolicy = this.utilitySerice.isEmptyOrNull(this.renewalDataStorage.IsMultiPlanPolicy) ? false : this.renewalDataStorage.IsMultiPlanPolicy;
    if (this.renewalDataStorage.UWStatusCode == 'Yes' || this.renewalDataStorage.IsMultiPlanPolicy) {
      this.hideEdit = true;
    } else {
      this.hideEdit = false;
    }
  }

  initForms() {
    this.rnAgent = this.renewalDataStorage.AgentName;
    let tempCoPay = 0;
    tempCoPay = typeof (this.renewalDataStorage.VoluntaryCopay) == 'string' ? parseInt(this.renewalDataStorage.VoluntaryCopay) : this.renewalDataStorage.VoluntaryCopay;
    let productTypeName = this.renewalDataStorage.SubProductType == 34 ? this.renewalDataModal.productType.find(x => x.key == parseInt(this.renewalDataStorage.SubProductCode)) : null;
    if (this.renewalDataStorage.SubProductType == 34) {
      let SumInsuredDetails = this.renewalDataStorage.SumInsuredDetails.map(detail => {
        // Convert SumAmount to string and check if it is 9999999999
        let sumAmountStr = detail.SumAmount.toString();
        if (sumAmountStr === '9999999999') {
          sumAmountStr = 'Unlimited';
        }

        return {
          ...detail,
          SumAmountDisplay: sumAmountStr,
        };
      });
      this.renewalDataStorage.SumInsuredDetails = SumInsuredDetails;
    }
    this.PlanDetailsForm = this.fb.group({
      productName: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.ProductName) ? '' : this.renewalDataStorage.ProductName],
      hospitalizationCoverSI: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.SumInsured.toString()) ? '0' : this.renewalDataStorage.SumInsured.toString()],
      sumInsured: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.SumInsured) ? '' : this.renewalDataStorage.SumInsured],
      voluntaryDeductible: [this.utilityService.isEmptyOrNull(this.renewalDataStorage?.VoluntaryDeductible) ? this.renewalDataStorage.VoluntaryDeductible : this.renewalDataModal.voluntaryDeductible],
      cumulativeBonus: [this.renewalDataStorage.NewASI],
      numberOfInsured: [this.renewalDataStorage.multiPlanDtls[0].NoOfMember],
      zone: [this.renewalDataStorage.Zone],
      copaymentPercentTaken: [this.utilityService.isEmptyOrNull(this.renewalDataStorage?.CoPay) ? '0' : this.renewalDataStorage?.CoPay[this.renewalDataStorage.CoPay.indexOf((tempCoPay))].toString()],
      VoluntaryDeductibleTaken: [this.utilityService.isEmptyOrNull(this.renewalDataStorage?.VoluntaryDeductible) ? '0' : this.renewalDataStorage?.VoluntaryDeductible],
      productType: [this.renewalDataStorage.SubProductCode],
      productTypeName: [this.renewalDataStorage.SubProductType == 34 ? productTypeName?.value : '']
    })

    this.ApplicantDetailsForm = this.fb.group({
      isPanNumberVisible: [false],
      panNumber: [''],
      applicantFullName: ['', Validators.required],
      applicantDOB: [''],
      applicantAnnualIncome: ['']

    })

    this.PermanentDetailsForm = this.fb.group({
      permanentAddress1: ['', Validators.required],
      permanentAddress2: [''],
      permanentAddressLandmark: [''],
      permanentPincode: ['', Validators.required],
      permanentState: ['', Validators.required],
      permanentCity: ['', Validators.required],
      permanentStateCode: [''],
      permanentCityCode: [''],
      maritalStatus: ['', Validators.required],
      applicantEmailAddress: ['', Validators.required],
      applicantMoblieNo: ['', [Validators.required, Validators.minLength((10))]],
      correspondenceCheckbox: [true],
      permanentCityDropdown: ['']
    })

    this.OtherDetailsForm = this.fb.group({
      registeredGST: ['No'],
      panNo: ['', Validators.pattern(Constants.panNumberPatternRegExp)],
      constitutionBusiness: [''],
      customerType: [''],
      gstRegistrationStatus: [''],
      GSTINOrUIN: ['GSTIN'],
      GSTINNo: ['', Validators.pattern(patterns.GSTIN)],
      UINNo: ['', Validators.pattern(patterns.UIN)]
    })

    // Declarations Form
    this.declarationsForm = this.fb.group({
      agentDeclarationChecked: [true],
      privacyPolicyChecked: [true]
    })

    //Nominee Form
    let isNomineeTitleAvailable = ['0', '1', '2', '21'].includes(this.renewalDataStorage.NomineeTitle);
    let isAppointeeTitleAvailable = ['0', '1', '2', '21'].includes(this.renewalDataStorage.AppointeeTitle);
    let finalNomineeTitle = isNomineeTitleAvailable ? this.renewalDataStorage.NomineeTitle : '';
    let finalAppointeeTitle = isAppointeeTitleAvailable ? this.renewalDataStorage.AppointeeTitle : '';
    this.NomineeDetailsForm = this.fb.group({
      nomineeTitle: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.NomineeTitle) ? '' : this.renewalDataStorage.NomineeTitle ? finalNomineeTitle : this.renewalDataModal.title[0].id, Validators.required],
      nomineeFullName: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.NomineeName) ? '' : this.renewalDataStorage.NomineeName, Validators.required],
      nomineeRelationship: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.NomineeRelationShipId) ? '' : this.renewalDataStorage.NomineeRelationShipId == '0' ? this.nomineeRelationshipDetails : this.renewalDataStorage.NomineeRelationShipId, Validators.required],
      nomineeDOB: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.NomineeDOB) ? '' : (this.renewalDataStorage.NomineeDOB ? new Date(this.renewalDataStorage.NomineeDOB) : ''), Validators.required]
    })

    // Appointee Form
    let title = '';
    this.renewalDataModal.title.forEach(xy => {
      if (xy.id == this.renewalDataStorage.NomineeTitle) {
        title = xy.val;
      }
    })
    this.AppointeeDetailsForm = this.fb.group({
      appointeeTitle: [this.renewalDataStorage.AppointeeTitle == '' ? this.renewalDataModal.title : finalAppointeeTitle],
      appointeeFullName: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.AppointeeName) ? '' : this.renewalDataStorage.AppointeeName],
      appointeeRelationship: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.AppointeeRelationShipId) ? '' : this.renewalDataStorage.NomineeRelationShipId == '0' ? this.insuredRelationshipDetails : this.renewalDataStorage.AppointeeRelationShipId],
      appointeeDOB: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.AppointeeDOB) ? '' : new Date(this.renewalDataStorage.AppointeeDOB)]
    })

    this.correspondenceDetailsForm = this.fb.group({
      correspondenceAddress1: [this.renewalDataStorage.customerDetails.MAILINGADDRESSLINE1],
      correspondenceAddress2: [this.renewalDataStorage.customerDetails.MAILINGADDRESSLINE2],
      correspondencePincode: [this.renewalDataStorage.customerDetails.MAILINGPINCODE],
      correspondenceCity: [this.renewalDataStorage.customerDetails.MAILING_CITYDISTRICT_NAME],
      correspondenceState: [this.renewalDataStorage.customerDetails.MAILING_STATE_NAME],
      correspondenceStateCode: [this.renewalDataStorage.customerDetails.MAILINGSTATE_CODE],
      correspondenceCityCode: [this.renewalDataStorage.customerDetails.MAILINGCITYDISTRICT],
      correspondenceStateCity: [this.renewalDataStorage.customerDetails.MAILING_CITYDISTRICT_NAME + '-' + this.renewalDataStorage.customerDetails.MAILING_STATE_NAME],
      correspondenceStateCityDropdown: ['']
    })

    // Emi form
    this.emiForm = this.fb.group({
      EMIOption: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.InstallmentFrequencyName) ? '' : this.renewalDataStorage.InstallmentFrequencyName],
      requireEMI: [this.utilityService.isEmptyOrNull(this.renewalDataStorage.EMIOptionVisibleFlag) ? '' : this.renewalDataStorage.EMIOptionVisibleFlag],
      EMIApplicable: [this.renewalDataStorage.proposalRenewal ? this.renewalDataStorage.InstallmentApplicable ? "Yes" : "No" : "No"]

    })

    this.CibilForm = this.fb.group({
      changeCibil: ['No']
    })

    this.showZoneList = this.renewalDataStorage.changeZone;
    this.zoneForm = this.fb.group({
      changeZone: ["No"],
      revisedZone: [""]
    });
    //befit form
    this.befitForm = this.fb.group({
      befit: ['No']
    })
  }

  submit(payMode) {
    this.isSubmitted = true;
    if (this.declarationsForm.controls['privacyPolicyChecked'].value && this.declarationsForm.controls['agentDeclarationChecked'].value) {
      if (!this.isApplySuccessfully) {
        window.scrollTo(0, 0);
        this.errorPopupData = this.popupService.fetchErrorPopupMsg("Selected premium is not updated, kindly apply the premium");
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
      if (!this.hideEdit) {
        if (this.NomineeDetailsForm.valid && this.AppointeeDetailsForm.valid && (!this.hideEdit && this.ApplicantDetailsForm.valid) && this.zoneForm.valid && (!this.hideEdit && this.renewalInsuredComponent.insuredForm.valid) && this.PermanentDetailsForm.valid) {
          this.renewalDataStorage.SubProductType == 34 ? this.proposalCreate(payMode) : this.saveEditCustomer(payMode);
        } else if (!this.ApplicantDetailsForm.valid) {
          this.scrollError(this.ApplicantDetailsForm.controls);
        } else if (!this.PermanentDetailsForm.valid) {
          this.panelPermanentOpenState = true;
          this.scrollError(this.PermanentDetailsForm.controls);
        } else if (!this.NomineeDetailsForm.valid) {
          this.scrollError(this.NomineeDetailsForm.controls);
        } else if (this.showAppointee && !this.AppointeeDetailsForm.valid) {
          this.scrollError(this.AppointeeDetailsForm.controls);
        } else if (!this.hideEdit && this.renewalInsuredComponent.insuredForm.invalid) {
          this.renewal_emit_service.emitinsuredFormValid(true);
          this.utilityService.popUpDialog(`Please fill insured details`);
        }
      } else {
        if (this.PermanentDetailsForm.controls['correspondenceCheckbox'].value == false) {
          if (this.permanentZone != this.corresspondenceZone) {
            this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.zonePopupError);
            this.popupService.openGenericPopup(this.errorPopupData);
            return;
          }
        }
        else if (this.NomineeDetailsForm.valid) {
          this.saveEditCustomer(payMode);
        }
      }
    }
  }

  scrollError(formName) {
    try {
      let controlFoundAndFocused = false;
      let invalidControlName: string | null = null;

      for (const key of Object.keys(formName)) {
        if (formName[key].invalid) {
          invalidControlName = key;
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          if (invalidControl) {
            invalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

            try {
              invalidControl.focus();
              controlFoundAndFocused = document.activeElement === invalidControl;

              if (!controlFoundAndFocused) {
                this.utilityService.popUpDialog(`${invalidControlName} is required`);
              }
            } catch (error) {
                this.utilityService.popUpDialog(`${invalidControlName} is required`);              
            }
          } else {
            let msg = '';
            if (formName[key].errors['pattern']) {
              msg = `Please enter valid pattern`
            } else if (formName[key].errors['required']) {
              msg = `${key} is required.`
            }
            throw new Error(msg);
          }
          break; // Stop after finding the first invalid control
        }
      }

      if (!controlFoundAndFocused && invalidControlName) {
        this.utilityService.popUpDialog(`${invalidControlName} is required`);
      }

    } catch (error) {
      this.utilityService.popUpDialog(`${error.message}`);
    }
  }

  premiumValuePatch() {
    //On arrival of page need to auto select premium which is recieved from Rnfetch
    Object.entries(this.renewalDataStorage).forEach(([key, value], index) => {
      if (this.renewalDataStorage[`Premium${index + 1}Year`])
        this.premiumData.push(this.renewalDataStorage[`Premium${index + 1}Year`]);
    });

    if (this.renewalDataStorage.Premium1YearFlag) {
      this.selectedCoverageIndex = 0;
      //emit selected premium
      this.customFooterService.showRenewalFooter(this.renewalDataStorage.Premium1Year);
      this.finalSelectedPremiumData = this.renewalDataStorage.Premium1Year;
      this.isApplySuccessfully = true;
      if (parseInt(this.finalSelectedPremiumData.TotalPremium) >= 80000) {
        this.renewalDataStorage.panRequired = true;
      }
      else {
        this.renewalDataStorage.panRequired = false;
      }
    }
    else if (this.renewalDataStorage.Premium2YearFlag) {
      this.selectedCoverageIndex = 1;
      //emit selected premium
      this.customFooterService.showRenewalFooter(this.renewalDataStorage.Premium2Year);
      this.finalSelectedPremiumData = this.renewalDataStorage.Premium2Year;
      this.isApplySuccessfully = true;
    } else {
      this.selectedCoverageIndex = 2;
      //emit selected premium
      this.customFooterService.showRenewalFooter(this.renewalDataStorage.Premium3Year);
      this.finalSelectedPremiumData = this.renewalDataStorage.Premium3Year;
      this.isApplySuccessfully = true;
    }
  }

  patchData() {
    //patch title for appointee
    if (!this.utilitySerice.isEmptyOrNull(this.AppointeeDetailsForm.controls['appointeeTitle'].value)) {
      this.patchTitle(this.AppointeeDetailsForm.controls['appointeeTitle'].value, 'appointee')
    }
    else {
      this.appointeeTitle = this.renewalDataModal.title;
    }
    //patch title for nominee
    if (!this.utilitySerice.isEmptyOrNull(this.NomineeDetailsForm.controls['nomineeTitle'].value)) {
      this.patchTitle(this.NomineeDetailsForm.controls['nomineeTitle'].value, 'nominee')
    }
    else {
      this.nomineeTitle = this.renewalDataModal.title;
    }
    //adult child member patching value
    this.renewalDataStorage.multiPlanDtls[0].insuredDetails.filter(member => {
      if (member.MemberType == "Adult") {
        return true;
      } else {
        this.childInsured.push(member);
        return false;
      }
    });
    //kyc patching
    this.rnKycDetails = !this.utilityService.isEmptyOrNull(this.renewalDataStorage.customerDetails.CKYCID) || !this.utilityService.isEmptyOrNull(this.renewalDataStorage.customerDetails.EKYCID) || !this.utilityService.isEmptyOrNull(this.renewalDataStorage.customerDetails.ReferenceNo) ? true : false;

    if (this.rnKycDetails) {
      this.isKYCDoneSuccessfully = true;
      this.renewalDataStorage["CKYCID"] = this.renewalDataStorage.customerDetails.CKYCID;
      this.renewalDataStorage["PepFlag"] = !this.utilitySerice.isEmptyOrNull(this.renewalDataStorage.customerDetails.PEPFLAG) ? this.renewalDataStorage.customerDetails.PEPFLAG : false;
      this.renewalDataStorage["EKYCID"] = this.renewalDataStorage.customerDetails.EKYCID;
      this.renewalDataStorage["ILkycReferenceNumber"] = this.renewalDataStorage.customerDetails.ReferenceNo;
    } else {
      this.isKYCDoneSuccessfully = false;
    }
    // Patch Applicant Details
    this.patchRnFetchApplicantData(this.renewalDataStorage.customerDetails);

    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
  }

  async patchRnFetchApplicantData(customerDetails) {
    //Patching of applicant basic details from RN Fetch
    this.ApplicantDetailsForm.controls['applicantFullName'].setValue(customerDetails.CustomerName.split(" ").filter(val => val != '').join(" ").toUpperCase());
    // this.ApplicantDetailsForm.controls['applicantFullName'].setValue(customerDetails.CustomerName);
    this.ApplicantDetailsForm.controls['applicantDOB'].setValue(customerDetails.DOB);
    this.ApplicantDetailsForm.controls['applicantAnnualIncome'].setValue(customerDetails.ANNUALINCOME == 'SELECT' ? null : '');

    // Patching of applicant permanent details from RN Fetch
    this.PermanentDetailsForm.controls['permanentAddress1'].setValue(customerDetails.PERMANENTADDRESSLINE1);
    this.PermanentDetailsForm.controls['permanentAddress2'].setValue(customerDetails.PERMANENTADDRESSLINE2);
    this.PermanentDetailsForm.controls['permanentAddressLandmark'].setValue(customerDetails.PERMANENTLANDMARK);
    this.PermanentDetailsForm.controls['permanentPincode'].setValue(customerDetails.PERMANENTPINCODE);
    this.PermanentDetailsForm.controls['maritalStatus'].setValue(customerDetails.MARITALSTATUS);
    this.PermanentDetailsForm.controls['applicantEmailAddress'].setValue(customerDetails.EmailId);
    this.PermanentDetailsForm.controls['applicantMoblieNo'].setValue(customerDetails.MOBILENUMBER);
    this.PermanentDetailsForm.controls['permanentCity'].setValue(customerDetails.PERMANENTCITYDISTRICT);
    this.PermanentDetailsForm.controls['permanentCityCode'].setValue(customerDetails.PERMANENTCITYCODE);
    this.PermanentDetailsForm.controls['permanentState'].setValue(customerDetails.PERMANENTSTATE);
    this.PermanentDetailsForm.controls['permanentStateCode'].setValue(customerDetails.PERMANENTSTATECODE);

    this.correspondenceAddresscheckbox();
    // Patching of Other applicant Details from RN Fetch
    this.OtherDetailsForm.controls['registeredGST'].setValue(customerDetails.GSTInApplicable == true ? 'Yes' : 'No');
    this.OtherDetailsForm.controls['panNo'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].PanDetails : '');
    this.OtherDetailsForm.controls['constitutionBusiness'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].ConstitutionOfBusiness : '');
    this.OtherDetailsForm.controls['customerType'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].CustomerType : '');
    this.OtherDetailsForm.controls['gstRegistrationStatus'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].GSTRegistrationStatus : '');
    this.OtherDetailsForm.controls['GSTINOrUIN'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].GSTINNo ? 'GSTIN' : customerDetails.GSTInData[0].UINNo ? 'UIN' : 'GSTIN' : 'GSTIN');
    this.OtherDetailsForm.controls['GSTINNo'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].GSTINNo : '');
    this.OtherDetailsForm.controls['UINNo'].setValue(customerDetails.GSTInApplicable == true ? customerDetails.GSTInData[0].UINNo : '');

    if (this.renewalDataStorage.SubProductType != 42) {
      this.ApplicantDetailsForm.get('applicantAnnualIncome').clearValidators(); //clearValidators();
      this.ApplicantDetailsForm.get('applicantAnnualIncome').patchValue("");
      this.ApplicantDetailsForm.get('applicantAnnualIncome').updateValueAndValidity();
    } else if (this.renewalDataStorage.SubProductType == 42) {
      this.ApplicantDetailsForm.get('applicantAnnualIncome').setValidators([Validators.required]);
      this.ApplicantDetailsForm.get('applicantAnnualIncome').updateValueAndValidity();
    }
    this.befitForm.controls['befit'].patchValue('No');

    this.checkZoneApplicability(this.renewalDataStorage.Zone);
  }

  onValueChange() {
    //on corresspondence address change
    console.log(this.hideEdit);
    if (!this.hideEdit) {
      this.correspondenceDetailsForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(async ([prevValue, selectedValue]) => {
        console.log('correspondenceDetailsForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue.correspondencePincode != selectedValue.correspondencePincode) {
            this.zoneForm.reset();
            this.zoneForm.controls['revisedZone'].setValue("");
            this.zoneForm.controls['revisedZone'].clearValidators();
            this.zoneForm.controls['revisedZone'].updateValueAndValidity();
            this.showZoneCard = false;
            this.showZoneList = false;
            this.zoneForm.controls['changeZone'].patchValue('No');
          }
        }
      });
    }

    //on applicant income change
    if (!this.hideEdit) {
      if (this.renewalDataStorage.SubProductType == 42) {
        this.ApplicantDetailsForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(async ([prevValue, selectedValue]) => {
          console.log('ApplicantDetailsForm')
          console.log(this.initialLoad);
          // this flag initialLoad is used to skip the first trigger of the subscription.
          if (this.initialLoad) {
            this.initialLoad = false;
            console.log(this.initialLoad);
            return;
          } else {
            if (prevValue.applicantAnnualIncome != selectedValue.applicantAnnualIncome) {
              if (!this.hideEdit) {
                this.isRecalculationNeeded = true;

              }
            }
          }
        });
      }
    }

    //on plan detail form change
    if (!this.hideEdit) {
      this.PlanDetailsForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        console.log('PlanDetailsForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue.hospitalizationCoverSI != selectedValue.hospitalizationCoverSI || prevValue.sumInsured != selectedValue.sumInsured
            || prevValue.voluntaryDeductible != selectedValue.voluntaryDeductible || prevValue.cumulativeBonus != selectedValue.cumulativeBonus || prevValue.productType != selectedValue.productType
            || prevValue.copaymentPercentTaken != selectedValue.copaymentPercentTaken || prevValue.VoluntaryDeductibleTaken != selectedValue.VoluntaryDeductibleTaken) {
            if (!this.utilityService.isUndefinedORNull(prevValue.productType)) {
              if (prevValue.productType != selectedValue.productType) {
                this.getAddonList('', '');
              }
            }
            this.isRecalculationNeeded = true;
            console.log('RecalculatPlanDetailsForm', this.isRecalculationNeeded)
          }
        }
      });
    }

    //on befit value change
    if ((!this.hideEdit) && (this.renewalDataStorage.SubProductType == 22 || this.renewalDataStorage.SubProductType == 20 || this.renewalDataStorage.SubProductType == 19)) {
      this.befitForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        // if (this.befitForm.controls['befit'].value == 'Yes') {
        console.log('befitForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue != selectedValue) {
            this.isRecalculationNeeded = true;
            console.log('RecalculatbefitForm', this.isRecalculationNeeded)
          }
        }
        // }
      });
    }

    //on GST form value change
    if (!this.hideEdit) {
      this.OtherDetailsForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        console.log('OtherDetailsForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          // if (this.OtherDetailsForm.controls['registeredGST'].value == 'Yes') {
          if (prevValue.GSTINNo != selectedValue.GSTINNo || prevValue.GSTINOrUIN != selectedValue.GSTINOrUIN || prevValue.UINNo != selectedValue.UINNo
            || prevValue.constitutionBusiness != selectedValue.constitutionBusiness || prevValue.customerType != selectedValue.customerType
            || prevValue.gstRegistrationStatus != selectedValue.gstRegistrationStatus || prevValue.panNo != selectedValue.panNo || prevValue.registeredGST != selectedValue.registeredGST) {
            this.isRecalculationNeeded = true;
            console.log('RecalculatOtherDetailsForm', this.isRecalculationNeeded)
          }
          // }
        }
      });
    }

    //on zone form value change
    if (!this.hideEdit) {
      this.zoneForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        console.log('zoneForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue.revisedZone != selectedValue.revisedZone || prevValue.changeZone != selectedValue.changeZone) {
            this.isRecalculationNeeded = true;
            console.log('RecalculatzoneForm', this.isRecalculationNeeded)
          }
        }
      });
    }

    //on emi form value change
    if (!this.hideEdit) {
      this.emiForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        console.log('emiForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue != selectedValue) {
            this.isRecalculationNeeded = true;
            console.log('RecalculatemiForm', this.isRecalculationNeeded)
          }
        }
      });
    }

    //on cibil form value change
    if (!this.hideEdit) {
      this.CibilForm.valueChanges.pipe(startWith(1), pairwise()).subscribe(([prevValue, selectedValue]) => {
        console.log('CibilForm')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          console.log(this.initialLoad);
          return;
        } else {
          if (prevValue.changeCibil != selectedValue.changeCibil) {
            this.isRecalculationNeeded = true;
            console.log('RecalculatCibilForm', this.isRecalculationNeeded)
          }
        }
      });
    }

    //on insured value change
    //on ped select check emi condition
    if (!this.hideEdit) {
      this.renewal_emit_service.insuredFormValueChange$.subscribe(values => {
        console.log('insuredFormValueChange')
        console.log(this.initialLoad);
        // this flag initialLoad is used to skip the first trigger of the subscription.
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        } else {
          if (!this.utilityService.isEmptyOrNull(values)) {
            values.insuredDetails.forEach((insured: any) => {
              const hasPreexisting = insured.hasOwnProperty('isPreExistingDisease') ? true : false;
              if (hasPreexisting && insured.isPreExistingDisease) {
                if (this.renewalDataStorage.InstallmentApplicable) {
                  window.scrollTo(0, 0);
                  this.errorPopupData = this.popupService.fetchErrorPopupMsg("You are not eligible for EMI");
                  this.popupService.openGenericPopup(this.errorPopupData);
                  this.emiForm.controls['EMIApplicable'].setValue("No");
                  this.renewalDataStorage.InstallmentApplicable = false;
                  this.renewalDataStorage.InstallmentFrequencyName = '';
                  this.renewalDataStorage.InstallmentEMI = {};
                  this.showEMIOptionList = false;
                  this.emiForm.controls['EMIOption'].setValue(this.renewalDataStorage.InstallmentFrequencyName);
                  return;
                }
              }
            })
            this.isRecalculationNeeded = true;
            console.log('RecalculatinsuredFormValueChange', this.isRecalculationNeeded)
          }
        }
      });
    }
  }

  async getStateCity(pinCode: string, tag) {
    this.correspondenceDetailsForm.controls['correspondenceStateCity'].patchValue('')
    if (pinCode.length == 6) {
      let data: RTOListRequestPayload = {
        "Pincode": pinCode,
        "CityID": ""
      }
      await new Promise(resolve => {
        this.rtoListService.getStatesCityByPin(data).subscribe({
          next: (response: RTOListResponsePayload) => {
            if (response.StatusCode == Constants.statusCode_success) {
              if (tag === 'correspondencePincode') {

                if (this.renewalDataStorage.SubProductType == 42) {
                  this.constructBefit(pinCode);
                }
                if (this.renewalDataStorage.SubProductType == 30 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 32 || this.renewalDataStorage.SubProductType == 33) {
                  this.getAddonList(this.renewalDataStorage.SumInsured, pinCode);
                }

                this.correspondenceCityData = [];
                this.correspondenceCityData = response.Data;
                // if (response.Data.length > 1) {
                //   this.correspondenceCityData.push(response.Data[0]);
                // }
                if (response.Data.length == 1) {
                  let statedata = response.Data[0];
                  this.correspondenceDetailsForm.controls['correspondenceCity'].patchValue(statedata.CityName);
                  this.correspondenceDetailsForm.controls['correspondenceCityCode'].patchValue(statedata.CityID);
                  this.correspondenceDetailsForm.controls['correspondenceState'].patchValue(statedata.StateName);
                  this.correspondenceDetailsForm.controls['correspondenceStateCode'].patchValue(statedata.StateId);
                  this.correspondenceDetailsForm.controls['correspondenceStateCity'].patchValue(statedata.CityName + ' - ' + statedata.StateName);
                  this.getZoneFromCity();
                }
              } else {
                this.permamnentCityData = response.Data;
              }
            }

            return resolve(true);
          },
          error: error => {
          }
        })
      });
    }
  }

  async getZoneFromCity() {
    if (this.renewalDataStorage.SubProductType == 40 || this.renewalDataStorage.SubProductType == 42 || this.renewalDataStorage.SubProductType == 30 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 32 || this.renewalDataStorage.SubProductType == 33 || this.renewalDataStorage.SubProductType == 34) {
      let prodCode = this.renewalDataModal.ProductCode.find(x => x.Key == this.renewalDataStorage.SubProductType);
      let params: ZoneRequestPayload;
      params = {
        "subproductcode": this.renewalDataStorage.SubProductCode,
        "productcode": prodCode.value.toString(),
        "State": this.correspondenceDetailsForm.controls['correspondenceState'].value,
        "City": this.correspondenceDetailsForm.controls['correspondenceCity'].value,
        "Pincode": this.correspondenceDetailsForm.controls['correspondencePincode'].value,
      }
      await new Promise(resolve => {
        this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
          next: (response: ZoneResponsePayload) => {
            if (response.StatusCode === Constants.statusCode_success) {
              this.correspondanceCityZone = response.Data.zone;
              if (this.PermanentDetailsForm.controls['correspondenceCheckbox'].value == false) {
                if (this.renewalDataStorage.Zone.toLowerCase() != this.correspondanceCityZone.toLowerCase()) {
                  this.correspondenceDetailsForm.controls['correspondenceState'].reset(),
                    this.correspondenceDetailsForm.controls['correspondenceCity'].reset(),
                    this.correspondenceDetailsForm.controls['correspondencePincode'].reset(),
                    this.correspondenceDetailsForm.controls['correspondenceStateCity'].reset(),
                    this.correspondenceDetailsForm.controls['correspondenceStateCityDropdown'].reset(),
                    this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.zonePopupError);
                  this.popupService.openGenericPopup(this.errorPopupData);
                  return;
                }
              }
              return resolve(true);
            }
          },
          error: error => {
            this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            this.popupService.openGenericPopup(this.errorPopupData);
          }
        })
      })
    }
  }

  refineZoneValue(zoneValue) {
    if (!this.utilitySerice.isEmptyOrNull(zoneValue)) {
      const toPascalCase = zoneValue.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
      return toPascalCase;
    }
  }

  correspondenceCityStateData() {
    let data = this.correspondenceCityData.find(cityData => cityData.CityName == this.correspondenceDetailsForm.controls['correspondenceStateCityDropdown'].value);

    this.correspondenceDetailsForm.controls['correspondenceCity'].patchValue(data?.CityName);
    this.correspondenceDetailsForm.controls['correspondenceCityCode'].patchValue(data?.CityID);
    this.correspondenceDetailsForm.controls['correspondenceState'].patchValue(data?.StateName);
    this.correspondenceDetailsForm.controls['correspondenceStateCode'].patchValue(data?.StateId);
    this.getZoneFromCity();
  }

  permanentCityChange() {
    let data = this.permamnentCityData.find(cityData => cityData.CityName == this.PermanentDetailsForm.controls['permanentCityDropdown'].value);

    this.PermanentDetailsForm.controls['permanentCity'].setValue(data?.CityName);
    this.PermanentDetailsForm.controls['permanentCityCode'].setValue(data?.CityID);
    this.PermanentDetailsForm.controls['permanentState'].setValue(data?.StateName);
    this.PermanentDetailsForm.controls['permanentStateCode'].setValue(data?.StateId);
  }

  emiConditionCheck() {
    this.emiOptionList = this.renewalDataStorage.InstallmentFrequency;
    if (this.renewalDataStorage.proposalRenewal && this.renewalDataStorage.InstallmentApplicable) {
      this.renewalDataStorage.EMIOptionVisibleFlag = this.renewalDataStorage.InstallmentFlag && this.renewalDataStorage.Premium1YearFlag && (this.renewalDataStorage.Premium1Year.PolicyStatus == "APPROVE");
      this.onclickRequireEMI(this.renewalDataStorage.EMIOptionVisibleFlag);

    }
    else {
      this.renewalDataStorage.InstallmentApplicable = false;
      this.renewalDataStorage["InstallmentFrequencyName"] = "";
      this.renewalDataStorage["InstallmentEMI"] = {
        InstallmentNetPremium: 0,
        InstallmentTaxAmount: 0,
        InstallmentTotalPremium: 0
      }
      this.renewalDataStorage["EMIOptionVisibleFlag"] = this.renewalDataStorage.InstallmentFlag;
    }
  }

  callKYC() {
    // this.aryaApiKycCalling(); //arya kyc changes
    this.newKycCalling(); //flutter kyc changes
  }

  newKycCalling() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          let source = this.utilityService.identifyEnvironmentType(kycToken);
          let resourceParams = JSON.stringify(source);
          const externalUrlFrame = document.getElementById('externalUrlFrame') as HTMLIFrameElement;
          externalUrlFrame.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        }
      },
      error: error => {
      }
    }));
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        // setTimeout(() => {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
        // }, 0);
      }
      else {
        this.isKYCDoneSuccessfully = false;
        this.ekycMandatory = false;
        this.utilityService.popUpDialog('Kindly complete E-KYC/C-KYC to proceed further.');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1 : '',
            address_line_2: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_2 : '',
            pin_code: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.pin_code : '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city : '',
            state: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.state : '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  //kyc block
  receiveMessage(event) {
    $('#externalUrlModal').modal('hide');
    if (!this.utilityService.isEmptyOrNull(event.data)) {
      let res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
      this.getKYCDetails(res);
    }
  }

  //get kyc details
  getKYCDetails(res: KycDetailsResponse) {
    if ((res != null) && (res.StatusCode == Constants.statusCode_success)) {
      let tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      this.ekycMandatory = true;
      this.ilKycReferenceNumber = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.il_kyc_ref_no : '';
      this.cKycId = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.ckyc_number : '';
      this.isKYCDoneSuccessfully = tempResponse.data.hasOwnProperty('kyc_details') && ((!this.utilityService.isEmptyOrNull(this.cKycId) || !this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) || !this.utilityService.isEmptyOrNull(this.eKycId))) ? true : false;
      this.isOVDCase = tempResponse.data.statusMessage == Constants.statusMsg_Manual ? true : false;
      this.setKYCDetails(tempResponse.data);
    }
  }
  // onChangeApplicant(value) {
  //   this.applicantChange = value;
  //   if (!this.hideEdit) {
  //     this.isRecalculationNeeded = value;
  //   }
  // }

  //set kyc details
  setKYCDetails(response: KycData) {
    if (response) {
      this.eKycId = '';
      this.eKycPan = response.kyc_details.certificate_type != 'PAN' ? '' : response.kyc_details.certificate_number;
      this.renewalDataStorage["CKYCID"] = response.kyc_details.ckyc_number;
      this.renewalDataStorage["PepFlag"] = !this.utilitySerice.isEmptyOrNull(response.kyc_details.pep_flag) ? response.kyc_details.pep_flag : false;
      this.renewalDataStorage.customerDetails.PEPFLAG = !this.utilitySerice.isEmptyOrNull(response.kyc_details.pep_flag) ? response.kyc_details.pep_flag : false;
      this.renewalDataStorage["EKYCID"] = this.eKycId;
      this.renewalDataStorage["ILkycReferenceNumber"] = response.kyc_details.il_kyc_ref_no;
      this.renewalDataStorage["isKYCDoneSuccessfully"] = this.isKYCDoneSuccessfully;
      this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
    }
    else {
      this.renewalDataStorage["CKYCID"] = "";
      this.renewalDataStorage["PepFlag"] = false;
      this.renewalDataStorage.customerDetails.PEPFLAG = false;
      this.renewalDataStorage["EKYCID"] = "";
      this.renewalDataStorage["ILkycReferenceNumber"] = "";
      this.renewalDataStorage["isKYCDoneSuccessfully"] = false;

    }
  }

  // On click of suminsured set data from premiumData
  selectSumInsured(selectedPremiumData: any, index: any) {
    this.isApplySuccessfully = false;
    this.premiumData = [];
    this.PlanDetailsForm.controls['hospitalizationCoverSI'].setValue(selectedPremiumData?.SumInsured.toString(), { emitEvent: false });
    Object.entries(selectedPremiumData?.PremiumDetails).forEach(([key, value]: [any, PremiumDataStructure], index) => {
      this.premiumData.push(value);
    });
    this.selectedSumInsuredIndex = index;
  }

  // Change sum insured function
  changeSumInsured(event) {
    // If it is hap check for world wide cover
    // If world wide cover is greater or equal to 25L then emit world wide cover or else emit false
    // To make worldwide cover false 
    // this. renewalDataStorage.SumInsured = event;
    if (event >= 2500000 && this.renewalDataStorage.InstallmentApplicable) {
      window.scrollTo(0, 0);
      this.errorPopupData = this.popupService.fetchErrorPopupMsg("You are not eligible for EMI");
      this.popupService.openGenericPopup(this.errorPopupData);
      this.emiForm.controls['EMIApplicable'].setValue("No");
      this.renewalDataStorage.InstallmentApplicable = false;
      this.renewalDataStorage.InstallmentFrequencyName = '';
      this.renewalDataStorage.InstallmentEMI = {};
      this.showEMIOptionList = false;
      this.emiForm.controls['EMIOption'].setValue(this.renewalDataStorage.InstallmentFrequencyName);
    } else {
      this.renewalDataStorage.SumInsured = event;
      if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hap) {
        this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
        if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hap) {
          let addonRequired = event >= this.renewalDataModal.maxSumInsured ? true : false;
          this.renewal_emit_service.emitHapData(addonRequired);
        }
      }
      else if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hep) {
        this.renewal_emit_service.emitHepAddonData(this.addonList);
      }
    }
  }

  // on click of coverage period setting an index
  selectCoverage(index) {
    this.isApplySuccessfully = false;
    this.selectedCoverageIndex = index;
    if (index == 0) {
      this.renewalDataStorage.Premium1YearFlag = true;
      this.renewalDataStorage.Tenure = 1;
    }
    else if (index == 1) {
      this.renewalDataStorage.Tenure = 2;
      this.renewalDataStorage.InstallmentApplicable = false;
    } else {
      this.renewalDataStorage.Tenure = 3;
      this.renewalDataStorage.InstallmentApplicable = false;
    }
    this.checkEMIApplicablity();
    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
  }

  //pan number
  setPanNoVisibility() {
    if (this.finalSelectedPremiumData?.TotalPremium > this.renewalDataModal.totalPremiumLimitForPanNO) {
      this.ApplicantDetailsForm.get('isPanNumberVisible').patchValue(true);
      let panNumber = this.ApplicantDetailsForm.value.panNumber ? this.ApplicantDetailsForm.value.panNumber : this.renewalDataStorage?.customerDetails?.PAN;
      this.ApplicantDetailsForm.get('panNumber').patchValue(panNumber);
      this.ApplicantDetailsForm.get('panNumber').setValidators([Validators.required, Validators.pattern(Constants.panNumberPatternRegExp)]);
      this.ApplicantDetailsForm.get('panNumber').updateValueAndValidity();
    } else {
      this.ApplicantDetailsForm.get('isPanNumberVisible').patchValue(false);
      this.ApplicantDetailsForm.get('panNumber').patchValue('');
      this.ApplicantDetailsForm.get('panNumber').clearValidators();
      this.ApplicantDetailsForm.get('panNumber').updateValueAndValidity();
    }
  }

  //apply button for premium
  applyPremium() {
    if (this.renewalDataStorage?.SubSequentSIPremiums == null) {
      if (this.befitForm.controls['befit'].value == 'Yes') {
        this.finalSelectedPremiumData.TotalPremium = (this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalPremium + this.BefitSelectedPremium);
        this.finalSelectedPremiumData.sumInsured = this.renewalDataStorage.SumInsured;
        this.finalSelectedPremiumData.BasicPremium = this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].BasicPremium;
        this.finalSelectedPremiumData.TotalTax = this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalTax;
      }
      else {
        this.finalSelectedPremiumData = {
          TotalPremium: this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalPremium,
          sumInsured: this.renewalDataStorage.SumInsured,
          BasicPremium: this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].BasicPremium,
          TotalTax: this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalTax,
          Tenure: this.selectedCoverageIndex + 1
        }
      }
    }
    else {
      if (this.renewalDataStorage.InstallmentApplicable) {
        this.finalSelectedPremiumData.TotalPremium = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].InstallmentEMI.InstallmentTotalPremium;
        this.finalSelectedPremiumData.sumInsured = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].SumInsured;
        this.finalSelectedPremiumData.BasicPremium = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].InstallmentEMI.InstallmentNetPremium;
        this.finalSelectedPremiumData.TotalTax = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].InstallmentEMI.InstallmentTaxAmount;
      }
      else {
        this.finalSelectedPremiumData = {
          TotalPremium: this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].TotalPremium,
          sumInsured: this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].SumInsured,
          BasicPremium: this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].BasicPremium,
          TotalTax: this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails[this.selectedCoverageIndex].TotalTax,
          Tenure: this.selectedCoverageIndex + 1
        }

      }
      this.renewalDataStorage.SumInsured = this.finalSelectedPremiumData.sumInsured;
      this.renewalDataStorage.multiPlanDtls[0].SumInsured = this.finalSelectedPremiumData.sumInsured;
      this.renewalDataStorage.Premium1Year = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails.find(x => x.Tenure == 1);
      this.renewalDataStorage.Premium2Year = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails.find(x => x.Tenure == 2);
      this.renewalDataStorage.Premium3Year = this.renewalDataStorage.SubSequentSIPremiums[this.selectedSumInsuredIndex].PremiumDetails.find(x => x.Tenure == 3);
      this.renewalDataStorage.Tenure = this.selectedCoverageIndex + 1;
    }
    if (this.renewalDataStorage.SubProductType == 42) {
      this.changeSumInsured(this.finalSelectedPremiumData.sumInsured)
    }

    if (this.renewalDataStorage.SumInsured >= 2500000 && this.renewalDataStorage.InstallmentApplicable) {
      window.scrollTo(0, 0);
      this.errorPopupData = this.popupService.fetchErrorPopupMsg("EMI is not available for SI exceeding 20 lakh");
      this.popupService.openGenericPopup(this.errorPopupData);
      return;
    }
    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
    this.isApplySuccessfully = true;
    this.customFooterService.showRenewalFooter(this.finalSelectedPremiumData);
    this.setPanNoVisibility();
    if (!this.renewalDataStorage.IsMultiPlanPolicy) {
      if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hep || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.he || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hsp || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hs) {
        this.getAddonList(this.renewalDataStorage.SumInsured, this.renewalDataStorage.customerDetails.MAILINGPINCODE);
      }
    }

  }

  //nominee dob age calculation
  calculateNomineeAge() {
    let nomineeAge = this.utilityService.calculateAge(this.NomineeDetailsForm.controls['nomineeDOB'].value);
    if (nomineeAge < 18) {
      this.showAppointee = true;
      this.AppointeeDetailsForm.get('appointeeTitle').setValidators([Validators.required]);
      this.AppointeeDetailsForm.get('appointeeFullName').setValidators([Validators.required]);
      this.AppointeeDetailsForm.get('appointeeRelationship').setValidators([Validators.required]);
      this.AppointeeDetailsForm.get('appointeeDOB').setValidators([Validators.required]);
      this.AppointeeDetailsForm.updateValueAndValidity();
    } else {
      this.showAppointee = false;
      this.AppointeeDetailsForm.get('appointeeTitle').clearValidators();
      this.AppointeeDetailsForm.get('appointeeTitle').updateValueAndValidity();
      this.AppointeeDetailsForm.get('appointeeFullName').clearValidators();
      this.AppointeeDetailsForm.get('appointeeFullName').updateValueAndValidity();
      this.AppointeeDetailsForm.get('appointeeRelationship').clearValidators();
      this.AppointeeDetailsForm.get('appointeeRelationship').updateValueAndValidity();
      this.AppointeeDetailsForm.get('appointeeDOB').clearValidators();
      this.AppointeeDetailsForm.get('appointeeDOB').updateValueAndValidity();
    }
  }

  //get nominee min max date
  getNomineeMinMaxDate() {
    let nomineeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.nomineeMinDate = nomineeMinMaxDate.minDate;
    this.nomineeMaxDate = nomineeMinMaxDate.maxDate;
  }

  //get appointee min max date
  getAppointeeMinMaxDate() {
    let appointeeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.appointeeMinDate = appointeeMinMaxDate.minDate;
    this.appointeeMaxDate = this.utilityService.subtractYears(new Date(), 18);
  }

  //check emi applicable or not
  checkEMIApplicablity() {
    let pedMembers = this.renewalDataStorage.multiPlanDtls[0].insuredDetails.filter(data => data.PedIllness.length);
    if (pedMembers.length >= 1) {
      this.showEMIOptionList = false;
      this.isPedApplicable = true;
    }
    else {
      this.isPedApplicable = false;
    }
    this.renewalDataStorage.EMIOptionVisibleFlag = this.renewalDataStorage.InstallmentFlag && (this.renewalDataStorage.Tenure == 1) && (this.renewalDataStorage.Premium1Year.PolicyStatus == "APPROVE") && !this.isPedApplicable;
  }

  //on emi checkbox
  onclickRequireEMI(data) {
    let ped = [];
    this.renewalInsuredComponent.getInsuredArray.controls.forEach((insured: any) => {
      ped = this.getPedDataForRNFetchObject(insured.get('insuredPED'));
    })
    if (this.renewalDataStorage.SumInsured >= 2500000 && data) {
      window.scrollTo(0, 0);
      this.errorPopupData = this.popupService.fetchErrorPopupMsg("EMI is not available for SI exceeding 20 lakh");
      this.popupService.openGenericPopup(this.errorPopupData);
      this.emiForm.controls['EMIApplicable'].setValue("No");
      return;
    }
    if (ped.length > 0) {
      window.scrollTo(0, 0);
      this.errorPopupData = this.popupService.fetchErrorPopupMsg("You are not eligible for EMI");
      this.popupService.openGenericPopup(this.errorPopupData);
      this.emiForm.controls['EMIApplicable'].setValue("No");
      return;
    }
    this.isEmiClick = data;
    this.showEMIOptionList = data;
    this.showEMIPremium = data;
    if (!data && !this.showEMIOptionList) {
      this.emiForm.controls['EMIApplicable'].setValue("No");
      this.renewalDataStorage.InstallmentApplicable = false;
      this.renewalDataStorage.InstallmentFrequencyName = '';
      this.renewalDataStorage.InstallmentEMI = {};
      this.emiForm.controls['EMIOption'].setValue(this.renewalDataStorage.InstallmentFrequencyName);
    }
    else if (data) {
      this.renewalDataStorage.InstallmentApplicable = true;
      this.emiForm.controls['EMIApplicable'].setValue("Yes");
    }
    this.renewalDataStorage.InstallmentApplicable = data;
  }

  // whenever click on emi Options need to call Recalculate api.
  onSelectEMIOption(event) {
    this.renewalDataStorage.InstallmentApplicable = true;
    this.renewalDataStorage.InstallmentFrequencyName = this.emiForm.controls['EMIOption'].value;
    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
  }

  //zone upgrade
  upgradeZoneValue(val) {
    this.showZoneList = val;
    this.renewalDataStorage.changeZone = val;
    if (!val) {
      this.zoneForm.controls['changeZone'].setValue("No");
      this.zoneForm.controls['revisedZone'].setValue("");
      this.zoneForm.controls['revisedZone'].clearValidators();
      this.zoneForm.controls['revisedZone'].updateValueAndValidity();
      let ZoneData = this.renewalDataStorage.Zone;
      this.renewalDataStorage.Zone = ZoneData;
      this.renewalDataStorage.RevisedZone = ZoneData;
    }
    else {
      this.zoneForm.controls['changeZone'].setValue("Yes");
      if (this.renewalDataStorage.SubProductType == 40) {
        this.renewalDataStorage.RevisedZone = GSZone.rnFetchZone1;
      }
      else {
        this.zoneForm.controls['revisedZone'].setValidators([Validators.required]);
        this.zoneForm.controls['revisedZone'].updateValueAndValidity();
        this.renewalDataStorage.RevisedZone = "";
      }
      // this.checkZoneApplicability(this.correspondanceCityZone);
      // switch (this.renewalDataStorage.SubProductType) {
      //   case 40:
      //     this.zoneList = [rnZones.zone1];
      //     break;
      //   case 42:
      //     if (this.renewalDataStorage.ZoneRefilingflag == "Yes") {
      //       this.zoneList = [HAPRefilingZone.rnFetchZone1, HAPRefilingZone.rnFetchZone2, HAPRefilingZone.rnFetchZone3]
      //       if (this.renewalDataStorage.Zone == HAPRefilingZone.rnFetchZone2) {
      //         this.zoneList = [HAPRefilingZone.rnFetchZone1];
      //       } else if (this.renewalDataStorage.Zone == HAPRefilingZone.rnFetchZone3) {
      //         this.zoneList = [HAPRefilingZone.rnFetchZone1, HAPRefilingZone.rnFetchZone2]
      //       } else {
      //         this.zoneList = [HAPRefilingZone.rnFetchZone1]
      //       }
      //     } else {
      //       if (this.renewalDataStorage.Zone == HAPZone.rnFetchZone2) {
      //         this.zoneList = [rnZones.zone1];
      //       } else if (this.renewalDataStorage.Zone == HAPZone.rnFetchZone3) {
      //         this.zoneList = [rnZones.zone1, rnZones.zone2]
      //       } else {
      //         this.zoneList = [rnZones.zone1]
      //       }
      //     }
      //     break;
      //   case 33:
      //   case 32:
      //   case 31:
      //   case 30:
      //     this.zoneList = [rnZones.zone1, rnZones.zone2, rnZones.zone3]
      //     break;
      //   default:
      //     break;
      // }
    }
  }

  // On select zone calling Recalculate api
  onSelectZone(event: any) {
    let revisedZone = event.value;
    switch (this.renewalDataStorage.SubProductType) {
      case 40:
        this.renewalDataStorage.RevisedZone = revisedZone;
        break;
      case 42:
        if (this.renewalDataStorage.ZoneRefilingflag == "Yes") {
          this.renewalDataStorage.RevisedZone = revisedZone;
        }
        else {
          this.renewalDataStorage.RevisedZone = revisedZone == rnZones.zone1 ? HAPZone.rnFetchZone1 : revisedZone == rnZones.zone2 ? HAPZone.rnFetchZone2 : (revisedZone == rnZones.zone3) ? HAPZone.rnFetchZone3 : '';
          this.zoneForm.controls['revisedZone'].patchValue(this.renewalDataStorage.RevisedZone);
        }
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        // this.renewalDataStorage.RevisedZone = (revisedZone == HEPZone.getZoneZone1) ? HEPZone.rnFetchZone1 : (revisedZone == rnZones.zone2) ? HEPZone.rnFetchZone2 : (revisedZone == rnZones.zone3) ? HEPZone.rnFetchZone3 : '';
        this.renewalDataStorage.RevisedZone = revisedZone;
        this.zoneForm.controls['revisedZone'].patchValue(this.renewalDataStorage.RevisedZone);
        break;
      case 34:
        this.renewalDataStorage.RevisedZone = revisedZone;
        this.zoneForm.controls['revisedZone'].patchValue(this.renewalDataStorage.RevisedZone);
        break;
      default:
        break;
    }
  }

  //recalcuate api
  recalculatePremium() {
    this.isRecalculated = true;
    if (this.OtherDetailsForm.valid && this.zoneForm.valid && this.renewalInsuredComponent.insuredForm.valid && this.ApplicantDetailsForm.valid) {
      let request: GetRenewalRecalculateRequestPayload = this.recalculateProposalRequest();
      this.subscription.push(this.renewalService.renewalRecalculate(request).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.isRecalculationNeeded = false;
            let data = response.Data;
            this.cibilApplicable = response.Data.IsCibilEligible;
            this.renewalDataStorage.SubSequentSIPremiums = response.Data.SubSequentSIPremiums;
            this.renewalDataStorage.InstallmentEMI = this.utilityService.isEmptyOrNull(data.InstallmentEMI) ? [] : data.InstallmentEMI;
            this.renewalDataStorage.Premium1Year = data.Premium1Year;
            this.renewalDataStorage.Premium2Year = data.Premium2Year;
            this.renewalDataStorage.Premium3Year = data.Premium3Year;
            this.premiumData = [];
            this.renewalDataStorage.CibilDiscountPercentage = response.Data.CibilDiscountPercentage

            if (this.CibilForm.controls['changeCibil'].value == 'No') {
              this.onLoadCibilFlag = false
            } else {
              this.cibilApplicable = response.Data.IsCibilEligible;
              this.onLoadCibilFlag = true
              // this.CibilForm.controls['changeCibil'].setValue('Yes')
            }
            if (this.renewalDataStorage.InstallmentApplicable) {
              this.premiumData.push(this.renewalDataStorage.Premium1Year);
              if (!this.utilityService.isEmptyOrNull(data.InstallmentEMI)) {
                this.finalSelectedPremiumData.TotalPremium = data.InstallmentEMI.InstallmentTotalPremium;
                this.finalSelectedPremiumData.BasicPremium = data.InstallmentEMI.InstallmentNetPremium;
                this.finalSelectedPremiumData.TotalTax = data.InstallmentEMI.InstallmentTaxAmount;
              }
            }
            else {
              Object.entries(this.renewalDataStorage).forEach(([key, value], index) => {
                if (this.renewalDataStorage[`Premium${index + 1}Year`])
                  this.premiumData.push(this.renewalDataStorage[`Premium${index + 1}Year`]);
              });
              this.finalSelectedPremiumData = this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`];
            }
            this.customFooterService.showRenewalFooter(this.finalSelectedPremiumData);
            let indexOfRNFetchSI = !this.utilityService.isUndefinedORNull(this.renewalDataStorage.SubSequentSIPremiums) ? this.renewalDataStorage.SubSequentSIPremiums.findIndex(x => x.SumInsured == this.renewalDataStorage.SumInsured) : 0;
            this.selectedSumInsuredIndex = this.renewalDataStorage.SubSequentSIPremiums ? indexOfRNFetchSI != -1 ? indexOfRNFetchSI : 0 : 0;
            this.setPanNoVisibility()
            if ((this.renewalDataStorage?.ZoneRefilingflag != 'Yes') && (this.renewalDataStorage?.SubProductType == 22 || this.renewalDataStorage?.SubProductType == 20 || this.renewalDataStorage?.SubProductType == 19 || (this.renewalDataStorage?.SubProductType == 42 && this.showBefit && !this.renewalDataStorage.InstallmentApplicable))) {
              if (this.befitForm.controls['befit'].value == 'Yes') {
                this.onbefitPanSelection(this.selectedCoverName);
              }
            }
          }
        }
      }))
    } else if (this.zoneForm.invalid) {
      this.scrollError(this.zoneForm.controls);
    } else if (this.OtherDetailsForm.invalid) {
      this.scrollError(this.OtherDetailsForm.controls);
    } else if (this.renewalInsuredComponent.insuredForm.invalid) {
      this.renewal_emit_service.emitinsuredFormValid(true);
    } else if (this.ApplicantDetailsForm.invalid) {
      this.scrollError(this.ApplicantDetailsForm.controls);
    }

  }

  checkZoneApplicability(zoneResponse) {
    switch (this.renewalDataStorage.SubProductType) {
      case 40:
        if (zoneResponse == GSZone.getZoneZone1) {
          this.showZoneCard = false;
        }
        else {
          this.showZoneList = false;
          this.showZoneCard = true;
        }
        break;
      case 42:
        if (this.renewalDataStorage.IsHaapRefiling) {
          if (zoneResponse == HAPRefilingZone.rnFetchZone4 || zoneResponse == HAPRefilingZone.rnFetchZone1 || !this.hideEdit) {//if (zoneResponse == HAPRefilingZone.getZoneZone4 || zoneResponse == HAPRefilingZone.getZoneZone1 || !this.hideEdit) {
            this.showZoneCard = false;
          }
          if (zoneResponse == HAPRefilingZone.rnFetchZone3) {
            this.showZoneCard = true;
            this.zoneList = [HAPRefilingZone.rnFetchZone1, HAPRefilingZone.rnFetchZone2]
          }
          if (zoneResponse == HAPRefilingZone.rnFetchZone2) {
            this.showZoneCard = true;
            this.zoneList = [HAPRefilingZone.rnFetchZone1]
          }
        }
        else {
          if (this.renewalDataStorage.IsMultiPlanPolicy || zoneResponse == HAPZone.getZoneZone1) {
            this.showZoneCard = false;
          }
          else {
            this.showZoneCard = true;
          }
        }
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        if (!this.hideEdit && zoneResponse == HEPZone.getZoneZone4) {
          this.showZoneCard = false;
        }
        else {
          this.zoneList = [HEPZone.recalculateZone1, HEPZone.recalculateZone2, HEPZone.recalculateZone3];
          this.showZoneCard = true;
        }
        break;
      case 34:
        if (!this.hideEdit && zoneResponse == MaxZone.getZone1 && zoneResponse == MaxZone.getZone4) {
          this.showZoneCard = false;
        }
        if (zoneResponse == MaxZone.getZone3) {
          this.showZoneCard = true;
          this.zoneList = [MaxZone.getZone1, MaxZone.getZone2]
        }
        if (zoneResponse == MaxZone.getZone2) {
          this.showZoneCard = true;
          this.zoneList = [MaxZone.getZone1]
        }
        break;
      default:
        break;
    }
  }

  checkForCibil(value: any) {
    this.cibilDiscount = value = true;
  }

  // recalculate request payload
  recalculateProposalRequest(): any {
    let Zone, RevisedZone;
    switch (this.renewalDataStorage.SubProductType) {
      case 40:
        if (this.zoneForm.controls['changeZone'].value == "Yes") {
          Zone = this.renewalDataStorage.Zone;
          RevisedZone = GSZone.recalculateZone1;
        } else {
          Zone = this.renewalDataStorage.Zone;
          RevisedZone = this.renewalDataStorage.RevisedZone;
        }
        break;
      case 42:
        if (this.renewalDataStorage.ZoneRefilingflag == "Yes") {
          Zone = this.renewalDataStorage.Zone;
          RevisedZone = this.renewalDataStorage.RevisedZone;
        }
        else {
          Zone = (this.renewalDataStorage.Zone == HAPZone.rnFetchZone1) ? HAPZone.recalculateZone1 : (this.renewalDataStorage.Zone == HAPZone.rnFetchZone2) ? HAPZone.recalculateZone2 : (this.renewalDataStorage.Zone == HAPZone.rnFetchZone3) ? HAPZone.recalculateZone3 : '';
          RevisedZone = (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone1) ? HAPZone.recalculateZone1 : (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone2) ? HAPZone.recalculateZone2 : (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone3) ? HAPZone.recalculateZone3 : '';
        }
        break;
      case 19:
      case 20:
        Zone = this.renewalDataStorage.Zone;
        RevisedZone = this.renewalDataStorage.RevisedZone;
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        Zone = this.renewalDataStorage.Zone;
        RevisedZone = this.renewalDataStorage.RevisedZone;
        break;
      case 34:
        Zone = this.renewalDataStorage.Zone;
        RevisedZone = this.renewalDataStorage.RevisedZone;
        break;
      default:
        break;
    }

    let adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
    let childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
    let totalmember = adultCount + childCount;
    let multiPlanDtls =
      [
        {
          SumInsured: this.PlanDetailsForm.controls['hospitalizationCoverSI'].value,
          DeductibleSI: this.renewalDataStorage.multiPlanDtls[0].DeductibleSI,
          AgeOfEldest: this.renewalDataStorage.multiPlanDtls[0].AgeOfEldest,
          AdditionalSI: this.renewalDataStorage.multiPlanDtls[0].AdditionalSI,
          NoOfMember: totalmember,
          NoOfAdults: adultCount,
          NoOfKids: childCount,
          isFloater: true,//by default
          insuredDetails: this.getInsuredDataFromForm(),
        }
      ];
    let request: any = {
      UserType: "Agent",
      PolicyNo: this.renewalDataStorage.PolicyNo,
      // ProductName: this.renewalDataStorage.ProductName,
      // isJammuAndKashmir: this.renewalDataStorage.isJammuAndKashmir,
      IsHigherSiRequired: this.renewalDataStorage.IsHigherSiRequired,
      HigherSuminsured: 0,
      IsProductSiCrossedSiLimit: this.renewalDataStorage.IsProductSiCrossedSiLimit,
      IsCentralizedDeal: this.renewalDataStorage.isCentralizedDeal,
      // PrevASI: this.renewalDataStorage.PrevASI,
      NewASI: this.renewalDataStorage.NewASI,
      PrevInsuredAmt: this.renewalDataStorage.PrevPolicySI,
      VoluntaryCopay: this.PlanDetailsForm.controls['copaymentPercentTaken'].value ? parseInt(this.PlanDetailsForm.controls['copaymentPercentTaken'].value) : '0',
      VoluntaryDeductible: this.PlanDetailsForm.controls['voluntaryDeductible'].value ? parseInt(this.PlanDetailsForm.controls['voluntaryDeductible'].value) : this.renewalDataStorage.VoluntaryDeductible,
      SumInsured: this.PlanDetailsForm.controls['hospitalizationCoverSI'].value,
      // CustomerId: this.renewalDataStorage.customerDetails.CustomerId,
      LoadingPercent: this.renewalDataStorage.loadingPercentage,
      LoadingAmount: this.renewalDataStorage.loadingDiscount.toString(),
      PrevPolicyStartDate: this.renewalDataStorage.PrevPolicyStartDate,
      PrevPolicyEndDate: this.renewalDataStorage.PrevPolicyEndDate,
      ClaimStatus: this.renewalDataStorage.ClaimStatus,
      // IsJammuKashmir: this.renewalDataStorage.isJammuAndKashmir,
      Tenure: this.renewalDataStorage.Tenure,
      RevisedZone: RevisedZone,
      Zone: Zone,
      // PrevASI: this.renewalDataStorage.SubProductType == 34 || this.renewalDataStorage.SubProductType == 42 ? undefined : this.renewalDataStorage.PrevASI,
      ZoneRefilingflag: this.renewalDataStorage.ZoneRefilingflag,
      SubProductCode: this.renewalDataStorage.SubProductType == 34 ? this.PlanDetailsForm.controls['productType'].value : this.renewalDataStorage.SubProductCode,
      CustomerStateCode: this.PermanentDetailsForm.controls['permanentStateCode'].value ? this.PermanentDetailsForm.controls['permanentStateCode'].value : 0,
      CustomerStateName: this.PermanentDetailsForm.controls['permanentState'].value,
      SubProductType: this.renewalDataStorage.IsHigherSiRequired ? this.renewalDataStorage.higherPremiumDetails.SubProductType : this.renewalDataStorage.SubProductType,
      DealId: this.renewalDataStorage.DealId,
      PEDWaitingPeriod: "2 Years",
      PostHospitalization: "180 Days",
      PreHospitalization: "60 Days",
      SpecificConditionWaiting: "2 Years",
      InstallmentApplicable: this.renewalDataStorage.InstallmentApplicable,
      InstallmentFrequency: this.renewalDataStorage.InstallmentFrequencyName,
      ProposalAnnualIncome: this.renewalDataStorage.SubProductType == 42 ? (this.ApplicantDetailsForm.controls['applicantAnnualIncome'].value).toString() : "",
      multiPlanDtls: multiPlanDtls
    }
    if (this.renewalDataStorage.SubProductType == 42) {
      request.IsCibilTaken = this.cibilDiscount;
      request.CibilDiscountRequest = {
        userName: this.ApplicantDetailsForm.controls['applicantFullName'].value,
        userPincode: this.PermanentDetailsForm.controls['permanentPincode'].value,
        userMobile: (this.PermanentDetailsForm.controls['applicantMoblieNo'].value).toString(),
      }
    }
    this.PlanDetailsForm.controls['zone'].setValue(Zone);

    if (this.renewalDataStorage.IsMultiPlanPolicy) {
      Object.assign(request, { "IsMultiPlanPolicy": this.renewalDataStorage.IsMultiPlanPolicy })
    }
    return request;
  }

  //set data
  setRenewalResponseData(data) {
    let adultCount = 0
    let childCount = 0
    let totalmember = 0
    if (!this.hideEdit) {
      adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
      childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
      totalmember = adultCount + childCount;

      this.renewalDataStorage.multiPlanDtls =
        [
          {
            SumInsured: this.PlanDetailsForm.controls['hospitalizationCoverSI'].value,
            DeductibleSI: this.renewalDataStorage.multiPlanDtls[0].DeductibleSI,
            AgeOfEldest: this.renewalDataStorage.multiPlanDtls[0].AgeOfEldest,
            AdditionalSI: this.renewalDataStorage.multiPlanDtls[0].AdditionalSI,
            NoOfMember: this.utilityService.isEmptyOrNull(totalmember) ? this.renewalDataStorage.multiPlanDtls[0].NoOfMember : totalmember,
            NoOfAdults: this.utilityService.isEmptyOrNull(adultCount) ? this.renewalDataStorage.multiPlanDtls[0].NoOfAdults : adultCount,
            NoOfKids: this.utilityService.isEmptyOrNull(childCount) ? this.renewalDataStorage.multiPlanDtls[0].NoOfKids : childCount,
            isFloater: true,//by default
            insuredDetails: this.getInsuredDataFromForm(),
          }
        ];
    }

    this.renewalDataStorage.SumInsured = this.finalSelectedPremiumData.sumInsured ? this.finalSelectedPremiumData.sumInsured : this.renewalDataStorage.SumInsured;
    this.renewalDataStorage.customerDetails.PAN = this.ApplicantDetailsForm.controls['panNumber'].value;

    this.renewalDataStorage.VoluntaryCopay = Number(this.PlanDetailsForm.controls['copaymentPercentTaken'].value);
    this.renewalDataStorage.VoluntaryDeductible = (this.renewalDataStorage?.SubProductType == 20 || this.renewalDataStorage?.SubProductType == 19) ? Number(this.renewalDataStorage.VoluntaryDeductible) : Number(this.PlanDetailsForm.controls['VoluntaryDeductibleTaken'].value);

    //nominee details
    this.renewalDataStorage.NomineeDOB = this.utilityService.formatDate(this.NomineeDetailsForm.controls['nomineeDOB'].value);
    this.renewalDataStorage.NomineeName = this.NomineeDetailsForm.controls['nomineeFullName'].value;
    this.renewalDataStorage.NomineeTitle = this.NomineeDetailsForm.controls['nomineeTitle'].value;
    this.renewalDataStorage.NomineeRelationShipId = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    let relationshipName = this.nomineeRelationshipDetails.find(x => x.RelationshipID == this.NomineeDetailsForm.controls['nomineeRelationship'].value);
    this.renewalDataStorage.NomineeRelationShip = relationshipName?.RelationshipName;
    //appointee details
    this.renewalDataStorage.AppointeeDOB = this.AppointeeDetailsForm.controls['appointeeDOB'].value;
    this.renewalDataStorage.AppointeeName = this.AppointeeDetailsForm.controls['appointeeFullName'].value;
    this.renewalDataStorage.AppointeeTitle = this.AppointeeDetailsForm.controls['appointeeTitle'].value;
    this.renewalDataStorage.AppointeeRelationShipId = this.AppointeeDetailsForm.controls['appointeeRelationship'].value;
    let appointeerelationship = this.apointeeRelationShipDetails.find(x => x.RelationshipID == this.AppointeeDetailsForm.controls['appointeeRelationship'].value);
    this.renewalDataStorage.AppointeeRelationShip = appointeerelationship?.RelationshipName;
    this.renewalDataStorage.TotalPremium = this.finalSelectedPremiumData.TotalPremium;
  }

  GetPincodeID(pinCode, cityCode) {
    this.renewalService.getPincodeID({ "Pincode": pinCode, "CityID": cityCode }).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          return response.Data
        } else {
          return '';
        }
      }
    });
  }

  proposalCreate(payMode) {
    let request: any = this.saveProposalRequest();
    this.subscription.push(this.renewalService.renewalProposal(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          let data = response.Data.policyDetails;
          let req = {
            PolicyID: data.PolicyID,
            PfProposalNumber: data.PfProposalNumber,
            totalPremium: this.finalSelectedPremiumData.TotalPremium,
          }
          if (this.befitForm.controls['befit'].value == 'Yes') {
            this.befitCreation(payMode, data);
          } else {
            this.paymentCreation(payMode, data);
          }
        }
      }
    }));
  }

  //on click paybutton
  saveEditCustomer(payMode) {
    let requestData: SaveEditCustomerRequestPayload = this.saveCustomerRequest();
    if (this.renewalDataStorage.customerDetails.CustomerType == "C") {
      this.proposalCreate(payMode);
    } else {
      this.subscription.push(this.renewalService.renewalSaveCustomer(requestData).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success) {
            let data = response.Data;
            this.proposalCreate(payMode);
          }
        }
      }));
    }


  }

  checkFor80DTax(event: any) {
    this.taxLumsum = event.value;
  }

  // saveeditproposal request payload
  saveProposalRequest() {
    let Zone, RevisedZone, ZoneUpgrade;
    switch (this.renewalDataStorage.SubProductType) {
      case 40:
        Zone = (this.renewalDataStorage.Zone == GSZone.rnFetchZone1) ? GSZone.proposalZone1 : (this.renewalDataStorage.Zone == GSZone.rnFetchZone2) ? GSZone.proposalZone2 : (this.renewalDataStorage.Zone == GSZone.rnFetchZone3) ? GSZone.proposalZone3 : '';
        RevisedZone = (this.renewalDataStorage.RevisedZone == GSZone.rnFetchZone1) ? GSZone.proposalZone1 : (this.renewalDataStorage.RevisedZone == GSZone.rnFetchZone2) ? GSZone.proposalZone2 : (this.renewalDataStorage.RevisedZone == GSZone.rnFetchZone3) ? GSZone.proposalZone3 : '';
        ZoneUpgrade = this.showZoneList;
        break;
      case 42:
        if (this.renewalDataStorage.ZoneRefilingflag == "Yes") {
          Zone = this.renewalDataStorage.Zone;
          RevisedZone = this.renewalDataStorage.RevisedZone;
        }
        else {
          Zone = (this.renewalDataStorage.Zone == HAPZone.rnFetchZone1) ? HAPZone.proposalZone1 : (this.renewalDataStorage.Zone == HAPZone.rnFetchZone2) ? HAPZone.proposalZone2 : (this.renewalDataStorage.Zone == HAPZone.rnFetchZone3) ? HAPZone.proposalZone3 : '';
          RevisedZone = (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone1) ? HAPZone.proposalZone1 : (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone2) ? HAPZone.proposalZone2 : (this.renewalDataStorage.RevisedZone == HAPZone.rnFetchZone3) ? HAPZone.proposalZone3 : '';
        }
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        Zone = this.renewalDataStorage.Zone;
        RevisedZone = this.renewalDataStorage.RevisedZone;
        break;
      case 34:
        Zone = this.renewalDataStorage.Zone;
        RevisedZone = this.renewalDataStorage.RevisedZone;
        break;
      default:
        break;
    }
    this.setRenewalResponseData(this.renewalDataStorage);
    let selectedSumInsured = this.utilityService.isEmptyOrNull(this.finalSelectedPremiumData.SumInsured) ? this.renewalDataStorage.SumInsured : this.finalSelectedPremiumData.sumInsured;
    let Tenure = this.finalSelectedPremiumData.Tenure == 0 ? this.renewalDataStorage.Tenure : this.finalSelectedPremiumData.Tenure
    if (this.renewalDataStorage.multiPlanDtls.length == 1) {
      this.renewalDataStorage.multiPlanDtls[0].SumInsured = selectedSumInsured;
    }
    let relationshipId = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    let relationshipName = this.nomineeRelationshipDetails.find(x => x.RelationshipID == relationshipId);
    let appointeeId = this.AppointeeDetailsForm.controls['appointeeRelationship'].value;
    let appointeeName = this.apointeeRelationShipDetails.find(x => x.RelationshipID == appointeeId);

    let adultCount = 0;
    let childCount = 0;
    let totalmember = 0;
    let multiPlanDtls = []
    if (!this.hideEdit) {
      let adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
      let childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
      let totalmember = adultCount + childCount;
      multiPlanDtls =
        [
          {
            SumInsured: this.PlanDetailsForm.controls['hospitalizationCoverSI'].value,
            DeductibleSI: this.renewalDataStorage.multiPlanDtls[0].DeductibleSI,
            AgeOfEldest: this.renewalDataStorage.multiPlanDtls[0].AgeOfEldest,
            AdditionalSI: this.renewalDataStorage.multiPlanDtls[0].AdditionalSI,
            NoOfMember: totalmember,
            NoOfAdults: adultCount,
            NoOfKids: childCount,
            isFloater: true,//by default
            insuredDetails: this.getInsuredDataFromForm(),
          }
        ];
    }
    let request: any = {
      CustomerId: this.renewalDataStorage.customerDetails.IpartnerCustomerId,
      DealId: this.renewalDataStorage.DealId,
      IpAddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      IsCombo: false,
      IsGHD: "false",
      IsJammuKashmir: this.renewalDataStorage.isJammuAndKashmir.toString(),
      IsSoftCopyDiscountApplicable: false,
      LoadingAmount: this.renewalDataStorage.loadingDiscount,
      LoadingPercent: this.renewalDataStorage.loadingPercentage,
      VoluntaryCopay: this.renewalDataStorage.VoluntaryCopay,
      multiPlanDtls: !this.hideEdit ? multiPlanDtls : this.renewalDataStorage.multiPlanDtls,
      NewASI: this.renewalDataStorage.NewASI,
      NomineeDOB: this.NomineeDetailsForm.controls['nomineeDOB'].value,
      NomineeName: this.NomineeDetailsForm.controls['nomineeFullName'].value,
      NomineeRelationshipId: this.NomineeDetailsForm.controls['nomineeRelationship'].value,
      NomineeRelationshipName: relationshipName.RelationshipName,
      NomineeTitle: this.NomineeDetailsForm.controls['nomineeTitle'].value,
      NoOfPlans: this.renewalDataStorage.NoOfPlans,
      PfCustomerId: this.renewalDataStorage.customerDetails.CustomerId,
      PfProposalNo: this.renewalDataStorage.PfProposalNo,
      PolicyNo: this.renewalDataStorage.PolicyNo,
      PremiumRecievedFromCustomer: (this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalPremium).toString(), //selectedTotalPremium.toString(),
      // PrevASI: this.renewalDataStorage.PrevASI,
      PreviousPolicyPremium: "",
      PrevNCBPercentage: "0.0",
      ZoneUpgrade: ZoneUpgrade, //only for gs
      PrevPolicyEndDate: this.renewalDataStorage.PrevPolicyEndDate,
      PrevPolicySI: this.renewalDataStorage.PrevPolicySI,
      PrevPolicyStartDate: this.renewalDataStorage.PrevPolicyStartDate,
      Product24: this.taxLumsum,
      RevisedZone: RevisedZone,
      SubLimit: this.renewalDataStorage.SubLimit,
      SubProductCode: this.renewalDataStorage.SubProductType == 34 ? this.PlanDetailsForm.controls['productType'].value : this.renewalDataStorage.SubProductCode,
      SubProductType: this.renewalDataStorage.SubProductType == 30 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 32 ? this.renewalDataStorage.SubProductType = 33 : this.renewalDataStorage.SubProductType,
      SumInsured: selectedSumInsured,
      Tenure: Tenure,
      TotalPremium: this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalPremium,//selectedTotalPremium,
      TotalTax: this.renewalDataStorage[`Premium${this.selectedCoverageIndex + 1}Year`].TotalTax,//TotalTax,
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      // VoluntaryDeductibleSI: this.renewalDataStorage.VoluntaryDeductible,
      VoluntaryDeductible: this.renewalDataStorage.VoluntaryDeductible,
      Zone: Zone,
      ZoneRefilingflag: this.renewalDataStorage.ZoneRefilingflag,
      PostHospitalization: "180 Days",
      PreHospitalization: "60 Days",
      SpecificConditionWaiting: "2 Years",
      PEDWaitingPeriod: "2 Years",
      CKYCId: this.renewalDataStorage.CKYCID, //ADDED NEW KEYS COMMONLY
      EKYCid: this.renewalDataStorage.EKYCID, //ADDED NEW KEYS COMMONLY
      PEPFlag: this.renewalDataStorage.customerDetails.PEPFLAG, //ADDED NEW KEYS COMMONLY
      ILKYCReferenceNumber: this.renewalDataStorage.ILkycReferenceNumber, //ADDED NEW KEYS COMMONLY
      InstallmentApplicable: this.renewalDataStorage.InstallmentApplicable,
      InstallmentFrequency: this.renewalDataStorage.InstallmentFrequencyName,
      InstallmentEMI: this.renewalDataStorage.InstallmentEMI.length == 0 ? {} : this.renewalDataStorage.InstallmentEMI,
      ProposalAnnualIncome: this.renewalDataStorage.SubProductType == 42 ? (this.ApplicantDetailsForm.controls['applicantAnnualIncome'].value).toString() : "",
      ...(this.showAppointee
        ? {
          AppointeeDOB: this.AppointeeDetailsForm.controls['appointeeDOB'].value,
          AppointeeName: this.AppointeeDetailsForm.controls['appointeeFullName'].value,
          AppointeeRelationshipId: this.AppointeeDetailsForm.controls['appointeeRelationship'].value,
          AppointeeRelationShip: appointeeName.RelationshipName,
          AppointeeTitle: this.AppointeeDetailsForm.controls['appointeeTitle'].value,
        }
        : {}
      ),
    }
    if (this.renewalDataStorage.SubProductType != 34) {
      request.VoluntaryDeductible = this.renewalDataStorage.VoluntaryDeductible;
      request.ZoneUpgrade = ZoneUpgrade; //only for gs
    } else {
      request.BasicPremium = this.finalSelectedPremiumData.BasicPremium;
      request.ClaimStatus = this.renewalDataStorage.ClaimStatus;
      request.ComboLanNo = '';
      request.PrevASI = this.renewalDataStorage.PrevASI;
      request.PreviousPolicies = {
        InsurerCompany: '',// send null always
        PolicyNo: this.renewalDataStorage.PolicyNo,
        PolicyStartDate: this.renewalDataStorage.PrevPolicyStartDate,
        PolicyEndDate: this.renewalDataStorage.PrevPolicyEndDate,
      }
      request.CustomerDetails = {
        CustomerType: "Individual",//need to  check by siddhi
        CustomerName: this.renewalDataStorage.customerDetails.CustomerName,
        DateOfBirth: this.utilityService.dateformate(this.ApplicantDetailsForm.controls['applicantDOB'].value.replaceAll('-', '/')),
        PinCode: this.correspondenceDetailsForm.controls['permanentPincode'].value,
        PANCardNo: this.renewalDataStorage.customerDetails.PAN,
        Email: this.PermanentDetailsForm.controls['applicantEmailAddress'].value,
        MobileNumber: this.PermanentDetailsForm.controls['applicantMoblieNo'].value,
        AddressLine1: this.PermanentDetailsForm.controls['permanentAddress1'].value,
        AddressLine2: this.PermanentDetailsForm.controls['permanentAddress2'].value,
        CountryCode: 100,// always 100 will be value
        StateCode: this.PermanentDetailsForm.controls['permanentStateCode'].value,
        CityCode: this.PermanentDetailsForm.controls['permanentCityCode'].value,
        Gender: this.renewalDataStorage.customerDetails.Gender,
        MobileISD: '91',
        AadharNumber: '',// conffirm by siddhi
        IsCollectionofform60: false,
        AadharEnrollmentNo: null,//AadharEnrollmentNo
        eIA_Number: null,//AadharEnrollmentNo
        CorelationId: '',//need to pass
        CustomerID: 0,
        // CKYCId: this.renewalDataStorage.customerDetails.CKYCID,
        // EKYCid: this.renewalDataStorage.customerDetails.EKYCID,
        // PEPFlag: this.renewalDataStorage.customerDetails.PEPFLAG,
        // ILKYCReferenceNumber: this.renewalDataStorage.ILkycReferenceNumber,
        CorrespondingAddress: {
          "AddressLine1": this.correspondenceDetailsForm.controls['correspondenceAddress1'].value,
          "AddressLine2": this.correspondenceDetailsForm.controls['correspondenceAddress2'].value,
          "CountryCode": 100,// always 100 will be value
          "StateCode": this.correspondenceDetailsForm.controls['correspondenceStateCode'].value.toString(),
          "CityCode": this.correspondenceDetailsForm.controls['correspondenceCityCode'].value.toString(),
          "Pincode": this.correspondenceDetailsForm.controls['correspondencePincode'].value.toString()
        },
        DateOfIncorporation: null,
        SourceOfFunds: null,
        OtherFunds: null,
        CIN: null
      }
      if (this.OtherDetailsForm.controls['registeredGST'].value == 'Yes') {
        let GSTDetails = {
          "GSTExemptionApplicable": 'yes',
          "GSTInNumber": this.OtherDetailsForm.controls['GSTINNo'].value,
          "GSTToState": 'MAHARASHTRA',
          "ConstitutionOfBusiness": this.OtherDetailsForm.controls['constitutionBusiness'].value,
          "CustomerType": this.OtherDetailsForm.controls['customerType'].value,
          "PanDetails": this.OtherDetailsForm.controls['panNo'].value,
          "GSTRegistrationStatus": this.OtherDetailsForm.controls['gstRegistrationStatus'].value
        }
        request.CustomerDetails.GSTDetails = GSTDetails;
      } else {
        request.CustomerDetails.GSTDetails = null
      }
    }
    if (this.renewalDataStorage.SubProductType == 42) {
      request.CibilDiscountPercentage = this.renewalDataStorage.CibilDiscountPercentage;
    }
    return request;
  }
  saveCustomerRequest(): any {
    let requestData = {
      "CustomerID": 0, // no point of sending this,
      "PfCustomerID": !this.utilityService.isEmptyOrNull(this.renewalDataStorage.customerDetails.CustomerId) ? this.renewalDataStorage.customerDetails.CustomerId : '',
      "HasAddressChanged": true,// This is going by default true
      "SetAsDefault": true,// This is going by default true
      "TitleText": this.renewalDataStorage.customerDetails.Gender == 'Male' ? 'Mr.' : 'Mrs.',
      "TitleValue": this.renewalDataStorage.customerDetails.Gender == 'Male' ? '1' : '0',
      "Name": this.ApplicantDetailsForm.controls['applicantFullName'].value,
      "DateOfBirth": this.utilityService.dateformate(this.ApplicantDetailsForm.controls['applicantDOB'].value.replaceAll('-', '/')),
      "MaritalStatusValue": this.PermanentDetailsForm.controls['maritalStatus'].value ? (this.PermanentDetailsForm.controls['maritalStatus'].value == 'MARRIED' ? '2' : '1') : '-1',
      "MaritalStatusText": this.PermanentDetailsForm.controls['maritalStatus'].value ? this.PermanentDetailsForm.controls['maritalStatus'].value : '',
      // Unused params starts
      "OccupationValue": 0,
      "OccupationText": "",
      "AnnualIncomeValue": 0,
      "AnnualIncomeText": "",
      "IdentityProofText": "",
      "IdentityNumber": "",
      "IdentityProofValue": 0,
      // unused params end
      "PresentAddrLine1": this.correspondenceDetailsForm.controls['correspondenceAddress1'].value,
      "PresentAddrLine2": this.correspondenceDetailsForm.controls['correspondenceAddress2'].value,
      "PresentAddrLandmark": '',
      "PresentAddrCityValue": this.correspondenceDetailsForm.controls['correspondenceCityCode'].value,
      "PresentAddrCityText": this.correspondenceDetailsForm.controls['correspondenceCity'].value,
      "PresentAddrStateValue": this.correspondenceDetailsForm.controls['correspondenceStateCode'].value,
      "PresentAddrStateText": this.correspondenceDetailsForm.controls['correspondenceState'].value,
      "PresentAddrPincode": this.correspondenceDetailsForm.controls['correspondencePincode'].value,
      "EmailAddress": this.PermanentDetailsForm.controls['applicantEmailAddress'].value,
      "MobileNumber": this.PermanentDetailsForm.controls['applicantMoblieNo'].value,
      "LandlineNumber": null, // not required,
      "PANNumber": this.ApplicantDetailsForm.controls['panNumber'].value ? this.ApplicantDetailsForm.controls['panNumber'].value : '',
      "isPermanentAsPresent": false,
      "PermanentAddrLine1": this.PermanentDetailsForm.controls['permanentAddress1'].value,
      "PermanentAddrLine2": this.PermanentDetailsForm.controls['permanentAddress2'].value,
      "PermanentAddrLandmark": this.PermanentDetailsForm.controls['permanentAddressLandmark'].value,
      "PermanentAddrPincode": this.PermanentDetailsForm.controls['permanentPincode'].value,
      "PermanentAddrStateValue": this.PermanentDetailsForm.controls['permanentStateCode'].value,
      "PermanentAddrStateText": this.PermanentDetailsForm.controls['permanentState'].value,
      "PermanentAddrCityValue": this.PermanentDetailsForm.controls['permanentCityCode'].value,
      "PermanentAddrCityText": this.PermanentDetailsForm.controls['permanentCity'].value,
      // ends
      "isGSTINApplicable": this.OtherDetailsForm.controls['registeredGST'].value == 'Yes' ? true : false,
      "CKYCID": this.renewalDataStorage["CKYCID"], //need to add
      "SkipDedupeLogic": "true", // by default,
      "pepFlag": this.renewalDataStorage.customerDetails.PEPFLAG, //need to add
      "ilkycReferenceNumber": this.renewalDataStorage["ILkycReferenceNumber"], //need to add
      "EKYCid": this.renewalDataStorage["EKYCID"],
      "GSTDetails": {}
    }

    if (this.OtherDetailsForm.controls['registeredGST'].value == 'Yes') {
      let GSTDetails = {
        "CONSTITUTION_OF_BUSINESS_TEXT": this.OtherDetailsForm.controls['constitutionBusiness'].value,
        "CUSTOMER_TYPE_TEXT": this.OtherDetailsForm.controls['customerType'].value,
        "PAN_NO": this.OtherDetailsForm.controls['panNo'].value,
        "GST_REGISTRATION_STATUS_TEXT": this.OtherDetailsForm.controls['gstRegistrationStatus'].value,
        "GSTIN_NO": this.OtherDetailsForm.controls['GSTINNo'].value,
        "GST_STATE_NAME": ''
      }
      requestData.GSTDetails = GSTDetails;
    }
    return requestData;

  }

  buttonSelection(buttonData: EmitButtonData) {
    switch (buttonData['label']) {
      case 'Pay Now':
      case 'PayNow': {
        this.submit('PayNow');
        break;
      }
      case 'Pay Later':
      case 'PayLater':
        {
          this.submit('PayLater');
          break;
        }
      case 'Payment Link':
      case 'PaymentLink': {
        this.submit('PaymentLink');
        break;
      }
      case 'Recalculate': {
        this.recalculatePremium();
      }

    }
  }

  paymentCreation(payMode, req) {
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: this.befitForm.controls['befit'].value == 'Yes' ? true : false,
      homePlan: false,
      zeroTatProduct: false,
      proposalPolicyID: req.PolicyID.toString(),
      bundalPolicyID: req.bundlePolicyID,
      totalPremium: req.totalPremium,
      proposalNumber: req.PfProposalNumber,
      bundleProposalNumber: req.bundleProposalNumber,
    }
    switch (payMode) {
      case 'PaymentLink': {
        //payment service
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
      case 'PayNow': {
        //payment service 
        this.paymode_payment_service.CreateBundlePayment(data);
        break;
      }
      case 'PayLater': {
        //payment service
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
    }
  }

  constructBefitPlanPremium(pincode) {
    let request1 = {
      pincode: pincode,
      SubProductCode: this.renewalDataStorage.SubProductCode,
      SumInsured: String(this.renewalDataStorage.SumInsured),
      ProductCode: this.renewalDataStorage.ProductCode
    }
    return request1
  }


  //show plans
  onCheckBefit(value) {
    this.bifitCovers = [];
    this.BefitSelectedPremium = 0;
    this.BenefitDiscription = [];
    this.selectedCoverId = 0;
    if (value == 'Yes') {
      this.befitForm.controls['befit'].patchValue('Yes');
      let item = [];
      this.renewalDataStorage.befitCovers.forEach((x: any) => {
        if (!x.IsHMP) {
          item.push(x);
        }
      })
      this.bifitCovers = item;
    } else {
      this.befitForm.controls['befit'].patchValue('No');
      this.finalSelectedPremiumData.OptionalCovers = 0;
      this.customFooterService.showRenewalFooter(this.finalSelectedPremiumData);
    }
  }

  //HMP checked and unchecked condition
  checkHMPCondition(value) {
    let item = [];
    this.BefitSelectedPremium = 0;
    this.BenefitDiscription = [];
    this.bifitCovers = [];
    this.selectedCoverId = 0;
    if (value.checked) {
      this.renewalDataStorage.befitCovers.forEach((x: any) => {
        if (x.IsHMP) {
          item.push(x);
        }
      })
    } else {
      this.renewalDataStorage.befitCovers.forEach((x: any) => {
        if (!x.IsHMP) {
          item.push(x);
        }
      })
    }
    this.bifitCovers = item;
  }

  constructionBefitPlanPremium() {
    let adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
    let childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
    let request1 = {
      AgeOfEldestMember: 28,
      CoverId: this.selectedCoverId,
      NoOfAdult: adultCount,
      NoOfKid: childCount,
      Tenure: this.renewalDataStorage.Tenure,
    }
    return request1
  }

  //on plan selection
  onbefitPanSelection(value) {
    this.selectedCoverName = value;
    this.renewalDataStorage.befitCovers.forEach((x: any) => {
      if (x.CoverName == value) {
        this.selectedCoverId = x.CoverId;
        this.BenefitDiscription = x.befitCoverBenefits
      }
    })
    let request1 = this.constructionBefitPlanPremium();
    this.subscription.push(this.healthservice.getBefitPlanPremiumDetails(request1).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.BefitSelectedPremium = response.Data.TotalPremium;
        this.befitResponse = response;
        this.finalSelectedPremiumData.OptionalCovers = this.BefitSelectedPremium;
        this.customFooterService.showRenewalFooter(this.finalSelectedPremiumData);
      }
      else {
        this.befitForm.controls['befit'].patchValue("No");
        this.BenefitDiscription = [];
        this.finalSelectedPremiumData.OptionalCovers = 0;
        this.customFooterService.showRenewalFooter(this.finalSelectedPremiumData);
      }
    }))
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  //befit proposal
  befitCreation(paymode, policyData) {
    let request: CreateBefitProposalRequestPayload = this.constructCreateBefit(policyData);
    this.subscription.push(this.wrapperService.createBefitProposal(request).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.zeroTatResponseBefit = response;
        this.savehealthPolicydetails(paymode, policyData);
      }
    }))
  }

  savehealthPolicydetails(paymode, policyData) {
    let request: SavehealthPolicyRequestPayload = this.constructPolicyDetails(paymode, policyData);
    this.subscription.push(this.healthservice.savehealthPolicyDetails(request).subscribe(response => {
      if (response.StatusCode == Constants.statusCode_success) {
        this.saveHeathRes = response;
        let req = {
          PolicyID: policyData.PolicyID,
          PfProposalNumber: policyData.PfProposalNumber,
          totalPremium: this.finalSelectedPremiumData.TotalPremium,
          bundlePolicyID: response.Data.PolicyId,
          bundleProposalNumber: response.Data.PfProposalNo,
        }
        this.paymentCreation(paymode, req);
      }
      else {
        this.spinner.hide();
      }
    }))
  }

  constructPolicyDetails(paymode, policyData): SavehealthPolicyRequestPayload {
    let adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
    let childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
    let requestData: SavehealthPolicyRequestPayload =
    {
      "PayMode": paymode.toLowerCase(),
      "correlation": this.zeroTatResponseBefit.Data.correlationId,
      "pfProposalNo": this.zeroTatResponseBefit.Data.proposalNumber,
      "userType": this.utilityService.isEmptyOrNull(this.channelData) ? this.renewalDataStorage.customerDetails.CustomerType : this.channelData.UT,
      "noOfAdults": adultCount,
      "noOfKids": childCount,
      "sumInsured": this.renewalDataStorage.SumInsured.toString(),
      "tenure": this.finalSelectedPremiumData.Tenure,
      "dealID": this.renewalDataStorage.DealId,
      "policyType": "2",
      "subPolicyType": "37",
      "policyStartDate": policyData.PolicyStartDate,
      "policyEndDate": policyData.PolicyEndDate,
      "basicPremium": this.befitResponse.Data.BasicPremium,//home proposal left
      "totalTax": this.befitResponse.Data.TotalTax,
      "totalPremium": this.befitResponse.Data.TotalPremium, // home proposal left
      "members": [],
      "ipaddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "titleId": this.renewalDataStorage.customerDetails.Gender == 'Male' ? '1' : '0',//left from insured page
      "customerID": !this.utilityService.isEmptyOrNull(this.renewalDataStorage.customerDetails.CustomerId) ? parseInt(this.renewalDataStorage.customerDetails.CustomerId) : 0,
      "customerName": this.renewalDataStorage.customerDetails.CustomerName,
      "customerDOB": this.utilityService.dateformate(this.renewalDataStorage.customerDetails.DOB),
      "customerType": this.renewalDataStorage.customerDetails.CustomerType,
      "emailID": this.renewalDataStorage.customerDetails.EmailId,
      "mobileNo": this.renewalDataStorage.customerDetails.MOBILENUMBER,
      "address": this.renewalDataStorage.customerDetails.PERMANENTADDRESSLINE1,
      "pincode": this.renewalDataStorage.customerDetails.MAILINGPINCODE,
      "cityID": this.renewalDataStorage.customerDetails.MAILINGCITYDISTRICT,
      "stateID": this.renewalDataStorage.customerDetails.MAILINGSTATE_CODE,
      "countryId": parseInt(this.renewalDataStorage.customerDetails.MAILINGCOUNTRY_CD),
      "permanentAddress": this.renewalDataStorage.customerDetails.MAILINGADDRESSLINE1,
      "permanentAddrPincodeID": this.renewalDataStorage.customerDetails.PERMANENTPINCODE,
      "permanentAddrStateID": this.renewalDataStorage.customerDetails.PERMANENTSTATECODE,
      "permanentAddrCityID": this.renewalDataStorage.customerDetails.PERMANENTCITYCODE,
      "pfCustomerId": policyData.PfCustomerId,//this.renewalDataStorage.customerDetails.CustomerId,
      "BancaToken": ''
    }
    this.renewalDataStorage.multiPlanDtls[0].insuredDetails.forEach(elem => {
      let gender = (elem.TitleId == 1 ? "Male" : elem.TitleId == 21 ? "Third gender" : "Female");
      let memberType = elem.KidAdultType == 2 ? 'adult' : 'child'
      requestData.members.push({
        "memberType": memberType, "titleID": elem.TitleId, "name": elem.InsuredName, "relationshipID": elem.RelationShipID.toString(), "relationshipName": elem.RelationShipName, "dob": elem.DateofBirth,
        "height": (elem.Height).toString(), "weight": elem.Weight.toString(), "otherDisease": null, "addOnAgeGroup": null, "addOnName": null, "gender": gender,
        "planID": this.befitResponse.Data.PlanCode, "policyValidFrom": requestData.policyStartDate, "policyValidTill": requestData.policyEndDate, "sumInsuredValue": 0, "sumInsured": 0
      });
    })
    return requestData;
  }

  constructCreateBefit(policyData): CreateBefitProposalRequestPayload {
    let relationshipId = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    let relationshipName = this.nomineeRelationshipDetails.find(x => x.RelationshipID == relationshipId)
    let postData: CreateBefitProposalRequestPayload = {
      "BaseProductProposalNo": policyData.PfProposalNumber,
      "CorrelationId": this.utilitySerice.createCorrelationId(),
      "CustomerDetails":
      {
        "AddressLine1": this.renewalDataStorage.customerDetails.MAILINGADDRESSLINE1,
        "CityCode": this.renewalDataStorage.customerDetails.MAILINGSTATE_CODE,
        "CustomerID": this.renewalDataStorage.customerDetails.CustomerId,
        "CustomerName": this.renewalDataStorage.customerDetails.CustomerName,
        "DateOfBirth": this.utilitySerice.formateDateWithMonth(this.renewalDataStorage.customerDetails.DOB),
        "Email": this.renewalDataStorage.customerDetails.EmailId,
        "Gender": this.renewalDataStorage.customerDetails.Gender,
        "MobileNumber": this.renewalDataStorage.customerDetails.MOBILENUMBER,
        "PANCardNo": this.renewalDataStorage.customerDetails.PAN,
        "PinCode": this.renewalDataStorage.customerDetails.MAILINGPINCODE,
        "StateCode": this.renewalDataStorage.customerDetails.MAILINGSTATE_CODE,
      },
      "DealId": this.renewalDataStorage.DealId,
      "ILProductCode": this.befitResponse.Data.ILProductCode,
      "PolicyStartDate": this.utilitySerice.formatDateWithDash(policyData.PolicyStartDate),
      "PolicyEndDate": this.utilitySerice.formatDateWithDash(policyData.PolicyEndDate),
      "PolicyTenure": policyData.Tenure,
      "SubProductCode": this.befitResponse.Data.ProductCode,
      "riskDetails": {
        "InwardDate": this.utilityService.formatDateWithDash(new Date()),
        "NomineeDOB": this.utilityService.formatDate(this.NomineeDetailsForm.controls['nomineeDOB'].value),
        "NomineeName": this.NomineeDetailsForm.controls['nomineeFullName'].value,
        "NomineeRelationship": relationshipName.RelationshipName,
        "PlanCode": this.befitResponse.Data.PlanCode,
        "RisksList": [
          {
            "SumInsured": Number(this.befitResponse.Data.SumInsured),
            "coverDetailsList": [{
              "CoverDescription": this.selectedCoverName,
              "SumInsured": Number(this.befitResponse.Data.SumInsured),
            }]
          }]
      },
      "insuredDataDetails": this.insuredDetails(),
    }
    return postData;
  }

  insuredDetails() {
    let memberDetails = [];
    if (this.renewalDataStorage.multiPlanDtls.length > 0) {
      if (this.renewalDataStorage.multiPlanDtls[0].insuredDetails.length > 0) {
        this.renewalDataStorage.multiPlanDtls[0].insuredDetails.forEach((data, index) => {
          let tempMemberObj = {
            'InsuredName': data['InsuredName'],
            'InsuredRelation': data['RelationShipName'],
            'InsuredAge': data['InsuredAge'] ? data['InsuredAge'] : this.calculateAge(data['DateofBirth']),
            'InsuredDOB': this.utilitySerice.formatDateWithDash(data['DateofBirth']),
            'InsuredGender': (data['TitleId'] == 1 ? "Male" : data['TitleId'] == 21 ? "Third gender" : "Female"),
            'HeightInFeet': data['HeightInFeet'],
            'HeightInInches': data['HeightInInches'],
            'Weight': data['Weight'],
            'AddOnCoverDetails': data['NewAddOnCoverDetails']
          }
          memberDetails.push(tempMemberObj);
        })
      }
    }
    return memberDetails;
  }

  calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);
    const timeDiff = today.getTime() - birthDate.getTime();
    const ageInMilliseconds = timeDiff;

    // Calculate the age in years
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 3600 * 24 * 365.25));

    return ageInYears;
  }

  deletePlan(index: any) {
    if (index > -1) {
      let popupData: PopupModal = {
        popupType: popupType.confirmation,
        imgName: '',
        header: popupHeader.confirmdelete,
        description: popupDescriptionMsg.planDeleteDescription,
        buttonLabel: '',
        buttonAction: ''
      }
      this.popupService.openGenericPopup(popupData);
      let deleteSubscribe = this.popupService.delete$
        .subscribe(status => {
          if (status) {
            this.renewalDataStorage.multiPlanDtls.splice(index, 1);
            this.recalculatePremium();
          }
          deleteSubscribe.unsubscribe();
        });
    }
  }

  onRefresh(status) {
    if (status) {
      this.ngOnInit();
    }
  }

  openDeclarationPopup(declarationType: string) {
    let data = {
      rnAgent: this.rnAgent
    }

    this.declarationService.openDeclarationPopup(declarationType, data);
  }

  correspondenceAddresscheckbox() {
    this.renewalStorageService.correspondenceCheckbox = this.PermanentDetailsForm.controls['correspondenceCheckbox'].value;
    if (!this.PermanentDetailsForm.controls['correspondenceCheckbox'].value) {
      this.correspondenceDetailsForm.reset();
      this.correspondenceCityData = [];
      this.correspondenceDetailsForm.get('correspondenceAddress1').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondenceAddress1').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondencePincode').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondencePincode').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondenceStateCity').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondenceStateCity').updateValueAndValidity();
    } else {
      this.correspondenceDetailsForm.controls['correspondenceAddress1'].setValue(this.renewalDataStorage.customerDetails.PERMANENTADDRESSLINE1);
      this.correspondenceDetailsForm.controls['correspondenceAddress2'].setValue(this.renewalDataStorage.customerDetails.PERMANENTADDRESSLINE2);
      this.correspondenceDetailsForm.controls['correspondencePincode'].setValue(this.renewalDataStorage.customerDetails.PERMANENTPINCODE);
      this.correspondenceDetailsForm.controls['correspondenceCity'].setValue(this.renewalDataStorage.customerDetails.PERMANENTCITYDISTRICT);
      this.correspondenceDetailsForm.controls['correspondenceCityCode'].setValue(this.renewalDataStorage.customerDetails.PERMANENTCITYCODE);
      this.correspondenceDetailsForm.controls['correspondenceState'].setValue(this.renewalDataStorage.customerDetails.PERMANENTSTATE);
      this.correspondenceDetailsForm.controls['correspondenceStateCode'].setValue(this.renewalDataStorage.customerDetails.PERMANENTSTATECODE);
      this.correspondenceDetailsForm.get('correspondenceAddress1').clearValidators();
      this.correspondenceDetailsForm.get('correspondenceAddress1').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondencePincode').clearValidators();
      this.correspondenceDetailsForm.get('correspondencePincode').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondenceStateCity').clearValidators();
      this.correspondenceDetailsForm.get('correspondenceStateCity').updateValueAndValidity();

      if (this.renewalDataStorage.SubProductType == 30 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 32 || this.renewalDataStorage.SubProductType == 33) {
        this.getAddonList(this.renewalDataStorage.SumInsured, this.PermanentDetailsForm.controls['permanentPincode'].value);
      }
      if (this.renewalDataStorage.SubProductType == 42) {
        this.constructBefit(this.PermanentDetailsForm.controls['permanentPincode'].value);
      }
      this.getZoneFromCity();
    }
    this.correspondenceDetailsForm.updateValueAndValidity();
  }

  changeGST(value) {
    if (value == 'No') {
      this.renewalDataStorage.customerDetails.GSTInData = [];
      this.OtherDetailsForm.get('constitutionBusiness').clearValidators();
      this.OtherDetailsForm.get('constitutionBusiness').patchValue("");
      this.OtherDetailsForm.get('constitutionBusiness').updateValueAndValidity();
      this.OtherDetailsForm.get('panNo').clearValidators();
      this.OtherDetailsForm.get('panNo').patchValue("");
      this.OtherDetailsForm.get('panNo').updateValueAndValidity();
      this.OtherDetailsForm.get('gstRegistrationStatus').clearValidators();
      this.OtherDetailsForm.get('gstRegistrationStatus').patchValue("");
      this.OtherDetailsForm.get('gstRegistrationStatus').updateValueAndValidity();
      this.OtherDetailsForm.get('customerType').clearValidators();
      this.OtherDetailsForm.get('customerType').patchValue("");
      this.OtherDetailsForm.get('customerType').updateValueAndValidity();
      this.OtherDetailsForm.get('GSTINNo').clearValidators();
      this.OtherDetailsForm.get('GSTINNo').patchValue("");
      this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
      this.OtherDetailsForm.get('UINNo').clearValidators();
      this.OtherDetailsForm.get('UINNo').patchValue("");
      this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
      this.OtherDetailsForm.updateValueAndValidity();
    } else {
      this.OtherDetailsForm.get('constitutionBusiness').setValidators([Validators.required]);
      this.OtherDetailsForm.get('constitutionBusiness').updateValueAndValidity();
      this.OtherDetailsForm.get('panNo').setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
      this.OtherDetailsForm.get('panNo').updateValueAndValidity();
      this.OtherDetailsForm.get('gstRegistrationStatus').setValidators([Validators.required]);
      this.OtherDetailsForm.get('gstRegistrationStatus').updateValueAndValidity();
      this.OtherDetailsForm.get('customerType').setValidators([Validators.required]);
      this.OtherDetailsForm.get('customerType').updateValueAndValidity();
      value = this.OtherDetailsForm.value.GSTINOrUIN;
      if (value == 'GSTIN') {
        this.OtherDetailsForm.get('GSTINNo').setValidators([Validators.required, Validators.pattern(patterns.GSTIN)]);
        this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
        this.OtherDetailsForm.get('UINNo').clearValidators();
        this.OtherDetailsForm.get('GSTINNo').patchValue(this.renewalDataStorage.customerDetails.GSTInData[0]?.GSTINNo);
        this.OtherDetailsForm.get('UINNo').patchValue('');
        this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
      }
      if (value == 'UIN') {
        this.OtherDetailsForm.get('UINNo').setValidators([Validators.required, Validators.pattern(patterns.UIN)]);
        this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
        this.OtherDetailsForm.get('GSTINNo').clearValidators();
        this.OtherDetailsForm.get('GSTINNo').patchValue('');
        this.OtherDetailsForm.get('UINNo').patchValue(this.renewalDataStorage.customerDetails.GSTInData[0]?.UINNo);
        this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
      }
    }
  }

  VDvalue(value) {
    if (parseInt(value) >= 2500000 && this.renewalDataStorage.InstallmentApplicable) {
      window.scrollTo(0, 0);
      this.errorPopupData = this.popupService.fetchErrorPopupMsg("You are not eligible for EMI");
      this.popupService.openGenericPopup(this.errorPopupData);
      this.emiForm.controls['EMIApplicable'].setValue("No");
      this.renewalDataStorage.InstallmentApplicable = false;
      this.renewalDataStorage.InstallmentFrequencyName = '';
      this.renewalDataStorage.InstallmentEMI = {};
      this.showEMIOptionList = false;
      this.emiForm.controls['EMIOption'].setValue(this.renewalDataStorage.InstallmentFrequencyName);
    }
    if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hep || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.he || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hsp || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hs) {
      this.getAddonList(this.renewalDataStorage.SumInsured, this.renewalDataStorage.customerDetails.MAILINGPINCODE);
    }

    this.renewalDataStorage.SumInsured = value;
    if (this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hep || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.he || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hsp || this.renewalDataStorage.SubProductType == this.renewalDataModal.subProductType.hs) {
      this.getAddonList(this.renewalDataStorage.SumInsured, this.renewalDataStorage.customerDetails.MAILINGPINCODE);
    }
    this.renewalDataStorage?.SumInsuredDetails.forEach(x => {
      if (x.SumAmount == value) {
        let data = {
          'vdValue': x.VDValues,
          'SI': x.SumAmount
        }
        this.renewal_emit_service.emitVDValueInsuredAddonData(data);
      }
    })

  }

  coPaymentPercentTaken(value) {
    this.PlanDetailsForm.controls['copaymentPercentTaken'].setValue(value);
    this.PlanDetailsForm.controls['VoluntaryDeductibleTaken'].setValue('0');
  }

  VoluntaryDeductibleTaken(value) {
    this.PlanDetailsForm.controls['VoluntaryDeductibleTaken'].setValue(value);
    this.PlanDetailsForm.controls['copaymentPercentTaken'].setValue('0');
  }

  addonCopayChangeEvent(copay) {
    this.PlanDetailsForm.controls['copaymentPercentTaken'].patchValue(String(copay));
    this.PlanDetailsForm.controls['VoluntaryDeductibleTaken'].setValue('0');
    this.renewalDataStorage.VoluntaryCopay = copay.toString();
  }

  getAddonList(sumInsured, pincode) {
    sumInsured = this.utilityService.isEmptyOrNull(sumInsured) ? this.renewalDataStorage.SumInsured : sumInsured;
    pincode = this.utilityService.isEmptyOrNull(pincode) ? this.renewalDataStorage.customerDetails.PERMANENTPINCODE : pincode;
    //hep
    if (this.renewalDataStorage.SubProductType == 33 || this.renewalDataStorage.SubProductType == 32 || this.renewalDataStorage.SubProductType == 31 || this.renewalDataStorage.SubProductType == 30) {
      let data = this.constructHEPAddonRequest(sumInsured, pincode);
      this.subscription.push(this.renewalService.getHEPAddonList(data).subscribe({
        next: (response: GetHEPAddOnListResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.addonList = response.Data.NewAddOnCoverDetails;
            this.renewalDataStorage.SumInsured = sumInsured;
            this.renewalDataStorage.addonList = this.addonList;
            this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
            let data = {
              addon: this.addonList,
              si: sumInsured
            }
            this.renewal_emit_service.emitHepAddonData(data);
            this.constructBefit(pincode);
            this.loadInsured = true;
          }
        },
        error: error => {
        }
      }))
    } else if (this.renewalDataStorage.SubProductType == 34) {
      let data = this.constructAddonRequest();
      this.subscription.push(this.renewalService.getAddonList(data).subscribe({
        next: (response: GetAddOnListResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.addonList = response.Data.NewAddOnCoverDetails;
            this.renewalDataStorage.SumInsured = sumInsured;
            this.renewalDataStorage.addonList = this.addonList;
            let data = {
              addon: this.addonList,
              productCode: this.PlanDetailsForm.controls['productType'].value
            }
            this.renewal_emit_service.emitMaxProductType(data)
            this.loadInsured = true;
          }
        },
        error: error => {
        }
      }))
    }
  }

  constructBefit(pincode) {
    let request1 = this.constructBefitPlanPremium(pincode);
    this.subscription.push(this.renewalService.getBefitApplicableDetails(request1).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.showBefit = response.Data.IsBefitApplicable;
        this.renewalDataStorage.IsBefitApplicable = response.Data.IsBefitApplicable;
      } else {
        this.showBefit = false;
        this.renewalDataStorage.IsBefitApplicable = false;
      }
      this.renewal_emit_service.befitApplicableData(this.showBefit)
    }))
  }

  constructHEPAddonRequest(sumInsured, pincode) {
    let data: GetHEPAddOnListRequestPayload = {
      "Pincode": pincode,
      "ProductCode": "3816", // api chnages abhijit---now from renewal data 
      "SubProductCode": this.renewalDataStorage.SubProductCode,
      "SumInsured": sumInsured.toString()
    }
    return data;
  }

  constructAddonRequest() {
    let data: GetAddOnListRequestPayload = {
      "SubType": this.renewalDataStorage.SubProductType.toString(),
      "ZoneRefilingflag": this.renewalDataStorage.IsHaapRefiling == true ? "yes" : "no",
      "subproductcode": this.renewalDataStorage.SubProductType == 34 ? this.PlanDetailsForm.controls['productType'].value : ''
    }
    return data;
  }

  getInsuredDataFromForm() {
    let insuredData: any = [];
    this.renewalInsuredComponent.getInsuredArray.controls.forEach((insured: any) => {
      let data: any = {
        AddOn1: false,
        AddOn10: false,
        AddOn11: false,
        AddOn12: false,
        AddOn13: false,
        AddOn2: false,
        AddOn3: false,
        AddOn4: false,
        AddOn5: false,
        AddOn6: false,
        AddOn7: false,
        AddOn8: false,
        AddOn9: false,
        TitleId: parseInt(insured.get('insuredTitle').value),
        InsuredName: insured.get('insuredName').value,
        DateofBirth: this.utilityService.formatDate1(insured.get('insuredDOB').value),
        RelationShipID: this.getRelationId(insured.get('insuredRelationWithAppl').value),
        RelationShipName: insured.get('insuredRelationWithAppl').value,
        PedIllness: this.getPedDataForRNFetchObject(insured.get('insuredPED')),
        Height: 0,
        HeightInInches: insured.get('insuredHeightInInches').value,
        HeightInFeet: insured.get('insuredHeightInFeet').value,
        Weight: parseInt(insured.get('insuredWeight').value),
        KidAdultType: insured.get('memberType').value == 'Adult' ? 2 : 1,
        DateOfJoining: this.utilityService.formatDate1(insured.get('insuredDateOfJoining').value),
        IsExisting: insured.get('isExistingInsured').value,
        MemberType: insured.get('memberType').value,
        VaccinationDate: insured.get('insuredVaccineDate').value,
        VaccineDate: insured.get('insuredVaccineDate').value,
        IsPneumococcalVaccination: insured.get('isVaccineTaken').value,
        UWPedIlness: insured.get('insuredUWPedIllness').value,
        Occupation: this.getOccupationdata(insured.get('insuredAddOn')) ? this.getOccupationdata(insured.get('insuredAddOn')) : null,
        AddOnCoverDetails: [],//bydefault empty
        NewAddOnCoverDetails: this.getAddonCoverDetails(insured.get('insuredAddOn'), insured.get('memberType').value),//NewAddOnCoverDetails
      }
      if (this.renewalDataStorage.SubProductType == 42) {
        data.InsuredLoading = insured.get('InsuredLoading').value;
      }
      insuredData.push(data);
    })

    return insuredData;
  }

  getOccupationdata(addonData: any) {
    let occupationData = "";
    addonData.controls.forEach((addon, i) => {
      let addonId = addon.get('CoverId').value;
      if (addonId == 39 || addonId == 71) {
        occupationData = addon.get('insuredOccupation').value;
      }
    })
    return occupationData;
  }

  getAddonCoverDetails(addonData: any, membertype) {
    let coverDetails: any = [];
    addonData.controls.forEach((addon, i) => {
      if (addon.get("IsAddonSelected" + i).value) {
        let fields: any = {
          CoverCode: addon.get('CoverCode').value,
          CoverId: addon.get('CoverId').value,
          CoverText: addon.get('CoverText').value,
          CoverVariantType: addon.get('CoverVariantType').value,
          Description: addon.get('Description').value,
          IsForAdult: addon.get('IsForAdult').value,
          IsForChild: addon.get('IsForChild').value,
          IsForFemaleAdult: addon.get('IsForFemaleAdult').value,
          IsVisible: addon.get('IsVisible').value,
          SiLimit: addon.get('SiLimit').value,
          maxAgeAllowed: addon.get('maxAgeAllowed').value,
          IsAddonSelected: addon.get("IsAddonSelected" + i).value,
        };
        if (this.renewalDataStorage.SubProductType == 34) {
          fields.ApplicableSubProductList = addon.get('ApplicableSubProductList').value
        }
        let subProductType = this.renewalDataStorage.IsHigherSiRequired == true ? this.renewalDataStorage.higherPremiumDetails.SubProductType : this.renewalDataStorage.SubProductType;
        if ((fields.CoverId === 59 || fields.CoverId == 60) && membertype === 'Child' && (subProductType == 30 || subProductType == 31 || subProductType == 32 || subProductType == 33)) {
          // coverDetails = coverDetails;
        } else {
          coverDetails.push(fields);
        }
      }
    })
    return coverDetails;
  }

  getRelationId(relationshipName) {
    const relationship = this.insuredRelationshipDetails.find(x => x.RelationshipName === relationshipName);
    return relationship ? parseInt(relationship.RelationshipID) : 0;
  }

  getPedDataForRNFetchObject(pedData: any) {
    let pedDetails = []
    pedData.controls.forEach((d, i) => {
      if (d.get(`PEDSelected${i}`).value) {
        pedDetails.push({
          'OtherPedName': d.get('PEDName').value,
          'PedCode': d.get('PEDCode').value,
          'PedName': d.get('PEDName').value,
        })
      }
    });
    return pedDetails || [];
  }

  fetchDetails() {
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [any, RelationshipResponsePayload]) => {
        this.handleMultipleResponse(responseList)

      },
      error: error => {
      }
    })
    )
  }

  handleMultipleResponse(responseList: [any, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
    }
    else {
      this.manageAddonResponseData(responseList[0].Data);
      this.manageHealthRelationShipsData(responseList[1].Data);
    }
  }

  manageAddonResponseData(data) {
    this.addonList = data.NewAddOnCoverDetails;
    this.occupation = data.Occupation;
    let occupationObj = data.Occupation;
    for (let i in occupationObj) {
      this.occupationList.push({ key: occupationObj[i], value: i })
    }
    this.occupationList = this.utilityService.sortDetails(this.occupationList, "value");
    if (this.renewalDataStorage.SubProductType == 20 || this.renewalDataStorage.SubProductType == 19) {
      this.addonList.forEach(addon => {
        addon.CoverText = addon.Description.replaceAll('_', ' ');
      })
    }
    this.renewalDataStorage.addonList = this.addonList;
    this.renewalDataStorage.occupationList = this.occupationList;
    this.renewal_emit_service.emitOccupationData(this.occupationList);
    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
    this.loadInsured = true;
  }


  manageHealthRelationShipsData(data) {
    // Set relationships at first instance to avoid multiple calls
    this.storageService.setAESEncryptedData(userEnums.Relationships, data);
    if (this.renewalDataStorage.SubProductType == 40) {
      this.nomineeRelationshipDetails = data.NomineeAppointeeRelationship.filter(x => {
        return x.RelationshipID == "1037" || x.RelationshipID == "1017" || x.RelationshipID == "1018"
          || x.RelationshipID == "1028" || x.RelationshipID == "1266" || x.RelationshipID == "1024"
          || x.RelationshipID == "1029" || x.RelationshipID == "1025" || x.RelationshipID == "1020" || x.RelationshipID == "1026"
          || x.RelationshipID == "1036"
      });
      this.insuredRelationshipDetails = data.InsuredRelationship.filter(x => {
        return x.RelationshipID == "1019" || x.RelationshipID == "1020" || x.RelationshipID == "1017" || x.RelationshipID == "1018";
      });
    }
    this.nomineeRelationshipDetails = data.NomineeAppointeeRelationship;
    this.insuredRelationshipDetails = data.InsuredRelationship;
    this.apointeeRelationShipDetails = this.utilityService.sortDetails(data.NomineeAppointeeRelationship, 'RelationshipName');
    this.renewalDataStorage.InsuredRelationList = this.insuredRelationshipDetails;
    this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
  }

  requestDataFromMultipleSources(): Observable<[any, RelationshipResponsePayload]> {
    let productType = this.renewalDataModal.ProductType.find(x => x.Key === this.renewalDataStorage.SubProductType);
    let product: RelationshipRequestPayload = {
      product: productType.value
    }
    let data: GetAddOnListRequestPayload = {
      "SubType": this.renewalDataStorage.SubProductType.toString(),
      "ZoneRefilingflag": this.renewalDataStorage.IsHaapRefiling == true ? "yes" : "no",
      "subproductcode": this.renewalDataStorage.SubProductType == 34 ? this.renewalDataStorage.SubProductCode : ''
    }
    return this.renewalService.getAddonList(data).pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(GetAddOnListResponsePayload => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(GetAddOnListResponsePayload),
          this.healthMasterService.getHealthProposalRelationships(product)
        ]);
      })
    );
  }

  getHealthAilment() {
    let diseaseRequestParams: HealthAilmentRequestPayload = {
      'isAgent': healthToggle.Yes,
      'productType': this.renewalDataModal.ProductType.find(x => x.Key === Number(this.renewalDataStorage.SubProductType)).value,
    };
    this.subscription.push(this.healthMasterService.getHealthAilmentList(diseaseRequestParams).subscribe({
      next: response => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.renewalDataStorage.diseaseList = response.Data;
          this.renewalStorageService.setRenewalDataStorage(this.renewalDataStorage);
        }
      },
      error: error => {
      }
    }))
  }

  patchTitle(val, tag) {
    let selectedRelationship = this.nomineeRelationshipDetails.find(x => x.RelationshipID == val);
    const titleArray = selectedRelationship.Gender === 'FMALE'
      ? this.renewalDataModal.FemaleTitleArray
      : selectedRelationship.Gender === 'MALE'
        ? this.renewalDataModal.MaleTitleArray
        : this.renewalDataModal.title;

    if (tag === 'nominee') {
      this.nomineeTitle = titleArray;
      this.NomineeDetailsForm.controls['nomineeTitle'].patchValue(this.nomineeTitle[0].id);
    } else {
      this.appointeeTitle = titleArray;
      this.NomineeDetailsForm.controls['nomineeTitle'].patchValue(this.appointeeTitle[0].id);
    }
  }

  ngAfterViewInit(): void {
    // After patching the form, set initialLoad to false
    this.initialLoad = false;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';
import { DownloadResponsePayload, DownloadpdfRequestPayload, DownloadPDFGenericRequestPayload } from '../interface/file';
import { Observable } from 'rxjs';
import { userEnums } from '../enums/userEnums';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private generic: GenericService, private storageService: StorageService) { }
  readonly baseURI = `${environment.baseURL}`;
  readonly downloadPDF = 'File/DownloadPDF';
  readonly bancsPolicyDownloadPDF = 'BancsPolicy/BancsPDFDownload';
  readonly bancsQuoteDownloadPDF = 'BancsPolicy/DownloadQuotePDF';
  readonly downloadProposalPDF_ishield = 'IPRUIPS/ViewProposal';
  readonly downloadPolicyPDF_ishield = 'IPRUIPS/DownloadHAPPolicy';
  readonly downloadProposalPDF_CS = 'file/GetCritiProposalFormPDF';
  readonly downloadProposalPDF_FS = 'file/GetFSProposalFormPDF';
  readonly ipartnerURI = "https://cldilmobilapp01.insurancearticlez.com/mobileapi/";
  readonly uatBrochureURL = 'https://panacf.insurancearticlez.com/Brochures/';
  readonly prodBrochureURL = 'https://pana.icicilombard.com/Brochures/';
  readonly genericPDFDownload = 'File/DownloadPDFGeneric';
  readonly quotePDF = 'File/DownloadQuotePDF'
  readonly quoteElevatePDF = 'File/DownloadElevateQuotePDF'

  downloadPDFGeneric(data: DownloadPDFGenericRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.genericPDFDownload}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  downloadBrochure(prdId): Observable<Blob> {
    let url = '';
    if (environment.sanity || environment.uat) {
      url = this.uatBrochureURL + prdId;
    } else {
      url = this.prodBrochureURL + prdId;
    }
    return this.generic.downloadFile(url);
  }

  downloadProposal(data) {
    let endPoint;
    let body = {};

    if (data.isBancsPolicy) {
      endPoint = data.policySubType == 44 ? this.downloadPolicyPDF_ishield : this.bancsPolicyDownloadPDF;
      body = data.policySubType == 44 ? { hadvPolicyID: data.policyId } : { type: data.policyType, value: data.policyId.trim() }
    } else {
      endPoint = data.policySubType == 44 ? this.downloadProposalPDF_ishield : this.downloadPDF;
      body = data.policySubType == 44 ? { BundleID: data.bundleId } :
        { type: 'PROPOSAL', value: Number.isInteger(data.policyId) ? data.policyId : data.policyId.trim() }
    }
    return { endPoint: endPoint, body: body, policyId: data.policyID }
  }

  downloadPolicy(data,) {
    let body = {};
    let endPoint = '';

    if (data.isBancsPolicy) {
      endPoint = data.policySubType == 44 ? this.downloadPolicyPDF_ishield : this.bancsPolicyDownloadPDF;
      body = data.policySubType == 44 ? { hadvPolicyID: data.policyId } : { type: data.policyType, value: data.policyId.trim() }
    } else {
      endPoint = data.policySubType == 44 ? this.downloadPolicyPDF_ishield : this.downloadPDF;
      body = data.policySubType == 44 ? { hadvPolicyID: data.policyId } : { type: data.policyType, value: data.policyId.trim() }
    }
    return { endPoint: endPoint, body: body, policyId: data.policyID }
  }

  downloadPDfRequest(data: DownloadpdfRequestPayload): Observable<DownloadResponsePayload> {
    let endpoint;
    if (data.isBancsPolicy) {
      endpoint = `${this.baseURI}${this.bancsPolicyDownloadPDF}`;
    } else {
      endpoint = `${this.baseURI}${this.downloadPDF}`;
    }
    return this.generic.genericService<DownloadResponsePayload>(endpoint, { type: data.type, value: data.value });
  }

  generatePDF(endPoint, body): Observable<DownloadResponsePayload> {
    const endpoint = `${this.baseURI}${endPoint}`;
    return this.generic.genericService<DownloadResponsePayload>(endpoint, body);
  }

  downloadQuotePDF(data: any): Observable<any> {
    const endpoint = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `${this.baseURI}${this.bancsQuoteDownloadPDF}` : `${this.baseURI}${this.quotePDF}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  downloadElevateQuotePDF(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.quoteElevatePDF}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  // downloadPdf(base64String:string, fileName: string) {
  //   // Create a data URL for the base64 string
  //   const dataUrl = `data:application/pdf;base64,${base64String}`;

  //   // Create a download link
  //   const link = document.createElement('a');
  //   link.href = dataUrl;
  //   link.download = fileName;

  //   // Append the link to the body and trigger the click event
  //   document.body.appendChild(link);
  //   link.click();

  //   // Clean up after a short delay
  //   setTimeout(() => {
  //     document.body.removeChild(link);
  //   }, 100);
  // }

  //I have changed downloadPDF & base64ToUint8Array, for reference.
  downloadPdf(base64String: string, fileName: string): void {
    const dataUrl = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    
    // Detect if the browser is Safari or Edge
    const userAgent = navigator.userAgent;
    const isSafariOrEdge = userAgent.includes('Safari') && !userAgent.includes('Chrome') || userAgent.includes('Edg');
    
    // For Safari/Edge or when Blob is not supported, use the data URL
    link.addEventListener('click', () => {
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    });
    
    link.href = dataUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }

  webDownload(base64String: string, fileName: string): void {
    const dataUrl = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    
    // Check if the browser supports the Blob constructor
    const blobSupported = typeof Blob !== 'undefined';
    
    // Check if the browser is Safari or Edge
    const isSafari = /Version\/\d+(\.\d+)*\s+Safari/.test(navigator.userAgent);
    // const isSafari = /Version\/[\d\.]+.*Safari/.test(navigator.userAgent);
    const isEdge = /Edg/.test(navigator.userAgent);
    const isSafariOrEdge = isSafari || isEdge;
  
    if (!isSafariOrEdge || !blobSupported) {
      // For non-Safari/Edge browsers or when Blob is not supported, proceed as usual
      link.href = dataUrl;
      link.download = fileName;
      
      // Add the link to the document body
      document.body.appendChild(link);
      
      // Trigger the download
      link.click();
      
      // Clean up after a short delay
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } 
  }

  //I have changed downloadPDF & base64ToUint8Array, for reference.
  // Helper function to convert base64 string to Uint8Array
  // private base64ToUint8Array(base64String: string): Uint8Array {
  //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
  //   const base64 = (base64String + padding)
  //     .replace(/\-/g, '+')
  //     .replace(/_/g, '/');

  //   const rawData = window.atob(base64);
  //   const outputArray = new Uint8Array(rawData.length);

  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  //   return outputArray;
  // }

  private base64ToUint8Array(base64String: string): Uint8Array {
    const normalizedBase64 = base64String.replace(/\s/g, ''); // Remove line breaks and other whitespace
    const rawData = window.atob(normalizedBase64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }
}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../services/generic.service';
import { ElevateMasterResponsePayload,ElevateAddonsRequestPayload, ElevateAddonsResponsePayload, ElevateBUIServiceAddonResponse } from '../interface/elevate';
import { SaveEditProposalV2HAPRequestPayload, SaveEditProposalV2RequestPayload, SaveEditProposalV2ResponsePayload } from '../interface/max-protect';
import { SaveEditQuoteHapV1Request } from '../interface/transactionHealthAdvantege';

@Injectable({
  providedIn: 'root'
})
export class ElevateService {

  constructor(private generic: GenericService) {

  }
  readonly baseURI = `${environment.baseURL}Elevate/`;
  readonly elevateMasterData = 'ElevateMasterData';
  readonly SaveEditQuote = 'SaveEditQuote';
  readonly elevateAddons = 'GetElevateAddons'
  readonly elevateProposal = 'SaveEditProposal'
  readonly elevateBUIServiceAddon = 'SaveEditQuoteV1'

  getElevateMasterData(): Observable<ElevateMasterResponsePayload> {
    const endpoint = `${this.baseURI}${this.elevateMasterData}`;
    return this.generic.genericService<ElevateMasterResponsePayload>(endpoint);
  }

  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuote}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  fetchElevateAddons(data:ElevateAddonsRequestPayload) :Observable<ElevateAddonsResponsePayload>{
    const endpoint = `${this.baseURI}${this.elevateAddons}`;
    return this.generic.genericService<ElevateAddonsResponsePayload>(endpoint, data);
  }

  fetchBUIElevateAddons(data:SaveEditQuoteHapV1Request) :Observable<ElevateBUIServiceAddonResponse>{
    const endpoint = `${this.baseURI}${this.elevateBUIServiceAddon}`;
    return this.generic.genericService<ElevateBUIServiceAddonResponse>(endpoint, data);
  }

  saveProposal(data: SaveEditProposalV2RequestPayload | SaveEditProposalV2HAPRequestPayload) :Observable<SaveEditProposalV2ResponsePayload>{
    const endpoint = `${this.baseURI}${this.elevateProposal}`;
    return this.generic.genericService<SaveEditProposalV2ResponsePayload>(endpoint, data);
  }

}
import { Component, HostListener, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable, Subscription, fromEvent } from 'rxjs';

import { HealthDataStorage } from '../health.modal';
import { HAPModal } from '../products/hap/hap-modal';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { environment } from 'src/environments/environment';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { ProductCode } from 'src/app/shared/enums/healthEnums';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { HomeDataRequestPayload } from 'src/app/shared/interface/home';
import { HomeService } from 'src/app/shared/proxy-services/home.service';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { TravelDataStorageService } from '../products/travel/travel.service';
import { SummitRedirectionResponse } from 'src/app/shared/interface/summit-report';
import { SummitReportService } from 'src/app/shared/proxy-services/summit-report.service';
import { popupType, popupHeader, popupDescriptionMsg, popupButtonAction } from 'src/app/shared/enums/popupEnums';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { GetRenewalCountRequestPayload, GetRenewalCountResponsePayload } from 'src/app/shared/interface/agent';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { TravelService } from 'src/app/shared/services/travel.service';
import { HealthClaimService } from 'src/app/shared/components/sidemenu/health-claim/services/health-claim.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  healthProducts = [];
  travelProducts = [];
  homeProducts = [];
  tripguardProducts = [];
  businessHead = [];
  myServices = [];
  myTransactions = [];

  currentDate: string = '';
  renewalValue: string = '-';
  paymentPending: string = '-';
  businessReport: string = '-';

  agentDetails: AgentMasterStructure;
  // agentDetailsLocal:AgentMasterStructureLocal
  popStateEventTrggered: boolean = false;
  channelData: ChannelData;
  previousURL: string = '';
  isAuthenticate: boolean = false;
  loadContent: boolean = false;
  healthData: HealthDataStorage;
  activeTabIndex: number = 0;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // What is logic of assignment in below 2 lines??
  curdate = (this.monthNames[new Date().getMonth()]).toString() + ' ' + new Date().getFullYear().toString();
  dattee: string = this.curdate;
  private subscription: Subscription[] = [];
  selectedTabIndex: number = 0;
  hideSpinner: boolean = false;
  scrolling: boolean = false;
  nextClubName = '';
  currentClubTarget = 0;
  nextClubTarget = 0;
  currentClubName = '';
  totalWeightGWP = 0;
  requiredGrowth = 0;
  isSummitVisible = false;

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  left: string = '';
  sectionNumber: number = 3;

  @HostListener('window:popstate', ['$event']) onClickBack(
    event: PopStateEvent
  ) {
    history.pushState(null, '');
    this.browserBackButton();
  }

  constructor(
    private dialog: MatDialog,
    private domSanitization: DomSanitizer,
    private datePipe: DatePipe,
    private router: Router,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
    private agentService: AgentService,
    private popupService: PopupService,
    private homeService: HomeService,
    private travelDataStorageService: TravelDataStorageService,
    private summitReportService: SummitReportService,
    private auth: AuthService,
    private utilityService: UtilityService,
    private agentV1Service: AgentV1Service,
    private healthClaimService: HealthClaimService,
    private travelService: TravelService) {
    history.pushState(null, '');
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.previousURL = event.url;
          // Update the previous URL 
        }
      }
    );
  }

  ngOnInit(): void {

    /**
     * @author Kunal Sali
     * fetching agent details on init because we are practically using this data in every fucntion so dont need call for service in every function
     */
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);

    /**
     * Resetting orion journey code flag to false as we are coming back to landing page
     */
    this.storageService.setAESEncryptedData(userEnums.IsOrionJourney, 'false');
    let BFlag  = (!this.utilityService.isUndefinedORNull(this.channelData.BFLAG) && this.channelData.BFLAG == "true") ? true : false;
    if (!this.agentDetails.AMLFLAG && !BFlag) {
      let popupData: PopupModal = {
        popupType: popupType.AML,
        imgName: '',
        header: '',
        description: '',  
        buttonLabel: popupButtonAction.memberIndex,
        buttonAction: popupButtonAction.logout,
      }
      this.popupService.openGenericPopup(popupData);
    }

    if (sessionStorage.getItem(userEnums.HealthFormData)) {
      sessionStorage.removeItem(userEnums.HealthFormData);
    }

    if (sessionStorage.getItem(userEnums.QuotePDFData)) {
      sessionStorage.removeItem(userEnums.QuotePDFData);
    }

    window.scrollTo(0, 0);
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    let tempDate: Date = new Date();
    this.currentDate = this.datePipe.transform(tempDate, 'MMMM yyyy');
    this.sessionStorageService.remove(userEnums.Relationships);
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.isAuthenticate = this.auth.check();
    if (this.isAuthenticate) {
      this.storageService.setAESEncryptedData(userEnums.ConverToProposal, null);
      this.storageService.setAESEncryptedData(userEnums.BefitHome, null);
      this.storageService.setAESEncryptedData(userEnums.PreviousUrl, this.previousURL);
    }
    if (this.agentDetails?.IsAgentParent) {
      this.fetchSummitReportData();
    }
    this.fetchDataAsPerDate();
    this.constructBusinessHead();
    this.constructHealthProducts();
    this.constructHomeProducts();
    this.constructTripGuardProducts();
    this.constructTravelProducts();
    this.constructMyServices();
    this.constructMyTransactions();
    this.constructTripGuardProducts();
    if (this.channelData) {
      if (this.channelData.CN === 'NYSAHEALTH') {
        this.setTabIndex();
      } else {
        this.setData();
      }
    }
    this.channelData.AN = this.agentDetails.AgentName;
  }

  setData() {
    this.selectedTabIndex = this.getTabIndex(this.channelData.PRDT.toLowerCase());
    let tabData = { 'index': this.selectedTabIndex }
  }

  setTabIndex() {
    const productList = this.agentV1Service.productTypeList(this.agentDetails, ' ');
    if (productList.length > 0) {
      this.storageService.setAESEncryptedData(userEnums.AgentProductMap, productList);
      this.selectedTabIndex = this.getTabIndex(productList[0].productName.toLowerCase());
    } else {
      this.setData();
    }
  }

  setSummitRange() {
    const newVal = Number(((this.totalWeightGWP) * 100) / (this.nextClubTarget));
    this.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
  }

  ngAfterViewInit(): void {
    if (this.channelData.CIP.includes('MFA')) {
      this.utilityService.scrollToElementById("productsList");
    }
  }

  onWindowScroll() {
    this.scrolling = true;
    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  formatLabel(value: number) {
    return value.toFixed(0);
  }

  // getRedirectionUrl() {
  //   this.summitReportService.getRedirectionUrlApi().subscribe((res: SummitRedirectionResponse) => {
  //     if (res.StatusCode == Constants.statusCode_success) {
  //       let finalUrl = res.Data;
  //       window.location.href = finalUrl;
  //     }
  //   })
  // }

  @ViewChild('iframe') iframe: TemplateRef<any>;
  iframeUrl: SafeResourceUrl | null = null;
  getRedirectionUrl() {
    this.summitReportService.getRedirectionUrlApi().subscribe((res: SummitRedirectionResponse) => {
      if (res.StatusCode == Constants.statusCode_success) {
        const trustedUrlPattern = /^(https:\/\/panacf\.insurancearticlez\.com|https:\/\/panaapi\.ilgicdash\.com|https:\/\/pana\.icicilombard|http:\/\/localhost(:\d+)?|https:\/\/localhost(:\d+)?)(\/.*)?$/;
        if (trustedUrlPattern.test(res.Data)) {
          this.iframeUrl = this.domSanitization.bypassSecurityTrustResourceUrl(res.Data);
          this.dialog.open(this.iframe, { maxWidth: '100%', width: '95%' });
        } else {
          console.error('Untrusted URL:', res.Data);
        }
      }
    })
  }

  @ViewChild('iframe2') iframe2: TemplateRef<any>;
  iframeUrl2: SafeResourceUrl | null = null;
  getHealthClaimnUrl(payload) {
    this.healthClaimService.getHealthClaimURL(payload).subscribe(res => {
      if (res.StatusCode == Constants.statusCode_success) {
        const trustedUrlPattern = /^(https:\/\/panacf\.insurancearticlez\.com|https:\/\/panaapi\.ilgicdash\.com|https:\/\/pana\.icicilombard|https:\/\/digitalpos-uat\.insurancearticlez\.com|http:\/\/localhost(:\d+)?|https:\/\/localhost(:\d+)?)(\/.*)?$/;
        if (trustedUrlPattern.test(res.Data)) {
          this.iframeUrl = this.domSanitization.bypassSecurityTrustResourceUrl(res.Data);
          this.dialog.open(this.iframe, { maxWidth: '100%', width: '95%' });
        } else {
          console.error('Untrusted URL:', res.Data);
        }
      }
    })
  }

  fetchSummitReportData() {
    this.summitReportService.getSummitData({}).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.currentClubName = response.Data.currentClubName;
          this.nextClubName = response.Data.nextClubName;
          this.currentClubTarget = response.Data.currentClubTarget;
          this.nextClubTarget = response.Data.nextClubTarget;
          this.totalWeightGWP = response.Data.gwpAchievement.totalWeightedGwp;
          this.requiredGrowth = response.Data.gwpGrowth.requiredGrowth;
          this.isSummitVisible = response.Data.isSuccess;
          if (window.innerWidth < 992 && this.isSummitVisible) {
            this.setSummitRange();
          }
        }
      }
    });
  }

  getTabIndex(product) {
    let tabIndex = 0;
    switch (product) {
      case routeEnums.HEALTH:
        break;
      case routeEnums.HOME:
        tabIndex = 1;
        break;
      case routeEnums.TRAVEL:
        tabIndex = 2;
        break;
      default:
        break;
    }
    return tabIndex;
  }

  fetchDataAsPerDate() {
    let tempDate = this.dattee;
    let tempArray = tempDate.split(' ');
    let monthNumber = this.monthNames.findIndex((month) => {
      return month === tempArray[0];
    })
    let obj: GetRenewalCountRequestPayload = {
      Month: monthNumber + 1,
      Year: parseInt(tempDate.split(' ')[1]),
      QuoteTodayFlag: true
    }
    this.subscription.push(this.agentService.renewalCountData(obj).subscribe({
      next: (response: GetRenewalCountResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.renewalValue = String(response.Data.RenewalTotalCount);
          this.paymentPending = String(response.Data.PendingPaymentCount);
          this.businessReport = String(response.Data.BBRCount ?? '0');
          this.storageService.setAESEncryptedData(userEnums.RenewalValue, this.renewalValue)
          this.storageService.setAESEncryptedData(userEnums.PendingPaymentValue, this.paymentPending)
          this.loadContent = true;
          this.hideSpinner = true;
          this.constructBusinessHead();
        }
        else if (response.StatusCode != Constants.statusCode_success) {
          this.renewalValue = '-';
          this.paymentPending = '-';
          this.businessReport = '-';
          this.loadContent = true;
          this.hideSpinner = true;

        }
      },
      error: error => {
        this.renewalValue = '-';
        this.paymentPending = '-';
        this.businessReport = '-';
        this.loadContent = true;
        this.hideSpinner = true;

      }
    }));
  }

  browserBackButton(): Observable<boolean> | boolean {
    let deactivateRoute: boolean;
    this.popStateEventTrggered = false;
    if (document.location.href.includes(`/${routeEnums.LOGIN}`) || !this.popStateEventTrggered) {
      let popupData: PopupModal = {
        popupType: popupType.confirmation,
        imgName: '',
        header: popupHeader.confirmlogout,
        description: popupDescriptionMsg.backButtonLogoutDescription,
        buttonLabel: '',
        buttonAction: ''
      }
      this.popupService.openGenericPopup(popupData);
      deactivateRoute = false;
      this.subscription.push(this.popupService.logout$.subscribe(status => {
        if (status) {
          this.sessionStorageService.clear();

          if (this.channelData.CN.includes('MOTOR')) {
            window.location.href = environment.nysaMotorLoginRedirection;
            return;
          } else if (this.channelData.CN.includes('BANCA')) {
            window.location.href = environment.nysaBancaLoginRedirection;
            return;
          }
          this.router.navigate([`/${routeEnums.LOGIN}`]);
          deactivateRoute = status;
          this.popStateEventTrggered = true;
        } else {
          this.storageService.setAESEncryptedData(userEnums.PreviousUrl, this.previousURL);
          this.popStateEventTrggered = false
        }
      }));

    } else if (!document.location.href.includes(`/${routeEnums.LOGIN}`) && !document.location.href.includes(`/${routeEnums.DASHBOARD}`) && !this.popStateEventTrggered) {
      this.sessionStorageService.clear();
      this.router.navigate([`/${routeEnums.LOGIN}`]);
    }

    return deactivateRoute !== undefined ? deactivateRoute : true;

  }

  constructBusinessHead() {
    let businessHead = [
      { name: 'Pending Payments', data: this.paymentPending, routePath: `/${routeEnums.PendingPayment}`, imagePath: '../../../assets/images/landingpage/pending_payment.svg' },
      { name: 'Renewal Due', data: this.renewalValue, routePath: `/${routeEnums.Renewals}`, imagePath: '../../../assets/images/landingpage/renewal_due.svg' },
      { name: 'Booked Business Report', data: this.businessReport, routePath: `/${routeEnums.BookedBusinessReport}`, imagePath: '../../../assets/images/landingpage/report.svg' }
    ];
    this.businessHead = businessHead;
  }

  constructMyServices() {
    let services = [
      { serviceName: 'Instant Renewal', imagePath: '../../../assets/images/dashboard/Instant_Renewal.svg', routePath: `/${routeEnums.InstantRenewal}` },
      { serviceName: 'Health Claim', imagePath: '../../../assets/images/dashboard/Health_Claim.svg', routePath: `/${routeEnums.HEALTH_CLAIM}` },
      { serviceName: 'Book Business Report', imagePath: '../../../assets/images/dashboard/Book_Business_Report.svg', routePath: `/${routeEnums.BookedBusinessReport}` },
      { serviceName: 'Create Customer', imagePath: '../../../assets/images/dashboard/Create_Customer.svg', routePath: `/${routeEnums.CREATE_CUSTOMER}` },     
      { serviceName: 'Brochure', imagePath: '../../../assets/images/dashboard/Brochure.svg', routePath: `/${routeEnums.Brochure}` },
      { serviceName: 'Update KYC', imagePath: '../../../assets/images/dashboard/UpdateKYC.svg', routePath: `/${routeEnums.UpdateKYC}` },
    ];
    this.myServices = services;
  }

  getQuote() {
    this.router.navigate([`/${routeEnums.QuickQuote}`])
  }

  constructMyTransactions() {
    let transactions = [
      { transactionName: 'My Policies', imagePath: '../../../assets/images/dashboard/My_Policies.svg', routePath: `/${routeEnums.MyPolicies}` },
      { transactionName: 'Pending Payment', imagePath: '../../../assets/images/dashboard/Pending_Payment.svg', routePath: `/${routeEnums.PendingPayment}` },
      { transactionName: 'Saved Quotes', imagePath: '../../../assets/images/dashboard/SaveQuote.svg', routePath: `/${routeEnums.SavedQuotes}` },
      { transactionName: 'Advance PID', imagePath: '../../../assets/images/dashboard/Advance_PID.svg', routePath: `/${routeEnums.PID}` },
      { transactionName: 'Pay In Slip', imagePath: '../../../assets/images/dashboard/Pay_In_Slip.svg', routePath: `/${routeEnums.PAY_IN_SLIP}` },
      { transactionName: 'Renewal Pre-Proposal', imagePath: '../../../assets/images/dashboard/Pay_In_Slip.svg', routePath: `/${routeEnums.Renewal_Pre_Proposal}` },
    ];
    this.myTransactions = transactions;
  }

  constructHealthProducts() {
    // Below logic works as per service
    let isCHIMapped = this.agentDetails?.MappedProduct?.Health?.isHealthCHIMapped;
    let isBoosterMapped = this.agentDetails?.MappedProduct?.Health?.isHealthBoosterMapped;
    let productDetails = [
      { productName: 'Elevate', productCode: 3837, routePath: `/${routeEnums.Elevate}`, isProductMapped: this.agentDetails?.MappedProduct?.Health?.Visible_Elevate, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/elevate.svg", show: true },
      { productName: 'Health AdvantEdge Plus', productCode: this.agentDetails.isValidForOrion ? new HAPModal().subProductType.orionHap : 42, routePath: this.agentDetails.isValidForOrion ? `/${routeEnums.OrionHap}` : `/${routeEnums.HAP}`, isProductMapped: this.agentDetails?.MappedProduct?.Health?.HealthCHISubProducts?.Visible_HealthAdvantedge_Plus, isOrionProduct: this.agentDetails.isValidForOrion ? true : false, imagePath: "../../../assets/images/dashboard/health_adv.svg", show: true },
      { productName: 'Max Protect', productCode: 34, routePath: `/${routeEnums.MaxProtect}`, isProductMapped: isCHIMapped && this.agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/max_protect.svg", show: true },
      // { productName: 'Health Elite Plus', productCode: 33, routePath: `/${routeEnums.HealthElitePlus}`, isProductMapped: isCHIMapped && this.agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Health_Elite.svg", show: true },
      { productName: 'Golden Shield', productCode: 40, routePath: `/${routeEnums.GoldenShield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isGoldenShield, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Golden_Shield.svg", show: true },
      { productName: 'Health Booster', productCode: 20, routePath: `/${routeEnums.HEALTH_BOOSTER}`, isProductMapped: isBoosterMapped && this.agentDetails?.MappedProduct?.Health?.HealthBoosterSubProducts?.Visible_SuperTopup, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Health_Booster.svg", show: true },
      { productName: 'Arogya Sanjeevani', productCode: 22, routePath: `/${routeEnums.ASP}`, isProductMapped: this.agentDetails.MappedProduct.Health.isHealthASPMapped, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Arogya_sanjeevani.svg", show: true },
      { productName: 'Family Shield', productCode: 23, routePath: `/${routeEnums.Familyshield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isFamilyShield, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Family_Shield.svg", show: true },
      { productName: 'Criti Shield', productCode: 38, routePath: `/${routeEnums.CritiShield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isCritiShieldMapped, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/criti_shield.svg", show: true },
      // { productName: 'Health Shield', productCode: 30, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShield, imagePath: "assets/images/products/13_Health-Shield.png" },
      // { productName: 'Health Shield Plus', productCode: 31, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShieldPlus, imagePath: "assets/images/products/14_Health-Shield-Plus.png" },
      // { productName: 'Health Elite', productCode: 32, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElite, imagePath: "assets/images/health_dash.png" },
      // { productName: 'i-Shield', productCode: 44, routePath: '', isProductMapped: agentDetails.MappedProduct.Health.isIShieldMapped, imagePath: "assets/images/health_dash.png", productDetails:["Life Insurance +Health Insurance cover","Guaranteed cumulative Bonus :20% of SI maximum upto 100% (under health cover)","Life cover : till the age of 85/99 years of age"] },
    ]
        this.healthProducts = productDetails;
    if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == true || this.channelData.BFLAG == "true")) {
      let productCode = ProductCode[this.channelData.ST.toUpperCase()];
      let productToFilter = this.healthProducts.find(product => productCode === product.productCode);
      this.healthProducts = this.healthProducts.filter(product => product.productCode == productToFilter.productCode);
    }
  }

  // checkForOrionProducts() {
  //   if ((environment.environment == 'sanity' || environment.environment == 'preprod') && this.agentDetails.isValidForOrion) {
  //     this.healthProducts.push(
  //       { productName: 'Orion Health Advantedge Plus', productCode: new HAPModal().subProductType.orionHap, routePath: `/${routeEnums.OrionHap}`, isProductMapped: true, isOrionProduct: true, imagePath: "../../../assets/images/dashboard/health_adv.svg", show: true },
  //     );
  //   }
  // }

  constructTravelProducts() {
    let productDetails = [
      { productName: 'International Travel', productCode: 4, imagePath: '../../../assets/images/internationaltravel.svg', routePath: `${routeEnums.INTERNATIONAL_TRAVEL}`, isProductMapped: this.agentDetails?.MappedProduct?.Travel?.isTRAVELINTERNATIONALMapped },
      { productName: 'Student Medical Travel', productCode: 3, imagePath: '../../../assets/images/studentmedical.svg', routePath: `${routeEnums.STUDENT_MEDICAL_TRAVEL}`, isProductMapped: this.agentDetails?.MappedProduct?.Travel?.isTRAVELSTUDENTMapped }
    ];
    this.travelProducts = productDetails;
  }

  constructTripGuardProducts() {
    let productDetails = [
      { productName: 'TripSecure+', productCode: 6, imagePath: '../../../assets/images/TripSecureLogo.svg', routePath: `${routeEnums.TRAVELSAFE}`, isProductMapped: this.agentDetails?.MappedProduct?.Travel?.isTRIPSECUREPLUSMapped },
    ];
    this.tripguardProducts = productDetails;
  }

  constructHomeProducts() {
    let productDetails = [
      { productName: 'Home', productCode: 3, imagePath: '../../../assets/images/home_dashboard.svg', routePath: ``, isProductMapped: this.agentDetails?.MappedProduct?.Home?.isHomeMapped }];
    this.homeProducts = productDetails;
  }

  /**
   * @RefactoredFunction
   * @author Kunal Sali
   * removing specific Route parameter and adding whole object as parameter.
   * @param product as we are already looping through array on HTML we don't need looping again and again here. pass whole product or service object as parameter and you will get all the related info.
   * @param domain Hardcoded parameter which specifies domain like HEALTH, TRAVEL or HOME 
   * 
   * Removed some hardcoded conditions and replaced it with already existing enums
   */

  navigate(product, domain) {

    if (domain == 'others') {
      if (product.serviceName == "Health Claim") {
        let login = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
        let payload = {
          IMID: login['Uowkjsdjssrme'],
          Type: "HEALTH_CLAIMS"
        };
        this.getHealthClaimnUrl(payload);
      } else {
        this.router.navigate([product.routePath]);
      }
    }
    else if (domain == routeEnums.HEALTH) {
      if (product.isProductMapped) {
        this.storageService.setAESEncryptedData(userEnums.IsOrionJourney, this.storageService.getAESDecryptedData(userEnums.AgentDetails).isValidForOrion && product.isOrionProduct ? 'true' : 'false');
        if ((!this.channelData.CN.includes('MOTOR') && !this.channelData.CN.includes('BANCA') && !this.channelData.CN.includes('MFA') && !this.channelData.CN.includes('PRO'))) {
          this.channelData.CIP = this.channelData.CN = 'NYSAHEALTH';
        }
      } else {
        const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.dealIDNotMapped);
        this.popupService.openGenericPopup(errorPopupData);
      }
    }
    else if (domain == routeEnums.TRAVEL) {
      if (product.isProductMapped) {
        this.travelDataStorageService.resetTravelDataStorage();
        this.channelData.CIP = this.channelData.CN = 'NYSATRAVEL';
        this.channelData.PRDT = 'Travel';
        this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);
      }
      else {
        const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.dealIDNotMapped);
        this.popupService.openGenericPopup(errorPopupData);
      }
    }
    else if (domain == routeEnums.HOME) {
      if (product.isProductMapped) {
        this.channelData.CIP = this.channelData.CN = 'NYSAHOME';
        this.channelData.PRDT = 'HomeApp';
        this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);

        let req: HomeDataRequestPayload = {
          "FlowType": "NEW",
          "IMID": this.channelData.IMID,
          "ProductType": this.channelData.PRDT,
          "ChannelName": this.channelData.CN,
          "ViewType": "Portal",
          "UserType": "Agent",
          "ChannelIP": this.channelData.CN,
          "DealId": this.agentDetails.MappedProduct.Home.HomeDealId,
          "SessionId": this.channelData.SID || '1'
        }
        this.subscription.push(this.homeService.getHomeData(req).subscribe({
          next: (response) => {
            if (response.StatusCode === Constants.statusCode_success) {
              window.location.href = response.Data.url;
            }
          },
          error: error => {
            let errorPopupData = this.popupService.fetchErrorPopupMsg('Home Insurance Response Failed');
            this.popupService.openGenericPopup(errorPopupData);
          }
        }));
      }
      else {
        const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.dealIDNotMapped);
        this.popupService.openGenericPopup(errorPopupData);
      }
    }
    else if (domain == routeEnums.TRAVELSAFE) {
      if (product.isProductMapped) {
        if ((!this.channelData.CN.includes('MOTOR') && !this.channelData.CN.includes('BANCA') && !this.channelData.CN.includes('MFA') && !this.channelData.CN.includes('PRO'))) {
          this.channelData.CN = 'NYSATRAVEL';
          this.channelData.CIP = 'NYSAHEALTH'
        }
        this.channelData.PRDT = 'TripGuard';
        this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);

        let req: any = {
          "FlowType": "NEW",
          "IMID": this.channelData.IMID,
          "ProductType": this.channelData.PRDT,
          "ChannelName": this.channelData.CN,
          "ViewType": "Portal",
          "UserType": "Agent",
          "ChannelIP": this.channelData.CIP,
          "SessionId": this.channelData.SID || '1',
          "AgentName": this.channelData.AN
        }
        this.subscription.push(this.travelService.getTravelSafeData(req).subscribe({
          next: (response) => {
            if (response.StatusCode === Constants.statusCode_success) {
              window.location.href = response.Data.url;
            }
          },
          error: error => {
            let errorPopupData = this.popupService.fetchErrorPopupMsg('Home Insurance Response Failed');
            this.popupService.openGenericPopup(errorPopupData);
          }
        }));
      }
      else {
        const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.dealIDNotMapped);
        this.popupService.openGenericPopup(errorPopupData);
      }
    }

    if ((domain == routeEnums.HEALTH && product.isProductMapped) || (domain == routeEnums.TRAVEL && product.isProductMapped)) {
      this.storageService.setAESEncryptedData(userEnums.SubProductCode, domain == routeEnums.HEALTH ? product.productCode : product.productCode[0]);
      let routePath = domain == routeEnums.HEALTH ? routeEnums.HEALTH : routeEnums.TRAVEL;
      this.storageService.setAESEncryptedData(userEnums.ProductType, domain);
      this.router.navigate([routePath + '/' + product.routePath]);
    }
  }

  scrollToSection(value: string) {
    let section: HTMLElement | null = null;
    let offset: number = 0;
    if (value === 'MyProducts') {
      section = document.getElementById('MyProducts');
      offset = -60;
      this.sectionNumber = 0;
    } else if (value === 'MyServices') {
      section = document.getElementById('MyServices');
      offset = -120;
      this.sectionNumber = 1;
    } else if (value === 'MyTransactions') {
      section = document.getElementById('MyTransactions');
      offset = -120;
      this.sectionNumber = 2;
    }

    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/services/generic.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthClaimService {

  constructor(private generic : GenericService) { }

  readonly baseURI = `${environment.baseURL}`;
  readonly healthClaimDetails = 'HealthClaim/GetHealthClaimDetails';
  readonly healthClaimStatus = 'HealthClaim/HealthClaimStatus';
  readonly healthClaimURL = 'HealthClaim/HealthClaimRedirectionUrl';

  getHealthClaimDetails(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.healthClaimDetails}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getHealthClaimStatus(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.healthClaimStatus}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getHealthClaimURL(data) {
    const endpoint = `${this.baseURI}${this.healthClaimURL}`;
    return this.generic.genericService<any>(endpoint, data);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/authentication/auth.guard';
import { routeEnums } from './shared/enums/routeEnums';
import { SummaryPageComponent } from './modules/health/health-shared-components/summary-page/summary-page.component';
import { PaymentConfirmationComponent } from './shared/components/payment-confirmation/payment-confirmation.component';
import { PaymentLinkSummaryComponent } from './shared/components/payment-link/payment-link-summary/payment-link-summary.component';
import { DocumentUploadComponent } from './modules/health/document-upload/document-upload/document-upload.component';
import { PendingPaymentComponent } from './shared/components/sidemenu/pending-payment/pending-payment.component';
import { MyPolicyComponent } from './shared/components/sidemenu/my-policy/my-policy.component';
import { ZeroTatInsuredDetailsComponent } from './modules/health/zero-tat-health-shared-component/zero-tat-insured-details/zero-tat-insured-details.component';
import { SavedQuotesComponent } from './shared/components/sidemenu/saved-quotes/saved-quotes.component';
import { PayInSlipComponent } from './shared/components/sidemenu/pay-in-slip/pay-in-slip.component';
import { PidComponent } from './shared/components/sidemenu/pid/pid.component';
import { RenewalsComponent } from './shared/components/sidemenu/renewals/renewals-list/renewals.component';
import { PagenotfoundComponent } from './layout/pagenotfound/pagenotfound.component';
import { environment } from 'src/environments/environment';
import { InstantRenewalComponent } from './shared/components/sidemenu/instant-renewal/instant-renewal.component';
import { BrochureComponent } from './shared/components/sidemenu/brochure/brochure.component';
import { RenewalZerotatSummaryComponent } from './shared/components/sidemenu/renewals/renewal-zerotat-summary/renewal-zerotat-summary.component';
import { BookedBusinessReportComponent } from './shared/components/sidemenu/BBR/booked-business-report/booked-business-report.component';
import { KycDetailsComponent } from './shared/components/kyc-details/kyc-details.component';
import { TravelSummaryDetailsComponent } from './modules/health/products/travel/travel-summary-details/travel-summary-details.component';
import { RenewalHomeSummaryComponent } from './shared/components/sidemenu/renewals/renewal-home-summary/renewal-home-summary.component';
import { NewRenewalSummaryComponent } from './shared/components/sidemenu/renewals/new-renewal-summary/new-renewal-summary.component';
import { HealthClaimComponent } from './shared/components/sidemenu/health-claim/health-claim.component';
import { QuickQuoteComponent } from './modules/health/quick-quote/quick-quote/quick-quote.component';
import { ElevateOfflineCalculatorComponent } from './shared/components/offline-calculator/elevate-offline-calculator/elevate-offline-calculator.component';
import { UpdateKycComponent } from './shared/components/sidemenu/update-kyc/update-kyc.component';
import { OldUploadDocumentComponent } from './modules/health/document-upload/old-upload-document/old-upload-document.component';
import { OrionPaymentLinkSummaryComponent } from './shared/components/payment-link/orion-payment-link-summary/orion-payment-link-summary.component';

const ROUTE_PATHS = routeEnums;

const routes: Routes = [
  // Uncomment abelow object to check functionality from login and comment swap
  {
    path: environment.uat ? ROUTE_PATHS.UAT : ROUTE_PATHS.ROOT,
    redirectTo: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.LOGIN}` : ROUTE_PATHS.LOGIN,
    pathMatch: 'full'
  },

  {
    path: ROUTE_PATHS.ROOT,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [

      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.DASHBOARD}` : ROUTE_PATHS.DASHBOARD,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },

      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.HEALTH}` : ROUTE_PATHS.HEALTH,
        loadChildren: () => import('./modules/health/health.module').then(m => m.HealthModule),
      },

      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.TRAVEL}` : ROUTE_PATHS.TRAVEL,
        loadChildren: () => import('./modules/health/products/travel/travel.module').then(m => m.TravelModule),
      },

      {
        // path: ROUTE_PATHS.DocumentUpload,
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.DocumentUpload}` : ROUTE_PATHS.DocumentUpload,
        component: DocumentUploadComponent
      },
      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.OldDocumentUpload}` : ROUTE_PATHS.OldDocumentUpload,
        component: OldUploadDocumentComponent
      },
      {
        path: ROUTE_PATHS.ZerotatInsuredDetails,
        component: ZeroTatInsuredDetailsComponent
      },
      {
        path: ROUTE_PATHS.HomeRenewalSummary,
        component: RenewalHomeSummaryComponent
      },
      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PendingPayment}` : ROUTE_PATHS.PendingPayment,
        component: PendingPaymentComponent
      },

      {
        path: ROUTE_PATHS.Renewals,
        component: RenewalsComponent
      },
      {
        path: ROUTE_PATHS.Create_Customer,
        component: KycDetailsComponent
      },
      {
        path: ROUTE_PATHS.InstantRenewal,
        component: InstantRenewalComponent
      },
      {
        path: ROUTE_PATHS.TravelSummaryDetails,
        component: TravelSummaryDetailsComponent
      },
      {
        path: ROUTE_PATHS.ZerotatRenewalSummary,
        component: RenewalZerotatSummaryComponent
      },
      {
        path: ROUTE_PATHS.RenewalSummary,
        component: NewRenewalSummaryComponent
      },
      {
        path: ROUTE_PATHS.Brochure,
        component: BrochureComponent
      },
      {
        path: ROUTE_PATHS.UpdateKYC,
        component: UpdateKycComponent
      },
      {
        path: ROUTE_PATHS.BookedBusinessReport,
        component: BookedBusinessReportComponent
      },
      {
        path: ROUTE_PATHS.HealthClaim,
        component: HealthClaimComponent
      },

      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.MyPolicies}` : ROUTE_PATHS.MyPolicies,
        component: MyPolicyComponent
      },

      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.SummaryAsync}` : ROUTE_PATHS.SummaryAsync,
        component: SummaryPageComponent,
      },
      {
        path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.QuickQuote}` : ROUTE_PATHS.QuickQuote,
        component: QuickQuoteComponent,
      }
    ]
  },

  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.LOGIN}` : ROUTE_PATHS.LOGIN,
    // canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },

  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.SWAP}` : ROUTE_PATHS.SWAP,
    loadChildren: () => import('./modules/swap/swap.module').then(m => m.SwapModule),
  },

  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PaymentConfirmation}` : ROUTE_PATHS.PaymentConfirmation,
    component: PaymentConfirmationComponent
  },

  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.OrionPaymentLinkSummary}` : ROUTE_PATHS.OrionPaymentLinkSummary,
    component: OrionPaymentLinkSummaryComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PaymentLinkSummary}` : ROUTE_PATHS.PaymentLinkSummary,
    component: PaymentLinkSummaryComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PendingPayment}` : ROUTE_PATHS.PendingPayment,
    component: PendingPaymentComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.MyPolicies}` : ROUTE_PATHS.MyPolicies,
    component: MyPolicyComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.SavedQuotes}` : ROUTE_PATHS.SavedQuotes,
    component: SavedQuotesComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PAY_IN_SLIP}` : ROUTE_PATHS.PAY_IN_SLIP,
    component: PayInSlipComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.PID}` : ROUTE_PATHS.PID,
    component: PidComponent
  },

  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.DEVELOPER}` : ROUTE_PATHS.DEVELOPER,
    loadChildren: () => import('./developer-dashboard/developer-dashboard.module').then(m => m.DeveloperDashboardModule),
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.CalculateEevate}` : ROUTE_PATHS.CalculateEevate,
    component: ElevateOfflineCalculatorComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.OrionPaymentLinkSummary}` : ROUTE_PATHS.OrionPaymentLinkSummary,
    component: OrionPaymentLinkSummaryComponent
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.WILDCARD}` : ROUTE_PATHS.WILDCARD,
    component: PagenotfoundComponent  //Temproary Componenet this needs to be replaced by page unavailable -->
  },
  {
    path: environment.uat ? `${ROUTE_PATHS.UAT}/${ROUTE_PATHS.OrionPaymentLinkSummary}` : ROUTE_PATHS.OrionPaymentLinkSummary,
    component: OrionPaymentLinkSummaryComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

// Use enums to declare modal that can be used between shared components
export enum patterns {
    email = "\\S+@\\S+\\.\\w+",
    number = '/^[0-9]+$/', //Use reg ex from constants
    text = '^[a-zA-Z ]+$',// Use regex from constants
    panNo = "[A-Z]{5}[0-9]{4}[A-Z]{1}",
    GSTIN = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
    UIN = '/^[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1}$/',
    pincode = '^[1-9][0-9]{5}$',
    weight = '^((?!(0))[0-9]{1,3})$',
    phoneNumber = '^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$',
    ificCode = '^[A-Z]{4}0[A-Z0-9]{6}$',
    accountNumber = '[0-9]{9,18}',
    heightFeetInches = '^[1-7][\.]([0-9]|1[0-1])$',
    alphabetsOnly = '^[a-zA-Z ]+$', //'^[a-zA-Z \-\']+',
    alphaNumric = "[a-zA-Z0-9 ]+"

}

export enum validationPatterns {
    required = 'required',
    pattern = 'pattern',
    minlength = 'minlength',
    maxlength = 'maxlength'
}


export enum healthProducts {
    hap = "Health AdvantEdge Plus",
    gs = "Golden Shield",
    CS = "Criti-Shield",
    asp = "Arogya Sanjeevani",
    maxProtect = "Max Protect",
    hb = "Health Booster",
    hep = "Health Elite Plus",
    elevate = 'Elevate'
}

export enum healthProposalProductCode {
    hap = "HADV",
    gs = "HGS",
    asp = "ASP",
    cs = "CS",
    maxProtect = "Max Protect",
    hb = "HBooster",
    hep = "CHI",
    fs = "FS",
    elevate = 'ELEV'
}

export enum zoneDetails {
    Zone1 = 'Zone_I',
    Zone2 = 'Zone_II',
    Zone3 = 'Zone_III',
}

export enum zoneRefillingDetails {
    Zone_1 = 'Zone A',
    Zone_2 = 'Zone B',
    Zone_3 = 'Zone C',
    Zone_4 = 'Zone D',
}

export enum zoneHEPDetails {
    Zone1 = 'Zone_A',
    Zone2 = 'Zone_B',
    Zone3 = 'Zone_C',
    Zone4 = 'Zone_D',
}
//For renewals
export enum HEPZone {
    rnFetchZone1 = 'Zone A',
    rnFetchZone2 = 'Zone B',
    rnFetchZone3 = 'Zone C',
    rnFetchZone4 = 'Zone D',
    proposalZone1 = 'Zone A',
    proposalZone2 = 'Zone B',
    proposalZone3 = 'Zone C',
    proposalZone4 = 'Zone D',
    recalculateZone1 = 'Zone A',
    recalculateZone2 = 'Zone B',
    recalculateZone3 = 'Zone C',
    recalculateZone4 = 'Zone D',
    getZoneZone1 = 'Zone A',
    getZoneZone2 = 'Zone B',
    getZoneZone3 = 'Zone C',
    getZoneZone4 = 'Zone D',
}
export enum HAPRefilingZone {
    getZoneZone1 = 'ZONE A',
    getZoneZone2 = 'ZONE B',
    getZoneZone3 = 'ZONE C',
    getZoneZone4 = 'ZONE D',
    rnFetchZone1 = 'Zone A',
    rnFetchZone2 = 'Zone B',
    rnFetchZone3 = 'Zone C',
    rnFetchZone4 = 'Zone D',
}

export enum MaxZone {
    getZone1 = 'Zone A',
    getZone2 = 'Zone B',
    getZone3 = 'Zone C',
    getZone4 = 'Zone D'
}

export enum HAPZone {
    rnFetchZone1 = 'Zone_I',
    rnFetchZone2 = 'Zone_II',
    rnFetchZone3 = 'Zone_III',
    rnFetchZone4 = 'Zone_IV',
    proposalZone1 = 'Zone 1',
    proposalZone2 = 'Zone 2',
    proposalZone3 = 'Zone 3',
    proposalZone4 = 'Zone 4',
    recalculateZone1 = 'Zone I',
    recalculateZone2 = 'Zone II',
    recalculateZone3 = 'Zone III',
    recalculateZone4 = 'Zone IV',
    getZoneZone1 = 'ZONE A',
    getZoneZone2 = 'ZONE B',
    getZoneZone3 = 'ZONE C',
    getZoneZone4 = 'ZONE D',
}

export enum MPZone {
    Zone1 = 'Zone A',
    Zone2 = 'Zone B',
    Zone3 = 'Zone C',
    Zone4 = 'Zone D',
}

export enum GSZone {
    rnFetchZone1 = 'Zone I',
    rnFetchZone2 = 'Zone II',
    rnFetchZone3 = 'Zone III',
    proposalZone1 = 'Zone 1',
    proposalZone2 = 'Zone 2',
    proposalZone3 = 'Zone 3',
    recalculateZone1 = 'Zone I',
    recalculateZone2 = 'Zone II',
    recalculateZone3 = 'Zone III',
    getZoneZone1 = 'ZONE 1',
    getZoneZone2 = 'ZONE 2',
    getZoneZone3 = 'ZONE 3'
}
export enum rnZones {
    zone1 = 'Zone I',
    zone2 = 'Zone II',
    zone3 = 'Zone III',
    zone4 = 'Zone IV'
}
export enum healthToggle {
    Yes = 'Yes',
    No = 'No',
}
export enum healthMembers {
    Adult = 'Adult',
    Child = 'Child',
}
export enum healthProductType {
    New = 'New',
    Portability = 'Portability',
}

export enum healthYears {
    OneYear = '1 Years',
    TwoYear = '2 Years',
    ThreeYear = '3 Years',
    FourYear = '4 Years',
}

export enum healthDays {
    ThirtyDays = '30 Days',
    SixtyDays = '60 Days',
    NinetyDays = '90 Days',
    OneTwentyDays = '120 Days',
    OneEightyDays = '180 Days',
}

export enum UserType {
    Agent = 'Agent',
    Customer = 'Customer',
}

export enum ChildDOBType {
    Adult1Normal = 'Adult1Normal',
    Child1Adult0 = 'Child1Adult0',
    ChildDepRelation = 'ChildDepRelation',
    ChildIndepenRelation = 'ChildIndepenRelation'
}

// If you make any changes in insured details json do make changes here also
export enum insuredControls {
    applicantRelationship = 'applicantRelationship',
    title = 'title',
    fullName = 'fullName',
    dob = 'dob',
    heightFeetInches = 'feetInchesHeight',
    weight = 'weight',
    pneumoccalDOB = 'pneumoccalDOB',
    previouslyInsured = 'previouslyInsured',
    sumInsured = 'sumInsured',
    insuredSince = 'insuredSince',
    waiverPeriod = 'waiverPeriod',
    existingHIP = "existingHIP",
    typeOfPolicyHB = "typeOfPolicyHB",
    policyPeriodHB = "policyPeriodHB",
    sumInsuredHB = "sumInsuredHB",
    insuranceCompanyNameHB = "insuranceCompanyNameHB",
    preExistinDisease = 'preExistinDisease',
    gender = 'gender',
    pendingCriticalIllnessInsurance = "pendingCriticalIllnessInsurance",
    otherCriticalIllnesspolicy = "otherCriticalIllnesspolicy",
    remarkCs = "remarkCs",
    remarkCriti = "remarkCriti",
    motherName = 'motherName',
    nomineeName = 'nomineeName'

}

export enum insuredControls1 {
    ailment = 'ailment'
}

// If you make any changes in portability details json do make changes here also
export enum portabilityControls {
    previousPolicyClaims = 'previousPolicyClaims',
    previousPolicyPED = 'previousPolicyPED',
    typeOfPolicy = 'typeOfPolicy',
    insuranceCompanyName = 'insuranceCompanyName',
    portabilityReason = 'portabilityReason',
    policyNumber = 'policyNumber',
    policyStartDate = 'policyStartDate',
    policyEndDate = 'policyEndDate',
    premiumAmount = 'premiumAmount',
    portabilitySumInsured = 'portabilitySumInsured'
}

// Kyc Controls
// If you make any changes in kyc details json do make changes here also
export enum kycControls {
    // applicant details
    applicantPanCardNumber = 'applicantPanCardNumber',
    applicantTitle = 'applicantTitle',
    applicantFullName = 'applicantFullName',
    applicantDOB = 'applicantDOB',
    // correspondence address
    correspondenceAddress1 = 'correspondenceAddress1',
    correspondenceAddress2 = 'correspondenceAddress2',
    correspondenceLandmark = 'correspondenceLandmark',
    correspondencePincode = 'correspondencePincode',
    correspondenceState = 'correspondenceState',
    correspondenceCity = 'correspondenceCity',
    // permanent address
    permanentAddress1 = 'permanentAddress1',
    permanentAddress2 = 'permanentAddress2',
    permanentLandmark = 'permanentLandmark',
    permanentPincode = 'permanentPincode',
    permanentState = 'permanentState',
    permanentCity = 'permanentCity',
    permanentMaritalStatus = 'permanentMaritalStatus',
    permanentEmail = 'permanentEmail',
    permanentMobileNumber = 'permanentMobileNumber',
    permanentSameAddress = 'permanentSameAddress',
    // other details
    gstRegistered = 'gstRegistered',
    business = 'business',
    panNumber = 'panNumber',
    otherCustomerType = 'otherCustomerType',
    gstStatus = 'gstStatus',
    gstApplicable = 'gstApplicable',
    gstin = 'gstin',
    uin = 'uin',
    riskStartDate = 'riskStartDate',
    // Nominee Details
    nomineeTitle = 'nomineeTitle',
    nomineeFullName = 'nomineeFullName',
    nomineeRelationship = 'nomineeRelationship',
    nomineeDOB = 'nomineeDOB',
    // Apointee Details
    appointeeTitle = 'appointeeTitle',
    appointeeFullName = 'appointeeFullName',
    appointeeRelationship = 'appointeeRelationship',
    appointeeDOB = 'appointeeDOB',
    // customer type
    customerType = 'customerType',
    // search customer
    customerName = 'customerName',
    customerEmail = 'customerEmail',
    customerMobile = 'customerMobile',
    pfCustomerID = 'pfCustomerID',
    customerList = 'customerList',
    or = 'or',
    // form groups
    applicantDetails = 'applicantDetails',
    correspondenceAddress = 'correspondenceAddress',
    permanentAddress = 'permanentAddress',
    otherDetails = 'otherDetails',
    nomineeDetails = 'nomineeDetails',
    appointeeDetails = 'appointeeDetails',
    searchCustomer = 'searchCustomer',
    //other details field for HB
    insuredHIP = 'insuredHIP',
    taxExemptionHB = 'taxExemptionHB',

    isNomineeSameAsSponsor = 'isNomineeSameAsSponsor',
    nomineeName = 'nomineeName',

    sponsorType = 'sponsorType',
    sponsorTitle = 'sponsorTitle',
    sponsorName = 'sponsorName'

}

export enum sponsorType {
    individual = "Individual",
    institution = "Institution"
}

// Field Type
export enum fieldTypeDetails {
    input = "input",
    datepicker = "datepicker",
    dropdown = "dropdown",
    radio = "radio",
    textarea = "textarea"
}


export enum policyType {
    Individual = "Individual",
    Floater = "Floater"
}

export enum insuredDiseaseID {
    Fourty = 40,
    FourtyOne = 41
}

export enum remarkList {
    Remark = 'remark',
    Remark1 = 'remark1',
    Remark2 = 'remark2'
}

export enum gender {
    Male = 'male',
    Female = 'female',
    ThirdGender = 'Third Gender'
}

export enum genderShortCut {
    Male = 'M',
    Female = 'F'
}

export enum maritalSatus {
    Single = 'single',
    Married = 'married'
}

export enum addOnCovers {
    AddOn3 = "Maternity cover",
    AddOn4 = "New born",
    AddOn5 = "Vaccination for new born",
    AddOn6 = "Domestic air ambulance",
    AddOn8 = "Personal accident",
    AddOn10 = "Tele Consultations",
    AddOn11 = "World wide cover",
    AddOn12 = "Sum Insured Protector",
    AddOn13 = "Claim Protector"
}

export enum HBAddOns {
    AddOn1 = "Optional Add-on Covers 1 (All Members)",
    AddOn2 = "Optional Add-on Covers 2 (Member Basis)",
    AddOn3 = "Optional Add-on Covers 3 (Member Basis)",
}

export enum RelationShipGender {
    Male = "Male",
    Female = "Fmale",
    Neutral = "MF"
}

export enum UWStatusEnum {
    A = "Pending for Counter Offer Acceptance",
    B = "Counter Offer Accepted",
    C = "Rejected",
};

export enum KycUserType {
    Coorperate = 'C',
    Individual = 'I'
}

export enum ProductType {
    ZeroTat = 'ZeroTat',
    Indemnity = 'Indemnity',
    Travel = 'Travel',
}

export enum ProductCode {
    HAP = 42,
    MaxProtect = 34,
    GS = 40,
    ASP = 22,
    HB = 20,
    HEP = 33,
    FS = 23,
    CS = 38,
    Elevate = 45
}

// Refiled Addons Enums
export enum RefiledHapAddons {
    CriticalIllness = 67,
    Maternity = 68,
    NewBorn = 69,
    VaccinationNewBorn = 70,
    PersonalAccident = 71,
    Befit = 0, // just for FE
    SumInsured = 72,
    ClaimProtector = 73,
    CompassionateVisit = 74,
    NursingAtHome = 75,
    BefitA = 76,
    BefitB = 77,
    BefitC = 78,
    BefitD = 79,
    BefitE = 80,
    BefitF = 81,
    BefitG = 82,
    BefitH = 83
}

export enum relationEnum {
    Self = 'SELF',
    Spouse = 'SPOUSE'
}

export enum titleEnum {
    Mrs = '0',
    Ms = '2'
}

export enum GenderMapping {
    Male = 'male',
    Female = 'fmale',
    MF = 'mf'
}

export enum PortabilityReason {
    ClaimNotHandled = 'Claim not handled properly',
    lowerClaimNotHandled = 'claim not handled properly'
}
export enum popupType {
    generic = 'generic',
    premiumSummary = 'premiumSummary',
    zeroTatpremiumSummary = 'zeroTatpremiumSummary',
    confirmation = 'confirmation',
    payment = 'payment',
    editPortability = 'editPortability',
    chequeStatus = 'chequeStatus',
    email = "email",
    riskCategory = 'riskCategory',
    uploadDox = 'uploadDocx',
    renewalSummary = 'renewalSummary',
    travelPremiumSummary = 'travelSummary',
    salesGenie = 'salesGenie',
    declarations = 'declarations',
    search = 'search',
    filter = 'filter',
    hapRefillingSummary = 'hapRefillingSummary',
    travel = 'travel',
    AML = 'AML',
    claimStatus = 'claimStatus',
    ipro = 'ipro',
    travelPaymentLink = 'travelPaymentLink',
    checkBoxPopup = 'checkBoxPopup'
}


export enum popupDescriptionMsg {
    kycIncomplete = 'Kindly complete E-KYC/C-KYC to proceed further.',
    pfQuoteId = 'Your PF quote ID is: ',
    paymentLinkSuccess = 'Payment Link sent successfully on registered ',
    proposalId = 'Your Proposal number is: ',
    pedDescription = 'Do you really want to proceed this without selecting any PED?',
    refreshDescription = 'Are you sure you want to refresh data?',
    sessionExpired = 'Your session has expired.Please login to continue...',
    selectCover = 'Please select cover for Member',
    backButtonDescription = 'Do you want to proceed back?',
    backButtonLogoutDescription = 'Are you sure you want to Logout?',
    savedQuotesSuccessMessage = 'Your PF Quote Id is',
    planDeleteDescription = 'Are you sure you want to delete the plan?',
    backButtonDashboardDescription = 'Are you sure you want to Dashboard?',
    emiNotApplicable = 'You are not Eligible for EMI',
    memberPopup = 'Select the member you want to remove',
    cibilKYC = 'Cibil & KYC details cannot be different',
    maternityKycPopup = 'You have opted maternity cover for applicant. Please select title only Mrs., Ms., Mx.',
    emailSuccess = 'Email sent successfully on registered ',
    cibilDiscount = 'Please click on save button before proceeding',
    querySuccess = 'Query run successful!',
    jsonConvertError = 'Cannot beautify to JSON',
    xmlConvertError = 'Cannot beautify to XML',
    createCustomer = 'PF Customer ID is ',
    noPriviledges = 'You don\'t have privilege to run this query',
    dealIDNotMapped = 'You are not authorized to sell this product. Kindly get the product mapped in order to start selling this product.',
    kycAgain = 'Please do kyc again in order to proceed further.',
    cibilNotEligible = 'You are not eligible for Cibil Discount',
    cibilEligible = 'You are eligible for Cibil Discount',
    selfErrorMsg = 'SELF can be selected only for one Insured',
    zonePopupError = 'Zone cannot be different',
    amlPopupMsg = 'I hereby confirm that I have read and understood the AML Link & Code of Conduct Link applicable to Insurance advisors as specified by the company and I agree and undertake to be bound by the same.',
    requiredDetails = 'Kindly fill the required details to proceed',
    // requiredSaveDetails = 'Kindly fill the required details and save them to proceed.',
    requiredSaveDetails = 'Please click on “Save” after entering all required details. Also kindly read and accept declarations.',
    pincodeError = "Error! There is a mismatch between your KYC location or risk location or mailing location's pincode and city",
    csLargerSI = "This Proposals needs Financial underwriting , please connect to concern Product UW",
    pilotUserMsg = "Kindly complete your KYC verification using a govt. approved ID proof.",
    jumpStartDescription = "Please disclose any pre-existing conditions for insured members, as you have chosen 'Jumpstart"
}

export enum popupImgPath {
    alertGif = 'assets\\images\\alert.gif',
    successGif = 'assets\\images\\completed.gif',
    warningGif = 'assets\\images\\verifyIndicator.gif',
    imgName = "imgName",
}


export enum popupHeader {
    error = 'Error',
    quoteSaved = 'Quote saved successfully',
    success = 'Success',
    pedNotSelected = 'You have not selected any PED for',
    refreshData = 'Refresh',
    sessionExpired = 'Session Timeout.',
    backbuttonpressed = 'You have pressed back button',
    confirmlogout = 'Logout',
    confirmDashboard = 'Dashboard',
    quoteSuccess = 'Quote Saved Successfully!',
    confirmdelete = 'Delete',
    cibilPopup = 'Cibil Discount',
    customerCreated = 'Customer created successfully',
    kycSuccess = 'KYC updated successfully'
}

export enum popupButton {
    ok = 'OK',
    cancel = 'Cancel',
    sendLink = ''
}

export enum popupButtonAction {
    close = 'close',
    logout = 'logout',
    dashboard = 'dashboard',
    memberIndex = 'apply',
    quote = 'quote',
    emitSendPaymentLink = 'sendPaymentLink'
}

export enum popupChequeStatus {
    confirmed = 'CONFIRMED',
    submitted = 'SUBMITTED',
    accepted = 'ACCEPTED',
    cancelled = 'CANCELLED'
}
